<div class="grid grid-cols-3 container mx-auto">
  <div class="col-span-2">
    <h1 class="text-8xl font-bold mb-20 mt-10">
      <span class="text-gradient bg-gradient-to-r from-pink-500 via-purple-600 to-indigo-500">
        {{isAuthenticated ? 'Select a workspace' : 'Welcome to Grok'}}
      </span>
    </h1>

    <ng-container *ngIf="isAuthenticated">
      <ng-container *ngFor="let item of sections; let i = index">
        <div class="mt-10 flex items-center justify-start gap-10 cursor-pointer border-0 hover:border-0 focus:border-0" (click)="routeTo(item.path)">
          <span class="font-bold text-3xl text-white bg-indigo-500 rounded-full h-20 w-20 flex items-center justify-center">{{i + 1}}</span>
          <span class="font-bold text-3xl text-indigo-500">{{item.title}}</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!isAuthenticated">
      <div class="text-gray-600 bg-white shadow-md p-8 rounded-xl">
        <p class="text-3xl font-semibold mb-10">You are not authorised to use this application.</p>
        <p class="text-xl text-gray-400 mb-10">
          Use the below button and login to the LMS with your registered username and password.
        </p>
        <button class="bg-button text-white p-4 text-xl font-medium rounded-md shadow-md mb-5" (click)="login()">LMS LOGIN</button>
        <p class="text-md text-gray-400 mb-10">Please contact the admin to register.</p>
      </div>
    </ng-container>
  </div>
</div>
