<div class="bg-gradient-to-b from-pink-100 via-pink-50 to-indigo-50 py-10">

  <form class="p-4" [formGroup]="loginForm" (submit)="onLogin()">
    <img src="../../../../assets/img/logo.png" class="h-10 mx-auto" alt="">
    <div class="flex justify-evenly text-left my-8">
      <h4 class="text-3xl sm:text-2xl font-semibold text-black-500 tracking-wide">
        Login
      </h4>
    </div>
    <div class="flex flex-wrap justify-evenly -mx-3 mb-6 my-4">
      <div class="w-full md:w-full px-3 mb-6">
        <label class="block tracking-wide text-gray-700 text-sm font-bold" for='username'>Username</label>
        <input
          class="appearance-none block w-full bg-transparent text-gray-900 font-medium border-0 border-b-2 border-gray-400 py-3 px-1 leading-tight focus:outline-none focus:bg-transparent"
          type='text' formControlName="username" placeholder="Username" name="username" id="username" autocomplete="false"
          [ngClass]="{ 'is-invalid': isSubmitted && f.username.errors }">
          <div *ngIf="isSubmitted && f.username.errors" class="invalid-feedback">
            <div class="text-red-600 text-xs" *ngIf="f.username.errors.required">Username is required</div>
          </div>
      </div>
      <div class="w-full md:w-full px-3 mb-6">
        <label class="block tracking-wide text-gray-700 text-sm font-bold" for='Password'>Password</label>
        <input
          class="appearance-none block w-full bg-transparent text-gray-900 font-medium border-0 rounded-bl-none rounded-br border-b-2 border-gray-400 rounded-lg py-3 px-1 leading-tight focus:outline-none"
          formControlName="password" name="password" placeholder="Password" id="password" type='password' autocomplete="false"
          [ngClass]="{ 'is-invalid': isSubmitted && f.password.errors }" minlength="6" maxlength="25" >
        <div *ngIf="isSubmitted && f.password.errors" class="invalid-feedback">
          <div class="text-red-600 text-xs" *ngIf="f.password.errors.required">Password is required</div>
          <div class="text-red-600 text-xs" *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
          <div class="text-red-600 text-xs" *ngIf="f.password.errors.maxlength">Password must not be greator than 25 characters
          </div>
        </div>
      </div>
      <!-- <div class="w-full flex items-center justify-between px-3 mb-6 ">
        <label for="remember" class="w-full inline-flex items-center w-1">
          <input type="checkbox" name="" id="" class="mr-5 bg-white shadow w-5 h-5">
          <span class="w-full text-sm font-bold text-gray-700 pt-1">Remember Me</span>
        </label>
      </div> -->
      <div class="items-center flex justify-evenly w-full px-3 mb-10 mt-10">
        <button type="submit" class="appearance-none block w-full bg-button text-gray-100
          font-bold border border-gray-200 rounded-lg py-3 px-3
          leading-tight hover:bg-pink-600 focus:outline-none
          focus:bg-purple focus:border-gray-600">
          <div class="flex justify-center gap-2">
            LOGIN
            <img src="assets/img/spinner.svg"  *ngIf="showLoader"  class="animate-spin items-center">
          </div>
        </button>
      </div>
      <!-- <div class="mt-2 text-black-500 text-sm w-full block align-middle text-center justify-self-auto px-3 mb-6">
        Don't have an account?<span class="text-pink-500 px-2 text-sm font-bold" (click)="onSignup()"> SIGN UP</span>
      </div> -->
    </div>
  </form>

  <div class="absolute flex bottom-0 w-full" *ngIf="showErrorMessage">
    <div class="w-full text-white bg-red-400 shadow-lg rounded-lg p-2 m-4 text-xs flex justify-between">
      <div>{{errorMessage}}</div>
      <span aria-hidden="true" class="cursor-pointer" (click)="clearErrorMessage()">&times;</span>
    </div>
  </div>

</div>
