<div class="w-full h-screen">
  <as-split
    direction="horizontal"
    (dragStart)="onDrag($event); repairBlockly()"
    (dragEnd)="repairBlockly()"
    (drag)="repairBlockly()"
    (drop)="repairBlockly()"
    [gutterSize]="5"
  >
    <as-split-area [size]="100" [minSize]="25" [maxSize]="75" *ngIf="hasAppId">
      <div class="w-full h-screen relative">
        <div class="">
          <app-blockly
            [toolbox]="toolbox"
            [isCompareResult]="isCompareResult"
            (emitGeneratedCode)="onCode($event)"
            [isReadOnly]="true"
            [toggle]="true"
            (tutorials)="setToturials($event)"
            (moduleNameEmitter)="setModuleName($event)"
            (isBlockWithSimulator)="toggleBlockState($event)"
            [sizeChanged]="size"
            (videoTutorial)="onClkVideo($event)"
            (applicationdata)="getAppData($event)"
            (leftBlockCodeEmitter)="leftBlockReceiverEvent($event)"
            (compareBlockEmitter)="compareBlockEvent($event)"
            (resetCompareBlockResultEmitter)="resetCompareBlockResult($event)"
            [parentComponent]="'BlockProgrammingComponent-IDE'"
          >
          </app-blockly>
        </div>
      </div>
    </as-split-area>
    <as-split-area [size]="100" [minSize]="25" [maxSize]="75" *ngIf="true">
      <div class="w-full h-full bg-white relative">
        <div class="box">
          <app-blockly-section
            [(switchType)]="blockType"
            [code]="code"
            (emitXMLEvent)="saveXML($event)"
            [toggle]="toggle"
            (emitBlockState)="toggleBlockState($event)"
            [selectedApplication]="selectedApplication"
            [moduleKey]="moduleKey"
            [isAdmin]="false"
            [isCompareResult]="isCompareResult"
            (rightBlockCodeEmitter)="rightBlockReceiverEvent($event)"
            (compareBlockEmitter)="compareBlockEvent($event)"
            (resetCompareBlockResultEmitter)="resetCompareBlockResult($event)"
            [parentComponent]="'BlockProgrammingComponent-IDE'"
          ></app-blockly-section>
        </div>
        <div class="flex floating-button justify-end py-2 box-1 my-2">
          <button
            class="blockly-panel-btn"
            *ngIf="isDeveloperMode"
            (click)="printXML()"
          >
            Print XML
          </button>

          <button class="blockly-panel-btn" (click)="switch()">
            SWITCH TO
            {{
              blockType?.toLowerCase() == this.codeName?.toLowerCase()
                ? this.blockName
                : this.codeName
            }}
          </button>

          <div
            *ngIf="isRedirectUrl && false"
            class="relative inline-block text-left"
          >
            <div>
              <button
                (click)="toggleOptions(!showSelectOptions)"
                type="button"
                class="inline-flex justify-center w-full rounded-lg px-4 py-2 bg-button text-md font-medium text-white focus:outline-none"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                OPTIONS
                <svg
                  class="-mr-1 ml-2 h-5 w-5 mt-0.5"
                  style="margin-top: 4px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div
              *ngIf="showSelectOptions"
              style="z-index: 60"
              class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <div
                  class="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-button"
                  (click)="selectOption('create_new'); createNew()"
                  id="menu-item-0"
                >
                  Create New
                </div>
                <div
                  class="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-button"
                  (click)="selectOption('get_block'); openSideBar()"
                  id="menu-item-1"
                >
                  Get Block
                </div>
                <div
                  class="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-button"
                  (click)="selectOption('save_block'); openSaveSideBar()"
                  id="menu-item-2"
                >
                  {{ selectedApplicationIdentifier ? "Update" : "Save" }} Block
                </div>
              </div>
            </div>
          </div>
          <!-- Action button like undo,redo, zoomIn, zoomOut Focus -->
          <div
            *ngIf="
              blockType?.toLowerCase() == this.codeName?.toLowerCase()
                ? false
                : true
            "
            class="floating-button justify-end py-2 box-1 action-buttons"
          >
            <button (click)="zoomIn($event)" class="wd-48">
              <img
                class="img"
                src="assets/img/blockly/zoom-in.svg"
                title="Zoom In"
              />
            </button>
            <hr />
            <button (click)="zoomOut($event)" class="wd-48">
              <img
                class="img"
                src="assets/img/blockly/zoom-out.svg"
                title="Zoom Out"
              />
            </button>
            <hr />
            <button (click)="focus()" class="wd-48">
              <img
                class="img"
                src="assets/img/blockly/recenter.svg"
                title="Focus"
              />
            </button>
            <hr />
            <button (click)="undo()" class="wd-48">
              <img class="img" src="assets/img/blockly/undo.svg" title="Undo" />
            </button>
            <hr />
            <button (click)="redo()" class="wd-48">
              <img class="img" src="assets/img/blockly/redo.svg" title="Redo" />
            </button>
            <hr />
            <button (click)="delete()" class="wd-48">
              <img
                class="img"
                src="assets/img/blockly/delete.svg"
                title="Delete"
              />
            </button>
          </div>
        </div>
        <br />
          <div class="flex floating-button justify-end py-2 box-1 my-2">
            <button class="blockly-panel-btn hide-block" (click)="hideRef()">
              {{ isHide ? "Show": "Hide"}}
            </button>
          </div>
      </div>
    </as-split-area>
  </as-split>
</div>

<!-- <div *ngIf="isTemplate"> -->
  <div >
  <div
    class="p-2 absolute bottom-5 left-2 rounded-full"
    (click)="startTour()"
    style="z-index: 100 !important"
    *ngIf="isTemplate" 
    [class.cursor-pointer]="!isTutorialsDisabled"
    [class.cursor-no-drop]="isTutorialsDisabled"
    >
    <img id="infoImg"
      class="hidden-info h-10 w-10"
      [src]="isTutorialsDisabled ? 'assets/img/info-gray.svg' : 'assets/img/info.svg'"
      title="{{title}}"
      />
  </div>
</div>

<ng-container *ngIf="infoSteps">
  <ng-container *ngFor="let step of infoSteps; let i = index">
    <div
      [title]="step?.value || step?.title"
      joyrideStep="{{getJoyId(i,step)}}"
      [prevTemplate]="preButton"
      class="absolute"
      [class]="step.location"
      [stepPosition]="step.direction"
      [nextTemplate]="nextButton"
      [stepContent]="step.type === 'video' ? videoTutorial : undefined"
      [stepContentParams]="{ tutorialUrl: step?.url }"
      [doneTemplate]="doneButton"
    ></div>
  </ng-container>
</ng-container>

<ng-template #preButton>
  <button class="reverse-next-img"><img src="assets/img/next.svg" /></button>
</ng-template>
<ng-template #nextButton>
  <button><img src="assets/img/next.svg" /></button>
</ng-template>
<ng-template #doneButton>
  <button class="font-bold text-xs">END</button>
</ng-template>
<ng-template #videoTutorial let-tutorialUrl="tutorialUrl" class="w-full">
  <iframe [src]="tutorialUrl"></iframe>
</ng-template>

<app-side-panel
  *ngIf="showSideBarList"
  (emitCloseEvent)="sidePanelClosed($event)"
>
  <p class="text-2xl font-bold mb-4">Get Block Program</p>
  <app-save-list
    (saveDataEvent)="getApplicationData($event)"
    [applications]="applications$ | async"
  ></app-save-list>
</app-side-panel>

<app-side-panel
  *ngIf="showAddBlockBarList"
  (emitCloseEvent)="sidePanelClosed($event)"
>
  <p class="text-2xl font-bold mb-4">
    {{ selectedApplicationIdentifier ? "Update" : "Save" }} Block Program
  </p>
  <app-save-block-circuit
    [selectedApplication]="selectedApplication"
    (saveNewBlockEvent)="updateNewBlock($event)"
    (emitCancelEvent)="sidePanelClosed($event)"
  >
  </app-save-block-circuit>
</app-side-panel>

<div *ngIf="isVideo">
  <ng-container >
    <div style="z-index: 600;" class="w-full min-w-screen h-screen animated fadeIn faster fixed  left-0 top-0 flex justify-center items-center inset-0 outline-none focus:outline-none bg-no-repeat bg-center bg-color bg-cover">
          <div (click)="videoClose()" class="btn-close" > <img src="assets/img/icons/xmark-solid-1.svg"></div>
          <iframe style="height:530px; width: 992px; " [src]="videoUrl | safe"   allow="autoplay; picture-in-picture encrypted-media;"  allowfullscreen></iframe>
    </div>
  </ng-container>
</div>

<app-modal [modalType]="'new_variable'"></app-modal>