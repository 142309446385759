import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, finalize, map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IAppState } from '../index.state';
import { showSpinner } from '../layout/layout.action';
import { authenticateError, authenticateSuccess, authenticateUser, setUser } from './auth.action';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authenticationService: AuthService,
    private router: Router,
    private store: Store<IAppState>
  ) { }

  authenticateUser$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(authenticateUser),
        exhaustMap((payload: any) => {
          const {username, password} = payload.payload;
          return this.authenticationService.authenticateLMS(username, password).pipe(
            map((data: any) => {
              if (data.status) {
                sessionStorage.setItem('token', data.session_id);
                this.router.navigateByUrl('/admin');
                return setUser({ user: data });
              } else {
                throw Error(data);
              }
            }),
            map((data) => authenticateSuccess({payload: data})),
            finalize(() => this.store.dispatch(showSpinner({value: false}))),
            catchError(error => of(authenticateError({ error }))))
        })
    );
  });
}
