import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IDEService } from 'src/app/shared/services/ide.service';

@Component({
  selector: 'app-edit-user-device',
  templateUrl: './edit-user-device.component.html',
  styleUrls: ['./edit-user-device.component.scss']
})
export class EditUserDeviceComponent implements OnInit {

  @Input() device!: any;
  @Output() emitCancelEvent = new EventEmitter();
  deviceName!: string;
  deviceToken!: string;

  constructor(private ideService: IDEService) { }

  ngOnInit(): void {
    this.deviceName = this.device.deviceName;
    this.deviceToken = this.device.deviceToken;
  }

  async onSubmit() {
    const device_name = this.deviceName;
    const token = this.deviceToken;
    try {
      const payload: any = { token, device_name };
      const result: any = await this.ideService.editDevice(payload).toPromise();
      if (result) {
        this.cancel();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async active(selectedDevice: any) {
    const active = this.device.active;
    const token = this.deviceToken;
    // const { deviceToken, active } = selectedDevice;
    const payload: any = { token, active };
    try {
      const result: any = await this.ideService.activeDevice(payload).toPromise();
      if (result) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }

  }

  cancel() {
    this.emitCancelEvent.emit(true);
  }
}
