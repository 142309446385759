import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IApplication } from "../models/application";

/**
 * Service class to handle data and http request related to Application and Application-Template APIs.
 */
@Injectable({
  providedIn: "root",
})
export class ApplicationService {
  constructor(private http: HttpClient) { }

  /* GET Requests (single) */

  /**
   * 1. Method to get the TEMPLATE of an application.
   * @param applicationIdentifier
   */
  getApplicationTemp(applicationIdentifier: any) {
    // const url = `${environment.apiURL}/admin/application-template/get`;
    const url = `${environment.apiURL}/admin/application-template/v2/get`;
    return this.http.get<IApplication>(url, {
      params: { application_identifier: applicationIdentifier },
    });
  }

  /*
  * Deprecated 
  */
  // getApplicationTempV3(applicationIdentifier: any) {
  //   const url = `${environment.apiURL}/admin/application-template/v3/get`;
  //   return this.http.get<IApplication>(url, {
  //     params: { application_identifier: applicationIdentifier },
  //   });
  // }

  async getApplicationTemplateV3(applicationIdentifier: any) {
    const url = `${environment.apiURL}/admin/application-template/v3/get`;
    let templateData = await this.http.get<{ Application: IApplication }>(url, {
      params: { application_identifier: applicationIdentifier },
    }).toPromise();

    templateData.Application = await this.fetchDefinitionDataFromUrls(templateData?.Application);
    templateData.Application = await this.cleanDefinitionUrls(templateData?.Application);

    return templateData;
  }

  async fetchDefinitionDataFromUrls(app: IApplication): Promise<IApplication> {
    if (!app) {
      return app;
    }

    if (app.applicationDefinition_url) {
      app.applicationDefinition = await this.getData(app.applicationDefinition_url).toPromise();
    }
    if (app.configurationDefinition_url) {
      app.configurationDefinition = await this.getData(app.configurationDefinition_url).toPromise();
    }
    if (app.simulatorDefinition_url) {
      app.simulatorDefinition = await this.getData(app.simulatorDefinition_url).toPromise();
    }
    if (app.toolboxDefinition_url) {
      app.toolboxDefinition = await this.getData(app.toolboxDefinition_url).toPromise();
    }
    if (app.pythonInterfaceDefinition_url) {
      app.pythonInterfaceDefinition = await this.getData(app.pythonInterfaceDefinition_url).toPromise();
    }

    return app;
  }

  async cleanDefinitionUrls(app: IApplication): Promise<IApplication> {
    if (!app) {
      return app;
    }

    if (app.hasOwnProperty('configurationDefinition_url')) {
      delete app['configurationDefinition_url'];
    }
    if (app.hasOwnProperty('applicationDefinition_url')) {
      delete app['applicationDefinition_url'];
    }
    if (app.hasOwnProperty('toolboxDefinition_url')) {
      delete app['toolboxDefinition_url'];
    }
    if (app.hasOwnProperty('simulatorDefinition_url')) {
      delete app['simulatorDefinition_url'];
    }
    if (app.hasOwnProperty('pythonInterfaceDefinition_url')) {
      delete app['pythonInterfaceDefinition_url'];
    }

    return app;
  }

  getData(dataUrl: any) {
    const url = dataUrl;
    return this.http.get(url, { responseType: 'text' });
  }
  /**
   * Method to get particular APPLICATION.
   * @param applicationIdentifier
   */
  getApplication(applicationIdentifier: any) {
    const url = `${environment.apiURL}/admin/application/v2/get`;
    return this.http.get<IApplication>(url, {
      params: { application_identifier: applicationIdentifier },
    });
  }

  /* GET Requests (ALL) */

  /**
   * 1. Method to list all APPLICATION-TEMPLATE.
   * @param organizationID
   */
  getAllApplicationTemp(organization_id: string, module_name: string = "") {
    const url = `${environment.apiURL}/admin/application-template/get-all`;
    return this.http.get<IApplication>(url, {
      params: {
        organization_id,
        module_name,
      },
    });
  }

  /**
   * 2. Method to list all APPLICATIONS.
   * @param organizationID
   * @param app_user_id
   */
  getAllApplication(organization_id: string, app_user_id: any, module_name: string = "") {
    const url = `${environment.apiURL}/admin/application/get-all`;
    return this.http.get<IApplication>(url, {
      params: {
        organization_id,
        app_user_id,
        module_name,
      },
    });
  }

  /** POST Requests (create) */
  /**
   * 1. Method to create a new APPLICATION-TEMPLATE.
   * @param applicationTemp
   */
  createApplicationTemp(applicationTemp: IApplication) {
    const url = `${environment.apiURL}/admin/application-template/create`;
    return this.http.post<IApplication>(url, applicationTemp);
  }

  /**
   * 2. Method to create a new APPLICATION-TEMPLATE.
   * @param application
   */
  createApplication(application: any) {
    const url = `${environment.apiURL}/admin/application/create`;
    return this.http.post<IApplication>(url, application);
  }

  /** PUT Requests (update) */

  /**
   * 1. Method to update an APPLICATION-TEMPLATE.
   * @param payload
   * @param applicationIdentifier
   */
  updateApplicationTemp(payload: any, applicationIdentifier: string) {
    const url = `${environment.apiURL}/admin/application-template/update`;
    return this.http.put<IApplication>(url, payload, {
      params: {
        application_identifier: applicationIdentifier,
      },
    });
  }

  updateApplicationTempV2(payload: any, applicationIdentifier: string) {
    const url = `${environment.apiURL}/admin/application-template/v2/update`;
    return this.http.put<IApplication>(url, payload, {
      params: {
        application_identifier: applicationIdentifier,
      },
    });
  }

  updateApplicationTempV3(payload: any, applicationIdentifier: string) {
    const url = `${environment.apiURL}/admin/application-template/v3/update`;
    return this.http.put<IApplication>(url, payload, {
      params: {
        application_identifier: applicationIdentifier,
      },
    });
  }

  /**
   * 2. Method to update an APPLICATION.
   * @param payload
   * @param applicationIdentifier
   */
  updateApplication(payload: any, applicationIdentifier: string) {
    const url = `${environment.apiURL}/admin/application/update`;
    return this.http.put<IApplication>(url, payload, {
      params: {
        application_identifier: applicationIdentifier,
      },
    });
  }

  updateApplicationV2(payload: any, applicationIdentifier: string) {
    const url = `${environment.apiURL}/admin/application/v2/update`;
    return this.http.put<IApplication>(url, payload, {
      params: {
        application_identifier: applicationIdentifier,
      },
    });
  }

  /** PUT Requests (update) v2 */

  /**
   * 1. Method to update an APPLICATION-TEMPLATE.
   * @param payload
   * @param applicationIdentifier
   */
  updateAppTemplateV2(payload: any, applicationIdentifier: string) {
    const url = `${environment.apiURL}/admin/application-template/v2/update`;
    return this.http.put<IApplication>(url, payload, {
      params: {
        application_identifier: applicationIdentifier,
      },
    });
  }

  // /**
  //  * 2. Method to update an APPLICATION.
  //  * @param payload
  //  * @param applicationIdentifier
  //  */
  // updateApplication(payload: any, applicationIdentifier: string) {
  //   const url = `${environment.apiURL}/admin/application/update`;
  //   return this.http.put<IApplication>(url, payload, {
  //     params: {
  //       application_identifier: applicationIdentifier,
  //     },
  //   });
  // }

  /** DELETE Requests  */

  /**
   * Method to delete an APPLICATION-TEMPLATE.
   * @param applicationIdentifier
   */
  deleteApplicationTemp(applicationIdentifier: string) {
    const url = `${environment.apiURL}/admin/application-template/delete`;
    return this.http.delete<IApplication>(url, {
      params: {
        application_identifier: applicationIdentifier,
      },
    });
  }

  /**
   * 2. Method to delete an APPLICATION.
   * @param applicationIdentifier
   */
  deleteApplication(applicationIdentifier: string) {
    const url = `${environment.apiURL}/admin/application/delete`;
    return this.http.delete<IApplication>(url, {
      params: {
        application_identifier: applicationIdentifier,
      },
    });
  }

  /** Clone Requests  */

  /**
   * Method to Clone an APPLICATION-TEMPLATE.
   * @param applicationIdentifier
   */
  cloneApplicationTemplate(payload: any) {
    const url = `${environment.apiURL}/admin/application-template/clone`;
    return this.http.post<IApplication>(url, payload);
  }

  getApplicationIndustries() {
    const url = `${environment.apiURL}/admin/application/get-industries`;
    return this.http.get<any>(url).toPromise();
  }

  /**
   * 2. Method to Clone an APPLICATION.
   * @param applicationIdentifier
   */
  cloneApplication(payload: any) {
    const url = `${environment.apiURL}/admin/application/clone`;
    return this.http.post<IApplication>(url, payload);
  }

  getDummyDataFromLocalJSON() {
    return this.http.get<{}>('assets/json/circuitDefinition.json');
  }

  getDummyApplicationListFromLocalJSON() {
    return this.http.get<{}>('assets/json/application-list.json');
  }

  getDummyApplicationFromLocalJSON() {
    return this.http.get<{}>('assets/json/application-sensors.json');
  }

  gccAPI(challengeId: string, studentId: string, applicationIdentifier: string) {
    const url = `${environment.gccApiURL}studentChallenge/application`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': 'Bearer'+ ' ' + sessionStorage.getItem('gccToken')
      })
    }
    
    return this.http.post(url, {
        StudentId: studentId,
        ChallengeId: challengeId,
        ApplicationIdentifier: applicationIdentifier,
    }, httpOptions);
  }
}
