<app-circuit-section (emitSavedJSON)="saveCircuit($event)" (emitLoadState)="setLoadState($event)" [konvaUtils]="konvaUtils"></app-circuit-section>

<app-side-panel *ngIf="showSideBarList" (emitCloseEvent)="sidePanelClosed($event)" (boxWidth)='5/6'>

  <p class="text-2xl font-bold mb-5">{{ loadState? 'Load': 'Save' }} Circuit</p>

  <app-save-list (saveDataEvent)="selectApplication($event)" [applications]="(applicationsList$ | async)" [selectedAppliation]="selectedAppliation">
  </app-save-list>

  <div class="p-4 fixed bottom-0">
    <button class="bg-button text-white p-2 rounded-md shadow font-bold mr-2" type="button"
      (click)="loadState? loadExistingCircuit() : saveCircuitDesign()">{{ loadState? 'LOAD': 'SAVE' }}
      CIRCUIT</button>
    <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button"
      (click)="sidePanelClosed($event)">CANCEL</button>
  </div>

</app-side-panel>
