<div class="relative flex">
  <!-- Heading -->
  <div class="fixed m-2"> 
    <h3 class="font-semibold p-4">Reference Diagram</h3>
  </div>
  <!-- Play video button and popup start -->
  <div *ngIf="video">
    <ng-container >
      <div class="w-full min-w-screen h-screen animated fadeIn faster fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-color bg-cover">
            <div (click)="videoPlay()" class="btn-close"> <img src="assets/img/icons/xmark-solid-1.svg"></div>
            <iframe style="height:530px; width: 992px;" [src]="videoUrl | safe"   allow="autoplay; picture-in-picture encrypted-media;"  allowfullscreen></iframe>
      </div>
    </ng-container>
  </div>

  <div class="p-1 absolute cursor-pointer z-10 switch right-0" *ngIf="isVideoBtn">
    <!--  *ngIf="isTemplate" -->
    <div class="flex box-1 my-2 floating-button">
      <button class="video-box ring-2 ring-button rounded-lg p-2 mr-2" (click)="videoPlay()">
        <img src="assets/img/icons/video.svg" title="Play Video">
      </button>
    </div>
  </div>

  <div class="compare-circuit-btn" [class.top-0px]="!isVideoBtn"  *ngIf="isTemplate">
    <div class="flex box-1 my-2 floating-button">
      <button class="video-box ring-2 ring-button rounded-lg p-2 mr-2" (click)="compareCircuits()">
        <img src="assets/img/icons/compare-red-color.png" title="Show Differences" />
        <span class="circuit-diff-text">{{ circuitMismatchCount }}</span>
      </button>
    </div>
  </div>

  <!-- Play video end-->
  <div id="container-main" [class.zoomin]="zoom" [class.resize]="!isAdmin && !zoom" [class.apply-bg]="isAdmin"></div>
  <!-- Control Buttons -->
  <div class="control-btn-group" [class.full-page-view]="isFullCircuit" [class.menu-closed]="!isMenuOpen">
    <button class="p-2" (click)="konvaUtils.zoomInOut({action:'zoomin', areaName:'pre-build'})">
      <img src="assets/img/icons/zoomin.svg" class="h-7 w-7" title="Zoom In" />
    </button>
    <button class="p-2" (click)="konvaUtils.zoomInOut({action:'zoomout', areaName:'pre-build'})">
      <img src="assets/img/icons/zoomout.svg" class="h-7 w-7" title="Zoom Out" />
    </button>
    <button *ngIf="!isFullCircuit" class="p-2" (click)="emitWindowSize.emit({action:'maximize', areaName:'pre-build'})">
      <img src="assets/img/icons/maximize.svg" class="h-7 w-7" title="Maximize" />
    </button>
    <!-- <button *ngIf="!isFullCircuit" class="p-2" (click)="emitWindowSize.emit({action:'maximize', areaName:'workspace'})">
      <img src="assets/img/icons/minimize.svg" class="h-7 w-7" title="Minimize" />
    </button> -->
    <button *ngIf="isFullCircuit" class="p-2" (click)="emitWindowSize.emit({action:'minimize', areaName:'pre-build'})">
      <img src="assets/img/icons/minimize.svg" class="h-7 w-7" title="Minimize" />
    </button>
  </div>
</div>
