<div class="absolute bg-black opacity-80 inset-0 z-0"></div>
<div class="display-box">
  <div class="spaced">
    <div class="flex-auto justify-center m-5">
      <h2 class="text-xl font-bold">Download data files:</h2>
      <div></div>
      <table>
        <tbody>
          <tr>
            <td class="font-bold">All Files</td>
            <td><a href="/assets/notebooks/DS_data_files.zip" download>Download</a></td>
          </tr>
          <ng-container *ngFor="let file of files; let i = index">
            <tr>
              <td><strong>{{i+1}})</strong> {{file.title}} ({{file.name}})</td>
              <td><a href="/assets/notebooks/{{file.name}}" download>Download</a></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
