import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UserSessionService } from 'src/app/shared/services/user.session.service';


@Component({
  selector: 'app-choose-module',
  templateUrl: './choose-module.component.html',
  styleUrls: ['./choose-module.component.scss']
})
export class ChooseModuleComponent implements OnInit {
  @Output() emitModuleName = new EventEmitter();
  @Output() emitLogoutEvent = new EventEmitter();

  current_username: string;
  dropdownOpen: boolean;

  constructor(
    private userSessionService: UserSessionService
  ) {
    this.current_username = JSON.parse(sessionStorage.getItem("user") || "{}")['username'];
    this.dropdownOpen = false;
  }

  async ngOnInit() {
    await this.userSessionService.extendToken(this.current_username).toPromise();

  }

  emitModule(moduleName: string) {
    this.emitModuleName.emit(moduleName);
  }

  changeDropdown(flag?: boolean) {
    this.dropdownOpen = flag != undefined ? flag : !this.dropdownOpen;
  }

  logout() {
    this.emitLogoutEvent.emit(false);
    window.open("https://grok.seeklms.com/lms/logout", "_top");
  }
}
