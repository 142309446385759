<div class="w-full h-full bg-white relative">
  <div class="box">
    <app-blockly-section (emitXMLEvent)="saveXML($event)" [switchType]="blockType" [toggle]="false"
      (emitBlockState)="toggleBlockState($event)" [selectedApplication]="selectedApplication" [isAdmin]="true"
      [moduleKey]="moduleKey" [parentComponent]="'BlockProgrammingComponent-Admin'"></app-blockly-section>
  </div>
  <div class="flex justify-end py-2 box-1 my-2">
    <button class="bg-white ring-2 ring-button text-button p-2 text-md font-bold mr-2 rounded-lg" (click)="switch()">
      SWITCH TO 
      {{
      blockType?.toLowerCase() == this.codeName?.toLowerCase()
      ? this.blockName
      : this.codeName
      }}
    </button>
  </div>
  <!-- Action button like undo,redo, zoomIn, zoomOut Focus -->
  <div *ngIf="blockType?.toLowerCase() == this.codeName?.toLowerCase()
    ? false
    : true
    " class="floating-button justify-end py-2  box-1 action-buttons">
    <button   (click)="zoomIn($event)" class="wd-48">
      <img class="img" src="assets/img/blockly/zoom-in.svg"  title="Zoom In"  />  
    </button>
    <hr>
    <button    (click)="zoomOut($event)"  class="wd-48">
      <img class="img" src="assets/img/blockly/zoom-out.svg"  title="Zoom Out"   />  
    </button>
    <hr>
    <button    (click)="focus()"  class="wd-48">      
        <img class="img" src="assets/img/blockly/recenter.svg"   title="Focus"  />
    </button>
    <hr>
  <button  (click)="undo()"  class="wd-48" >     
        <img    class="img" src="assets/img/blockly/undo.svg"   title="Undo" />
    </button>
    <hr>
    <button  [disabled]="isRedo" (click)="redo()"  class="wd-48">
        <img   class="img" src="assets/img/blockly/redo.svg"  title="Redo" />    
    </button>
    <hr>
    <button    (click)="delete()"  class="wd-48">
        <img class="img" src="assets/img/blockly/delete.svg"  title="Delete"/>
    </button>
  </div>
</div>
<app-modal [modalType]="'new_variable'"></app-modal>



