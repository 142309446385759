<div class="w-full h-screen relative">
    <div class="flex p-4">
        <button 
            class="text-white btn mr-2 flex justify-center" 
            (click)="complieCode()" 
            [disabled]="isCodeRunning"
            [class.btn-color-3]="!isCodeRunning"
            [class.btn-color-5]="isCodeRunning"
            >
            <ng-container *ngIf="!isCodeRunning">
                <img src="assets/img/python-direct/run.svg" class="m-2 py-btn-logo" alt="run">
                <span class="text-white py-run-btn-text">Run</span>
            </ng-container>
            <ng-container *ngIf="isCodeRunning">
                <img src="assets/img/python-direct/loading.svg" class="m-2 py-btn-logo" alt="loading">
                <span class="text-white py-run-btn-text">Loading</span>
            </ng-container>
        </button>
        <button class="text-white btn btn-color-4 mr-2 flex justify-center" (click)="onClear()">
            <img src="assets/img/python-direct/clear.svg" class="m-2 py-btn-logo" alt="clear">
            <span class="text-white py-run-btn-text">Clear</span>
        </button>
        <button class="text-white btn btn-color-5 mr-2 flex justify-center" (click)="onClose()">
            <img src="assets/img/python-direct/close.svg" class="m-2 py-btn-logo" alt="close">
            <span class="text-white py-run-btn-text">Close</span>
        </button>
    </div>
    <div *ngIf="compiledOutput">
        <ng-container *ngFor="let op of compiledOutput">
            <ng-container *ngIf="!isBase64Image(op)">
                <div class="block-code text-white text-xs mr-2 p-4">
                    <span class="text-white text-sm">{{op}}</span>
                </div>
            </ng-container>
            <ng-container  *ngIf="isBase64Image(op)">
                <img class="m-2" [src]="op |safe" alt="image">
            </ng-container>
        </ng-container>
    </div>
</div>