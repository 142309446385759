<div class="">
  <label class="text-gray-600 text-sm">Select Application</label>
  <ng-select placeholder="Please select an application" [items]="applications" bindLabel="applicationName" [(ngModel)]="selectedAppliation"
    [searchFn]="customSearchFn" (change)="saveData($event)">
    <ng-template ng-option-tmp let-item="item">
      {{item.applicationName}} <br />
      <small>{{item.applicationIdentifier}}</small>
    </ng-template>
  </ng-select>
</div>
