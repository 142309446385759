import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimulatorDashboardComponent } from './simulator-dashboard.component'
import { PrebuildCircuitModule } from '../../../shared/components/prebuild-circuit/prebuild-circuit.module';

@NgModule({
  declarations: [SimulatorDashboardComponent],
  imports: [
    CommonModule,
    PrebuildCircuitModule
  ],
  exports: [SimulatorDashboardComponent]
})
export class SimulatorDashboardModule { }
