<ng-container>
  <p class="font-bold text-2xl mb-5">{{isEdit ? 'Edit': 'Create'}} Application</p>

  <div class="p-4">
    <form (ngSubmit)="onSubmit()" #applicationForm="ngForm">
      <div class="floating-input relative mb-10 flex flex-col">
        <input class="input-style" name="app-name" [(ngModel)]="application.applicationName" placeholder="Application Name">
        <label class="label-style">Name</label>
      </div>

      <!-- <div class="floating-input relative mb-10 flex flex-col">
        <select class="input-style" name="module-name" [(ngModel)]="application.moduleName">
          <option>IoT</option>
          <option>Robotics</option>
          <option>Data Science</option>
        </select>
        <label class="label-style">Module Name</label>
      </div> -->

      <div class="floating-input relative mb-10 flex flex-col">
        <input class="input-style" name="app-desc" [(ngModel)]="application.applicationDesc" placeholder="Application Description">
        <label class="label-style">Application Description</label>
      </div>

      <div class="floating-input relative mb-10 flex flex-col">
        <textarea class="input-style mb-2" name="prebuild-circuit" [(ngModel)]="application.configurationDefinition" rows="10" placeholder="Circuit Design JSON"></textarea>
        <label class="label-style">Circuit Design JSON</label>
      </div>

      <div class="floating-input relative mb-10 flex flex-col">
        <textarea class="input-style mb-2" name="prebuild-block-with-simulator" [(ngModel)]="application.applicationDefinition"  placeholder="Block programming XML (with simulator)" rows="10"></textarea>
        <label class="label-style">Prebuild Block Program With Simulator</label>
      </div>

      <div class="floating-input relative mb-10 flex flex-col">
        <textarea class="input-style mb-2" name="prebuild-block-without-simulator" [(ngModel)]="application.toolboxDefinition"  placeholder="Block programming XML (without simulator)" rows="10"></textarea>
        <label class="label-style">Prebuild Block Program Without Simulator</label>
      </div>

      <div class="">
        <button class="bg-button text-white p-2 rounded-md shadow font-bold mr-2" type="submit">UPDATE APPLICATION</button>
        <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button" (click)="cancel()">CANCEL</button>
      </div>
    </form>
  </div>
</ng-container>
