import { createReducer, on } from '@ngrx/store';
import circuitDesignActions from '../circuit-design/c-d.actions';
import { initialCircuitState } from '../circuit-design/c-d.state';

export const circuitDesignReducer = createReducer(
  initialCircuitState,
    on(circuitDesignActions.updateWholeData, (state, { payload, undoableData }) => {
    // add new target in the state
    const { targets, wires } = payload;
    return {
      ...state,
      targets,
      wires,
      undoableData,
    };
  }),
  on(circuitDesignActions.addTarget, (state, { payload, undoableData }) => {
    // add new target in the state
    const targets = [...state.targets, payload];
    return {
      ...state,
      targets,
      undoableData,
    };
  }),
  on(circuitDesignActions.removeTarget, (state, { payload, undoableData }) => {
    // delete the particular target by t-id
    const targets = state.targets.filter((el: any) => el.t_id !== payload);
    return {
      ...state,
      targets,
      undoableData,
    };
  }),
  on(circuitDesignActions.updateTarget, (state, { payload, undoableData }) => {
    // update new current position of the target in the state
    const index = state.targets.findIndex((el: any) => el.t_id === payload.id);
    const temp = JSON.parse(JSON.stringify(state.targets));
    // copy current coordinates to previous and update the new one in the current
    temp[index].prev = { ...state.targets[index].current };
    temp[index].current.x = payload.x;
    temp[index].current.y = payload.y;
    return {
      ...state,
      targets: temp,
      undoableData,
    };
  }),
  on(circuitDesignActions.updateDragTarget, (state, { payload, undoableData }) => {
    // update new current position of the target in the state
    const index = state.targets.findIndex((el: any) => el.t_id === payload.id);
    const temp = JSON.parse(JSON.stringify(state.targets));
    // copy current coordinates to previous and update the new one in the current
    temp[index].prev = { ...state.targets[index].current };
    temp[index].current.x = payload.x;
    temp[index].current.y = payload.y;
    return {
      ...state,
      targets: temp,
      undoableData,
    };
  }),
  on(circuitDesignActions.updateNameTarget, (state, { payload, undoableData }) => {
    const index = state.targets.findIndex((el: any) => el.t_id === payload.id);
    const temp = JSON.parse(JSON.stringify(state.targets));
    temp[index].customEditName = payload.customEditName;
    return {
      ...state,
      targets: temp,
      undoableData,
    };
  }),
  on(circuitDesignActions.addWire, (state, { payload, undoableData }) => {
    // add a new wire to the wires state
    const wires = [...state.wires, payload.wire];
    // add the wire-id in associated target's connectors array
    let tempTargets = JSON.parse(JSON.stringify(state.targets));
    const index = state.targets.findIndex(
      (el?: any) => el.t_id === payload.wire.start.t_id
    );
    tempTargets[index].connectors = [
      ...tempTargets[index]?.connectors,
      payload.wid,
    ];
    return {
      wires,
      targets: tempTargets,
      undoableData,
    };
  }),
  on(circuitDesignActions.addFullWire, (state, { payload, undoableData }) => {
    // add a new wire to the wires state
    const wires = [...state.wires, payload.wire];
    // add the wire-id in associated target's connectors array
    let tempTargets = JSON.parse(JSON.stringify(state.targets));
    const index = state.targets.findIndex(
      (el?: any) => el.t_id === payload.wire.start.t_id
    );
    tempTargets[index].connectors = [
      ...tempTargets[index]?.connectors,
      payload.wid,
    ];
    const index2 = state.targets.findIndex(
      (el?: any) => el.t_id === payload.wire.end.t_id
    );
    tempTargets[index2].connectors = [
      ...tempTargets[index2]?.connectors,
      payload.wid,
    ];
    return {
      wires,
      targets: tempTargets,
      undoableData,
    };
  }),
  on(circuitDesignActions.removeWire, (state, { payload, undoableData }) => {
    // delete the wire from the state by wire-id
    const wires = state.wires.filter((el: any) => el.w_id !== payload);
    // also remove that wire-id from the connectors array of the target it is connected to
    let tempTargets = JSON.parse(JSON.stringify(state.targets));
    tempTargets.forEach((el: any) => {
      el.connectors = el.connectors.filter((ele: any) => ele !== payload);
    });
    return {
      targets: tempTargets,
      wires,
      undoableData,
    };
  }),
  on(circuitDesignActions.updateWire, (state, { payload, undoableData }) => {
    const { dx, dy, points, wid, color } = payload;
    // update the new positions of the start, control and end anchor with respect to new target's position
    const tempWires = JSON.parse(JSON.stringify(state.wires));

    if (tempWires.length > 0) {
      const index = state.wires.findIndex((el: any) => el.w_id === wid);
      const wire = tempWires[index];
      // copy current coordinates to previous and update the difference in the current
      points.forEach((el: any) => {
        if (el === wire.start.c_id) {
          wire.start.prev = { ...wire.start.current };
          wire.start.current.x = wire.start.current.x + dx;
          wire.start.current.y = wire.start.current.y + dy;
        }
        if (el === wire.control.c_id) {
          wire.control.prev = { ...wire.control.current };
          wire.control.current.x = wire.control.current.x + dx;
          wire.control.current.y = wire.control.current.y + dy;
        }
        if (el === wire.end.c_id) {
          wire.end.prev = { ...wire.end.current };
          wire.end.current.x = wire.end.current.x + dx;
          wire.end.current.y = wire.end.current.y + dy;
        }
      });

      if (color) {
        wire.color = color;
      }
    }

    return {
      ...state,
      wires: tempWires,
      undoableData,
    };
  }),
  on(circuitDesignActions.updateDragWire, (state, { payload, undoableData }) => {
    const { dx, dy, points, wid, color } = payload;
    // update the new positions of the start, control and end anchor with respect to new target's position
    const tempWires = JSON.parse(JSON.stringify(state.wires));

    if (tempWires.length > 0) {
      const index = state.wires.findIndex((el: any) => el.w_id === wid);
      const wire = tempWires[index];
      // copy current coordinates to previous and update the difference in the current
      points.forEach((el: any) => {
        if (el === wire.start.c_id) {
          wire.start.prev = { ...wire.start.current };
          wire.start.current.x = wire.start.current.x + dx;
          wire.start.current.y = wire.start.current.y + dy;
        }
        if (el === wire.control.c_id) {
          wire.control.prev = { ...wire.control.current };
          wire.control.current.x = wire.control.current.x + dx;
          wire.control.current.y = wire.control.current.y + dy;
        }
        if (el === wire.end.c_id) {
          wire.end.prev = { ...wire.end.current };
          wire.end.current.x = wire.end.current.x + dx;
          wire.end.current.y = wire.end.current.y + dy;
        }
      });

      if (color) {
        wire.color = color;
      }
    }

    return {
      ...state,
      wires: tempWires,
      undoableData,
    };
  }),
  on(circuitDesignActions.updateWireColor, (state, { payload, undoableData }) => {
    const { wid, color } = payload;
    // update the new positions of the start, control and end anchor with respect to new target's position
    const tempWires = JSON.parse(JSON.stringify(state.wires));
    const index = state.wires.findIndex((el: any) => el.w_id === wid);
    const wire = tempWires[index];

    if (color) {
      wire.color = color;
    }

    return {
      ...state,
      wires: tempWires,
      undoableData,
    };
  }),
  on(circuitDesignActions.updateConnector, (state, { payload, undoableData }) => {
    const { wid, xp, yp, to, pinId, point } = payload;
    // update wire-id in connectors array of target
    const indexT = state.targets.findIndex((el: any) => el.t_id === to);
    let tempTargets = JSON.parse(JSON.stringify(state.targets));
    if (to !== undefined && to !== 'none') {
      if (tempTargets[indexT].connectors.includes(wid) === false) {
        tempTargets[indexT].connectors = [
          ...tempTargets[indexT].connectors,
          wid,
        ];
      }
    }
    // update the new current position of anchor(start, control, end) on dragend
    const indexW = state.wires.findIndex((el: any) => el.w_id === wid);
    let tempWires = JSON.parse(JSON.stringify(state.wires));
    const wire = tempWires[indexW];
    // add position of connector
    switch (point) {
      case 'start':
        wire.start.prev = { ...wire.start.current };
        wire.start.current.x = xp;
        wire.start.current.y = yp;
        wire.start.t_id = to || wire.start.t_id;
        wire.start.pinId = pinId || wire.start.pinId;
        break;
      case 'control':
        wire.control.prev = { ...wire.control.current };
        wire.control.current.x = xp;
        wire.control.current.y = yp;
        wire.control.t_id = to || wire.control.t_id;
        wire.control.pinId = pinId || wire.control.pinId;
        break;
      case 'end':
        wire.end.prev = { ...wire.end.current };
        wire.end.current.x = xp;
        wire.end.current.y = yp;
        wire.end.t_id = to || wire.end.t_id;
        wire.end.pinId = pinId || wire.end.pinId;
        break;
    }
    return {
      targets: tempTargets,
      wires: tempWires,
      undoableData,
    };
  }),
  on(circuitDesignActions.clearCanvas, (state) => (initialCircuitState))
);
