import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CodeModel } from '@ngstack/code-editor';
import { PythonDirectService } from 'src/app/shared/services/python-direct.service';
import { ToastrService } from 'ngx-toastr';
import { AlertMessageConstants } from 'src/app/shared/constants/messages';


@Component({
  selector: 'app-output-screen',
  templateUrl: './output-screen.component.html',
  styleUrls: ['./output-screen.component.scss'],
})
export class OutputScreenComponent implements OnInit, OnChanges {
  @Output() isCollaspedEvent: any = new EventEmitter();
  @Input() codeGenetared: any;
  isClose: boolean = true;
  isImage: boolean = false;
  isCodeRunning: boolean = false;

  compiledOutput: string[] = [];

  codeModel: CodeModel = {
    language: '',
    uri: '',
    value: '',
  };
  outputList: any;

  constructor(
    private pythonDirectService: PythonDirectService,
    private toasterService: ToastrService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void { }

  onClose() {
    this.isClose = false;
    this.isCollaspedEvent.emit(this.isClose);
  }

  // Clear output sreen
  onClear() {
    this.compiledOutput = [];
  }

  async complieCode() {
    if (this.isCodeRunning == true) {
      this.toasterService.info(
        AlertMessageConstants.PythonCodeExecutionAlreadyRunning
      );
      return;
    }
    this.isCodeRunning = true;
    let code: any;
    if (this.codeGenetared?.code) {
      // code from python direct
      code = this.codeGenetared.code.replace(/[\r]+/gm, '');
    } else {
      //code from block design
      code = this.codeGenetared.join('\n');
    }

    const tokenArray = [
      "qgTUXHomjkMhXdzDX2GOz43JOeyTcISY3L0qVwK4uvTMucc8CS",
      "9MomElRAUPaVU58j9ui5S5FK3uRzvF4dsZ8UeYrNk91bCD1ps1",
      "xZOlwhpaHD3lRYmn9NXdkWuSQjdsIp8CLH3acqDiL5vtBJbjQC"
    ];
  
    const randomToken = tokenArray[Math.floor(Math.random() * tokenArray.length)];

    await this.pythonDirectService.executePythonCode({ code: code, token: randomToken }).toPromise()
      .then((res: any) => {
        if (res?.output) {
          this.compiledOutput = this.cleanOutput(res.output.split(/\r?\n/));
        }
        this.toasterService.success(
          AlertMessageConstants.PythonCodeExecutionSuccess
        );
      })
      .catch(() =>
        this.toasterService.error(
          AlertMessageConstants.PythonCodeExecutionError
        )
      )
      .finally(() => (this.isCodeRunning = false));
  }

  // Method to check if the given string is base64 or not.
  /**
   * @param {string} base64_data
   * @returns {boolean}
   */
  isBase64Image(base64_data: string ): boolean { 
    base64_data = base64_data.trim();
    if (base64_data.length == 0 ) {
      return false;
    }    
    let isBase64Img = base64_data.startsWith('data:image/png;base64');
    return isBase64Img;
  }

  /**
   * Method to remove unwanted warning and information printed by Python
   * @param { string[] } compiledOutput
   * @returns { string[] }
   */
  cleanOutput(compiledOutput: string[]) {
    let result = compiledOutput.filter(line => {
      return !line.startsWith('[WARNING]\t') && !line.startsWith('[INFO] ')
    });
    console.log({
      compiledOutput,
      result
    })

    return result;
  }
}
