
<ng-container *ngIf="!sensorSaved">
    <div class="container bg-white rounded-lg p-8" >
      <form [formGroup]="sensorFormData">
          <div>
              <!-- <h1 class="text-xl font-bold mb-5 text-gray-800">Create New Sensor</h1> -->
              <h1 class="text-xl font-bold mb-5 text-gray-800">Edit Sensor</h1>
          </div>

          <p class="text-md mb-6">Fill in the details of the Sensor</p>

        <div class="floating-input relative mb-10 flex flex-col">
          <label class="text-gray-500">Sensor Name</label>
          <input class="input-style ip-h" name="sensor-name" placeholder="Sensor Name" formControlName="sensorName">
        </div>

        <div class="floating-input relative mb-5 flex flex-col">
          <label class="text-gray-500">Sensor Description</label>
          <textarea class="text-area-style" name="sensor-desc"  placeholder="Sensor Description" formControlName="sensorDesc" rows="3"></textarea>
        </div>

        <div class="floating-input relative mb-5 flex flex-col">
          <label class="text-gray-500">Sensor Type</label>
            <ng-select [clearable]="false" formControlName="sensorType" >
              <ng-option *ngFor="let sensor of sensorTypes" [value]="sensor">{{sensor}}</ng-option>
            </ng-select>
        </div>

        <div class="dropdown mb-5">
          <label class="text-gray-500">Module Type</label>
          <ng-select formControlName="moduleType" [items]="moduleTypes" [multiple]="true" bindLabel="name" groupBy="selectedAllGroup" [selectableGroup]="true" [dropdownPosition]="'bottom'"
            [selectableGroupAsModel]="false" [closeOnSelect]="false" bindValue="value" [(ngModel)]="selectedModule">
            <ng-template  ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [ngModel] ="item$.selected" [ngModelOptions]="{standalone: true}"/>
              <span class="pl-10">All</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/><span class="pl-10">{{item.name}}</span>
            </ng-template>
          </ng-select>
        </div>

        <div>        
          <label class="text-gray-500">Device Type</label>
          <ng-select [clearable]="false" class="w-full border-gray-300 rounded-lg shadow-sm mb-5" formControlName="deviceType">
            <ng-option *ngFor="let device of deviceTypes" [value]="device">{{device}}</ng-option>
          </ng-select>        
        </div>


        <div class="floating-input relative mb-10 flex flex-col">
          <label class="text-gray-500">Sensor Image URL</label>
          <input class="input-style ip-h" name="sensor-img"  placeholder="Sensor Image" formControlName="sensorImageURL">
        </div>

        <div class="floating-input relative mb-10 flex flex-col">
          <label class="text-gray-500">External Link</label>
          <input class="input-style p-3 ip-h" name="external-link"  placeholder="External Link" formControlName="externalLink">
        </div>

        <div class="floating-input relative mb-10 flex flex-col">
          <label class="text-gray-500">Metadata JSON</label>
          <textarea class="text-area-style" name="metadata" rows="5" formControlName="metadata"></textarea>
        </div>

        <div class="flex justify-end">
          <button class="bg-greenish-blue p-4 text-white rounded-lg m-2" (click)="saveSensor()">Save</button>
          <!-- <button class="bg-greenish-blue p-4 text-white rounded-lg m-2" (click)="saveSensor()">Save & Close</button> -->
        </div>
      </form>
      </div>
  </ng-container>


  <ng-container *ngIf="sensorSaved">
    <div class="container bg-white rounded-lg p-8">
      Sensor Saved Successfully
    </div>
  </ng-container>


