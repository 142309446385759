export class simulatorDefinition {
  visualization: string = "";
  visualization_panel: boolean = false;
  mobile_console: boolean = false;
  mobile_controller_logic: {
    id: string;
    conditions: any;
    messages: any;
  }[] = [];
}

export const initialSimulatorDefinition = new simulatorDefinition();
