import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { Router } from '@angular/router';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { Organization, OrganizationsService } from 'src/app/shared/services/organizations.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user-section',
  templateUrl: './user-section.component.html',
  styleUrls: ['./user-section.component.scss']
})
export class UserSectionComponent implements OnInit {

  activeUserSessionsList: any[] = []
  currentOrganization!: string;
  orgList: Organization[] = [];
  isSuperAdmin: boolean = true;
  isGrokCloudMember: boolean = true;
  appliedOrgName!: string | null;
  isSortUser: boolean = true;
  isSortUserId: boolean = true;
  isLoggedIn: boolean = true;

  constructor(
    private userSessionService: UserSessionService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private orgService: OrganizationsService
  ) { }

  routeTo(path: string) {
    this.router.navigateByUrl(`/admin/${path}`)
  }

  async ngOnInit() {
    this.setDefaultOrganization();
    this.activeUserSessionsList = await this.getUserSessionList();
    this.searchOrganizations();
  }

  setDefaultOrganization() {
    this.currentOrganization = this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization === environment.organizationID) { }
    else {
      this.isGrokCloudMember = false;
      this.appliedOrgName = this.currentOrganization;
    }
  }

  async applyOrganizationFilter(event: any) {
    if (event.target.value != "") {
      this.appliedOrgName = event.target.value;
    } else {
      this.appliedOrgName = null;
    }
    this.activeUserSessionsList = await this.getUserSessionList();
  }

  async searchOrganizations() {
    try {
      const result: any = await this.orgService.search().toPromise();
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }

  public async getUserSessionList() :Promise<any[]>{
    let userSessions = await this.userSessionService.getActiveUserSessions(this.appliedOrgName as string).toPromise();
    return userSessions.activeSessions;
  }

  async logout(item:any){
    let isLoggedOut = await this.userSessionService.forceLogout(item.lmsusername).toPromise();
    if(isLoggedOut){
      this.activeUserSessionsList= this.activeUserSessionsList.filter(data=> data.lmsusername != item.lmsusername);
    }    
  }

  formatDate(date: any) {
        return moment(date).local().format("DD/MM/YYYY HH:mm:ss");
  }
  
  sortData(colName: any, sortOrder: any) {

    if (colName == 'lmsuserName' && sortOrder == 'asc') {
      this.activeUserSessionsList.sort((a, b) => a.lmsusername.localeCompare(b.lmsusername));
      this.isSortUser = !this.isSortUser;
    }
    else if (colName == 'lmsuserName' && sortOrder == 'desc') {
      this.activeUserSessionsList.sort((a, b) => b.lmsusername.localeCompare(a.lmsusername));
      this.isSortUser = !this.isSortUser;
    }
    else if (colName == 'lmsuserid' && sortOrder == 'asc') {
      this.activeUserSessionsList.sort((a, b) => a.lmsuserid.localeCompare(b.lmsuserid));
      this.isSortUserId = !this.isSortUserId;
    }
    else if (colName == 'lmsuserid' && sortOrder == 'desc') {
      this.activeUserSessionsList.sort((a, b) => b.lmsuserid.localeCompare(a.lmsuserid));
      this.isSortUserId = !this.isSortUserId;
    }
    else if (colName == 'loggedintime' && sortOrder == 'asc') {
      this.activeUserSessionsList.sort((a, b) => a.loggedintime.localeCompare(b.loggedintime));
      this.isLoggedIn = !this.isLoggedIn;
    }
    else if (colName == 'loggedintime' && sortOrder == 'desc') {
      this.activeUserSessionsList.sort((a, b) => b.loggedintime.localeCompare(a.loggedintime));
      this.isLoggedIn = !this.isLoggedIn;
    }
    else {
      this.activeUserSessionsList.sort((a, b) => a.lmsusername.localeCompare(b.lmsusername));
      this.isSortUser = !this.isSortUser;
    }
  }
}
