import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class WsApplicationService {
  private socket!: WebSocket;
  private toasterService!: ToastrService;

  constructor() {}

  createObservableCircuit(device_identifier: any): any {
    const user: any = sessionStorage.getItem("user");
    const username: any = JSON.parse(user)?.username;
    if (device_identifier && username) {
      this.socket = new WebSocket(environment.wsUrl);
      const self = this;
      return new Observable((observer) => {
        this.socket.onopen = function () {
          self.socket.send(
            JSON.stringify({
              action: "subscribe",
              message: device_identifier,
              device_identifier: device_identifier,
              username: username,
            })
          );
        };
        this.socket.onmessage = (event) => {
          observer.next(event.data);
        };
        this.socket.onerror = (event) => {
          self.toasterService.error("Connection closed. Will reconnect in a while.")
          observer.error.bind(observer);
          let timeLapse = 2000;
          this.socket.close= (): void => {
            self.toasterService.info("Reconnecting...")
            setInterval(() => {
                this.createObservableCircuit(device_identifier);
            }, timeLapse);
            timeLapse = timeLapse + 2000;
            if(timeLapse > 15000){
              timeLapse = 15000;
            }
        }};
      });
    }
  }

  close() {
    this.socket.close() 
  }

  sendData(value: any, app_id: any) {
    const user: any = sessionStorage.getItem("user");
    const username: any = JSON.parse(user)?.username;
    this.socket.send(
      JSON.stringify({
        action: "broadcast",
        message: value,
        connectionId: app_id,
        username: username,
      })
    );
  }
}
