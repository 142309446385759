import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { getApplications } from "../../container/application/store/application.selectors";
import { showSidePanel } from "src/app/store/layout/layout.action";
import { ApplicationService } from "src/app/shared/services/application.service";
import { loadApplications } from "../application/store/application.actions";
import { KonvaUtils, CircuitLoadOptions } from "../../../shared/utils/konva.utils";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";

@Component({
  selector: "app-circuit-design",
  templateUrl: "./circuit-design.component.html",
  styleUrls: ["./circuit-design.component.scss"],
})
export class CircuitDesignComponent implements OnInit {
  applicationsList$ = this.store.select(getApplications);

  @Input() sensors: any[] = [];

  private circuitJsonData: any = {};
  private circuitState: any = "";
  showSideBarList = false;
  isAdmin: boolean = window.location.href.includes('/admin/application');
  loadState: boolean;
  konvaUtils!: KonvaUtils;
  selectedAppliation: any;

  constructor(
    private store: Store<IAppState>,
    private applicationTemplateService: ApplicationService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    this.konvaUtils = new KonvaUtils(this.store, this.selectedAppliation?.applicationIdentifier, `${CircuitDesignComponent.name}-${this.ngOnInit.name}`);
    this.loadApplicationData();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();

  }

  loadApplicationData() {
    this.store.dispatch(loadApplications());
  }

  setLoadState(event: any) {
    this.showSideBarList = true;
    this.loadState = event;
  }

  selectApplication(application: any) {
    if (application) {
      this.selectedAppliation = application;
    }
  }

  loadExistingCircuit() {
    this.getApplcationData(this.selectedAppliation?.applicationIdentifier);
  }

  private async getApplcationData(applicationIdentifier: string) {
    try {
      const result = await this.applicationTemplateService.getApplication(applicationIdentifier).toPromise();
      if (result) {
        this.loadExistingOnWorkspace(result);

      }
    } catch (error) { }
    finally { this.showSideBarList = false; }
  }
  private loadExistingOnWorkspace(existingJson: any): void {
    const configurationDefinition = existingJson.Application.configurationDefinition;
    let circuitDefinition = JSON.parse(configurationDefinition).circuitDefinition;
    try {
      this.konvaUtils.loadExisting(
        circuitDefinition,
        CircuitLoadOptions.WRITABLE,
        CircuitDesignComponent.name
      );
    } catch (e: any) {
      this.konvaUtils.loadExisting(
        circuitDefinition,
        CircuitLoadOptions.WRITABLE,
        `${CircuitDesignComponent.name}-catch`
      );
    }
  }

  async saveCircuitDesign() {
    const configurationDefinition = {
      circuitDefinition: this.circuitJsonData,
    };
    const payload = {
      configurationDefinition: JSON.stringify(configurationDefinition),
    };

    try {
      let result: any;
      // if (this.isAdmin) {
      //   result = await this.applicationTemplateService.updateApplicationTemp(payload, this.selectedAppliation?.applicationIdentifier).toPromise();
      // } else {
      result = await this.applicationTemplateService.updateApplication(payload, this.selectedAppliation?.applicationIdentifier).toPromise();
      // }
      this.showSideBarList = false;
    } catch (err) {
      console.log(err);
      this.showSideBarList = false;
    }
  }

  sidePanelClosed(event: any) {
    if (event) {
      this.store.dispatch(showSidePanel({ value: false }));
      this.showSideBarList = false;
    }
  }

  async saveCircuit(event: string) {
    let targets: any, wires: any;
    this.store.select("circuitDesign").subscribe((data) => {
      targets = data.targets;
      wires = data.wires;
    });
    // this.circuitJsonData = event;
    this.circuitJsonData = this.konvaUtils.save();
    this.circuitJsonData = Object.assign({ targets, }, { wires, }, this.circuitJsonData);
    this.showSideBarList = true;
  }
}
