import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface IPageChangeEvent {
  page: number;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() customPageSize = 10;

  @Input() items: any[] = [];
  @Input() page: number = 1;
  @Input() total: number = 0;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onPageChange = new EventEmitter<IPageChangeEvent>();
  @Output() emitPageSizeEvent = new EventEmitter;

  constructor() { }

  ngOnInit() {
  }

  prevPage() {
    this.onPageChange.next({
      page: this.page - 1
    });
  }

  nextPage() {
    this.onPageChange.next({
      page: this.page + 1
    });
  }

  lastPage() {
    this.onPageChange.next({
      page: Math.ceil(this.total/this.customPageSize)
    });
  }

  firstPage() {
    this.onPageChange.next({
      page: 1
    });
  }

  getPaginationText() {
    return this.total ? `of ${Math.ceil(this.total/this.customPageSize)}`: ''
  }

  reload(event: any) {
    if (event) {
      this.emitPageSizeEvent.emit(this.customPageSize);
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PaginationComponent
  ],
  imports: [ CommonModule, FormsModule ],
  exports: [
    PaginationComponent
  ],
})
export class PaginationModule {}
