import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


/**
 * Service class to handle data and http request related to Application and Application-Template APIs.
 */
@Injectable({
  providedIn: "root",
})
export class PythonDirectService {
  constructor(private http: HttpClient) { }


   /**
   * 2. Method to execute python code and get output
   * @param code
   */
    executePythonCode(code: any) {
        const url = `${environment.pythonDirectUrl}/run`;
        return this.http.post<any>(url, code);
    }
}