import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { AdminModule } from "./admin/admin.module";
import { SimulatorModule } from "./simulator/simulator.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth/auth.module";
import { NavbarModule } from "./components/navbar/navbar.module";
import { SideMenuModule } from "./components/side-menu/side-menu.module";
import { IDEModule } from "./ide/ide.module";
import { HttpConfigInterceptor } from "./shared/interceptors/http.inteceptor";
import { appReducers } from "./store/index.reducer";
import { initialAppState } from "./store/index.state";
import { AuthEffects } from "./store/auth/auth.effects";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommonModule } from "@angular/common";
import { SidePanelModule } from "./components/side-panel/side-panel.module";
import { JoyrideModule } from "ngx-joyride";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoaderService } from "./shared/services/loader.service";
import { LoaderModule } from "./shared/components/loader/loader.module";
import { historyReducer } from "./store/circuit-history/circuit-history.reducer";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    CommonModule,
    LoaderModule,
    IDEModule,
    AdminModule,
    SimulatorModule,
    AuthModule,
    NavbarModule,
    SideMenuModule,
    HttpClientModule,
    SidePanelModule,
    JoyrideModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    StoreModule.forRoot(appReducers, {
      initialState: initialAppState,
      metaReducers: [historyReducer],
    }),
    EffectsModule.forRoot([AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: [HTTP_INTERCEPTORS],
      useClass: HttpConfigInterceptor,
      multi: true,
  

    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
