    <ng-container>
        <p class="font-bold text-2xl mb-5">Edit Device</p>
    
        <div class="p-4">
        <form (ngSubmit)="onSubmit()" #deviceEditForm="ngForm">
            <div class="mb-10 flex flex-col">
            <label class="sr-only">LMS User Id</label>
            <input class="input-style p-3" name="app-name" [(ngModel)]="deviceName" placeholder="LMS User Id">
            </div>
    
            <div class="">
            <button class="bg-greenish-blue text-white p-2 rounded-md shadow font-bold mr-2" type="submit">UPDATE
                DEVICE</button>
            <button class="bg-greenish-blue text-white p-2 rounded-md shadow font-bold mr-2" type="button"
              (click)="active(device)"> {{ device.active === true ? "INACTIVATE": "ACTIVATE" }} DEVICE</button>
            <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button" (click)="cancel()">CANCEL</button>
            </div>
        </form>
        </div>
    </ng-container>
