<div [id]="modalType" class="d">
  <div class="overlay bg-black w-full h-full fixed top-0 left-0 opacity-50 z-50 text-4xl" (click)="closeModal(modalType)">
  </div>
  <div
    class="transition duration-700 ease-in-out fixed right-0 top-0  overflow-auto z-50 h-full bg-white shadow-xl modal" [class.delete-modal]="modalType === 'delete'">
    <div class="m-4 fixed right-0 z-50 cursor-pointer">
      <div type="button" class="close text-gray cursor-pointer" (click)="closeModal(modalType)">
        <span aria-hidden="true">&times;</span>
      </div>
    </div>
    <div class="p-4 relative">

      <!-- Modal Body For creating New Variable  -->
      <div *ngIf="modalType === 'new_variable'">
        <h4 class="p-4 text-md font-medium">New variable name:</h4>
        <input class="input-style" type="text" id="varible_name">
      </div>

      <!-- Modal Body For Delete  -->
      <div *ngIf="modalType === 'delete'">
        <h4 class="p-4 text-md font-light">Application {{ isAdmin? "Template" : ""}} <span
            class="text-md font-medium">"{{selectedApplication.applicationName}}"</span>will be deleted
          permanently.
        </h4>
        <p class="p-4 text-md font-medium greenish-blue">Are you sure?
        </p>
      </div>

      <div *ngIf="modalType === 'deleteDevice'">
        <h4 class="p-4 text-md font-light">
          <span
            class="text-md font-medium">"{{deleteSelectedDevice.deviceName}}"</span>
            device will be deleted permanently.
        </h4>
        <p class="p-4 text-md font-medium greenish-blue">Are you sure?
        </p>
      </div>

      <!-- Delete lab -->
      <div *ngIf="modalType === 'deleteLab'">
        <h4 class="p-4 text-md font-light">
          <span
            class="text-md font-medium">"{{deleteSelectedLab.labName}}"</span>
            lab will be deleted permanently.
        </h4>
        <p class="p-4 text-md font-medium greenish-blue">Are you sure?
        </p>
      </div>

      <!-- Modal Action buttons  -->
      <div class="p-4">
        <button class="bg-greenish-blue text-white p-2 rounded-md shadow font-bold mr-2" id="create-button"
          (click)="modalType === 'delete' || modalType === 'deleteDevice'  || modalType === 'deleteLab' ? deleteData($event):  closeModal(modalType)">{{ modalType === 'delete' || modalType === 'deleteDevice' || modalType === 'deleteLab'  ? "DELETE": "CREATE" }}</button>
        <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button" (click)="closeModal(modalType)">CANCEL</button>
      </div>
    </div>
    <!-- <div class="p-4 relative"  *ngIf="modalType === 'dialog'">
      <div >
        <h1 class="p-2 text-xl font-light text-center ">Are you going to Publish?</h1>
        <div>
          <div class="p-1 flex justify-center">
            <button class="bg-greenish-blue text-white p-2  rounded-md shadow font-bold mr-2 internally " (click)="changeInternallyPublishedFlag()">Internally</button>
          </div>
          <div class="text-center">
            <h1>or</h1>
          </div>
          <div class="p-1 flex justify-center">
            <button class="bg-greenish-blue text-white p-2 rounded-md shadow font-bold mr-2 all-users" (click)="changePublishedFlag()">To All Users</button>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="p-4">
      <button class="bg-button text-white p-2 rounded-md shadow font-bold mr-2" (click)="delete()">YES</button>
      <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button"
        (click)="sidePanelClosed($event)">CANCEL</button>
    </div> -->

  </div>
</div>
