import { Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { deviceTypes, sensorTypes } from 'src/app/shared/constants/sensors';
import { SensorService } from 'src/app/shared/services/sensor.service';

@Component({
  selector: 'app-edit-sensor',
  templateUrl: './edit-sensor.component.html',
  styleUrls: ['./edit-sensor.component.scss'],
})
export class EditSensorComponent implements OnInit {
  sensorId: any;
  sensorSaved: boolean;
  sensorFormData: any;
  readonly sensorTypes: string[] = sensorTypes;
  readonly deviceTypes: string[] = deviceTypes;
  moduleTypes: any[] = [
    { name: 'Internet of Things', value:'iot',selected: false },
    { name: 'Robotics',value:'robotics', selected: false },
    { name: 'AIoT',value:'aiot', selected: false },
    { name: 'IIoT',value:'iiot', selected: false }
  ];
  selectedModule: any[] = [];
  metadataJSON: any;
  formData: any;

  constructor(
    private router: ActivatedRoute,
    private sensorService: SensorService,
    private toasterService: ToastrService,
    private fb: FormBuilder
  ) {
    this.sensorSaved = false;
    this.sensorFormData = this.fb.group({
      sensorName: ['', Validators.required],
      sensorDesc: ['', Validators.required],
      sensorType: ['', Validators.required],
      deviceType: [''],
      sensorImageURL: ['', Validators.required],
      updatedBy: '',
      metadata: '',
      externalLink: [''],
      moduleType:['']
    });
  }

  ngOnInit(): void {
    this.sensorId = this.router.snapshot.paramMap.get('sensorid');
    this.sensorService.get(this.sensorId).subscribe((sensorData: any) => {
      this.formData = sensorData['Sensor'];

      if (this.formData.metadata) {
        this.metadataJSON = JSON.parse(this.formData.metadata);
      } else {
        this.metadataJSON = {};
      }

      this.sensorFormData = this.fb.group({
        sensorName: [this.formData.sensorName, Validators.required],
        sensorDesc: [this.formData.sensorDesc, Validators.required],
        sensorType: [this.formData.sensorType, Validators.required],
        deviceType: [this.formData.deviceType],
        moduleType:[this.formData.moduleType],
        sensorImageURL: [this.formData.sensorImageURL, Validators.required],
        updatedBy: [sessionStorage.getItem('username')],
        metadata: [JSON.stringify(this.metadataJSON)],
        externalLink: [
          this.metadataJSON['externalLink'] ||
            this.sensorFormData.value.externalLink,
        ],
      });
    });
     // for module type select all or one module
    this.selectedModule = [this.moduleTypes[0]];
    this.selectAllForDropdownItems(this.moduleTypes);
  }

  saveSensor() {
    this.metadataJSON = JSON.parse(this.sensorFormData.value.metadata);
    this.metadataJSON['externalLink'] = this.sensorFormData.value.externalLink;
    this.sensorFormData.value.metadata = JSON.stringify(this.metadataJSON)
    this.sensorService
      .updateV2(this.sensorId, this.sensorFormData.value)
      .subscribe((data: any) => {
        if(data?.success == true) {
          this.toasterService.success("Sensor updated successfully");
        } else {
          this.toasterService.error("Can not update the sensor currently. Please try again later.");
        }
      });
  }

  /**on module type select (all or one module) */
  selectAllForDropdownItems(items: any) {
    let allSelect = (items: any) => {
      items.forEach((element: any) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
