import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-credentials-warning',
  templateUrl: './credentials-warning.component.html',
  styleUrls: ['./credentials-warning.component.scss']
})
export class CredentialsWarningComponent implements OnInit {

  private source: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((data: any) => {
      this.source = data?.params?.source || "";
      this.source = decodeURIComponent(this.source);
      if (this.source) {
        console.log({ source: this.source });
      }
    });
  }

  onRefresh(): void {
    if (this.source != "") {
      this.toasterService.warning("Refreshing page...");
      this.router.navigateByUrl(this.source);
    }
  }

}
