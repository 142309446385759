import { IAuthState, initialAuthState } from "./auth/auth.state";
import { ILayout, initialLayoutState } from "./layout/layout.state";
import {
  CircuitDesignState,
  initialCircuitState,
} from "../store/circuit-design/c-d.state";
import { IApplicationState, initialApplication } from "../admin/container/application/store/application.state";

export interface IAppState {
  application: IApplicationState;
  auth: IAuthState;
  layout: ILayout;
  circuitDesign: CircuitDesignState;
}

export const initialAppState: IAppState = {
  application: initialApplication,
  auth: initialAuthState,
  layout: initialLayoutState,
  circuitDesign: initialCircuitState,
};

export function getInitialState(): IAppState {
  return initialAppState;
}
