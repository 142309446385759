import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  NgModule,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IDEService } from 'src/app/shared/services/ide.service';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { DevicesService } from 'src/app/shared/services/devices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UpdateDevice } from 'src/app/shared/models/device';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.scss'],
})
export class EditDeviceComponent implements OnInit {
  
  deviceName!: string;
  token!: any;
  showInfoPanel: boolean = false;
  savedDeviceName!: string;
  isSuperAdmin: boolean = true;

  constructor(
    private router: Router,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = window.location.href.includes('/admin/');
  }

  async onSubmit() {
    const username = this.sessionStorageService.getUsername();
    const device_name = this.deviceName;
    if (device_name === undefined || device_name === '' || device_name === null)
      return;
    try {
      const result: any = await this.ideService.addDevice(device_name, username).toPromise();
      if (result) {
        this.token = result?.token;
        this.showInfoPanel = true;
        this.savedDeviceName = this.deviceName;
        this.deviceName = '';
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  copyToken() {
    let dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = this.token;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.toasterService.success(`${this.token} is copied!`);
    this.cancel();
  }

  enableAddDevice() {
    this.showInfoPanel = false;
    this.savedDeviceName = '';
    this.token = '';
  }

  cancel(){
    if (this.isSuperAdmin) {
      this.router.navigateByUrl('/admin/devices')
    } else {
      this.router.navigateByUrl('/ide/devices')
    }
  }
}

@NgModule({
  declarations: [EditDeviceComponent],
  imports: [CommonModule, FormsModule,ReactiveFormsModule],
  exports: [EditDeviceComponent],
})
export class EditDeviceModule {}
