import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CodeEditorModule } from "@ngstack/code-editor";
import { AngularSplitModule } from "angular-split";
import { PythonDirectComponent } from "./python-direct.component";
import { EditorScreenComponent } from './editor-screen/editor-screen.component';
import { OutputScreenComponent } from './output-screen/output-screen.component';
import { SafePipesModule } from "src/app/shared/pipes/safe-pipe.module";
;


@NgModule({
    declarations:[PythonDirectComponent, EditorScreenComponent, OutputScreenComponent],
    imports:[CommonModule,FormsModule,AngularSplitModule,CodeEditorModule.forRoot(),SafePipesModule],
    exports:[PythonDirectComponent,OutputScreenComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class PythonDirectModule {}
