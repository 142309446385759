import { OnChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { createlab } from 'src/app/shared/models/lab';
import { LabService } from 'src/app/shared/services/lab.service';
import { OrganizationsService, Organization } from 'src/app/shared/services/organizations.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-lab',
  templateUrl: './create-lab.component.html',
  styleUrls: ['./create-lab.component.scss']
})
export class CreateLabComponent implements OnInit {

  labId!: number;
  labSaved: boolean = false;
  createLabForm = new FormGroup({
    orgName: new FormControl('', [Validators.required]),
    labName: new FormControl('', [Validators.required])
  })
  currentUser: any;
  orgList: Organization[] = [];
  isSuperAdmin: boolean = true;
  isGrokCloudMember: boolean = true;
  currentOrganization!: string;
  organizationName!: string;

  constructor(
    private router: Router,
    private labService: LabService,
    private sessionStorageService: SessionStorageService,
    private toasterService: ToastrService,
    private orgService: OrganizationsService
  ) { 
    this.setDefaultOrganization();
    this.isSuperAdmin = window.location.href.includes('/admin/');
  }

  setDefaultOrganization() {
    this.currentOrganization = this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization === environment.organizationID) { }
    else {
      this.organizationName = this.currentOrganization;
      this.isGrokCloudMember = false;
      this.createLabForm.patchValue({
        orgName: this.organizationName
      });
    }
  }

  ngOnInit(): void {
    this.searchOrganizations();
    this.currentUser = this.sessionStorageService.getUsername();
  }

  async searchOrganizations() {
    try {
      const result: any = await this.orgService.search().toPromise();
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }

  onSubmit() {
    const controls = this.createLabForm.controls;
    if (!this.createLabForm.invalid) {
      const payload: createlab = {
        labName: controls['labName'].value,
        orgName: controls['orgName'].value,
        createdBy: this.currentUser
      };
      this.labService.create(payload).subscribe((res: any) => {
        if (res['success'] == true) {
          this.toasterService.success("Lab create successfully");
          this.onCancel();
        } else {
          this.toasterService.error("Can not create the lab currently. Please try again later.");
        }
      })
    }
  }

  onCancel() {
    if (this.isSuperAdmin) {
      this.router.navigateByUrl('/admin/labs');
    } else {
      this.router.navigateByUrl('/ide/labs');
    }
  }
}
