<div class="p-8">
    <div class="device-mg">
        Device Session
    </div> 
        <div class="box items-center">
            <div class="relative w-full btn-1 my-6">
                <label class="flex absolute inset-y-0  items-center pl-3 pointer-events-none">                        
                    <svg aria-hidden="true" class="w-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </label>
                <input type="text" id="simple-search" class="rounded-lg btn-1 border-gray-200 org-name block p-2.5"  
                placeholder="Search by device or lab name" (keyup)="applyTextFilter($event)" style="padding-left: 35px;"
                [formControl]="searchString">
                </div> 
                <div class="flex p-2 items-center my-6 mr-2">
                    <label>
                        <input type="checkbox" class="ch-box rounded" 
                        [formControl]="availableDevicesOnly"
                        (click)="toggleAvailableDevicesCheckbox()">
                    </label>
                    Only show available devices
                </div>
                <div class="relative push my-6 mr-2">
                    <button class="bg-greenish-blue btn-1-wh text-white p-4 rounded-md mr-2" id="create-button"
                      (click)="refresh()">
                      <span class="btn-1">Refresh</span>
                    </button>
                    <select *ngIf="isGrokCloudMember" name="orgNames" id=""
                        class="btn-2 border-gray-200 rounded-lg mb-5 input-style org-name"
                        (change)="applyOrganizationFilter($event)">
                        <option value="" class="org-name">All Organizations</option>
                        <ng-container *ngFor="let org of orgList">
                            <option class="input-style"[value]="org.orgName">{{org.orgName}}</option>
                        </ng-container>
                    </select>
                    <select *ngIf="!isGrokCloudMember" name="labNames" id=""
                        class="btn-2 border-gray-200 rounded-lg mb-5 input-style org-name">
                        <option value="" disabled selected [value]="appliedOrgName">{{appliedOrgName}}</option>
                    </select>
                </div>                              
        </div>
        <div class="min-h-screen flex">
        <div class='overflow-x-auto w-full'>
            <table class="mx-auto max-w-full w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden">
                <thead class="bg-gray-200">
                    <tr class="text-gray-60 text-left">
                        <th class="font-semibold text-sm uppercase px-2 py-4">
                            <div class="flex inline-flex items-center">
                                <span>
                                    Device Name
                                </span>
                                <span (click)="changeOrdDeviceName('deviceName','DESC')" *ngIf="isDeviceName">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                </span>
                                <span (click)="changeOrdDeviceName('deviceName','ASC')" *ngIf="!isDeviceName">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <!-- <th class="font-semibold text-sm uppercase px-2 py-4">Device Token</th> -->
                        <th class="font-semibold text-sm uppercase px-2 py-4">
                            <div class="flex inline-flex items-center">
                                <span>
                                    Lab Name
                                </span>
                                <span (click)="changeOrdLabName('labName','DESC')" *ngIf="isLabName">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                </span>
                                <span (click)="changeOrdLabName('labName','ASC')" *ngIf="!isLabName">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <th class="font-semibold text-sm uppercase px-2 py-4">
                            <div class="flex inline-flex items-center">
                                <span>
                                    Org Name
                                </span>
                                <span (click)="changeOrdOrgName('orgName','DESC')" *ngIf="isOrgName">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                </span>
                                <span (click)="changeOrdOrgName('orgName','ASC')" *ngIf="!isOrgName">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <th class="font-semibold text-sm uppercase px-2 py-4">
                            <div class="flex inline-flex items-center">
                                <span>
                                    User Associated
                                </span>
                                    <span (click)="changeOrdAssosiatedUser('associatedUser','DESC')" *ngIf="isAssociatedUser">
                                        <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                    </span>
                                    <span (click)="changeOrdAssosiatedUser('associatedUser','ASC')" *ngIf="!isAssociatedUser">
                                        <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <th class="font-semibold text-sm uppercase px-2 py-4 text-center">
                            <div class="flex inline-flex items-center">
                                <span>
                                    Time Left
                                </span>
                                <span (click)="changeOrdTimeLeft('timeLeft','DESC')" *ngIf="isTimeLeft">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                </span>
                                <span (click)="changeOrdTimeLeft('timeLeft','ASC')" *ngIf="!isTimeLeft">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <th class="font-semibold text-sm uppercase px-2 py-4 text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="divide-y divide-gray-200 cursor-pointer" *ngFor="let item of filteredDeviceSessions">
                    <!-- <td class="py-3 px-2 text-left whitespace-nowrap">{{!item?.mac_address ? "---" : item.mac_address }}</td> -->
                    <td class="py-3 px-2 text-left whitespace-nowrap">{{item.deviceName}}</td>
                    <!-- <td class="py-3 px-2 text-left whitespace-nowrap">
                        <div class="my-2 flex flex-row text-right">
                        <button class="bg-transparent border-rad shadow-xl text-white border p-2"
                            (click)="copyToken(item.token)">
                            <div class="flex items-center h-4">
                            <img src="assets/img/icons/duplicate.svg" class="h-4 w-4 icon" >
                            <p class="cp">Copy</p>
                            </div>
                        </button>
                        </div>
                    </td> -->
                    <td class="py-3 px-2 text-left whitespace-nowrap">{{item.labName}}</td>
                    <td class="py-3 px-2 text-left whitespace-nowrap" >{{item.orgName}}</td>
                    <td class="py-3 px-2 text-left whitespace-nowrap">{{item.associatedUser? item.associatedUser:'-'}}</td>
                    <td class="text-center py-3 px-2 whitespace-nowrap">
                        {{item.timeLeft?item.timeLeft:'-'}}
                    </td>                    
                    <td class="py-3 px-2 whitespace-nowrap text-center">
                        <div class="my-2 text-center">
                            <button (click)="openModal(item,'associate')" class="btn-associate text-btn m-2" *ngIf="!item.associatedUser">Associate</button>
                            <button (click)="openModal(item,'extend')" class="btn-extend text-btn m-2" *ngIf="item.associatedUser">Extend</button>
                            <button (click)="openModal(item,'dissociate')" class="btn-dissociate text-btn m-2" *ngIf="item.associatedUser">Dissociate</button>
                        </div>
                    </td>
                </tbody>
            </table>
            <div class="text-center" *ngIf="!isDevice">
            <p class="text-xl font-bold m-5 p-10 text-gray-800">No Device Added</p>
            </div>
        </div>
        </div>
    </div>

    <ng-container *ngIf="isAction" >
        <app-action-popup [actionType]="actionType" [sessionData]="sessionData" (emitCloseEvent)="closeModal($event)"></app-action-popup>
    </ng-container>
    
