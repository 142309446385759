<div class="relative flex">

  <!-- Circuit Diagram -->
  <div id="container-draggable" [class.zoomin]="zoom"></div>

  <!-- Control Buttons -->
  <div class="control-btn-group">
    <button (click)="controlLogic('zoomIn')">
      <img src="assets/img/icons/zoomin.svg" title="Zoom In" />
    </button>
    <button (click)="controlLogic('zoomOut')">
      <img src="assets/img/icons/zoomout.svg" title="Zoom Out" />
    </button>
    <button (click)="controlLogic('maximize')">
      <img src="assets/img/icons/maximize.svg" title="Maximize" />
    </button>
  </div>
</div>
