import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { createlab, serachLab, updateLab } from "../models/lab";

/**
 * Service class to handle data and http request related to Labs APIs.
 */
@Injectable({
    providedIn: "root",
})
export class LabService{
    constructor(private http:HttpClient){}

    /**
   * Method to create a new lab.
   * @param lab
   */
    create(lab: createlab) {
        const url = `${environment.apiURL}/admin/labs/create`;
        return this.http.post<createlab>(url, lab);
    }

    /**
   * Method to get data of a lab.
   * @param labIdentifier
   */
    get(labIdentifier: any) {
        const url = `${environment.apiURL}/admin/labs/get?lab_identifier=${labIdentifier}`;
        return this.http.get<createlab>(url);
    }

    /**
   * Method to update data of a lab.
   * @param labIdentifier
   * @param updates
   */
    update(labIdentifier: number, updates?: any) {
        const url = `${environment.apiURL}/admin/labs/update?lab_identifier=${labIdentifier}`;
        return this.http.put<updateLab>(url,  updates );
    }

    /**
   * Method to delete a lab.
   * @param labID
   */
    deleteLab(labId:string){
        const url = `${environment.apiURL}/admin/labs/delete?lab_identifier=${labId}`;
        return this.http.delete(url);
    }


    /**
   * Method to search or sort  labs.
   * @param payload
   */
    search(payload: serachLab) {
        const url = `${environment.apiURL}/admin/labs/search`;
        return this.http.post<{}>(url, payload);
    }

}