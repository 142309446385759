<app-loader *ngIf='loading$ | async'></app-loader>

<div [class.layout]="showLayoutComponents$ | async">
  <app-navbar class="navbar" *ngIf="showLayoutComponents$ | async"></app-navbar>
  <!-- <app-side-menu class="sidenav" *ngIf="showLayoutComponents$ | async"></app-side-menu> -->

  
  <main>
    <router-outlet  class="col-span-3"></router-outlet>
  </main>

</div>

