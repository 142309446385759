import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { routes } from './auth.routes';
import { LoginModule } from './container/login/login.module';
import { AutoLoginComponent } from './container/auto-login/auto-login.component';

@NgModule({
  declarations: [
  
    AutoLoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoginModule,
    RouterModule.forChild(routes)
  ],
  exports: []
})
export class AuthModule { }
