<ng-container *ngIf="blockData">
  <div class="w-full h-screen">
    <as-split direction="horizontal" [disabled]="true" [gutterSize]=0>
      <as-split-area [size]="isMenuOpen ? 25 : 5">
        <ng-container *ngIf="hasAppId" >
          <iot-devices-section [sensors_id]="blockData?.sensors" (emitDraggedElement)="setDraggedElement($event)"
            (emitCollapseEvent)="toggleSideMenu($event)"></iot-devices-section>
        </ng-container>
        <ng-container *ngIf="!hasAppId" >
          <iot-devices-section [sensors]="blockData?.sensors" (emitDraggedElement)="setDraggedElement($event)"
            (emitCollapseEvent)="toggleSideMenu($event)"></iot-devices-section>
        </ng-container>
      </as-split-area>
      <as-split-area [size]="isMenuOpen ? 75 : 95">
        <as-split direction="vertical" [disabled]="true" [gutterSize]=0>
          <as-split-area [size]="isFullWorkspace ? 0 : isFullCircuit ? 100 : 50" [minSize]="30" [maxSize]="80"
            *ngIf="hasAppId">
            <div class="w-full h-screen relative">
              <app-prebuild-circuit [loadExistingCircuitJSON]="blockData.circuitDefinition" 
                [zoom]="true" [isMenuOpen]="isMenuOpen"
                [isAdmin]="false" (emitWindowSize)="setWindowSize($event)" 
                [circuitMismatchCount]="circuitMismatches.length"
                (emitCircuitComparisonTrigger)="openCircuitDifferencesPanel();
                renderMismatchedListItems()"
                [isFullCircuit]="isFullCircuit" [tutorialsData]="toturials">
              </app-prebuild-circuit>
            </div>
          </as-split-area>
          <as-split-area [size]="isFullWorkspace ? 100 : isFullCircuit ? 0 : 50" [minSize]="30" [maxSize]="80"
            *ngIf="true">
            <div class="bg-white w-full h-screen relative">
              <app-workspace data-attribute-id="workspace" 
                [element]="draggedElement" [isAdmin]="false" 
                [isFullCircuit]="isFullCircuit" [applicationIdentifier]="applicationIdentifier"
                (emitUpdatedJSON)="circuitUpdated($event)"
                (emitWindowSize)="setWindowSize($event)" [isFullWorkspace]="isFullWorkspace" 
                (openInfoPanel)="viewInformation($event)"></app-workspace>
            </div>
          </as-split-area>
        </as-split>
        <div id="draggable-ref-diagram" style="display: none;">
          <app-draggable-prebuild-circuit [loadExistingCircuitJSON]="blockData.circuitDefinition" [zoom]="true" [isMenuOpen]="isMenuOpen"
          [isAdmin]="false" (emitWindowSize)="setWindowSize($event)" [isFullCircuit]="isFullCircuit">
          </app-draggable-prebuild-circuit>
        </div>
      </as-split-area>
    </as-split>
  </div>
</ng-container>

<!-- Info Tour -->
<div>
  <button 
    class="p-2 absolute bottom-5 right-2 rounded-full z-60" 
    (click)="startTour()" 
    *ngIf="isTemplate"
    [class.cursor-pointer]="!isTutorialsDisabled"
    [class.cursor-no-drop]="isTutorialsDisabled">
    <!-- *ngIf="isTemplate" -->
    <img 
      [src]="isTutorialsDisabled ? 'assets/img/info-gray.svg' : 'assets/img/info.svg'"
      class="h-10 w-10" 
      title="{{title}}" 
      />
  </button>
</div>

<ng-container *ngIf="infoSteps">
  <ng-container *ngFor="let step of infoSteps let i = index;">
    <div [title]="step?.value || step?.title" joyrideStep="{{getJoyId(i,step)}}" [prevTemplate]="preButton" [stepPosition]="step.direction | lowercase"
      class="absolute" [class]="step.location | lowercase"  [nextTemplate]="nextButton" [stepContent]="step.type === 'video' ?  videoTutorial : undefined"  
      [stepContentParams]="{'tutorialUrl': step?.url}" [doneTemplate]="doneButton"
      >
    </div>
  </ng-container>
</ng-container> 

<ng-template #preButton>
  <button class="reverse-next-img"><img src="assets/img/next.svg"></button>
</ng-template>
<ng-template #nextButton>
  <button><img src="assets/img/next.svg"></button>
</ng-template>
<ng-template #doneButton>
  <button class="font-bold text-xs">END</button>
</ng-template>
<ng-template #videoTutorial let-tutorialUrl="tutorialUrl">
  <iframe [src]="tutorialUrl"></iframe>
</ng-template>

<app-side-panel *ngIf="(showSidePanel$ | async)" (emitCloseEvent)="sidePanelClosed($event)">
  <app-info-panel [sensorInfo]="sensorInfo"> </app-info-panel>
</app-side-panel>

<app-side-panel #mismatchPanel *ngIf="showCircuitDifferences" (emitCloseEvent)="closeCircuitDifferencesPanel()">
  <div style="margin-left: 15px;">
    <p class="heading-1"> Issues Found</p>
    <p class="sub-title-1">Review the issues found in the circuit</p>
    <div id="mismatchList"></div>
  </div>
</app-side-panel>