function readFile(file: string) {
  let allText;
  var raw = new XMLHttpRequest(); // create a request
  raw.open("GET", file, false); // open file
  raw.onreadystatechange = function () { // file is ready to read
    if (raw.readyState === 4) {
      if (raw.status === 200 || raw.status == 0) {
        allText = raw.responseText;
      }
    }
  }
  raw.send(null); // return control
  return allText;
}

// const toolbox = readFile("assets/blockly/toolbox.xml");
const common_cat = readFile("assets/blockly/toolbox/common.xml");
const iot_cat = readFile("assets/blockly/toolbox/iot.xml");
const aiot_cat = readFile("assets/blockly/toolbox/aiot.xml");
const robotics_cat = readFile("assets/blockly/toolbox/robotics.xml");
const data_science_cat = readFile("assets/blockly/toolbox/data_science.xml");
const artificial_intelligence_cat = readFile("assets/blockly/toolbox/artificial_intelligence.xml");
const iiot_cat = readFile("assets/blockly/toolbox/iiot.xml");
const admin_tools_cat = readFile("assets/blockly/toolbox/admin_tools.xml");

export const xml_data = `
  <xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
    ${common_cat}
  </xml>
`;

export function getToolbox(module_name?: string): string{
  let inner_xml = "";
 inner_xml += common_cat;
  switch (module_name?.toLocaleLowerCase()) {
    case "iot":
      inner_xml += iot_cat;
      break;
    case "aiot":
      inner_xml += aiot_cat;
      break;
    case "iiot":
      inner_xml += iiot_cat;
      break;
    case "robotics":
      inner_xml += robotics_cat;
      break;
    case "data_science":
      inner_xml += data_science_cat;
      break;
    case "artificial_intelligence":
      inner_xml += artificial_intelligence_cat;
      break;
    default:
      inner_xml += iot_cat;
      inner_xml += robotics_cat;
      inner_xml += data_science_cat;
      inner_xml += aiot_cat;
      inner_xml += artificial_intelligence_cat;
      inner_xml += iiot_cat;
      inner_xml += admin_tools_cat;
      break;
  }
  let output_xml = `
    <xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
      ${inner_xml}
    </xml>
  `;
  return output_xml;
}
