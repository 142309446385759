import { ActionReducerMap } from '@ngrx/store';

import { ActionReducer, Action, INIT } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';

import circuitHistoryAction, { Undoable } from './circuit-history.action';
import { IAppState, initialAppState } from '../index.state';

export const reducerMap = {} as ActionReducerMap<IAppState>;



export interface History {
  past: IAppState[];
  present: IAppState;
  future: IAppState[];
}

const historySub = new BehaviorSubject<History>({
  past: [],
  present: initialAppState,
  future: []
});

export const history$: Observable<History> = historySub.asObservable();

export function historyReducer(reducer: ActionReducer<IAppState>) {
  historySub.next({
    past: historySub?.value?.past || [],
    present: reducer(initialAppState, { type: INIT }),
    future: historySub?.value?.future || []
  });
  return function (state: IAppState, action: Action) {
    const { past, present, future } = historySub.value;
    switch (action.type) {
      case circuitHistoryAction.undo.type: {
        if (!past.length) {
          return state;
        }
        // use first past state as next present
        const previous = past[0];
        // ... and remove from past
        const newPast = past.slice(1);
        historySub.next({
          past: newPast,
          present: cloneDeep(previous),
          // push present into future for redo
          future: [present, ...future]
        });
        // console.log("Undo", historySub.value);
        return previous;
      }
      case circuitHistoryAction.redo.type: {
        if (!future.length) {
          return state;
        }
        // use first future state as next present
        const next = future[0];
        // ... and remove from future
        const newFuture = future.slice(1);
        historySub.next({
          // push present into past for undo
          past: [present, ...past],
          present: cloneDeep(next),
          future: newFuture
        });
        // console.log("Redo", historySub.value);
        return next;
      }
      default: {
        // derive next state
        const newPresent = reducer(state, action);
        if (present === newPresent) {
          return state;
        }
        // update undoable history
        if ((action as Undoable).undoable) {
          let newPast = [present, ...past];

          /**
           * Clear circuit was disabling undo-redo feature by making newPast = [] which erased past.
           * To keep undo-redo feature we need to maintain past, present & future.
           * To fix that bug we have added newPast = past.
           */
          if (["{}", "[]"].includes(JSON.stringify(present.circuitDesign?.undoableData?.children))) {
            newPast = past;
          }

          historySub.next({
            // push previous present into past for undo
            past: newPast,
            present: cloneDeep(newPresent),
            future: [] // clear future
          });
        }
        // console.log("Default", historySub.value);
        return newPresent;
      }
    }
  }
}
