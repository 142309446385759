<aside class="bg-greenish-blue h-full overflow-auto flex flex-col justify-between py-4">
  <section>
    <ul class="text-white text-sm">
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" [routerLink]="'/admin/dashboard'" [routerLinkActive]="['active']">
        <div class="flex items-center">
          <img src="assets/img/sidenav/dashboard.svg" class="h-6 w-6 mr-4 icon">
          <p>Dashboard</p>
        </div>
      </li>
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" [routerLink]="['/admin/applications']" [routerLinkActive]="['active']">
        <div class="flex items-center">
          <img src="assets/img/sidenav/feature.svg" class="h-6 w-6 mr-4 icon">
          <p>Applications</p>
        </div>
      </li>
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" [routerLink]="['/admin/sensors']" [routerLinkActive]="['active']">
        <div class="flex items-center">
          <img src="assets/img/sidenav/circuit-board.png" alt="Sensors Image" class="h-6 w-6 mr-4 icon">
          <p>Sensors</p>
        </div>
      </li>
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" [routerLink]="['/admin/application/create']" [routerLinkActive]="['active']">
        <div class="flex items-center">
          <img src="assets/img/sidenav/feature.svg" class="h-6 w-6 mr-4 icon">
          <p>Create Template</p>
        </div>
      </li>
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" [routerLink]="['/admin/users-session']" [routerLinkActive]="['active']">
        <div class="flex items-center">
          <img src="assets/img/sidenav/feature.svg" class="h-6 w-6 mr-4 icon">
          <p>Users Session</p>
        </div>
      </li>
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" [routerLink]="'/admin/devices'" [routerLinkActive]="['active']">
        <div class="flex items-center">
          <img src="assets/img/sidenav/circuit-board.png" class="h-6 w-6 mr-4 icon">
          <p>Devices</p>
        </div>
      </li>
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" [routerLink]="'/admin/circuit'" [routerLinkActive]="['active']">
        <div class="flex items-center">
          <img src="assets/img/sidenav/circuit.svg" class="h-6 w-6 mr-4 icon">
          <p>Circuit Design</p>
        </div>
      </li>
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" [routerLink]="'/admin/block'" [routerLinkActive]="['active']">
        <div class="flex items-center">
          <img src="assets/img/sidenav/block.svg" class="h-6 w-6 mr-4 icon">
          <p>Block Design</p>
        </div>
      </li>
    </ul>
  </section>
  <section>
    <ul class="text-white text-sm">
      <li class="font-semibold p-4 m-4 hover:bg-body hover:text-greenish-blue cursor-pointer" (click)="logout()">
        <div class="flex  items-center">
          <img src="assets/img/sidenav/exit.svg" class="h-6 w-6 mr-4 icon">
          <p>Logout</p>
        </div>
      </li>
    </ul>
  </section>
</aside>
