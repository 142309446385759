import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IotAppComponent } from './iot-app.component';
import { MobileLoginModule } from '../../components/mobile-login/mobile-login.module';
import { ScreenListModule } from '../../components/screen-list/screen-list.module';
import { ChooseModuleModule } from '../../components/choose-module/choose-module.module';

@NgModule({
  declarations: [
    IotAppComponent,
  ],
  imports: [
    CommonModule,
    MobileLoginModule,
    ScreenListModule,
    ChooseModuleModule
  ],
  exports: [
    IotAppComponent
  ]
})
export class IotAppModule { }
