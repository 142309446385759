import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaginationModule } from 'src/app/components/pagination/pagination.component';
import { EditApplicationModule } from './components/edit-application/edit-application.component';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { ApplicationComponent } from './application.component';
import { ApplicationEffect } from './store/application.effects';
import { applicationReducer } from './store/application.reducers';
import { APPLICATION_FEATURE } from './store/application.selectors';

@NgModule({
  declarations: [
    ApplicationComponent,
     ],
  imports: [
    CommonModule,
    EditApplicationModule,
    PaginationModule,
    ModalModule,
    StoreModule.forFeature(APPLICATION_FEATURE, applicationReducer),
    EffectsModule.forFeature([ApplicationEffect])
  ],
  exports: [
    ApplicationComponent
  ]
})
export class ApplicationModule { }
