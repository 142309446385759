<div class="">
  <div class="mb-10 flex flex-col">
    <label class="sr-only">Name</label>
    <input class="input-style" name="app-name" [(ngModel)]="applicationName" placeholder="Application Name">
  </div>

  <div class="mb-10 flex flex-col">
    <label class="sr-only">Description</label>
    <input class="input-style" name="app-desc" [(ngModel)]="applicationDesc" placeholder="Application Description">
  </div>

  <div class="">
    <button class="bg-button text-white p-2 rounded-md shadow font-bold mr-2" (click)="save()">{{selectedApplication ?
      'UPDATE': 'SAVE'}} APPLICATION</button>
    <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button" (click)="cancel()">CANCEL</button>
  </div>
</div>