import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidePanelModule } from 'src/app/components/side-panel/side-panel.module';
import { DeviceComponent } from '../device/device.component';
import { EditDeviceModule } from "./components/user-device-add/edit-device.component";
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditUserDeviceComponent } from './components/edit-user-device/edit-user-device.component';

@NgModule({
  declarations: [
    DeviceComponent,
    EditUserDeviceComponent
  ],
  imports: [
    CommonModule,
    SidePanelModule,
    EditDeviceModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    DeviceComponent
  ]
})
export class DeviceModule { }
