import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from '@angular/forms';

@Component({
  selector: "app-save-list",
  templateUrl: "./save-list.component.html",
  styleUrls: ["./save-list.component.scss"],
})
export class SaveListComponent implements OnInit {
  @Output() saveDataEvent = new EventEmitter();
  @Input() applications: any[] = [];
  @Input() selectedAppliation: any;

  constructor() {}

  ngOnInit(): void {}

  saveData(data: any) {
    this.saveDataEvent.emit(data);
  }

  customSearchFn(term: string, item: any) {
    term = term?.toLowerCase();
    return (
      item.applicationName?.toLowerCase().indexOf(term) > -1 ||
      item.applicationName?.toLowerCase() === term
    );
  }
}

@NgModule({
  declarations: [SaveListComponent],
  imports: [CommonModule, NgSelectModule, FormsModule],
  exports: [SaveListComponent],
})
export class SaveListModule {}
