import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';
import { BlockProgrammingComponent } from './block-programming.component';
import { BlocklyModule } from '../../../shared/components/blockly/blockly.module';
import { JoyrideModule } from 'ngx-joyride';
import { BlocklySectionModule } from '../../../admin/components/blockly-section/blockly-section.component';
import { SidePanelModule } from 'src/app/components/side-panel/side-panel.module';
import { SaveListModule } from '../../../admin/components/save-list/save-list.component'
import { SaveBlockCircuitModule } from '../../../shared/components/save-block-circuit/save-block-circuit/save-block-circuit.module'
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SafePipesModule } from 'src/app/shared/pipes/safe-pipe.module';
import { OutputScreenComponent } from 'src/app/admin/container/python-direct/output-screen/output-screen.component';
import { PythonDirectModule } from 'src/app/admin/container/python-direct/python-direct.module';


@NgModule({
  declarations: [BlockProgrammingComponent],
  imports: [
    CommonModule,
    AngularSplitModule,
    BlocklyModule,
    BlocklySectionModule,
    SidePanelModule,
    SaveListModule,
    SaveBlockCircuitModule,
    FormsModule,
    ModalModule,
    SafePipesModule,
    JoyrideModule.forRoot(),
    PythonDirectModule
  ],
  exports: [BlockProgrammingComponent],
})
export class BlockProgrammingModule {}
