import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs/internal/observable/throwError";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (request.url.includes(environment.apiURL)) {
      let headers = request.headers;
      if (token) {
        headers = headers.set("Authorization", token);
      }
      if (request.url.includes("verify-user-login")) {
        let localSavedCookie = localStorage.getItem('sessionId');
        if (localSavedCookie != null) {
          headers = headers.set("Cookie-Header", localSavedCookie);
        }
      }
      request = request.clone({ headers });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
