export interface ILayout {
  showLayout: boolean;
  showSpinner: boolean;
  showSidePanel: boolean;
}

export const initialLayoutState: ILayout = {
  showLayout: false,
  showSpinner: false,
  showSidePanel: false
}
