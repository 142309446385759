import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-step-tracker',
  templateUrl: './step-tracker.component.html',
  styleUrls: ['./step-tracker.component.scss'],
})
export class StepTrackerComponent implements OnInit, OnChanges {
  @Input() isInline!: boolean;
  @Input() currentStep: number = 1;
  @Input() moduleKey!: string;

  statusTracker: IStatusTracker[];
  remainingSteps: IStatusTracker[];

  @Output() allSteps = new EventEmitter();
  @Output() emitClickEvent = new EventEmitter();

  constructor(
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    this.initTracker();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  ngOnChanges(changes?: SimpleChanges) {
    const hasModuleKey = changes?.moduleKey?.currentValue;
    const step = changes?.currentStep?.currentValue;
    if (hasModuleKey) {
      this.initTracker();
      this.updateTracker();
    }
  }

  private initTracker() {
    this.statusTracker = [
      {
        title: 'Basic Details',
        index: 1,
      },
    ];
  }

  private updateTracker() {
    switch (this.moduleKey) {
      case 'iot':
      case 'aiot':
      case 'iiot':
        this.remainingSteps = [
          {
            title: 'Select Sensors',
            index: 2,
          },
          {
            title: 'Create Circuit',
            index: 3,
          },
          {
            title: 'Create Program',
            index: 4,
          },
          {
            title: 'Mobile Control Panel',
            index: 5,
          },
        ];
        break;
      case 'robotics':
        this.remainingSteps = [
          {
            title: 'Select Sensors',
            index: 2,
          },
          {
            title: 'Create Circuit',
            index: 3,
          },
          {
            title: 'Create Program',
            index: 4,
          }
        ];
        break;
      case 'data_science':
      case 'artificial_intelligence':
        this.remainingSteps = [
          {
            title: 'Create Program',
            index: 2,
          },
        ];
        break;
      default:
        this.initTracker();
        break;
    }
    if (this.remainingSteps) {
      this.statusTracker = [...this.statusTracker, ...this.remainingSteps];
      this.allSteps.emit(this.statusTracker);
    }
  }

  progress() {
    const progress = (this.statusNumber() * 100) / this.statusTracker.length;
    if (progress > 100) {
      return 100;
    }
    return progress;
  }

  statusNumber() {
    const status: any = this.statusTracker.find(
      (item) => item.index === this.currentStep
    );
    return status.index;
  }
}

@NgModule({
  declarations: [StepTrackerComponent],
  imports: [CommonModule],
  exports: [StepTrackerComponent],
})
export class StepTrackerModule { }

export interface IStatusTracker {
  title: string;
  index: number;
}
