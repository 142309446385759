<div class="grid grid-cols-1">
  <div class="p-2">
    <button class="blue-btn float-right custom-lg-btn" (click)="onNext()">Save & Exit</button>
    <button class="grey-btn float-right custom-lg-btn" (click)="goBack()">Back</button>
  </div>
</div>

<!-- Sensors Panel -->
<ng-container class="flex flex-col justify-between items-start">
  <div class="p-2 my-2 flex flex-row items-center">
    <button class="green-btn" (click)="addNewCondition()">Add condition</button>
  </div>
</ng-container>
<div class="sortable">
  <ng-container *ngFor="let item of conditions" class="flex flex-row justify-between items-center">
    <div id="conditions" class="flex flex-row justify-around items-center mb-2 flex-wrap">
      <div class="w-15">
        <form [formGroup]="sensorForm" novalidate>
          <label for="sensorId">Select Sensor</label>
          <ng-select appearance="outline" [searchable]="false" [clearable]="false" labelForId="sensorId"
            (change)="saveSensor($event, item._id)" formControlName="sensorId" class="sensor-dropdown-w">
            <ng-option [value]="customName" *ngFor="let customName of sensorCustomNames">
              {{ customName }}
            </ng-option>
          </ng-select>
        </form>
      </div>
      <div class="w-15">
        <form [formGroup]="logicForm" novalidate>
          <label for="logicId">Select Logic</label>
          <ng-select appearance="outline" [searchable]="false" [clearable]="false" labelForId="logicId"
            (change)="saveLogic($event, item._id)" formControlName="logicId">
            <ng-option [value]="element.key" *ngFor="let element of logic_options">
              {{ element.display }}
            </ng-option>
          </ng-select>
        </form>
      </div>
      <div class="flex flex-col">
        <label>Value</label>
        <input class="input-style" name="value" [(ngModel)]="item.value" placeholder="Value">
      </div>
      <div class="p-2" style="width: 20%">
        <span class="py-1 px-3 text-2xl m-2 green-btn move-container ">&#10021;</span>
        <span class="py-1 px-3 text-2xl m-2 red-btn" (click)="removeConditionField(item._id)">&times;</span>
      </div>
    </div>
  </ng-container>
</div>


<!-- Messages Panel -->
<ng-container class="flex flex-col justify-between items-start">
  <!-- Messages Heading-->
  <div class="p-2 my-2 flex flex-row items-center">
    <button class="green-btn" (click)="addNewField()">Add message</button>
  </div>

  <!-- Field Form Starts -->
  <div class="sortable">
    <div *ngFor="let item of input_fields" class="flex flex-row justify-around items-center flex-wrap" id="messages">
      <form [formGroup]="fieldForm" novalidate class="form-group">
        <label for="fieldId">Select Type</label>
        <ng-select appearance="outline" [searchable]="false" [clearable]="false" labelForId="fieldId"
          (change)="selectField($event, item._id)" formControlName="fieldId" class="field-select">
          <ng-option value="text">
            Text
          </ng-option>
          <ng-option value="image">
            Image
          </ng-option>
        </ng-select>
      </form>
      <div class="floating-input relative flex flex-col input-space">
        <input class="input-style" [name]="item.type === 'text' ? 'text-msg' : 'img-url'" [(ngModel)]="item.value"
          [placeholder]="item.type === 'text' ?  'Success Message' : 'Image URL'" [disabled]="item.type === 'image'">
        <!-- <label class="label-style">{{ item.type === 'text' ? 'Success Message' : 'Image URL' }}</label> -->
      </div>
      <div *ngIf="item.type === 'text'">
        <form [formGroup]="colorForm" novalidate>
          <label for="colorId">Select Color</label>
          <ng-select appearance="outline" [searchable]="false" [clearable]="false" labelForId="colorId"
            (change)="saveColor($event, item._id)" formControlName="colorId" class="field-select">
            <ng-option *ngFor="let element of colors" [value]="element.key">
              {{ element.name }}
            </ng-option>
          </ng-select>
        </form>
      </div>
      <div *ngIf="item.type !== 'text'">
        <label for="img" class="green-btn">Upload</label>
        <input class="img-input" type="file" id="img" name="img" accept="image/*" (change)="saveImage($event, item._id)"
          [(ngModel)]="item.image">
      </div>
      <div class="p-2" style="width: 20%">
        <span class="py-1 px-3 text-2xl m-2 green-btn move-container">&#10021;</span>
        <span class="py-1 px-3 text-2xl m-2 red-btn" (click)="removeField(item)">&times;</span>
      </div>
    </div>
  </div>
  <!-- Field Form Ends -->

</ng-container>


<div class="flex justify-around my-2">
  <button class="green-btn" (click)="addNext()">Save new condition</button>
</div>


<div class="sortable">
  <div *ngFor="let i of completeData" class="bg-ide-blue rounded-md flex flex-row justify-around my-2 text-white"
    id="conditions_messages">
    <div style="width: 40%; padding-left: 2rem">
      <p class="text-xl font-bold mb-5 px-4 p-2">Conditions</p>
      <!-- Conditions -->
      <div class="p-2 flex  items-center" *ngFor="let item of i.conditions">
        <span class="font-medium">{{ item.sensor }} &#8594;</span>
        <span>{{ item.logic }} &#8594;</span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <div style="width: 40%">
      <p class="text-xl font-bold mb-5 px-4 p-2">Messages</p>
      <!-- Conditions -->
      <div class="p-2 flex" *ngFor="let k of i.messages">
        <span>{{ k.type }}</span>
        <span> &#8594;</span>
        <span [style]="{ 'color': k.color }">{{ k.value }}</span>
      </div>
    </div>
    <div class="p-2" style="width: 20%">
      <span class="py-1 px-3 text-2xl m-2 green-btn move-container">&#10021;</span>
      <span class="py-1 px-3 text-2xl m-2 red-btn" (click)="removeCondition(i)">&times;</span>
    </div>
  </div>
</div>
