<div class="top-bar text-md">
  <div class="flex justify-evenly"><img src="assets/img/logo.png" class="icon h-10 my-5" /></div>
  <div class="username">{{current_username}}</div>
  <div *ngIf="dropdownOpen" (click)="changeDropdown(false)" class="fixed inset-0 h-full w-full z-10 bg-gray-200 opacity-50"></div>
  <div class="right-navbar" (click)="changeDropdown()">
    <img src="/assets/img/icons/3dots-vert.svg">
    <div *ngIf="dropdownOpen" class="absolute right-0 mt-2 py-2 bg-white rounded-md shadow-xl z-20">
      <span (click)="logout()" class="block px-4 py-2 text-sm capitalize text-blue-700 hover:bg-blue-500 hover:text-white" style="width: 6rem">
        Sign Out
      </span>
    </div>
  </div>
</div>
<div class="justify-center items-center">
  <div class="card-container" (click)="emitModule('iot')">
    Internet Of Things
  </div>
  <div class="card-container" (click)="emitModule('robotics')">
    Robotics
  </div>
</div>
