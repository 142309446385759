<div class="min-h-screen flex items-center justify-center   py-12 px-4 sm:px-6 lg:px-8 main-div">
    <div >
        <div>
            <img class="mx-auto w-auto image " src="assets/img/Illustration.svg">
        </div>
        <div class="mt-6 heading ">
            <p class="w-auto text-center text-xl lg:text-4xl">
                Oops looks like your credentials are already in use
            </p>
        </div>
        <div class="mt-6" >  
            <p class="mx-auto w-auto text-base paragraph font-normal" >
                If you’re the only one using this account you’ll need to log off from other devices and hit refresh 
                or contact the Grok administrator to help you get back in.
            </p>

        </div>  
        <div class=" mt-6 text-center">
            <button class="group relative  py-2 px-4 border text-white refresh-button" (click)="onRefresh()">
                REFRESH                
            </button>
        </div>
    </div>
</div>
