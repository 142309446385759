<div class="relative">
  <!-- Heading -->
  <div class="fixed m-2" [class.absolute]="isEdit || isAdmin">
    <h3 class="font-semibold p-4">Work Area</h3>
  </div>
  <div id="container"></div>
  <div id="menu" class="menu">
    <div>
      <button id="connect-to" (mouseenter)="connectToSubMenu(true)" [class.active]="isConnectToMenuOpen"
        *ngIf="isOverlay()">
        Connect to >>
      </button>
      <button id="remove-connection" class="coming-soon" (click)="konvaUtils.hideContextMenu()" *ngIf="isOverlay()">
        Remove Connection**
      </button>
      <button id="view-info" (click)="viewInformation(); konvaUtils.hideContextMenu();" *ngIf="!isOverlay()">
        View Info**
      </button>
      <button id="select-pins-button" (click)="selectPins($event)" [class.selecting]="konvaUtils.isSelectingPins"
        *ngIf="false">
        {{ konvaUtils.isSelectingPins ? "Stop selecting PINs" : "Start selecting PINs"}}
      </button>
      <button id="arrange" [class.active]="isArrangeMenuOpen" (mouseenter)="arrangeSubMenu(true)"
        (mouseleave)="arrangeSubMenu(false)" *ngIf="!isOverlay()">
        Arrange >>
      </button>
      <button id="change-color" *ngIf="konvaUtils.isCurrentShapeWire" (mouseenter)="changeColorSubMenu(true)"
        (mouseleave)="changeColorSubMenu(false)">
        Change color <span class="menu-arrow">></span>
      </button>
      <button id="duplicate" (click)="duplicateComponent($event)" *ngIf="!isOverlay()">
        Duplicate
      </button>
      <button id="delete-button" (click)="deleteCurrentShape($event)">
        Delete
      </button>
    </div>
  </div>

  <div id="arrange-menu" class="menu" (mouseenter)="arrangeSubMenu(true)" (mouseleave)="arrangeSubMenu(false)">
    <ng-container *ngFor="let direction of moveDirections">
      <button id="to{{direction}}" (click)="moveCurrentShape(direction)">
        Move to {{direction}}
      </button>
    </ng-container>
  </div>

  <div id="change-color-menu" class="menu" (mouseenter)="changeColorSubMenu(true)"
    (mouseleave)="changeColorSubMenu(false)">
    <ng-container *ngFor="let color of wireColors">
      <button id="{{color}}-color" class="initCap" (click)="konvaUtils.changeWireColor(color)">
        <span class="dot" [style.background-color]="color"></span> {{color}}
      </button>
    </ng-container>
  </div>

  <div id="connect-to-menu" class="menu" (mouseenter)="connectToSubMenu(true)">
    <ng-container *ngFor="let device of deviceList">
      <button (mouseenter)="selectPinsSubMenu(true, device.t_id)">
        {{device.customName}}
      </button>
    </ng-container>
  </div>

  <div id="connect-pin-menu" class="menu" (mouseenter)="connectToSubMenu(true)">
    <ng-container *ngFor="let pin of pinList">
      <button [ngStyle]="{'background-color':getColor(pin.attrs.group)}" [ngClass]="pin.attrs.group % 2 === 0 ? 'even' : 'odd'" (click)="createWireConnection(pin)"
        (mouseover)="showHideTooltip(pin,true)" (mouseout)="showHideTooltip(pin,false)">
        {{pin.attrs.display}}
      </button>
    </ng-container>
  </div>

  <div class="absolute right-10 top-2 flex shadow p-2 bg-gray-50 rounded-lg circuit-menu">
    <div *ngIf="isAdmin && !isEdit"  class="circuit-item cursor-pointer right-side" (click)="listAll()">
      <img src="assets/img/icons/load-circuit.svg" class="h-5 w-5" title="Load circuit" alt="Load circuit" />
      <div class="circuit-menu-text">Load circuit</div>
    </div>
    <div class="circuit-item cursor-pointer right-side" (click)="clearCanvasClicked()">
      <img src="assets/img/icons/clear.svg" class="h-5 w-5" title="Clear canvas" alt="Clear canvas" />
      <div class="circuit-menu-text">Clear canvas</div>
    </div>
    <div class="circuit-item cursor-pointer right-side" (click)="saveImage()">
      <img src="assets/img/icons/download.svg" class="h-5 w-5" title="Download" alt="Download" />
      <div class="circuit-menu-text">Download</div>
    </div>
    <div *ngIf="isAdmin" class="circuit-item cursor-pointer right-side" (click)="save()">
      <img src="assets/img/icons/save-image.svg" class="h-5 w-5" title="Save circuit" alt="Save circuit" />
      <div class="circuit-menu-text">Save circuit</div>
    </div>
    <div *ngIf="isAdmin && !isEdit" class="circuit-item cursor-pointer right-side" (click)="printToConsole()">
      <img src="assets/img/icons/print.svg" class="h-5 w-5" title="Print JSON" alt="Print JSON" />
      <div class="circuit-menu-text">Print JSON</div>
    </div>
    <div class="circuit-item right-side" [class]="canUndo ? 'cursor-pointer' : 'cursor-no-drop'" (click)="undo()">
      <img [src]="canUndo ? 'assets/img/icons/undo.svg' : 'assets/img/icons/undo-disabled.svg'" class="h-5 w-5" title="Undo" alt="Undo" />
      <div class="circuit-menu-text" [class]="canUndo ? 'text-black' : 'text-gray'">Undo</div>
    </div>
    <div class="circuit-item" [class]="canRedo ? 'cursor-pointer' : 'cursor-no-drop'" (click)="redo()">
      <img [src]="canRedo ? 'assets/img/icons/redo.svg' : 'assets/img/icons/redo-disabled.svg'" class="h-5 w-5" title="Redo" alt="Redo" />
      <div class="circuit-menu-text" [class]="canRedo ? 'text-black' : 'text-gray'">Redo</div>
    </div>
  </div>

  <ng-container *ngIf="isClearCanvasClicked">
    <div
      class="w-full min-w-screen h-screen animated fadeIn faster fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
      id="modal-id">
      <div class="absolute bg-black opacity-80 inset-0 z-0  "></div>
      <div style="height: 300px; width: 700px;"
        class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
        <!--content-->
        <div class="">
          <!--body-->
          <div class="text-center p-5 flex-auto justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto"
              fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-16 h-16 flex items-center text-red-500 mx-auto"
              viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd" />
            </svg>
            <h2 class="text-xl font-bold py-4 ">Are you sure?</h2>
            <p class="text-sm text-gray-500 px-8">Do you really want to clear the canvas?</p>
          </div>
          <!--footer-->
          <div class="p-3  mt-2 text-center space-x-4 md:block">
            <button (click)="cancelClear()"
              class="bg-blue-500 hover:bg-blue-700 text-black font-bold py-2 px-4 rounded-full">
              Cancel
            </button>
            <button (click)="clearWorkspace()"
              class="bg-blue-500 hover:bg-blue-700 text-red-500 font-bold py-2 px-4 rounded-full">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Control Buttons -->
  <div *ngIf="!isFullCircuit" class="fixed bottom-5 m-5" [class.control-button-bottom]="insideForm">
    <button *ngIf="isRefDiagram && isHide" class="p-2" (click)="hideRef(false)">
      <img src="assets/img/icons/unhide.svg" class="h-7 w-7" title="Show" alt="Show" />
    </button>

    <button *ngIf="isRefDiagram && !isHide" class="p-2" (click)="hideRef(true)">
      <img src="assets/img/icons/hide.svg" class="h-7 w-7" title="Hide" alt="Hide" />
    </button>

    <button class="p-2" (click)="konvaUtils.zoomInOut({action:'zoomin', areaName:'workspace'})">
      <img src="assets/img/icons/zoomin.svg" class="h-7 w-7" title="Zoom In" alt="Zoom In" />
    </button>
    <button class="p-2" (click)="konvaUtils.zoomInOut({action:'zoomout', areaName:'workspace'})">
      <img src="assets/img/icons/zoomout.svg" class="h-7 w-7" title="Zoom Out" alt="Zoom Out" />
    </button>
    <button *ngIf="!isFullWorkspace" class="p-2"
      (click)="emitWindowSize.emit({action:'maximize', areaName:'workspace'})">
      <img src="assets/img/icons/maximize.svg" class="h-7 w-7" title="Maximize" alt="Maximize" />
    </button>
    <button *ngIf="isFullWorkspace" class="p-2"
      (click)="emitWindowSize.emit({action:'minimize', areaName:'workspace'})">
      <img src="assets/img/icons/minimize.svg" class="h-7 w-7" title="Minimize" alt="Minimize" />
    </button>
  </div>
</div>
