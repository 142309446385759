import { createAction, props } from "@ngrx/store";

export enum EAuthState {
  AUTHENTICATE_USER = '[User] Authenticate User',
  AUTHENTICATE_SUCCESS = '[USER Authenticate Success',
  AUTHENTICATE_ERROR = '[User] Authenticate Error',
  SET_USER = '[User] Set User',
  SET_TOKEN = '[User] Set token',
  SET_ROLE = '[User] Set role',
  LOGOUT = '[User] Logout'
}

export const authenticateUser = createAction(EAuthState.AUTHENTICATE_USER, props<{payload: any}>());
export const authenticateSuccess = createAction(EAuthState.AUTHENTICATE_SUCCESS, props<{payload: any}>());
export const authenticateError = createAction(EAuthState.AUTHENTICATE_ERROR, props<{error: any}>());

export const setUser = createAction(EAuthState.SET_USER, props<{user: any}>());
export const setToken = createAction(EAuthState.SET_TOKEN, props<{token: string}>());
export const setUserRole = createAction(EAuthState.SET_ROLE, props<{role: string}>());
export const logout = createAction(EAuthState.LOGOUT);
