import { Injectable } from "@angular/core";
// import { HttpClient } from "@angular/common/http";
// import { IBoard } from "../models/board";
// import { environment } from "src/environments/environment";
// import { ISearch } from "../models/inteface";
import { environment } from "src/environments/environment";
import { OrganizationsService, CreateOrgPayload } from "./organizations.service";

/**
 * Service class to handle data and http request related to Board APIs.
 */
@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  constructor(
    private orgService: OrganizationsService
  ) { }

  /**
   * Method to search a board entry.
   * @param payload
   */
  getUsername() {
    const user: any = sessionStorage.getItem("user");
    const username: any = sessionStorage.getItem('student') ? (sessionStorage.getItem('student')) : JSON.parse(user)?.username;
    return username;
  }

  getCurrentOrganization() {
    const affiliateName: any = sessionStorage.getItem("affiliate_name") || environment.organizationID;
    if (affiliateName !==  environment.organizationID) {
      this.createOrganization(affiliateName);
    }
    const orgId: any = affiliateName;
    return orgId;
  }

  async createOrganization(affiliateName: string) {
    let payload: CreateOrgPayload = {
      orgName: affiliateName
    }
    try {
      await this.orgService.create(payload).toPromise()
    } catch (err) {
      console.log("Error creating organization")
    }
  }
}
