import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import * as moment from "moment";
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { environment } from 'src/environments/environment';
import { Organization, OrganizationsService } from 'src/app/shared/services/organizations.service';


@Component({
  selector: 'app-users-session',
  templateUrl: './users-session.component.html',
  styleUrls: ['./users-session.component.scss']
})
export class UsersSessionComponent implements OnInit {

  activeUserSessionsList: any[] = [];
  currentOrganization!: string;
  orgList: Organization[] = [];
  isSuperAdmin: boolean = true;
  isGrokCloudMember: boolean = true;
  appliedOrgName!: string | null;

  constructor(
    private userSessionService: UserSessionService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private orgService: OrganizationsService
  ) { }

  routeTo(path: string) {
    this.router.navigateByUrl(`/admin/${path}`)
  }

  async ngOnInit() {
    this.setDefaultOrganization();
    this.activeUserSessionsList = await this.getUserSessionList();
    this.searchOrganizations();
  }
  setDefaultOrganization() {
    this.currentOrganization = this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization === environment.organizationID) { }
    else {
      this.isGrokCloudMember = false;
      this.appliedOrgName = this.currentOrganization;
    }
  }

  async applyOrganizationFilter(event: any) {
    if (event.target.value != "") {
      this.appliedOrgName = event.target.value;
    } else {
      this.appliedOrgName = null;
    }
    this.activeUserSessionsList = await this.getUserSessionList();
  }

  async searchOrganizations() {
    try {
      const result: any = await this.orgService.search().toPromise();
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }

  public async getUserSessionList(): Promise<any[]> {
    let userSessions = await this.userSessionService.getActiveUserSessions(this.appliedOrgName as string).toPromise();
    return userSessions.activeSessions;
  }

  async logout(item: any) {
    let isLoggedOut = await this.userSessionService.forceLogout(item.lmsusername).toPromise();
    if (isLoggedOut) {
      this.activeUserSessionsList = this.activeUserSessionsList.filter(data => data.lmsusername != item.lmsusername);
    }
  }

  formatDate(date: any) {
    return moment(date).local().format("DD/MM/YYYY HH:mm:ss");
  }
}
