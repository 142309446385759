import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { logout } from 'src/app/store/auth/auth.action';
import { IAppState } from 'src/app/store/index.state';
import { showLayout } from 'src/app/store/layout/layout.action';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  constructor(
    private store: Store<IAppState>,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this.store.dispatch(logout());
    this.store.dispatch(showLayout({value: false}));
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

  routeTo(path: string) {
    this.router.navigateByUrl(path)
  }

}
