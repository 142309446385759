import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { CircuitLoadOptions, KonvaUtils } from '../../utils/konva.utils';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/index.state';
import { SensorService } from '../../services/sensor.service';
import { CircuitHistoryFacade } from 'src/app/store/circuit-history/circuit-history.facade';
import circuitHistoryAction from 'src/app/store/circuit-history/circuit-history.action';
import { ToastrService } from 'ngx-toastr';
import { UserSessionService } from '../../services/user.session.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { BlankCircuitChildren } from '../../constants/circuits';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnInit, OnChanges {
  @Input() isAdmin = false;
  @Input() isEdit = false;
  @Input() element: any;
  @Input() konvaUtils!: KonvaUtils;
  @Input() isNewWorkspace!: boolean;
  @Input() isFullWorkspace!: boolean;
  @Input() isHide!: boolean;
  @Input() isFullCircuit!: boolean;
  @Input() applicationIdentifier!: string;
  @Output() emitSavedJSON = new EventEmitter();
  @Output() emitLoadState = new EventEmitter();
  @Output() emitWindowSize = new EventEmitter();
  @Output() openInfoPanel = new EventEmitter();
  @Output() emitUpdatedJSON = new EventEmitter();

  // Flags to decide if menu will show or not
  isArrangeMenuOpen: boolean = false;
  isColorChangeMenuOpen: boolean = false;
  isConnectToMenuOpen: boolean = false;
  isConnectPinMenuOpen: boolean = false;
  isRefDiagram: boolean = false;
  // Dynamic Menu Items
  readonly wireColors = [
    "red",
    "black",
    "orange",
    "green",
    "cyan",
    "magenta",
    "purple",
    "blue",
    "gray",
  ];
  readonly moveDirections = [
    "Top",
    "Bottom",
    "Up",
    "Down",
  ];
  deviceList: any[] = [];
  // currentDeviceId?: string;
  pinList: any[] = [];

  // Menu Elements
  private arrangeMenuNode: any;
  private colorChangeMenuNode: any;
  private connectToMenuNode: any;
  private connectPinMenuNode: any;
  private currentDeviceId: any;

  //clear canvas clicked
  isClearCanvasClicked: boolean = false;
  insideForm: boolean = window.location.href.includes('/application/');
  title = "Highlight tooltip"
  pin: any; 
  canUndo: boolean = false;
  canRedo: boolean = false;

  constructor(
    private store: Store<IAppState>,
    readonly circuitHistoryFacade: CircuitHistoryFacade,
    private toasterService: ToastrService,
    private sensorService: SensorService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService) {
      circuitHistoryFacade.canUndo$.subscribe((status: any) => this.canUndo = status);
      circuitHistoryFacade.canRedo$.subscribe((status: any) => this.canRedo = status);
    }

  async ngOnInit() {
    if (!this.konvaUtils) {
      this.konvaUtils = new KonvaUtils(this.store, this.applicationIdentifier, `${WorkspaceComponent.name}-${this.ngOnInit.name}`);
    }
    this.konvaUtils.tempStage.subscribe((circuit: any) => {
      circuit = Object.assign({}, circuit);
      this.emitUpdatedJSON.emit(circuit);
    });
    if (!this.isEdit || this.isEdit === undefined) {
      this.initializeWorkspace();
    }
    if (this.isNewWorkspace) {
      this.initializeWorkspace();
    }
    this.konvaUtils.registerGlobalEvents(`${WorkspaceComponent.name}-${this.ngOnInit.name}`);
    this.registerUndoRedoKeyboardEvents();

    this.arrangeMenuNode = document.getElementById("arrange-menu");
    this.colorChangeMenuNode = document.getElementById("change-color-menu");
    this.connectToMenuNode = document.getElementById("connect-to-menu");
    this.connectPinMenuNode = document.getElementById("connect-pin-menu");

    if (document.querySelector<HTMLElement>("as-split:nth-child(1) as-split-area:nth-child(2) as-split:nth-child(1) as-split-area:nth-child(1)")) {
      this.isRefDiagram = true;
    }

    let allSensors: any = await this.sensorService.getAllSensors().toPromise();
    this.konvaUtils.updateAvailableSensorsList(allSensors);
    this.konvaUtils.resetChildren();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    const elementChanged = simpleChanges?.element?.currentValue;
    if (elementChanged) {
      this.konvaUtils.dragFromOutside(this.element);
    }
  }

  getColor(group: any) {
    let colorCode = ['#eaf7fb', '#fde8f5', '#e5ffeb', '#fbfde8', '#e8eefd']
    let colorCodeValue = 0;
    if (group !== undefined && group !== null) {
      colorCodeValue = group % colorCode.length;
      return colorCode[colorCodeValue]
    } else {
      return ''
    }
  }

  private initializeWorkspace(): void {
    const konva = this.konvaUtils;
    konva.initStage(WorkspaceComponent.name);
    konva.createLayer();
    konva.showContextMenu();
    konva.initLine();
  }
  // Context Menu properties

  hideRef(isHide: boolean) {
    this.isHide = isHide;
    if (this.isHide === true) {
      document.querySelector<HTMLElement>("as-split:nth-child(1) as-split-area:nth-child(2) as-split:nth-child(1) as-split-area:nth-child(1)").style.display = "none";
      if (document.querySelector("img[title='Maximize']")) {
        document.querySelector<HTMLElement>("#draggable-ref-diagram").style.display = "none";
        document.querySelector<HTMLElement>("as-split-area [direction='vertical'] as-split-area:nth-child(2)").style.flex = "0 0 100%";
      }
    }
    else if (this.isHide === false) {
      document.querySelector<HTMLElement>("as-split:nth-child(1) as-split-area:nth-child(2) as-split:nth-child(1) as-split-area:nth-child(1)").style.display = "";
      if (document.querySelector("img[title='Minimize']")) {
        document.querySelector<HTMLElement>("#draggable-ref-diagram").style.display = "";
        document.querySelector<HTMLElement>("as-split-area [direction='vertical'] as-split-area:nth-child(2)").style.flex = "0 0 50%";
      }
    } else {
      document.querySelector<HTMLElement>("as-split:nth-child(1) as-split-area:nth-child(2) as-split:nth-child(1) as-split-area:nth-child(1)").style.display = "none";
      document.querySelector<HTMLElement>("as-split-area [direction='vertical'] as-split-area:nth-child(2)").style.flex = "0 0 100%";
    }
  }

  deleteCurrentShape(e: any): void {
    this.konvaUtils.deleteCurrentShape(e);
    this.resetAvailablePinList();
  }

  selectPins(e: any): void {
    if (this.konvaUtils.isSelectingPins) {
      // console.log(this.konvaUtils.new_overlays);
    } else {
      this.konvaUtils.new_overlays = [];
    }
    this.konvaUtils.isSelectingPins = !this.konvaUtils.isSelectingPins;
    this.konvaUtils.hideContextMenu();
  }


  viewInformation(e?: any) {
    const sensorInfo: any = this.konvaUtils.viewInformation();
    this.openInfoPanel.emit(sensorInfo);
  }

  arrangeSubMenu(flag: boolean): void {
    this.hideSubMenu();
    this.isArrangeMenuOpen = flag;
    if (flag) { // mouse enter
      this.arrangeMenuNode.style.display = "initial";
    } else {    // mouse leave
      this.arrangeMenuNode.style.display = "none";
    }
  }

  changeColorSubMenu(flag: boolean): void {
    this.hideSubMenu();
    this.isColorChangeMenuOpen = flag;
    if (flag) { // mouse enter
      this.colorChangeMenuNode.style.display = "initial";
    } else {    // mouse leave
      this.colorChangeMenuNode.style.display = "none";
    }
  }

  connectToSubMenu(flag: boolean): void {
    this.hideSubMenu();
    this.selectPinsSubMenu(true);
    this.isConnectToMenuOpen = flag;
    if (flag) { // mouse enter
      this.connectToMenuNode.style.display = "initial";
      // Get list of other devices
      this.deviceList = this.getFilteredDeviceList(this.konvaUtils.currentShape.shape.attrs.targetId);
      // this.deviceList = this.getDevicesWithValidPins(this.deviceList);
      // this.resetAvailablePinList();
    } else {    // mouse leave
      this.connectToMenuNode.style.display = "none";
    }
  }

  getFilteredDeviceList(shapeId: string) {
    return this.konvaUtils.targets.filter((e: any) => e.t_id != shapeId);
  }

  selectPinsSubMenu(flag: boolean, t_id?: string): void {
    this.hideSubMenu();
    this.isConnectPinMenuOpen = flag;
    if (flag) { // mouse enter
      this.connectToMenuNode.style.display = "initial";
      this.connectPinMenuNode.style.display = "initial";
      // Get list of pins for selected device
      if (t_id) {
        this.pinList = this.getFinalPinList(t_id);
        // this.currentDeviceId = t_id;
      }
    } else {    // mouse leave
      this.connectPinMenuNode.style.display = "none";
    }
  }

  getAllPinsList() {
    return this.konvaUtils.allPins;
  }

  getFinalPinList(targetId: string) {
    const filteredPins = this.konvaUtils.stage.find("Circle").toArray().filter(circle => circle.attrs.targetId == targetId);
    // const allowedPins = this.konvaUtils.currentShape?.shape?.attrs?.allowedPins;
    // const validPins = filteredPins.filter(pin => allowedPins.includes(pin.attrs?.name));
    const availablePins = this.getAvailablePinList(filteredPins);

    return availablePins;
  }

  getDevicesWithValidPins(devices: any[]) {
    const validDevices = devices.filter(device => {
      const t_id = device?.t_id;
      const pins = this.getFinalPinList(t_id);
      if (pins && pins.length > 0) {
        return true;
      } else {
        return false;
      }
    });
    return validDevices;
  }

  getAvailablePinList(availablePins: any[]) {
    if (this.konvaUtils.undoableData.pinsInUse.length > 0) {
      return availablePins.filter((circle) => {
        if (!this.konvaUtils.undoableData.pinsInUse.includes(circle.attrs.pinId)) return circle; else return null;
      })
    } else return availablePins;
  }

  isPinAllowed(pin: any): boolean {
    const allowedPins = this.konvaUtils.currentShape?.shape?.attrs?.allowedPins;
    if (allowedPins?.includes(pin?.attrs?.name)) {
      return true;
    }
    return false;
  }


  createWireConnection(endPin: any) {
    const isAllowed = this.isPinAllowed(endPin);
    if (isAllowed == false) {
      this.toasterService.error("Can not connect to selected pin.");
      this.showHideTooltip(endPin, false);
    } else {
      let startPin = this.konvaUtils.currentShape.shape;
      // let startDevice = this.konvaUtils.currentShape.shape.parent.findOne("Image");
      // let endDevice = this.konvaUtils.targets.filter((e: any) => e.t_id == this.currentDeviceId)[0];
      let connection = {
        custom: true,
        target: {
          attrs: startPin.attrs,
          attrs2: endPin.attrs
        },
        // start : {
        //   x: startPin.attrs.x,
        //   y: startPin.attrs.y,
        //   pin: startPin,
        //   device: startDevice
        // },
        // end: {
        //   x: endPin.attrs.x,
        //   y: endPin.attrs.y,
        //   pin: endPin,
        //   device: endDevice
        // }
      }
      this.konvaUtils.createLineFromMenu(connection);
    }
    this.konvaUtils.hideContextMenu();
    this.resetAvailablePinList();
  }

  resetAvailablePinList() {
    this.pinList = [];
  }

  isOverlay() {
    let currentShape = this.konvaUtils.currentShape.shape;
    if (!currentShape) {
      return false;
    }
    let currentShapeType = currentShape.attrs.type;
    if (currentShapeType && currentShapeType == 'overlay') {
      return true;
    } else {
      return false;
    }
  }

  hideSubMenu() {
    this.isArrangeMenuOpen = false;
    this.isColorChangeMenuOpen = false;
    this.isConnectToMenuOpen = false;
    this.isConnectPinMenuOpen = false;

    this.arrangeMenuNode.style.display = "none";
    this.colorChangeMenuNode.style.display = "none";
    this.connectToMenuNode.style.display = "none";
    this.connectPinMenuNode.style.display = "none";
  }

  moveCurrentShape(direction: string): void {
    this.konvaUtils.moveCurrentShape(direction.toLowerCase());
  }

  //clear canvas confirmation
  clearCanvasClicked() {
    this.isClearCanvasClicked = true;
  }

  cancelClear() {
    this.isClearCanvasClicked = false;
  }

  // other properties
  clearWorkspace() {
    this.konvaUtils?.clearWorkspace(true);
    this.initializeWorkspace();
    this.konvaUtils?.registerGlobalEvents(`${WorkspaceComponent.name}-${this.clearWorkspace.name}`);
    this.isClearCanvasClicked = false;
  }

  printToConsole(): void {
    console.log(this.konvaUtils.save());
  }
  save(): void {
    this.emitSavedJSON.emit(this.konvaUtils.save());
    this.emitLoadState.emit(false);
  }

  listAll(): void {
    this.emitLoadState.emit(true);
  }

  saveImage(): void {
    this.konvaUtils.saveImage();
  }


  /**get mouseover sub pin tooltip */
  showHideTooltip(node: any, flag: boolean) {
    this.konvaUtils.showTooltip(node, flag);
  }

  /** tooltip is visible or not */
  isTooltipVisible(node: any) {
    if (node["attrs"].tooltipId) {
      return true;
    }
    else {
      return false;
    }
  }

  duplicateComponent(event: any) {
    event.preventDefault();
    let selectedSensorId = this.konvaUtils.getSensorId();
    let sensorInfo = this.konvaUtils.filterSelectedSensors(selectedSensorId)
    if (sensorInfo.length > 0) {
      this.konvaUtils.duplicate(sensorInfo, true)
    }
    this.konvaUtils.hideContextMenu();
  }

  undo() {
    /*
      Add Cntrl + Z and Cmd + Z to go back to the previous state
      restore previous state
    */
    // this.konvaUtils.undo();
    if (this.canUndo) {
      this.circuitHistoryFacade.dispatch(circuitHistoryAction.undo());
      this.refresh();
    }
  }

  redo() {
    /*
    Add Cntrl + SHIFT + Z and Cmd + SHIFT +Z to go back to the previous state
    */
    // this.konvaUtils.redo();
    if (this.canRedo) {
      this.circuitHistoryFacade.dispatch(circuitHistoryAction.redo());
      this.refresh();
    }
  }

  refresh() {    
    const updatedJSON = {
      targets: this.konvaUtils.targets,
      wires: this.konvaUtils.wires,
      children: this.konvaUtils.undoableData.children?.children,
      attrs: this.konvaUtils?.undoableData.children?.attrs,
      className: this.konvaUtils?.undoableData.children?.className
    }

    if (updatedJSON.children === undefined || updatedJSON.children?.length === 0) {
      this.konvaUtils?.removeAutoSavedCircuit();
      updatedJSON.children = BlankCircuitChildren;
    }
    if (updatedJSON.attrs) {
      this.konvaUtils.loadExisting(updatedJSON, CircuitLoadOptions.WRITABLE, this.refresh.name, true);
    }
  }

  registerUndoRedoKeyboardEvents() {
    window.addEventListener('keyup', (e: any) => {
      if (e.keyCode === 90 && e.ctrlKey === true && e.shiftKey === false) {
        this.undo();
        e.preventDefault();
        return;
      }
      if (e.keyCode === 90 && e.ctrlKey === true && e.shiftKey === true) {
        this.redo();
        e.preventDefault();
        return;
      }
    })
  }

  dummyFunction() {
    console.log("Dummy function called");
  }

  ngOnDestroy() {
    // save an array of items dispatched while saving to local storage. clear array when save() is called. if array has items then alert
    // if (this.isAdmin)
    // window.alert("There are unsaved changes in your circuit. Please save them before moving");
    // this.konvaUtils.clearWorkspace(true);
    this.konvaUtils.resetContainer();
  }
}

