import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/index.state';
import { showSidePanel } from 'src/app/store/layout/layout.action';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent implements OnInit {

  @Output() emitCloseEvent = new EventEmitter();
  @Input() boxWidth: any;
  @Input() isModal: boolean = false;

  constructor(
    private store: Store<IAppState>
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.store.dispatch(showSidePanel({value: false}));
    this.emitCloseEvent.emit(true);
  }

}
