<div class="p-8">  
  <div class="bg-white rounded-lg p-8">
    <nav class="text-black font-bold my-8 px-8" aria-label="Breadcrumb">
      <ol class="list-none p-0 inline-flex">
        <li class="flex items-center cursor-pointer">
          <a (click)="enableAddDevice()" [class.opacity-50]="showInfoPanel" [class.hover:opacity-100]="showInfoPanel">Add a
            Device</a>
          <div class="flex items-center" *ngIf="showInfoPanel">
            <div class="breadcrumb">
              <img src="assets/img/next.png" alt="">
            </div>
          </div>
        </li>
        <li class="flex items-center" *ngIf="showInfoPanel">
          <a>Info</a>
        </li>
      </ol>
    </nav>
    <p class="text-xl font-bold mb-5 text-gray-800 px-2">Create New Device For User</p>
    <p class="text-md mb-10">Fill In The Details Of The User</p>
    <form #basicForm="ngForm" (ngSubmit)="onSubmit()">
      <div class="mb-10 flex flex-col">
        <label class="sr-only">LMS User Id</label>
        <input class="input-style px-2" name="app-name" [(ngModel)]="deviceName" placeholder="LMS User Id"
          [disabled]="showInfoPanel">
      </div>
      <div class="flex">
        <button class="bg-greenish-blue p-4 text-md font-bold text-white rounded-lg" [class.opacity-50]="showInfoPanel"
          [disabled]="showInfoPanel">Generate Token</button>
      </div>
    </form>
  </div>
</div>

<div class="p-8 py-8" *ngIf="showInfoPanel">
  <p class="text-lg px-2 font-medium mb-5 text-gray-800"><span class="text-xl font-bold px-3">Device Name: </span>{{
    savedDeviceName }}</p>
  <div class="bg-white rounded-lg p-8">
    <div class="my-2 flex flex-row items-center flex-nowrap">
      <p class="p-2 m-2 text-md mb-5 text-gray-800">{{ token }}</p>
      <button class="bg-button text-white p-2 rounded-lg shadow-xl hover:bg-red-600" (click)="copyToken()" title="Copy Token">
        <div class="flex items-center">
          <img src="assets/img/copy.png" class="h-8 w-8 icon" alt="">
        </div>
      </button>
    </div>
  </div>
</div>