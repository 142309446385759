import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxBlocklyModule } from 'ngx-blockly';
import { AngularSplitModule } from 'angular-split';
import { BlocklyComponent } from './blockly.component';

@NgModule({
  declarations: [
    BlocklyComponent
  ],
  imports: [
    CommonModule,
    NgxBlocklyModule,
    AngularSplitModule
  ],
  exports: [
    BlocklyComponent
  ]
})
export class BlocklyModule { }
