<div class="w-full h-screen relative" style="width: 100% !important;">
    <div class="flex justify-end p-4">
        <button class="text-white btn btn-color-1 mr-2 flex justify-center" (click)="openFile()">
            <img src="assets/img/python-direct/upload.svg">
            <input type="file" style="display:none" (change)="handle($event)">
        </button>
        <div #dropdownElement>
            <div *ngIf="isPythonModule">
                <button class="text-white btn btn-color-2 mr-2 flex justify-center" (click)="downloadPythonCode()">
                    <img src="assets/img/python-direct/download.svg">
                </button>
            </div>
            <div *ngIf="!isPythonModule">
                <button class="text-white btn btn-color-2 mr-2 flex justify-center" (click)="showOptions(!showSelectOptions)">
                    <img src="assets/img/python-direct/download.svg">
                </button>
            </div>
            <div *ngIf="showSelectOptions" style="z-index: 60;"
            class="origin-top-right justify-center absolute right-0 mt-6 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div *ngFor="let item of downloadOptions">
                <div class=" text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-button"
                (click)="downloadCode(item.key)">{{ item.display }}
                </div>
            </div>
            </div>
        </div>
        <button class="text-white btn-color-2 single-arrow-btn flex justify-center" (click)="toggleMenu()">
            <img [class.collaped]="!isCollapsed || !isClose.onClose" src="assets/img/icons/single-arrow.svg">
        </button>
    </div>
    <div class="h-full w-full" [class.w-editor]="!isCollapsed">  
        <!-- theme="vs-dark" -->
        <ngs-code-editor 
            style="height: 100%;width: 100%;"
            language="python" 
            theme="vs-dark"
            [codeModel]="codeModel"
            [dependencies]="dependencies"   
            [options]="options"    
            (valueChanged)="onCodeChanged($event)">
        </ngs-code-editor>  
        <p id="codeEditor" style="background: white;color: green; padding: 30px;"></p>                     
    </div> 
</div>



