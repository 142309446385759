import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  NgModule,
  OnInit,
  Output,
  Input,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { IotDevicesSectionModule } from 'src/app/shared/components/iot-devices-section/iot-devices-section.module';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-select-sensors',
  templateUrl: './select-sensors.component.html',
  styleUrls: ['./select-sensors.component.scss'],
})
export class SelectSensorsComponent implements OnInit {
  @Output() onSavePressed = new EventEmitter();
  @Output() emitSensorData = new EventEmitter();
  @Output() onBackPressed = new EventEmitter();
  @Output() emitPanelData = new EventEmitter();

  @Input() allSelectedSensors: any = [];
  @Input() isEdit: boolean;
  @Input() selectedApplication: any;
  @Input() applicationData: any;

  sensors: any;

  private selectedGateways: any = { title: 'IOT GATEWAY', components: [] };
  private selectedInputs: any = { title: 'INPUT', components: [] };
  private selectedOutputs: any = { title: 'OUTPUT', components: [] };
  private selectedComponents: any = { title: 'COMPONENTS', components: [] };
  selectedSensorsId: string[] = [];
  selectedSensors: any[] = [];
  selectedNavMenu!: any;

  constructor(private sensorService: SensorService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService) { }

  async ngOnInit() {
    this.getAllSensors()
      .then((result: any) => {
        this.sensors = result?.sensors;
      })
      .then((result: any) => {
        if (this.isEdit) this.presetSensors();
      });
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();

  }

  ngOnChanges(changes: SimpleChanges) {
    const isConfigUpdated = changes?.configurationDefinition?.currentValue;
    if (isConfigUpdated) {
      this.presetSensors();
    }
  }

  private presetSensors() {
    const { configurationDefinition } = this.selectedApplication;
    if (configurationDefinition) {
      const data = JSON.parse(configurationDefinition);
      if (data?.sensors) {
        const sensorIds = data?.sensors;
        this.setAllSensors(sensorIds);
      }
    }
  }
  private setAllSensors(sensorIds: any): void {
    let selectedSensorsList: any = {};
    this.sensors.forEach((types: any) => {
      if (types.components?.length > 0) {
        types.components?.forEach((eachComponent: any) => {
          eachComponent['selected'] = false;
          eachComponent['disabled'] = false;
          if (sensorIds.includes(eachComponent.id)) {
            this.onSelect(eachComponent, types?.title);
            eachComponent['selected'] = true;
            eachComponent['disabled'] = true;
          }
        });
      }
    });
  }

  private getAllSensors(): any {
    const result: any = this.sensorService.getAllSensors().toPromise();
    return result;
  }

  onSelect(item: any, selectedType?: any) {
    const type = selectedType ? selectedType : this.selectedNavMenu.title;
    switch (type) {
      case 'IOT GATEWAY':
        this.addOrRemove(this.selectedGateways.components, item, selectedType);
        break;
      case 'INPUT':
        this.addOrRemove(this.selectedInputs.components, item, selectedType);
        break;
      case 'OUTPUT':
        this.addOrRemove(this.selectedOutputs.components, item, selectedType);
        break;
      case 'COMPONENTS':
        this.addOrRemove(
          this.selectedComponents.components,
          item,
          selectedType
        );
        break;
    }
    this.selectedSensors = [
      this.selectedGateways,
      this.selectedInputs,
      this.selectedOutputs,
      this.selectedComponents,
    ];
  }

  private addOrRemove(list: any, item: any, selectedType?: any) {
    const hasComponent = list.find((component: any) =>
      item.id.includes(component.id)
    );
    if (!hasComponent) {
      list.push(item);
      this.selectedSensorsId = [...this.selectedSensorsId, item.id];
    } else {
      const index = list.findIndex(
        (component: any) => component.id === item.id
      );
      list.splice(index, 1);
      this.selectedSensorsId = this.selectedSensorsId.filter((sensorId: any) => sensorId !== item.id);
    }
    item.selected = selectedType ? item.selected : !item.selected;
  }

  onNext() {
    const sensorData = {
      info: this.selectedSensors,
      ids: this.selectedSensorsId,
    };
    this.emitSensorData.emit(sensorData);
  }

  goBack() {
    this.onBackPressed.emit();
  }

  setSelectedMenu(menu: any) {
    this.selectedNavMenu = menu;
  }
}

@NgModule({
  declarations: [SelectSensorsComponent],
  imports: [CommonModule, IotDevicesSectionModule],
  exports: [SelectSensorsComponent],
})
export class SelectSensorModule { }
