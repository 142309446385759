<div class="p-8">
  <div class="flex gap-4 flex-wrap">
    <div class="w-2/3 flex-1 flex-row">
      <div class="h-full p-10">
        <ng-container>
          <div class="p-2 w-full h-1/2 flex justify-between">
            <div>
              <app-prebuild-circuit
                [loadExistingCircuitJSON]="blockDataCircuitDefinition"
                [zoom]="false"
                [isAdmin]="false"
              >
              </app-prebuild-circuit>
              <figure *ngIf="audioFile">
                <audio autoplay [src]="audioFile"></audio>
              </figure>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="
        bg-ide-blue
        w-1/3
        rounded-xl
        shadow-md
        h-screen
        overflow-auto
        custom-height
        hide
      "
    >
      <div class="bg-ide-blue h-full px-8 py-2">
        <ng-container *ngIf="!commandItems.length">
          <p class="text-white pt-4">
            Commands received will be displayed here...
          </p>
        </ng-container>
        <ng-container *ngIf="commandItems.length">
          <ng-container *ngFor="let item of commandItems; let i = index">
            <div
              class="
                mt-2
                flex-col
                items-center
                justify-start
                cursor-pointer
                border-0
                hover:border-0
                focus:border-0
              "
            >
              <div
                class="flex items-center justify-start text-sm"
                [class.text-yellow-500]="item.state"
                [class.text-red-500]="!item.state"
              >
                <span>
                  <span class="timestamp"
                    >[{{ item.time | date: "medium" }}]</span
                  >
                  :
                  <span class="mr-20 text-medium"
                    >{{ item.item || item.title }} is
                    {{ item.state ? "active" : "inactive" }}</span
                  >
                </span>
              </div>
              <div *ngIf="item.type == 'image' && item.state">
                <span class="text-sm text-yellow-200">
                  <span class="timestamp"
                    >[{{ item.time | date: "medium" }}]</span
                  >
                  : Received Image:
                </span>
                <img [src]="item.source" class="p-4" />
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
