import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";
import { environment } from "src/environments/environment";
import { catchError, finalize } from "rxjs/operators";
import { LoaderService } from "../services/loader.service";


@Injectable({
  providedIn: "root",
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
    private loadingService: LoaderService ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (request.url.includes(environment.apiURL)) {
      let headers = request.headers;
      if (token) {
        headers = headers.set("Authorization", token);
      }
      if (request.url.includes("verify-user-login")) {
        let localSavedCookie = localStorage.getItem('sessionId');
        if (localSavedCookie != null) {
          headers = headers.set("Cookie-Header", localSavedCookie);
        }
      }
      request = request.clone({ headers });
    }
    this.loadingService.show();
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      );
  }
}
