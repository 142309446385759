import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * Service class to handle authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string = '';

  constructor(
    private http: HttpClient
  ) { }

  getToken() {
    return this.token ? this.token : sessionStorage.getItem('api_key');
  }

  setToken(token: string) {
    this.token = token;
    sessionStorage.setItem('api_key', token);
  }

  authenticateLMS(username: string, password: string) {
    return this.http.post(environment.authURL, {username, password});
  }

}
