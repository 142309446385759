import { multiNumberDOM, dcDriveDOM, mesColorDOM, carControllerDOMConfig, vehicleControllerDOMConfig } from '../models/sensor';

export const climateSensorDOM: multiNumberDOM[] = [
    { display: 'Temp', suffix: '_temp', placeholder: 'Temperature' },
    { display: 'Hum', suffix: '_hum', placeholder: 'Humidity' },
];

export const powerMeterDOM: multiNumberDOM[] = [
    { display: 'Volt', suffix: '_volt', placeholder: 'Voltage' },
    { display: 'Curr', suffix: '_curr', placeholder: 'Current' },
    { display: 'Pw', suffix: '_pw', placeholder: 'Power' },
    { display: 'En', suffix: '_en', placeholder: 'Energy' },
    { display: 'Fq', suffix: '_fq', placeholder: 'Frequency' },
    { display: 'PF', suffix: '_pf', placeholder: 'Power Factor' },
];

export const heartbeatSensorDOM: multiNumberDOM[] = [
    { display: 'hb', suffix: '_hb', placeholder: 'HB' },
    { display: 'spo2', suffix: '_spo2', placeholder: 'SPO2' },
];

export const deviceTypes: string[] = ['Boolean', 'Number',"2Numbers","6Numbers","Character","Text", "Climate_sensor", "Power_meter", "Dc_Drive", "Mes_Color_Packet", "Car_Controller", "Vehicle_Controller"];

export const sensorTypes: string[] = ['INPUT', 'OUTPUT', 'IOT GATEWAY', 'COMPONENTS'];

export const dcDriveSensorDOM: dcDriveDOM[] = [
  { display: 'rpm', suffix: '_rpm', placeholder: 'RPM', type: 'numeric' },
  { display: 'mode', suffix: '_mode', placeholder: 'Mode', type: 'select' },
  { display: 'power', suffix: '_power', placeholder: 'Power', type: 'boolean' },
];

export const mesColorPacketDOM: mesColorDOM[] = [
  { display: 'Red', suffix: '_red' },
  { display: 'Green', suffix: '_green' },
  { display: 'Yellow', suffix: '_yellow' },
];

export const carControllerDOM: carControllerDOMConfig[] = [
  { display: 'car controller', placeholder: 'Car', type: 'select', carcolors: ['None', 'Red', 'Green', 'Yellow', 'Blue', 'Purple', 'Brown', 'White'].map(name => ({ name })), }
];

const vehicleList = Array.from({ length: 10 }, (_, index) => `Vehicle-${index + 1}`);

export const vehicleControllerDOM: vehicleControllerDOMConfig[] = [
  { display: 'Vehicle Controller', placeholder: 'vehicles', type: 'select', options: ['None', ...vehicleList].map(name => ({ name }))}
];