import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileAppComponent } from './mobile-app.component';
import { PrebuildCircuitModule } from '../../../shared/components/prebuild-circuit/prebuild-circuit.module';
import { WsApplicationService } from 'src/app/shared/services/ws-application.service';

@NgModule({
  declarations: [
    MobileAppComponent
  ],
  imports: [
    CommonModule,
    PrebuildCircuitModule
  ],
  exports: [
    MobileAppComponent
  ],
  providers:[WsApplicationService]
})
export class MobileAppModule { }
