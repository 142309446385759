<div class="p-8">
  <div class="menus">
    <div class="box">
      <div class="relative push w-full my-6 mr-2"></div>
      <div class="relative push w-full my-6 mr-2"></div>
      <div class="relative push w-full my-6 mr-2"></div>
      <div class="relative push w-full my-6 mr-2">
        <select *ngIf="isGrokCloudMember" name="orgNames" id=""
          class="btn-2 border-gray-200 rounded-lg mb-5 input-style org-name"
          (change)="applyOrganizationFilter($event)">
          <option value="" class="org-name">All Organizations</option>
          <ng-container *ngFor="let org of orgList">
            <option class="input-style" [value]="org.orgName">{{org.orgName}}</option>
          </ng-container>
        </select>
        <select *ngIf="!isGrokCloudMember" name="labNames" id=""
          class="btn-2 border-gray-200 rounded-lg mb-5 input-style org-name">
          <option value="" disabled selected [value]="appliedOrgName">{{appliedOrgName}}</option>
        </select>
      </div>
    </div>
    <div class="min-h-screen flex">
      <div class="overflow-x-auto w-full">
        <div class="min-w-screen min-h-screen flex justify-center font-sans">
          <div class="w-full">
            <div class="bg-white shadow-md rounded my-6">
              <table class="w-full table-auto">
                <thead class="bg-greenish-blue text-white ">
                  <tr class="text-white-600 uppercase text-sm leading-normal" style="height:61px; width:1378px; border-radius:10px;">
                    <th class="text-left font-semibold text-sm uppercase px-2 py-4">
                      <div class="flex inline-flex items-center">
                          <span>
                            LMS USER NAME
                          </span>
                          <span (click)="sortData('lmsuserName','desc')" *ngIf="isSortUser">
                              <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                          </span>
                          <span (click)="sortData('lmsuserName','asc')" *ngIf="!isSortUser">
                              <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                          </span>
                      </div>
                  </th>
                  <th class="text-left font-semibold text-sm uppercase px-2 py-4">
                    <div class="flex inline-flex items-center">
                        <span>
                          LMS USER ID
                        </span>
                        <span (click)="sortData('lmsuserid','desc')" *ngIf="isSortUserId">
                            <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                        </span>
                        <span (click)="sortData('lmsuserid','asc')" *ngIf="!isSortUserId">
                            <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                        </span>
                    </div>
                </th>
                <th class="text-left font-semibold text-sm uppercase px-2 py-4">
                  <div class="flex inline-flex items-center">
                      <span>
                        LAST LOGGED IN TIME
                      </span>
                    <span (click)="sortData('loggedintime','desc')"  *ngIf="isLoggedIn">
                        <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                    </span>
                    <span (click)="sortData('loggedintime','asc')"  *ngIf="!isLoggedIn">
                        <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                    </span>
                  </div>
              </th>
            <th class="text-center" style="padding-left:30px;">ACTION</th> 
                  </tr>
                </thead>
                <tbody class="text-gray-600 text-sm font-light">
                  <tr class=" table-row border-b border-gray-200 hover:bg-gray-100" style = "height: 79px; width:1378px;"
                    *ngFor="let item of activeUserSessionsList; let i = index">
                    <td class="text-left light-text" style="padding-left:30px;">
                      {{item.lmsusername}}
                    </td>
                    <td class="text-left light-text" style="padding-left:30px;">{{item.lmsuserid}}</td>
                    <td class="text-left light-text" style="padding-left:30px;">{{formatDate(item.loggedintime)}}</td>
                    <td class="text-center">
                      <div class="flex item-center justify-center">
                        <button class="red-btn float-right" (click)="logout(item)">Log Out</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="activeUserSessionsList?.length===0" style="justify-content: center;">No Record Found</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>