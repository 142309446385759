import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IDEService } from 'src/app/shared/services/ide.service';
import { ToastrService } from "ngx-toastr";
import { FormControl } from "@angular/forms";
import { DevicesService } from "src/app/shared/services/devices.service";
import { SearchDevices } from "src/app/shared/models/device";
import { PAGE_NUMBER, PAGE_SIZE, DESC } from "src/app/shared/constants/utils.constants"
import { OrganizationsService, Organization } from 'src/app/shared/services/organizations.service';
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-device",
  templateUrl: "./device.component.html",
  styleUrls: ["./device.component.scss"],
})
export class DeviceComponent implements OnInit {
  originalDeviceData: any;
  isDevice: boolean = false;
  showSidePanel!: boolean;
  selectedDevice!: any;
  isModalOpen: boolean = false;
  deleteSelectedDevice: any = null;
  filteredDevices: any;
  orgList: Organization[] = [];
  searchControl = new FormControl(null);
  SortByTypes = {
    DEVICE_NAME: "device_name",
    DEVICE_TOKEN: "device_token",
    LAB_NAME: "lab_name",
    ORG_NAME: "organization_name",
    CREATED_BY: "created_by",
    IS_ACTIVE: "is_active",
  }
  serachValue = new FormControl(null);
  organizationName: any;
  sessionId: string;
  currentOrganization!: string;
  userName: string;
  isSuperAdmin: boolean = true; //used to determine navigation urls
  isGrokCloudMember: boolean = true;

  constructor(
    private router: Router,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private deviceService: DevicesService,
    private orgService: OrganizationsService,
    private sessionStorageService: SessionStorageService
  ) { 
    this.setDefaultOrganization();
    this.isSuperAdmin = window.location.href.includes('/admin/');
  }

  setDefaultOrganization() {
    this.currentOrganization = this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization !== environment.organizationID) {
      this.organizationName = this.currentOrganization;
      this.isGrokCloudMember = false;
     }
    // else {
    //   this.isGrokCloudMember = false;
    //   this.organizationName = this.currentOrganization;
    // }
  }

  ngOnInit(): void {
    this.userName = this.sessionStorageService.getUsername();
    this.searchOrganizations();
    const isSession = JSON.parse(sessionStorage.getItem('user'));
    if(isSession){
      this.sessionId = isSession.session_id
      this.sortFilterColumn();
    }
  }

  navToInstructions() {
    if (this.isSuperAdmin) {
      this.router.navigateByUrl('/admin/connect-device');
    } else {
      this.router.navigateByUrl('/ide/connect-device');
    }
  }

  async searchOrganizations() {
    try {
      const result: any = await this.orgService.search().toPromise();
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }

  editDevice(item: any) {
    if (item.labIdentifier) {
      if (this.isSuperAdmin) { 
        this.router.navigateByUrl(`/admin/device/lab/edit/${item.deviceToken}`)
      } else {
        this.router.navigateByUrl(`/ide/device/lab/edit/${item.deviceToken}`)
      }
    }
    else {
      this.showSidePanel = true;
      this.selectedDevice = item;
    }
  }

  copyToken(token: string) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = token;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    this.toasterService.success(`${token} is copied!`);
  }

  openSelectedDevice(selectedDevice: any) {
    this.showSidePanel = true;
    this.selectedDevice = selectedDevice;
  }

  async delete(selectedDevice: any) {
    if (selectedDevice) {
      if (this.deleteSelectedDevice.labIdentifier) {
        this.deleteLabDevice(selectedDevice)
      }
      else {
        this.deleteUserDevice(selectedDevice)
      }
    }
  }

  async deleteUserDevice(selectedDevice: any) {
    const { deviceToken, deviceName } = this.deleteSelectedDevice;
    try {
      console.log(this.deleteSelectedDevice)
      const result: any = await this.ideService.deleteDevice(deviceToken).toPromise();
      if (result) {
        await this.sortFilterColumn();
        this.isModalOpen = false;
        this.toasterService.success(`${deviceName} is deleted!`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  closeModal(event: any) {
    this.deleteSelectedDevice = null;
    this.isModalOpen = false;
  }

  confirmAndDelete(item: any) {
    this.deleteSelectedDevice = item;
    this.isModalOpen = true;
  }

  sidePanelClosed(event: any) {
    if (event) {
      this.showSidePanel = false;
      this.selectedDevice = null;
      this.sortFilterColumn()
    }
  }

  applyFilter(event: Event) {
    const filterValue = ((event.target as HTMLInputElement).value).toLowerCase();
    this.sortFilterColumn('', '', filterValue, PAGE_NUMBER, PAGE_SIZE)
  }

  isDeviceName: boolean = true;
  changeOrdDevice(order: any) {
    this.isDeviceName = !this.isDeviceName;
    this.sortFilterColumn(this.SortByTypes.DEVICE_NAME, order, '', PAGE_NUMBER, PAGE_SIZE)
  }

  isLab: boolean = true;
  changeOrdLab(order: any) {
    this.isLab = !this.isLab;
    this.sortFilterColumn(this.SortByTypes.LAB_NAME, order, '', PAGE_NUMBER, PAGE_SIZE)
  }

  isOrgName: boolean = true;
  changeOrdOrg(order: any) {
    this.isOrgName = !this.isOrgName;
    this.sortFilterColumn(this.SortByTypes.ORG_NAME, order, '', PAGE_NUMBER, PAGE_SIZE)
  }

  isCreatedBy: boolean = true;
  changeOrdCrtBy(order: any) {
    this.isCreatedBy = !this.isCreatedBy;
    this.sortFilterColumn(this.SortByTypes.CREATED_BY, order, '', PAGE_NUMBER, PAGE_SIZE)
  }

  isStatus: boolean = true;
  changeOrdStatus(order: any) {
    this.isStatus = !this.isStatus;
    this.sortFilterColumn(this.SortByTypes.IS_ACTIVE, order, '', PAGE_NUMBER, PAGE_SIZE)
  }

  async sortFilterColumn(sortBy?: string, sortOrder?: string, searchVal?: string, pageNumber?: number, pageSize?: number) {
    try {
      const getAllDevices = {} as SearchDevices;
      getAllDevices.userOrgName = this.sessionStorageService.getCurrentOrganization();
      getAllDevices.searchOrgName = this.organizationName ? this.organizationName : null;
      getAllDevices.userName = this.userName || '' 
      getAllDevices.pageNumber = pageNumber ? pageNumber : PAGE_NUMBER;
      getAllDevices.pageSize = pageSize ? pageSize : PAGE_SIZE;
      getAllDevices.searchString = searchVal ? searchVal : this.serachValue.value;
      getAllDevices.orderBy = [{
        sortBy: sortBy ? sortBy : this.SortByTypes.DEVICE_NAME,
        sortOrder: sortOrder ? sortOrder : DESC
      }]
      const result: any = await this.deviceService.search(getAllDevices).toPromise();
      if (result.success) {
        this.filteredDevices = result?.devices;
        this.originalDeviceData =this.filteredDevices;
        this.isDevice = true;
      } else {
        this.filteredDevices = [];
      }
    } catch (error:any) {
      console.log(error);
      this.filteredDevices = [];
      this.toasterService.error(error.error.message)
    }
  }

  onChange(event: any) {
    if (event.target.value != "") {
      this.organizationName = event.target.value;
    } else {
      this.organizationName = null;
    }
    this.sortFilterColumn();
  }

  async deleteLabDevice(selectedDevice: any) {
    const { deviceToken, deviceName } = this.deleteSelectedDevice;
    try {
      const result: any = await this.deviceService.deleteDevice(deviceToken).toPromise();
      if (result) {
        await this.sortFilterColumn();
        this.isModalOpen = false;
        this.toasterService.success(`${deviceName} is deleted!`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  isMenuOpen: boolean = false;
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
  }

  isDeviceMenuOpen: boolean = false;
  toggleDeviceMenu(){
    this.isDeviceMenuOpen = !this.isDeviceMenuOpen;
  }
  onSelectModule(flag: string) {
    if (flag === 'lab') {
      if (this.isSuperAdmin) {
        this.router.navigateByUrl("/admin/device/lab/add");
      } else {
        this.router.navigateByUrl("/ide/device/lab/add");
      }
    }
    else {
      this.showSidePanel = false;
      if (this.isSuperAdmin) {
        this.router.navigateByUrl("/admin/device/user/add");
      } else {
        this.router.navigateByUrl("/ide/device/user/add");
      }
    }
  }
   /**Filter Device Type */
   onSelectDeviceType(device_Type:string){
      if (device_Type === "all") {
        this.filteredDevices=this.originalDeviceData; 
     } else  {
      this.filteredDevices=this.originalDeviceData.filter((a: any) => a.deviceType === device_Type);
     }  
     this.toggleDeviceMenu();
  }
}
