<div class="overlay bg-black w-full h-full fixed top-0 left-0 opacity-50 z-50" (click)="close()"></div>

<div class="transition duration-700 ease-in-out fixed right-0 top-0  overflow-auto z-50 h-full bg-white shadow-xl"
  [class.modal]="isModal" [class.deleteModal]="boxWidth" [ngClass]="boxWidth ? boxWidth :  'boxWidth'">
  <div class="m-4 fixed right-0 z-50 cursor-pointer">
    <div type="button" class="close text-gray cursor-pointer" (click)="close()">
      <span aria-hidden="true" [class.text-4xl]="!isModal" [class.text-3xl]="isModal">&times;</span>
    </div>
  </div>
  <div class="p-4 relative">
    <ng-content></ng-content>
  </div>
</div>
