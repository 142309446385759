import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class UserSessionService {
    constructor(private http: HttpClient) { }

    getActiveUserSessions(orgName: string) {
        let url = `${environment.apiURL}/admin/users/active-sessions`;
        if (orgName) url += `?orgName=${orgName}`;
        return this.http.get<any>(url)
    }

    extendToken(lms_username: string) {
        const url = `${environment.apiURL}/admin/users/extend-token`;
        return this.http.put<any[]>(url, {
            lms_username: lms_username
        })
    }

    forceLogout(username: string) {
        const url = `${environment.apiURL}/admin/users/force-logout`;
        return this.http.put<any[]>(url, {
            lms_username: username
        })
    }

    verifyUserLogin(username: string, orgName: string) {
        let body = {
            userName: username,
            orgName: orgName
        }
        const url = `${environment.apiURL}/admin/users/verify-user-login`;
        return this.http.post<any>(url, body)
    }
}