<div class="absolute bg-black opacity-80 inset-0 z-0"></div>
<div class="display-box">
  <!--content-->
  <div class="spaced">
    <!--body-->
    <div class="flex-auto justify-center m-5">
      <h2 class="text-xl font-bold">Follow below instructions:</h2>
      <div>
        <b>1. </b>Uninstall old Grok Manager using below command (if already installed)
        <br>
        <div><code>sudo apt remove grok-desktop -y</code></div>
      </div>
      <div><b>2. </b>
        <a [href]="debianPackageUrl" style="color: blue">Click here </a>to download installable package
      </div>
      <div><b>3. </b> Transfer downloaded file from Laptop to Raspberry Pi using VNC Client</div>
      <div><b>4. </b>Click on "Send files"</div>
      <div><b>5. </b>Select "{{fileName}}" from Downloads</div>
      <div><b>6. </b>Click on "Open"</div>
      <div><b>7. </b>Close "File transfer" window</div>
      <div><b>8. </b>Double click "{{fileName}}" on desktop of RPI from VNC</div>
      <div><b>8. </b>Click on "Install"</div>
      <div><b>9. </b>Enter password and click on OK</div>
      <div><b>10. </b>Go to Menu -> Programming -> Grok Manager</div>
      <div><b>11. </b>After opening "Grok Manager" click on "Register" button</div>
      <div><b>12. </b>Paste below mentioned token in "Grok Manager"</div>
      <div *ngIf="token"><code>{{token}}</code></div>
      <div><b>13. </b>Click on Go</div>
      <div><b>14. </b>Click on "Close"</div>
    </div>
    <!--footer-->
  </div>
</div>
