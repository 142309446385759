import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveBlockCircuitComponent } from './save-block-circuit.component'
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SaveBlockCircuitComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[SaveBlockCircuitComponent]
})
export class SaveBlockCircuitModule { }
