import { IDEAuthGuard } from "../shared/guards/ide.auth.guard";
import { CircuitBuilderComponent } from "./containers/circuit-builder/circuit-builder.component";
import { DashboardComponent } from "./containers/dashboard/dashboard.component";
import { BlockProgrammingComponent } from "./containers/block-programming/block-programming.component";
import { MobileAppComponent } from "./containers/mobile-app/mobile-app.component";
import { SimulatorAppComponent } from "./containers/simulator-app/simulator-app.component";
import { ApplicationCreatorComponent } from "../admin/container/application-creator/application-creator.component";
import { ApplicationComponent } from "../admin/container/application/application.component";
import { ConnectDeviceComponent } from "./containers/connect-device/connect-device.component";
import { DownloadFilesComponent } from "./containers/download-files/download-files.component";
import { CredentialsWarningComponent } from "./containers/credentials-warning/credentials-warning.component";
import { DeviceSessionComponent } from "./containers/device-session/device-session.component";
import { UserSectionComponent } from "./containers/user-section/user-section.component";
import { LabsListComponent } from '../admin/container/labs-list/labs-list.component';
import { DeviceComponent } from '../admin/container/device/device.component';
import { AddDeviceLabComponent } from '../admin/container/device/add-device-lab/add-device-lab.component';
import { CreateLabComponent } from '../admin/container/labs-list/create-lab/create-lab.component';
import { EditLabComponent } from '../admin/container/labs-list/edit-lab/edit-lab.component';
import { EditDeviceComponent } from '../admin/container/device/components/user-device-add/edit-device.component';
import { EditUserDeviceComponent } from '../admin/container/device/components/edit-user-device/edit-user-device.component';
import { PythonDirectComponent } from "../admin/container/python-direct/python-direct.component";

export const routes = [
  { path: '', component: DashboardComponent },
  { path: 'circuit', canActivate: [IDEAuthGuard], component: CircuitBuilderComponent },
  { path: 'application/circuit', canActivate: [IDEAuthGuard], component: CircuitBuilderComponent },
  { path: 'template/circuit', canActivate: [IDEAuthGuard], component: CircuitBuilderComponent },
  { path: 'block', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'application/block', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'template/block', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'circuit-direct', canActivate: [IDEAuthGuard], component: CircuitBuilderComponent },
  { path: 'block-direct', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'block-direct-iot', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'block-direct-robotics', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'block-direct-ds', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'block-direct-aiot', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'block-direct-ai', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'block-direct-iiot', canActivate: [IDEAuthGuard], component: BlockProgrammingComponent },
  { path: 'python-direct', canActivate: [IDEAuthGuard], component: PythonDirectComponent },
  { path: 'mobile-app', canActivate: [IDEAuthGuard], component: MobileAppComponent },
  { path: 'simulator-app', canActivate: [IDEAuthGuard], component: SimulatorAppComponent },
  { path: 'application/create', canActivate: [IDEAuthGuard], component: ApplicationCreatorComponent },
  { path: 'application/edit/:application_identifier', canActivate: [IDEAuthGuard], component: ApplicationCreatorComponent },
  { path: 'applications', canActivate: [IDEAuthGuard], component: ApplicationComponent },
  { path: 'connect-device', canActivate: [IDEAuthGuard], component: ConnectDeviceComponent },
  { path: 'files/:module_name', canActivate: [IDEAuthGuard], component: DownloadFilesComponent },
  { path: 'credentials-warning', canActivate: [IDEAuthGuard], component: CredentialsWarningComponent },
  { path: 'device-session', canActivate: [IDEAuthGuard], component: DeviceSessionComponent },
  { path: 'user-section', canActivate: [IDEAuthGuard], component: UserSectionComponent },
  { path: 'labs', canActivate: [IDEAuthGuard], component: LabsListComponent },
  { path: 'devices', canActivate: [IDEAuthGuard], component: DeviceComponent },
  { path: 'device/lab/add', canActivate: [IDEAuthGuard], component: AddDeviceLabComponent },
  { path: 'create-lab', canActivate: [IDEAuthGuard], component: CreateLabComponent },
  { path: 'edit-lab/:labid', canActivate: [IDEAuthGuard], component: EditLabComponent },
  { path: 'device/lab/edit/:device_token', canActivate: [IDEAuthGuard], component: AddDeviceLabComponent },
  { path: 'device/user/add', canActivate: [IDEAuthGuard], component: EditDeviceComponent },
  { path: 'device/user/edit/:device_token', canActivate: [IDEAuthGuard], component: EditUserDeviceComponent }
]
