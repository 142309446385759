import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import Konva from 'konva';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UserSessionService } from 'src/app/shared/services/user.session.service';

@Component({
  selector: 'app-robotics-visualization',
  templateUrl: './robotics-visualization.component.html',
  styleUrls: ['./robotics-visualization.component.scss']
})
export class RoboticsVisualizationComponent implements OnInit {
  @Input() current_location!: markerLocation;
  @Input() simulatorDefinition!: any;
  @Input() robot_status: boolean = false;

  layer: any;
  stage: any;

  visualization!: string;
  mobile_console: boolean = true;
  visualization_panel: boolean = false;

  directions: any = {
    list: [
      { name: "forward", angle: 0 },
      { name: "left", angle: -90 },
      { name: "backward", angle: -180 },
      { name: "right", angle: 90 },
    ],
    index: 0,
    // rr: 5000, // refresh rate
  };
  marker: any = {
    angle: 0,
    x: 449,
    y: 449,
    element: null
  };
  dottedLine: any = {
    element: null,
    rr: 10, // refresh rate
    points: []
  };
  visualization_config: any = {
    count: 0,
    zigzag_up: true,
    square_direction: "up",
    circle_element: null
  }

  constructor(
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    this.generateLine();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasLocation = changes?.current_location?.currentValue;
    const hasDefinition = changes?.simulatorDefinition?.currentValue;
    if (hasLocation) {
      this.marker.angle = hasLocation.degree;
      this.marker.x = hasLocation.x;
      this.marker.y = hasLocation.y;
      this.update_screen();
    }
    if (hasDefinition) {
      this.visualization = hasDefinition.visualization;
      this.mobile_console = hasDefinition.mobile_console;
      this.visualization_panel = hasDefinition.visualization_panel;
    }
  }

  startVisualization() {
    setInterval(() => {
      if (this.robot_status) {
        this.go_ahead();
      }
    }, this.dottedLine.rr); // rr = refresh rate
  }

  between(x: number, min: number, max: number) {
    return x >= min && x <= max;
  }

  generateLine() {
    var _this = this;
    let stage_area = document.getElementById("robotics-container");
    var width = stage_area?.offsetWidth;
    var height = stage_area?.offsetHeight;

    this.stage = new Konva.Stage({
      container: 'robotics-container',
      width: width,
      height: height,
      // draggable: true
    });

    this.layer = new Konva.Layer();

    this.dottedLine.element = new Konva.Line({
      points: this.dottedLine.points,
      stroke: 'white',
      strokeWidth: 5,
      lineCap: 'round',
      lineJoin: 'round',
      dash: [33, 10],
    });

    var imageObj = new Image();
    imageObj.onload = function () {
      _this.marker.element = new Konva.Image({
        x: _this.marker.x,
        y: _this.marker.y,
        image: imageObj,
        width: 50,
        height: 100,
      });

      // add the shape to the layer
      _this.layer.add(_this.marker.element);
      setInterval(() => {
        _this.marker.element.rotation(_this.marker.angle);
        _this.marker.element.position({ x: _this.marker.x, y: _this.marker.y });
        // _this.layer.batchDraw();
      }, _this.dottedLine.rr);
    };
    imageObj.src = '/assets/img/mobile-app/car-marker.png';

    this.layer.add(this.dottedLine.element);
    this.stage.add(this.layer);
    this.startVisualization();
  }

  update_screen() {
    let new_points = [this.marker.x, this.marker.y];
    if (this.dottedLine.element.points().length == 0) {
      this.dottedLine.element.points(new_points);
    } else {
      this.dottedLine.element.points(this.dottedLine.element.points().concat(new_points));
    }
    this.layer.batchDraw();
    this.reCenter();
  }

  go_ahead() {
    if (this.marker.element) {
      if (this.visualization == "circle") {
        this.marker.element.offsetX(-125);
        this.marker.element.offsetY(35);
      } else if (this.visualization == "swing-left") {
        this.marker.element.offsetX(15);
        this.marker.element.offsetY(30);
      } else {
        this.marker.element.offsetX(25);
        this.marker.element.offsetY(50);
      }
    }
    switch (this.visualization) {
      case "forward":
        --this.marker.y;
        this.marker.angle = 0;
        break;
      case "backward":
        ++this.marker.y;
        this.marker.angle = 0;
        break;
      case "left":
        --this.marker.angle;
        break;
      case "right":
        ++this.marker.angle;
        break;
      case "zigzag":
        if (this.visualization_config.zigzag_up == true) {
          --this.marker.y;
          this.marker.angle = 0;
        } else {
          --this.marker.x;
          this.marker.angle = -90;
        }
        this.visualization_config.count++;
        if (this.visualization_config.count % 100 == 0) {
          this.visualization_config.zigzag_up = !this.visualization_config.zigzag_up;
        }
        break;
      case "square":
        switch (this.visualization_config.square_direction) {
          case "up":
            this.marker.angle = 0;
            --this.marker.y;
            break;
          case "down":
            this.marker.angle = -180;
            ++this.marker.y;
            break;
          case "left":
            this.marker.angle = -90;
            --this.marker.x;
            break;
          case "right":
            this.marker.angle = 90;
            ++this.marker.x;
            break;
          default:
            break;
        }
        this.visualization_config.count++;
        if (this.visualization_config.count % 100 == 0) {
          this.visualization_config.zigzag_up = !this.visualization_config.zigzag_up;
          switch (this.visualization_config.square_direction) {
            case "up":
              this.visualization_config.square_direction = "left";
              break;
            case "left":
              this.visualization_config.square_direction = "down";
              break;
            case "down":
              this.visualization_config.square_direction = "right";
              break;
            case "right":
              this.visualization_config.square_direction = "up";
              break;
            default:
              break;
          }
        }
        break;
      case "circle":
        if (!this.visualization_config.circle_element) {
          this.visualization_config.circle_element = new Konva.Circle({
            radius: 150,
            fill: 'transparent',
            stroke: 'white',
            strokeWidth: 5,
            x: 450,
            y: 450
          });
          this.layer.add(this.visualization_config.circle_element);
          this.zoom("out");
        }
        if (this.marker.element) {
          this.visualization_config.count++;
          this.marker.angle = -(this.visualization_config.count % 360)
        }
        break;
      case "swing-left":
        if (!this.visualization_config.circle_element) {
          this.visualization_config.circle_element = new Konva.Circle({
            radius: 10,
            fill: 'transparent',
            stroke: 'white',
            strokeWidth: 5,
            x: 450,
            y: 450
          });
          this.layer.add(this.visualization_config.circle_element);
          this.zoom("out");
        }
        if (this.marker.element) {
          this.visualization_config.count++;
          this.marker.angle = -(this.visualization_config.count % 360)
        }
        break;
      default:
        return;
    }
    this.update_screen();
  }

  reCenter() {
    let scale = this.stage.scaleX();

    let stage_area = document.getElementById("robotics-container");
    let width = stage_area?.offsetWidth || 500;
    let height = stage_area?.offsetHeight || 500;

    this.layer.absolutePosition({
      x: (-this.marker.x * scale) + width / 2,
      y: (-this.marker.y * scale) + height / 2
    });
  }

  zoom(type: string) {
    let oldScale = this.stage.scaleX();
    let newScale;
    if (type == "in") {
      newScale = oldScale * 1.1;
    } else {
      newScale = oldScale * 0.9;
    }
    this.stage.scale({ x: newScale, y: newScale });
    this.stage.batchDraw();
  }
}
interface markerLocation {
  x: number,
  y: number,
  z: number,
  degree: number
}
