<div class="grid grid-cols-1">
  <div class="p-2">
    <button class="disabled-btn float-right custom-lg-btn" [disabled]="true">Exit</button>
    <button class="blue-btn float-right custom-lg-btn" (click)="onNext()">Next</button>
    <button class="grey-btn float-right custom-lg-btn" (click)="goBack()">Back</button>
  </div>

  <div>
    <p class="text-md mb-2">Select all the sensors which you'll need for your application</p>
    <p class="text-xs text-gray-400 mb-10">Click on selected ones to create meta data</p>
  </div>
</div>

<ng-container *ngIf="sensors">
  <div class="w-full h-screen">
    <iot-devices-section [sensors]="sensors" [isSelecting]="true" (emitSensorSelect)="onSelect($event)"
      (emitSelectedNavMenu)="setSelectedMenu($event)" [applicationData]="applicationData"></iot-devices-section>
  </div>
</ng-container>
