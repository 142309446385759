import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

export interface Organization {
    orgId: number,
    orgName: string
}

export interface CreateOrgPayload {
    orgName: string
}

@Injectable({
    providedIn: 'root'
})

export class OrganizationsService {
    constructor(private http: HttpClient) { }

    search(payload: any = {}) {
        const url = `${environment.apiURL}/admin/organizations/search`;
        return this.http.post(url, payload);
    }

    create(payload: CreateOrgPayload) {
        const url = `${environment.apiURL}/admin/organizations/create`;
        return this.http.post(url, payload);
    }
}