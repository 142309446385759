<div class="relative">
  <section *ngIf="!isNotebook" id="blocklyContainer">
    <ngx-blockly [config]="config" [generatorConfig]="generatorConfig" (pythonCode)="onCode($event)">
    </ngx-blockly>
  </section>

  <section *ngIf="isNotebook">
    <iframe [src]="notebook_url">
    </iframe>
  </section>


  <!-- Button for switch to notebook -->
  <div *ngIf="isReadOnly && ['data_science', 'artificial_intelligence'].includes(moduleName)" class="p-2 absolute cursor-pointer z-10 switch right-0">
    <div class="flex floating-button justify-end py-2 box-1 my-2">
      <button class="bg-white ring-2 ring-button text-button p-2 text-md font-bold mr-5 rounded-lg"
        (click)="toggleNotebook()">Switch To {{ isNotebook ? 'Block' : 'Notebook' }}</button>
    </div>
    <div class="flex box-1 my-2 floating-button justify-end" *ngIf="isVideoBtn && !isAdmin && ['data_science', 'artificial_intelligence'].includes(moduleName)">
      <button (click)="videoPlay()" class="video-box ring-2 ring-button rounded-lg p-2 mr-5">
        <img src="assets/img/icons/video.svg" title="Play Video" />
      </button>
    </div>
    <div *ngIf ="parentComponent == 'BlockProgrammingComponent-IDE'" class="flex box-1 my-2 floating-button justify-end">
      <button (click)="emitCompareBlock()" class="video-box ring-2 ring-button rounded-lg p-2 mr-5">
        <img src="assets/img/icons/compare-red-color.png" title="Compare Block" />
      </button>
    </div>
    <div class="flex floating-button justify-end">
      <a [href]="notebook_download_url" [download]="notebook_download_name"><button
          class="bg-white ring-2 ring-button text-button p-2 text-md font-bold mr-5 rounded-lg"
          *ngIf="isNotebook && notebook_download_url">
          Download Notebook
        </button></a>
    </div>
  </div>

  <!-- Play video start-->
  <div *ngIf="isVideoBtn && !isAdmin && !['data_science', 'artificial_intelligence'].includes(moduleName)"
    class="p-2 absolute cursor-pointer z-10 switch right-0">
    <div class="flex box-1 my-2 floating-button">
      <button (click)="videoPlay()" class="video-box ring-2 ring-button rounded-lg p-2 mr-5">
        <img src="assets/img/icons/video.svg" title="Play Video" />
      </button>
    </div>
  </div>
  <!-- Play video end-->

  <!-- compare blockly code on two sides-->

  <div *ngIf="!isAdmin && isTemplate && !['data_science', 'artificial_intelligence'].includes(moduleName) && parentComponent == 'BlockProgrammingComponent-IDE'" class="p-2 absolute cursor-pointer z-10 switch right-0" [class.compare-mt]="isVideoBtn && !isAdmin && !['data_science', 'artificial_intelligence'].includes(moduleName)">
    <div class="flex box-1 my-2 floating-button">
      <button (click)="emitCompareBlock()" class="video-box ring-2 ring-button rounded-lg p-2 mr-5">
        <img  src="assets/img/icons/compare-red-color.png" title="Compare Block" />
      </button>
    </div>
  </div>



  <div *ngIf="toggle && !['data_science', 'artificial_intelligence'].includes(moduleName)">
    <div class="absolute toggle-btn rounded-full cursor-pointer z-10" [class.block-direct-btn]="!isReadOnly">
      <label class="switch">
        <input type="checkbox" (click)="toggleState()" [disabled]="disableToggleBtn"
          [class.opacity-50]="disableToggleBtn" />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="slider-label text-black absolute text-xs z-10" [class.block-direct-label]="!isReadOnly">{{toggleBtnState
      ?
      "With" : "Without"}} Mobile</div>
  </div>
  <button *ngIf="isReadOnly && !isNotebook" class="p-2 absolute reload-btn rounded-full cursor-pointer z-10"
    (click)="reload()">
    <img class="" src="assets/img/refresh.svg" class="h-6 w-6" />
  </button>

</div>