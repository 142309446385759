import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ApplicationService } from "src/app/shared/services/application.service";
import { IDEService } from "src/app/shared/services/ide.service";
import { WsApplicationService } from "src/app/shared/services/ws-application.service";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { showSidePanel } from "src/app/store/layout/layout.action";
import { sidePanelSelector } from "src/app/store/layout/layout.selector";
import { trigger, transition, animate, style, state } from '@angular/animations';
import { ToastrService } from "ngx-toastr";
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { initialSimulatorDefinition, simulatorDefinition } from "src/app/shared/models/configDef";
import {climateSensorDOM ,heartbeatSensorDOM,powerMeterDOM,dcDriveSensorDOM, mesColorPacketDOM, carControllerDOM, vehicleControllerDOM} from 'src/app/shared/constants/sensors'
import { UserSessionService } from "src/app/shared/services/user.session.service";

@Component({
  selector: "app-screen-list",
  templateUrl: "./screen-list.component.html",
  styleUrls: ["./screen-list.component.scss"],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ],
})
export class ScreenListComponent implements OnInit, AfterViewInit {
  @Output() emitLogoutEvent = new EventEmitter();
  @Output() emitIsRunningCode = new EventEmitter();
  @Output() emitBack = new EventEmitter();

  @Input() moduleName = '';

  @ViewChild("search", { static: false }) searchField!: ElementRef;

  private deviceId!: string;
  private statusCollected = false;
  private lastStoreTimeStamp!: number;

  isDeviceOnline!: boolean;
  isMobileMode!: boolean;
  hostName: string = "";
  isRPIConnected: boolean = false;
  isSimulatorActive: boolean = false;
  dropdownOpen: boolean = false;

  simulatorDefinition: simulatorDefinition = initialSimulatorDefinition;
  controlPanelValues: any = {};
  robotics: any = {
    controller_type: "switch",
    status: true
  }
  current_location: any;

  runningApps: any[] = [];
  isSelectedRunningCode = false;
  screenListForm!: FormGroup;
  templates: any[] = [];
  applications: any[] = [];
  filteredApplications: any[] = [];
  selectedApplication!: any;
  runApplicationRequested!: any;
  sensors!: any;
  wsSubscription!: Subscription;
  commandItems: any[] = [];
  showTemplates = true;
  current_username: string;

  showSidePanel$ = this.store.select(sidePanelSelector);

  pulseoxymeterForm = this.formBuilder.group({
    _hb: [''],
    _spo2: ['']
  });
  heartbeatSensorDOM = heartbeatSensorDOM;
  climateSensorDOM = climateSensorDOM;
  powerMeterDOM = powerMeterDOM;
  dcDriveSensorDOM = dcDriveSensorDOM;
  mesColorPacketDOM = mesColorPacketDOM;
  carControllerDOM = carControllerDOM;
  vehicleControllerDOM = vehicleControllerDOM;

  fullSizeBlocks: string[] = ['2Numbers', 'Climate_sensor', 'Power_meter','Numbers'];

  dc_mode = [
    { id: 0, name: 'None' },
    { id: 1, name: 'Mode 1' },
    { id: 2, name: 'Mode 2' },
    { id: 3, name: 'Mode 3' },
    { id: 4, name: 'Mode 4' },
    { id: 5, name: 'Mode 5' },
    { id: 6, name: 'Mode 6' },
    { id: 7, name: 'Mode 7' },
    { id: 8, name: 'Mode 8' },
    { id: 9, name: 'Mode 9' },
    { id: 10, name: 'Mode 10' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private sessionStorageService: SessionStorageService,
    private wsApplicationService: WsApplicationService,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private store: Store<IAppState>,
    private userSessionService: UserSessionService
  ) {
    this.current_username = this.sessionStorageService.getUsername();
  }

  async ngOnInit() {

    this.screenListForm = this.formBuilder.group({
      search_item: ["", Validators.compose([Validators.required])],
      toggle_mode: ["", Validators.compose([Validators.required])],
    });

    await this.getRPIStatus();
    // this.createDeviceWebSocketConnection();
    await this.getAllApplications();
    this.isOnline();
    this.isMobileMode = false;
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  ngAfterViewInit() {
    fromEvent(this.searchField.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          const searchQuery = this.searchField.nativeElement.value;
          if (searchQuery) {
            this.filteredApplications = this.templates.filter((y: any) => {
              return y?.applicationName
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase());
            });
          } else {
            this.filteredApplications = this.templates;
          }
        })
      )
      .subscribe();
  }

  private createWebSocketConnection() {
    this.commandItems = [];
    const appId = this.selectedApplication.applicationIdentifier;
    if (appId && !this.isSelectedRunningCode) {
      this.wsSubscription = this.wsApplicationService
        .createObservableCircuit(appId)
        .subscribe((data: any) => {
          let parsedData = JSON.parse(data);
          if (!parsedData) return;

          const outputData = parsedData.find(
            (el: any) => el.ioType === "OUTPUT"
          );
          const state = outputData?.state;

          const actionableItems = outputData?.actionableItems;
          if (actionableItems) {
            actionableItems.forEach((item: any) => {
              if (item) {
                this.commandItems.unshift({
                  ...item,
                  state: state,
                  time: new Date(),
                });
              }
            });

            this.commandItems = this.commandItems.slice(0, 20);
          }
        });
    }
  }

  private getRPIStatus() {
    this.ideService.getRPIStatus(this.current_username)
      .then((result: any) => {
        this.isRPIConnected = !!result?.result?.length;
        this.deviceId = result.result[0].deviceIdentifier;
        this.hostName = result.result[0].hostname;
        this.getConnectedDevice();
        this.createDeviceWebSocketConnection();
      })
      .catch((err) => console.log(err));
  }

  async getConnectedDevice() {
    this.ideService.getDeviceStatus(this.deviceId).toPromise()
      .then((result) => this.statusCollected = true)
      .catch((err) => console.error(err));
  }

  private isOnline() {
    setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDiff = (currentTime - this.lastStoreTimeStamp) / 1000;
      this.isDeviceOnline = timeDiff < 15;
      if (!this.isDeviceOnline) {
        this.templates.map((item) => {
          item.isOnline = false;
        });
      }
    }, 1000);
  }

  private createDeviceWebSocketConnection() {
    const appId = this.deviceId;
    if (appId) {
      this.wsSubscription = this.wsApplicationService
        .createObservableCircuit(appId)
        .subscribe(async (data: any) => {
          let parsedData = JSON.parse(data);
          if (parsedData) {
            this.runningApps = parsedData.runningApps;
            if (this.runningApps) {
              this.templates.map((item: any) => {
                if (
                  this.runningApps?.length &&
                  this.runningApps?.includes(item.applicationName)
                ) {
                  item.isOnline = true;
                } else {
                  item.isOnline = false;
                }
              });
            }


            if (parsedData.topic === "simulator-log") {
              const { message, robot_location } = parsedData;
              this.current_location = robot_location;
              this.printConsoleMessages(message);
            } else if (parsedData.topic === "runApplication") {
              const { code } = parsedData;
              switch (code) {
                case "other_apps_running":
                  this.store.dispatch(showSidePanel({ value: true }));
                  break;

                case "code_not_found":
                  this.toasterService.error("Code does not exists on the Device");
                  break;

                default:
                  break;
              }
            } else if (parsedData.topic === "forceDissociate") {
              this.toasterService.show("The lab administrator has removed your access to this device. If this was unexpected please contact the lab admin to restore your connect back to the device.");
              this.wsApplicationService.close();
            }

            this.lastStoreTimeStamp = parsedData.timestamp;
          }
        });
    }
  }

  private async getAllApplications() {
    let template_promise = this.applicationService.getAllApplicationTemp(environment.organizationID, this.moduleName).toPromise();
    let application_promise = this.applicationService.getAllApplication(environment.organizationID, this.current_username, this.moduleName).toPromise();

    Promise.all([template_promise, application_promise]).then(([templates_list, applications_list]: any) => {
      this.templates = templates_list.applications;
      this.applications = applications_list.applications;
      this.refreshApplicationsList();
    })
  }

  refreshApplicationsList() {
    this.filteredApplications = this.showTemplates ? this.templates : this.applications;
  }

  get formControls() {
    return this.screenListForm?.controls;
  }

  async toggled() {
    const appId = this.selectedApplication.applicationIdentifier;
    const item = this.sensors;
    // state change of both Input and Output actionable items
    item.forEach((ele: any) => {
      ele.state = !ele.state;
    });
    const inputItems = item.find((ele: any) => ele.ioType === "INPUT");
    let inputActionableItems = inputItems?.actionableItems;
    for (let key in inputActionableItems) {
      inputActionableItems[key] = !inputActionableItems[key];
    }
    sessionStorage.setItem("state", JSON.stringify(item));
    // subscribe to socket
    this.wsApplicationService.sendData(item, appId);

    if (
      this.deviceId &&
      this.isDeviceOnline &&
      this.selectedApplication.isOnline
    ) {
      try {
        const payload = {
          inputActionableItems,
          applicationIdentifier: appId,
          device_identifier: this.deviceId,
        };
        await this.ideService.toggle(payload).toPromise();
      } catch (error) { }
    }
  }

  selectApplication(selectedApplication: any) {
    const appId = selectedApplication.applicationIdentifier;
    this.isSelectedRunningCode = this.runningApps.includes(appId);
    this.emitIsRunningCode.emit(this.isSelectedRunningCode);
    this.applicationService.getApplicationTemplateV3(appId)
      .then((template: any) => {
        if (template.Application?.simulatorDefinition) {
          this.simulatorDefinition = JSON.parse(template.Application.simulatorDefinition);
        }
        return template.Application;
      })
      .then((application: any) => {
        this.robotics.controller_type = application.controller_type;
        application = JSON.parse(application.configurationDefinition) || {};
        this.setSensors(application);
        this.isSimulatorActiveLogic(selectedApplication);
      })
      .then(() => {
        this.selectedApplication = selectedApplication;
        this.updateEmulatorView(appId); // update the iframe to display circuit
      });
  }

  async runApplicationSignal(application_name: string, action_type: string) {
    if (this.deviceId) {
      await this.ideService.runApplication(this.deviceId, application_name, action_type).toPromise();
    } else {
      console.log("Device is not online, Can't Run Application");
    }
  }

  async runApplication(application_name: any) {
    this.runApplicationRequested = application_name;
    await this.runApplicationSignal(application_name, "New");
  }

  async stopApplication(application_name: any) {
    await this.runApplicationSignal(application_name, "Stop");
  }

  async runApplicationForcefully() {
    const appId = this.runApplicationRequested;
    await this.runApplicationSignal(appId, "Force_New");
    this.store.dispatch(showSidePanel({ value: false }));
  }

  private setSensors(application: any) {
    let inputSensors: any = application.circuitDefinition.targets.filter(
      (ele: any) => ele.sensorType === "INPUT"
    );
    this.sensors = inputSensors;
    // this.sensors.forEach((sensor: any) => {
    //   this.controlPanelValues[sensor.customName + '_hb'] = 0;
    //   this.controlPanelValues[sensor.customName + '_spo2'] = 0;
    // });
    this.resetSensorInputs();
  }

  private async resetSensorInputs() {
    await this.sensors.forEach((sensor: any) => {
      if (sensor.deviceType == "Boolean") {
        this.controlPanelValues[sensor.customName] = false;
      } else if (sensor.deviceType == "Number") {
        this.controlPanelValues[sensor.customName] = 0;
      }
    });
  }

  // private sendMessageFromIFrame(msg: any) {
  //   window.parent.postMessage(msg, "*");
  // }

  private updateEmulatorView(appId: string) {
    const simulatorUrl = `${environment.domainUrl}simulator/id/${appId}`;
    // this.sendMessageFromIFrame(JSON.stringify(simulatorUrl));
    this.createWebSocketConnection();
  }

  isSimulatorActiveLogic(current_application_identifier: any): void {
    if (
      this.isRPIConnected // if device is registered
      && this.isDeviceOnline // if device is online
      && current_application_identifier.isOnline // if current application is running
    ) {
      this.isSimulatorActive = false;
    } else {
      this.isSimulatorActive = true;
    }
  }

  sidePanelClosed(event: any) {
    if (event) {
      this.store.dispatch(showSidePanel({ value: false }));
    }
  }

  updateValue(event: any, customName: string) {
    this.controlPanelValues[customName] = +event.target.value;
  }

  updateTextValue(event: any, customName: string) {
    this.controlPanelValues[customName] = event.target.value;
  }

  increaseValue(customName: string) {
    this.resetIfNotExist(customName);
    this.controlPanelValues[customName] += 1;
  }

  decreaseValue(customName: string) {
    this.resetIfNotExist(customName);
    this.controlPanelValues[customName] = this.controlPanelValues[customName] > 0 ? this.controlPanelValues[customName] - 1 : this.controlPanelValues[customName];
  }

  resetIfNotExist(customName: string) {
    if (this.controlPanelValues[customName] == undefined) {
      this.controlPanelValues[customName] = 0;
    }
  }

  setmesColorPacketValues(): void {
    const colorOrder = mesColorPacketDOM.reduce((acc: {[key: string]: number}, {display}, index) => {
      acc[display.toLowerCase()] = index;
      return acc;
    }, {});    

    Object.entries(this.controlPanelValues).forEach(([key, value]: [string, any]) => {
      if (key.startsWith("mes")) {
        const mesColorPacketName = key.match(/mes_color_packet_\d+/)[0];
        this.controlPanelValues[mesColorPacketName] = this.controlPanelValues[mesColorPacketName] || [0, 0, 0];
        const colorIndex = colorOrder[key.split('_').pop()];
        if (colorIndex !== undefined) {
          this.controlPanelValues[mesColorPacketName][colorIndex] = value;
        }
      }
    });
  }

  sendDataToDevice(inputActionableItems: any) {
    const payload = {
      inputActionableItems,
      applicationIdentifier: this.selectedApplication.applicationIdentifier,
      device_identifier: this.deviceId,
    };
    this.ideService
      .toggle(payload)
      .toPromise();
  }

  onSubmit() {
    this.isMobileMode = true;
    this.sendDataToDevice(this.controlPanelValues);
  }

  simulateMessages() {
    let sensor_names = Object.keys(this.controlPanelValues);
    this.simulatorDefinition.mobile_controller_logic.forEach((payload: any) => {
      let result: boolean = true;
      sensor_names.forEach((name: string) => {
        if (payload.condition[name]) {
          let current_condition = payload.condition[name];
          if (result && current_condition.eq != undefined && !(this.controlPanelValues[name] == current_condition.eq)) {
            result = false;
          }
          if (result && current_condition.not_eq != undefined && !(this.controlPanelValues[name] != current_condition.not_eq)) {
            result = false;
          }
          if (result && current_condition.from != undefined && !(this.controlPanelValues[name] >= current_condition.from)) {
            result = false;
          }
          if (result && current_condition.to != undefined && !(this.controlPanelValues[name] <= current_condition.to)) {
            result = false;
          }
        }
        Object.keys(payload.condition).forEach(key => {
          if (!sensor_names.includes(key)) {
            result = false;
          }
        });
      })
      if (result) {
        this.printConsoleMessages(payload.messages);
        return;
      }
    });
  }

  printConsoleMessages(messages: any) {
    messages.forEach((item: any) => {
      if (item.value || item.source) {
        this.commandItems.unshift({
          ...item,
          simulation: true,
          title: item.value || "",
          state: item.state,
          color: item.color,
          time: new Date(),
        });

        this.commandItems = this.commandItems.slice(0, 20);
      }
    });
  }

  changeDropdown(flag?: boolean) {
    this.dropdownOpen = flag != undefined ? flag : !this.dropdownOpen;
  }

  async clearForm() {
    this.isMobileMode = false;
    await this.resetSensorInputs();
    const payload = {
      inputActionableItems: {},
      applicationIdentifier: this.selectedApplication.applicationIdentifier,
      device_identifier: this.deviceId,
    };
    await this.ideService
      .toggle(payload)
      .toPromise();
  }

  changeDirection(direction: string) {
    this.sendDataToDevice({ direction });
    // this.printConsoleMessages([{
    //   type: 'text',
    //   value: `Moving ${direction}...`,
    //   color: '#FFF'
    // }])
  }

  changeRobotStatus(status: boolean) {
    this.sendDataToDevice({ status });
    this.robotics.status = status;
    // this.printConsoleMessages([{
    //   type: 'text',
    //   value: `${status ? 'Robot started...' : 'Movement stopped'}`,
    //   color: `${status ? '#B1E693' : '#FF7878'}`
    // }])
  }

  onBack() {
    if (this.selectedApplication) {
      this.controlPanelValues = {};
      this.sensors = [];
      sessionStorage.removeItem("state");
      this.selectedApplication = null;
      // this.sendMessageFromIFrame(false);
    } else {
      this.emitBack.emit();
    }
  }

  logout() {
    this.wsApplicationService.close();
    this.emitLogoutEvent.emit(false);
  }
}