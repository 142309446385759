<div class="bg-white rounded-4xl h-full" [class.bg-gray-100]="selectedApplication">
  <div class="header p-4 fixed w-full bg-white shadow-md">
    <div class="toolbox flex flex-col mb-4 text-blue-500" style="padding-top: 1rem;">
      <div class="cursor-pointer text-md">
        <div class="flex justify-evenly"><img src="assets/img/icon.png" class="icon w-10 h-10" /></div>
        <div class="username">{{current_username}}</div>
      </div>
    </div>
    <div class="title text-xl font-bold py-1">Search Application</div>
    <div class="text-search">
      <input class="search" class="bg-gray-200 rounded-md p-2 w-full" placeholder="Enter application name" #search />
    </div>
  </div>
  <div class="section-border"></div>

  <div class="custom-navbar">
    <div *ngIf="dropdownOpen" (click)="changeDropdown(false)"
      class="fixed inset-0 h-full w-full z-10 bg-gray-200 opacity-50"></div>
    <img src="/assets/img/icons/right-arrow.svg" class="back-btn" (click)="onBack()" />
  </div>

  <div class="p-4 w-full application-list" [class.fixed]="selectedApplication" *ngIf="!selectedApplication">
    <ng-container *ngFor="let application of filteredApplications">
      <div class="shadow-md rounded-md p-5 my-5 cursor-pointer flex " id="btn" [class.offline]="!application.isOnline"
        [class.online]="application.isOnline" style="align-items: baseline"
        [class.bg-gray-200]="selectedApplication == application" [@slideInOut] *ngIf="application.is_published">
        <p class="font-semibold text-xs text-gray-500 padding application-name"
          (click)="selectApplication(application)">
          {{ application.applicationName }}
        </p>
      </div>
    </ng-container>
  </div>

  <div *ngIf="selectedApplication"
    class="absolute bottom-0 w-100 shadow-md bg-white draggable transition delay-150 duration-300">

    <div class="flex justify-end px-2" *ngIf="isRPIConnected && moduleName!=='robotics'">
      <ng-container *ngIf="selectedApplication">
        <button class="mr-2 font-light text-xxxs mt-2 text-white px-1 flex items-center justify-end  rounded-lg"
          [class.bg-red-400]="!isSimulatorActive" [class.bg-green-400]="isSimulatorActive"
          (click)="isSimulatorActiveLogic(selectedApplication)">
          <span class="rounded-full p-1 mr-2" [class.bg-red-100]="!isSimulatorActive"
            [class.bg-green-100]="isSimulatorActive"></span>
            Simulator active
        </button>
      </ng-container>
    </div>

    <div class="p-4">
      <p class="text-sm font-bold mb-2">
        {{ selectedApplication.applicationName }}
      </p>

      <ng-container *ngIf="['iot', 'aiot', 'iiot'].includes(moduleName)">
        <span class="input-sensors-list">
          <ng-container *ngFor="let item of sensors">
            <div class="flex justify-between" 
            [class.input-sensor-block-half]="!fullSizeBlocks.includes(item.deviceType)"
            [class.input-sensor-block-full]="fullSizeBlocks.includes(item.deviceType)">
              <div class="mb-3 space-y-2 w-full text-md justify-between text-center">
                <label class="font-semibold text-gray-600 py-2">{{ item.customEditName ? item.customEditName : item.customName }}</label>
                <ng-container *ngIf="item.deviceType=='Number_only'">
                  <div class="justify-center text-center" style="text-align: -webkit-center;">
                    <input value="0" (change)="updateValue($event, item.customName)"
                      class="appearance-none block bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 text-center"
                      style="width:50%" required="required" type="number">
                  </div>
                </ng-container>

                <ng-container *ngIf="item.deviceType=='Character'">
                  <div class="justify-center text-center" style="text-align: -webkit-center;">
                    <input value="" (change)="updateTextValue($event, item.customName)"
                      class="appearance-none block bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 text-center"
                      style="width:50%" required="required" type="text" maxlength="1">
                  </div>
                </ng-container>

                <ng-container *ngIf="item.deviceType=='Text'">
                  <div class="justify-center text-center" style="text-align: -webkit-center;">
                    <input value="" (change)="updateTextValue($event, item.customName)"
                      class="appearance-none block bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 text-center"
                      style="width:80%" required="required" type="text">
                  </div>
                </ng-container>

                <ng-container *ngIf="item.deviceType=='Number'">
                  <div class="custom-number-input flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                    <button data-action="decrement"
                      class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-l cursor-pointer outline-none"
                      (click)="decreaseValue(item.customName)">
                      <span class="m-auto text-2xl font-thin">−</span>
                    </button>
                    <input type="number"
                      class="outline-none focus:outline-none text-center bg-light-blue font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                      name="custom-input-number" [value]="controlPanelValues[item.customName]"
                      (change)="updateValue($event,item.customName)">
                    <button data-action="increment"
                      class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-r cursor-pointer"
                      (click)="increaseValue(item.customName)">
                      <span class="m-auto text-2xl font-thin">+</span>
                    </button>
                  </div>
                </ng-container>

                <ng-container *ngIf="item.deviceType=='Boolean'">
                  <div>
                    <button class="left-btn" [class.active-btn]="!controlPanelValues[item.customName]"
                      (click)="controlPanelValues[item.customName]=false">Off</button>
                    <button class="right-btn" [class.active-btn]="controlPanelValues[item.customName]"
                      (click)="controlPanelValues[item.customName]=true">On</button>
                  </div>
                </ng-container>

                <ng-container *ngIf="item.deviceType=='2Numbers'">
                  <div style="margin: auto;">
                    <ng-container *ngFor="let sensorDOM of heartbeatSensorDOM">
                      <div class="custom-number-input flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <label class="font-semibold text-gray-600 py-2 w-20">{{sensorDOM.display}}</label>
                        <button data-action="decrement"
                          class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-l cursor-pointer outline-none"
                          (click)="decreaseValue(item.customName + sensorDOM.suffix)">
                          <span class="m-auto text-2xl font-thin">−</span>
                        </button>
  
                        <input type="number"
                          class="outline-none focus:outline-none text-center bg-light-blue font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                          name="custom-input-number" [value]="controlPanelValues[item.customName + sensorDOM.suffix]"
                          [placeholder]="sensorDOM.placeholder" (change)="updateValue($event, item.customName + '_' + sensorDOM.suffix)">
  
                        <button data-action="increment"
                          class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-r cursor-pointer"
                          (click)="increaseValue(item.customName + sensorDOM.suffix)">
                          <span class="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngIf="item.deviceType=='Dc_Drive'">
                  <div style="margin: auto;">
                    <br />
                    <ng-container *ngFor="let sensorDOM of dcDriveSensorDOM">
                      <div *ngIf="sensorDOM.type=='numeric'">
                        <label class="font-semibold text-gray-600 py-2 w-20">{{ sensorDOM.placeholder ? sensorDOM.placeholder :""}}</label>
                        <br />
                        <div
                          class="custom-number-input flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                          <button data-action="decrement"
                            class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-l cursor-pointer outline-none"
                            (click)="decreaseValue(item.customName + sensorDOM.suffix)">
                            <span class="m-auto text-2xl font-thin">−</span>
                          </button>

                          <input type="number"
                            class="outline-none focus:outline-none text-center bg-light-blue font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                            name="custom-input-number" [value]="controlPanelValues[item.customName + sensorDOM.suffix]"
                            [placeholder]="sensorDOM.placeholder"
                            (change)="updateValue($event, item.customName + '_' + sensorDOM.suffix)">

                          <button data-action="increment"
                            class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-r cursor-pointer"
                            (click)="increaseValue(item.customName + sensorDOM.suffix)">
                            <span class="m-auto text-2xl font-thin">+</span>
                          </button>
                        </div>
                      </div>

                      <div *ngIf="sensorDOM.type=='boolean'">
                        <br />
                        <label
                          class="font-semibold text-gray-600 py-2">{{ sensorDOM.placeholder ? sensorDOM.placeholder :""}}</label>
                        <br />
                        <button class="left-btn" [class.active-btn]="!controlPanelValues[item.customName]"
                          (click)="controlPanelValues[item.customName]=false">Off</button>
                        <button class="right-btn" [class.active-btn]="controlPanelValues[item.customName]"
                          (click)="controlPanelValues[item.customName]=true">On</button>
                      </div>

                      <div *ngIf="sensorDOM.type=='select'">
                        <br />
                        <label
                          class="font-semibold text-gray-600 py-2">{{ sensorDOM.placeholder ? sensorDOM.placeholder :""}}</label>
                        <select name="deviceType" [value]="dc_mode[0].id" id=""
                          class="w-full border-gray-300 rounded-lg shadow-sm mb-10 input-style"
                          (change)="updateValue($event, item.customName + '_' + sensorDOM.suffix)">
                          <option value="" disabled selected></option>
                          <ng-container *ngFor="let device of dc_mode">
                            <option class="input-style" value={{device.id}}>{{device.name}}</option>
                          </ng-container>
                        </select>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                
                <ng-container *ngIf="item.deviceType=='Climate_sensor'">
                  <div style="margin: auto;">
                    <ng-container *ngFor="let sensorDOM of climateSensorDOM">
                      <div class="custom-number-input flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <label class="font-semibold text-gray-600 py-2 w-20">{{sensorDOM.display}}</label>
                        <button data-action="decrement"
                          class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-l cursor-pointer outline-none"
                          (click)="decreaseValue(item.customName + sensorDOM.suffix)">
                          <span class="m-auto text-2xl font-thin">−</span>
                        </button>
  
                        <input type="number"
                          class="outline-none focus:outline-none text-center bg-light-blue font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                          name="custom-input-number" [value]="controlPanelValues[item.customName + sensorDOM.suffix]"
                          [placeholder]="sensorDOM.placeholder" (change)="updateValue($event, item.customName + sensorDOM.suffix)">
  
                        <button data-action="increment"
                          class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-r cursor-pointer"
                          (click)="increaseValue(item.customName + sensorDOM.suffix)">
                          <span class="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="item.deviceType=='Power_meter'">
                  <div style="margin: auto;">
                    <ng-container *ngFor="let sensorDOM of powerMeterDOM">
                      <div class="custom-number-input flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <label class="font-semibold text-gray-600 py-2 w-20">{{sensorDOM.display}}</label>
                        <button data-action="decrement"
                          class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-l cursor-pointer outline-none"
                          (click)="decreaseValue(item.customName + sensorDOM.suffix)">
                          <span class="m-auto text-2xl font-thin">−</span>
                        </button>
  
                        <input type="number"
                          class="outline-none focus:outline-none text-center bg-light-blue font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                          name="custom-input-number" [value]="controlPanelValues[item.customName + sensorDOM.suffix]"
                          [placeholder]="sensorDOM.placeholder" (change)="updateValue($event, item.customName + '_' + sensorDOM.suffix)">
  
                        <button data-action="increment"
                          class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-r cursor-pointer"
                          (click)="increaseValue(item.customName + sensorDOM.suffix)">
                          <span class="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngIf="item.deviceType=='Mes_Color_Packet'">
                  <div style="margin: auto;">
                    <br />
                    <ng-container *ngFor="let sensorDOM of mesColorPacketDOM">
                      <div class="custom-number-input flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <label class="font-semibold text-gray-600 py-2 w-20">{{sensorDOM.display}}</label>
                        <button data-action="decrement"
                          class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-l cursor-pointer outline-none"
                          (click)="decreaseValue(item.customName + sensorDOM.suffix); setmesColorPacketValues();">
                          <span class="m-auto text-2xl font-thin">−</span>
                        </button>
                        
                        <input type="number"
                        class="outline-none focus:outline-none text-center bg-light-blue font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700"
                        name="custom-input-number" [value]="controlPanelValues[item.customName + sensorDOM.suffix]"
                        [placeholder]="0" (change)="updateValue($event, item.customName + sensorDOM.suffix); setmesColorPacketValues();">
                        
                        <button data-action="increment"
                        class="text-gray-600 hover:text-gray-700 h-full w-20 rounded-r cursor-pointer"
                        (click)="increaseValue(item.customName + sensorDOM.suffix); setmesColorPacketValues();">
                          <span class="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
    
                <ng-container *ngIf="item.deviceType=='Car_Controller'">
                  <div style="margin: auto;">
                    <ng-container *ngFor="let carController of carControllerDOM">
                      <div *ngIf="carController.type=='select'">
                        <br />
                        <label
                          class="font-semibold text-gray-600 py-2">{{ carController.placeholder ? carController.placeholder :""}}</label>
                        <select name="deviceType" [value]="carController.carcolors" id=""
                          class="w-full border-gray-300 rounded-lg shadow-sm mb-10 input-style"
                          (change)="updateTextValue($event, item.customName)">
                          <option value="" disabled selected></option>
                          <ng-container *ngFor="let carcolor of carController.carcolors">
                            <option class="input-style" [value]="carcolor.name">{{ carcolor.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                
                <ng-container *ngIf="item.deviceType=='Vehicle_Controller'">
                  <div style="margin: auto;">
                    <ng-container *ngFor="let vehicleController of vehicleControllerDOM">
                      <div *ngIf="vehicleController.type=='select'">
                        <br />
                        <label
                          class="font-semibold text-gray-600 py-2">{{ vehicleController.placeholder ? vehicleController.placeholder :""}}</label>
                        <select name="deviceType" [value]="vehicleController.options" id=""
                          class="w-full border-gray-300 rounded-lg shadow-sm mb-10 input-style"
                          (change)="updateTextValue($event, item.customName)">
                          <option value="" disabled selected></option>
                          <ng-container *ngFor="let list of vehicleController.options">
                            <option class="input-style" [value]="list.name.toLowerCase()">{{ list.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

              </div>
            </div>
          </ng-container>


        </span>


        <ng-container >
          <button id="simulator-btn" (click)="simulateMessages()">Use Simulator</button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="moduleName == 'robotics'">
        <ng-container *ngIf="robotics.controller_type=='directions'">
          <div class="justify-center">
            <div class="w-full h-15 block px-2 text-center">
              <button class="blue-control-btn w-2/4" (click)="changeDirection('forward')">&#8657; </button>
            </div>
            <div class="w-full h-15 block px-2 flex">
              <button class="blue-control-btn float-left w-2/4" (click)="changeDirection('left')">&#8656;</button>
              <button class="text-red-500 red-control-btn float-center w-2/4"
                (click)="changeDirection('stop')">X</button>
              <button class="blue-control-btn float-right w-2/4" (click)="changeDirection('right')">&#8658; </button>
            </div>
            <div class="w-full h-15 block px-2 text-center">
              <button class="blue-control-btn w-2/4" (click)="changeDirection('backward')">&#8659; </button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="robotics.controller_type=='new_directions'">
          <div class="justify-center">
            <div class="w-full h-15 block px-2 flex">
              <button class="blue-control-btn float-left w-2/4" (click)="changeDirection('FL')"> &#8662;</button>
              <button class="blue-control-btn float-center w-2/4" (click)="changeDirection('FW')"> &#8657;</button>
              <button class="blue-control-btn float-right w-2/4" (click)="changeDirection('FR')"> &#8663; </button>
            </div>
            <div class="w-full h-15 block px-2 flex">
              <button class="blue-control-btn float-left w-2/4" (click)="changeDirection('LM')"> &#8656;</button>
              <button class="text-red-500 red-control-btn float-center w-2/4" (click)="changeDirection('SP')">X</button>
              <button class="blue-control-btn float-right w-2/4" (click)="changeDirection('RM')"> &#8658; </button>
            </div>
            <div class="w-full h-15 block px-2 flex">
              <button class="blue-control-btn float-left w-2/4" (click)="changeDirection('BL')">&#8665;</button>
              <button class="blue-control-btn float-center w-2/4" (click)="changeDirection('BW')">&#8659;</button>
              <button class="blue-control-btn float-right w-2/4" (click)="changeDirection('BR')">&#8664; </button>
            </div>
            <div class="w-full h-15 block px-2 flex">
              <button class="blue-control-btn float-left w-2/4" (click)="changeDirection('LT')">&#8624;</button>
              <button class="blue-control-btn float-right w-2/4" (click)="changeDirection('RT')">&#8625;</button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="robotics.controller_type=='switch'">
          <div class="justify-center w-full p-4 my-4">
            <span class="px-4 text-sm">Robot Status:</span>
            <button class="left-btn" [class.active-btn]="!robotics.status"
              (click)="changeRobotStatus(false)">Stop</button>
            <button class="right-btn" [class.active-btn]="robotics.status"
              (click)="changeRobotStatus(true)">Start</button>
          </div>
        </ng-container>
      </ng-container>

      <div *ngIf="moduleName != 'robotics' || (simulatorDefinition && simulatorDefinition.mobile_console == true)"
        class="
          bg-ide-blue
          w-full
          rounded-xl
          shadow-md
          h-screen
          overflow-auto
          output-height
        ">
        <div class="bg-ide-blue h-full p-2 text-xxs">
          <ng-container *ngIf="!commandItems.length">
            <p class="text-white pt-4 text-xxs">
              Commands received will be displayed here...
            </p>
          </ng-container>
          <ng-container *ngIf="commandItems.length">
            <ng-container *ngFor="let item of commandItems; let i = index">
              <div class="
                  mt-2
                  flex-col
                  items-center
                  justify-start
                  cursor-pointer
                  border-0
                  hover:border-0
                  focus:border-0
                ">
                <div *ngIf="item.type == 'image'">
                  <span class="text-xxs text-yellow-200">
                    <span class="timestamp">[{{ item.time | date: "mediumTime" }}]</span>
                    : Received Image:
                  </span>
                  <img [src]="item.value" class="p-2" />
                </div>
                <div class="flex items-center justify-start text-xxs" [class.text-yellow-500]="item.state"
                  [class.text-red-500]="!item.state" [ngStyle]="{ 'color': item?.color}" *ngIf="item.type != 'image'">
                  <span>
                    <span class="timestamp text-xxs">[{{ item.time | date: "mediumTime" }}]</span>
                    :
                    <span class="text-xxs" *ngIf="!item.simulation">{{ item.item || item.title }} is
                      {{ item.state ? "active" : "inactive" }}</span>
                    <span class="text-xxs" *ngIf="item.simulation">{{ item.item || item.title }}</span>
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div *ngIf="moduleName == 'robotics' && simulatorDefinition && simulatorDefinition.visualization_panel == true">
        <app-robotics-visualization [current_location]="current_location" [simulatorDefinition]="simulatorDefinition"
          [robot_status]="this.robotics.status"></app-robotics-visualization>
      </div>
    </div>
  </div>

  <!-- <section id="bottom-navigation" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile-->
  <section id="bottom-navigation" class="block fixed inset-x-0 bottom-0 z-10 bg-white shadow w-full"
    *ngIf="!selectedApplication">
    <div id="tabs" class="flex justify-between">
      <span class="w-full text-gray-500 justify-center inline-block text-center pt-2 pb-1"
        [class.active]="showTemplates" (click)="showTemplates=true;refreshApplicationsList()">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 42 42" class="inline-block mb-1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z"
              fill="currentColor" fill-opacity="0.1"></path>
            <path
              d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z"
              fill="currentColor"></path>
            <path
              d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z"
              stroke="currentColor" stroke-width="2" fill="currentColor" fill-opacity="0.1"></path>
            <rect fill="currentColor"
              transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) "
              x="17" y="10.3137085" width="30" height="2" rx="1"></rect>
            <rect fill="currentColor"
              transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) " x="-3"
              y="10.3137085" width="30" height="2" rx="1"></rect>
          </g>
        </svg>

        <span class="tab tab-home block text-xs">Templates</span>
      </span>
      <span class="w-full text-gray-500 justify-center inline-block text-center pt-2 pb-1"
        [class.active]="!showTemplates" (click)="showTemplates=false;refreshApplicationsList()">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 42 42" class="inline-block mb-1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M20.5890101,0.254646884 C12.8696785,5.50211755 8.0025785,14.258415 14.1941217,18.8708225 C23.16683,25.5550669 13.3362326,40.2698884 33.1021758,38.4149164 C29.6814884,40.8311956 25.5065164,42.2507054 21,42.2507054 C9.40202025,42.2507054 0,32.8486852 0,21.2507054 C0,9.79003409 9.18071714,0.473634138 20.5890101,0.254646884 Z"
              fill="currentColor" opacity="0.1"></path>
            <path
              d="M25.9500282,20.3643496 L22.4308312,38.2677802 C22.3775703,38.5387376 22.1147395,38.7152155 21.8437821,38.6619546 C21.6570955,38.6252584 21.507413,38.4857901 21.4576354,38.3021581 L16.5951895,20.3643496 L20.099732,4.44663907 C20.1385204,4.27046145 20.2692032,4.12883813 20.4417012,4.07604096 C20.7057521,3.99522179 20.9853245,4.14376046 21.0661436,4.40781135 L25.9500282,20.3643496 Z M21.3022963,22.2852638 C22.4068658,22.2852638 23.3022963,21.3898333 23.3022963,20.2852638 C23.3022963,19.1806943 22.4068658,18.2852638 21.3022963,18.2852638 C20.1977268,18.2852638 19.3022963,19.1806943 19.3022963,20.2852638 C19.3022963,21.3898333 20.1977268,22.2852638 21.3022963,22.2852638 Z"
              fill="currentColor"
              transform="translate(21.272609, 20.629524) rotate(-315.000000) translate(-21.272609, -20.629524) "></path>
            <circle stroke="currentColor" stroke-width="2" cx="21" cy="21" r="20"></circle>
          </g>
        </svg>

        <span class="tab tab-explore block text-xs">Applications</span>
      </span>
    </div>
  </section>
</div>

<app-side-panel *ngIf="(showSidePanel$ | async)" (emitCloseEvent)="sidePanelClosed($event)" [isModal]="true">
  <div>
    <h4 class="p-4 text-md font-medium">Other apps already running.</h4>
    <p class="font-light px-4 my-4">Do you want to kill previously running app?</p>
    <div class="p-4">
      <button class="bg-button text-white p-2 rounded-md shadow font-bold mr-2"
        (click)="runApplicationForcefully()">YES</button>
      <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button"
        (click)="sidePanelClosed($event)">CANCEL</button>
    </div>
  </div>
</app-side-panel>
