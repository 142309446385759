import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { PaginationModule } from "src/app/components/pagination/pagination.component";
import { ModalModule } from "src/app/shared/components/modal/modal.module";
import { CreateLabComponent } from "./create-lab/create-lab.component";
import { EditLabComponent } from "./edit-lab/edit-lab.component";
import { LabsListComponent } from "./labs-list.component";



@NgModule({
    imports:[ReactiveFormsModule,FormsModule,CommonModule,NgSelectModule,ModalModule,PaginationModule],
    declarations:[CreateLabComponent,LabsListComponent,EditLabComponent],

})

export class LabListModule{}

