<div class="p-8">
  <div class="flex gap-4 flex-wrap">
    <div class="flex-1">
      <div class="bg-white w-full rounded-lg shadow-md p-10">
        <ng-container *ngIf="true">
          <p class="text-3xl font-bold text-gray-600 mb-5">
           Simulator Mobile App
          </p>
          <p class="text-md font-normal text-gray-400 mb-5">
            Here you can simulate the mobile application which we have created
            using the previous screens.
            <br />
            To get started:
          </p>
          <div class="text-md font-normal text-gray-400">
            <ul class="list-disc list-inside">
              <li>
                Please use your LMS credentials to login to the mobile
                application.
              </li>
              <li>
                Once you are logged in, search for the application using the
                search bar.
              </li>
              <li>Use the control panel screen to trigger any action.</li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="false" style="display: none;">
          <p class="text-3xl font-bold text-gray-600 mb-5">
            Circuit Board Simulator
          </p>
          <p class="text-md font-normal text-gray-400 mb-5">
            The circuit board simulator renders a graphical representation of the
            change which is happening on the linked IOT device.
            <br />
            Toggle the switch on the mobile app to view the changes.
          </p>
          <div class="p-2 flex justify-between">
            <iframe [src]="simulatorUrl" class=""></iframe>
          </div>
        </ng-container>
      </div>
    </div>


    <div class="flex-1 flex justify-evenly flex-nowrap">
      <div class="iphone relative">
        <iframe [src]="mobileUrl" class=""></iframe>
      </div>
    </div>
  </div>
</div>
