import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CircuitSectionModule } from '../../components/circuit-section/circuit-section.component';
import { CircuitDesignComponent } from './circuit-design.component';
import { SaveListModule } from '../../components/save-list/save-list.component';
import { SidePanelModule } from 'src/app/components/side-panel/side-panel.module';


@NgModule({
  declarations: [
    CircuitDesignComponent
  ],
  imports: [
    CommonModule,
    CircuitSectionModule,
    SaveListModule,
    SidePanelModule
  ],
  exports: [
    CircuitDesignComponent
  ]
})
export class CircuitDesignModule { }
