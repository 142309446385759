import { createAction, props } from "@ngrx/store";

export enum EApplicationState {
  LOAD_APPLICATIONS_FAILURE = '[Application] Load Applications Failure',
  SET_APPLICATIONS = '[Application] Set Applications',
  LOAD_APPLICATIONS = '[Application] Load Applications',
  ADD_APPLICATION = '[Application] Add Application',
  UPDATE_APPLICATION = '[Application] Update Applications',
  SET_APPLICATION_TEMPLATES = '[Application] Set Application Template',
  LOAD_APPLICATION_TEMPLATES = '[Application] Load Application Templates',
  ADD_APPLICATION_TEMPLATE = '[Application] Add Application Template',
  UPDATE_APPLICATION_TEMPLATES = '[Application] Update Application Templates',
  SET_PAGE_NUMBER = '[Application] Set Page Number',
  SET_PAGE_SIZE = '[Application] Set Page Size',
  SET_TOTAL = '[Application] Set Total'
}

export const loadApplicationFailure = createAction(EApplicationState.LOAD_APPLICATIONS_FAILURE, props<{payload: any}>());

// Actions for Applications
export const loadApplications = createAction(EApplicationState.LOAD_APPLICATIONS);
export const setApplications = createAction(EApplicationState.SET_APPLICATIONS, props<{payload: any}>());
export const addApplication = createAction(EApplicationState.ADD_APPLICATION, props<{application: any}>());
export const updateApplication = createAction(EApplicationState.UPDATE_APPLICATION, props<{application: any}>());
export const deleteApplication = createAction(EApplicationState.UPDATE_APPLICATION, props<{id: string}>());

// Actions for Application Templates
export const loadApplicationTemplates = createAction(EApplicationState.LOAD_APPLICATION_TEMPLATES);
export const setApplicationTemplates = createAction(EApplicationState.SET_APPLICATION_TEMPLATES, props<{payload: any}>());
export const addApplicationTemplate = createAction(EApplicationState.ADD_APPLICATION_TEMPLATE, props<{applicationTemplate: any}>());
export const updateApplicationTemplate = createAction(EApplicationState.UPDATE_APPLICATION_TEMPLATES, props<{application: any}>());
export const deleteApplicationTemplate = createAction(EApplicationState.UPDATE_APPLICATION_TEMPLATES, props<{id: string}>());

// Other Actions
export const setPageNumber = createAction(EApplicationState.SET_PAGE_NUMBER, props<{value: number}>());
export const setPageSize = createAction(EApplicationState.SET_PAGE_SIZE, props<{value: number}>());
export const setTotal = createAction(EApplicationState.SET_TOTAL, props<{value: number}>());

// dummy action
export const dummyAction = createAction('DUMMY ACTION');
