import { createAction, Action, ActionCreator } from '@ngrx/store';
import { CircuitHistoryActionState } from './circuit-history.state';

export interface Undoable extends Action {
  readonly undoable?: boolean;
  type: string;
}

export function undoable(creator: ActionCreator) {
  const newCreator = (props?: object) => ({
    ...creator(props),
    undoable: true
  });
  return Object.defineProperty(newCreator, 'type', {
    value: creator.type,
    writable: false
  });
}

const undo = createAction(CircuitHistoryActionState.UNDO);

const redo = createAction(CircuitHistoryActionState.REDO);

const circuitHistoryAction = {
  undo, redo
}

export default circuitHistoryAction;
