<div class="p-8">
    <div class="lab-mng">
        Manage Labs
    </div> 
        <div class="box">
                <div class="relative w-full btn-1 my-6">
                    <label class="flex absolute inset-y-0  items-center pl-3 pointer-events-none">                        
                        <svg aria-hidden="true" class="w-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </label>
                    <input type="text" id="simple-search" class="br-1 btn-1 border-gray-200 org-name block p-2.5"  
                            placeholder="Search by lab name and created by" (keyup)="applyFilter($event)" 
                            style="padding-left: 35px;"  [formControl]="searchControl">
                </div>  
                <div class="push my-6 mr-2">
                    <select *ngIf="isGrokCloudMember" name="labNames" id="" class="btn-2 border-gray-200 rounded-lg mb-5 input-style org-name"  
                        (change)="onChange($event)">
                        <option value="" class="org-name">All Organizations</option>
                        <ng-container *ngFor="let org of orgList">
                            <option class="input-style"[value]="org.orgName">{{org.orgName}}</option>
                        </ng-container>
                    </select>
                    <select *ngIf="!isGrokCloudMember" name="labNames" id="" 
                        class="btn-2 border-gray-200 rounded-lg mb-5 input-style org-name">
                      <option value="" disabled selected [value]="organizationName">{{organizationName}}</option>
                    </select>
                </div>          
                <div class="my-6 text-right">
                    <button class="btn-3 bg-greenish-blue text-white p-2 rounded-lg shadow-xl hover:bg-red-600" (click)="createLab()">
                        Create Lab
                    </button>
                </div>                        
        </div>
        <div class="min-h-screen flex">
        <div class="overflow-x-auto w-full">
            <div class="min-w-screen min-h-screen flex justify-center font-sans">
            <div class="w-full">
                <div class="bg-white shadow-md rounded my-6">
                <table  class="mx-auto max-w-full w-full whitespace-nowrap bg-white divide-y divide-gray-300 overflow-hidden">
                    <thead class="bg-greenish-blue text-white">
                    <tr class="text-white-600 uppercase text-sm leading-normal">
                        <th class="text-left font-semibold text-sm uppercase px-2 py-4">
                            <div class="flex inline-flex items-center">
                                <span>
                                    Organization
                                </span>
                                <span (click)="changeOrderOrg('DESC')"  *ngIf="isSortOrg">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                </span>
                                <span (click)="changeOrderOrg('ASC')"  *ngIf="!isSortOrg">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <th class="text-left font-semibold text-sm uppercase px-2 py-4">
                            <div class="flex inline-flex items-center">
                            <span>Lab Name</span> 
                            <span (click)="changeOrderlab('DESC')" *ngIf="isSortLab">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                </span>
                                <span (click)="changeOrderlab('ASC')" *ngIf="!isSortLab">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <th class="text-center font-semibold text-sm uppercase px-2 py-4">                            
                            <div class="flex inline-flex items-center">
                                <span># Devices</span>
                                <span (click)="changeOrderRegisteredDevices('DESC')" *ngIf="isSortRegisteredDevices">
                                  <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                </span>
                                <span (click)="changeOrderRegisteredDevices('ASC')" *ngIf="!isSortRegisteredDevices">
                                  <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <th class="text-center font-semibold text-sm uppercase px-2 py-4">
                            <div class="flex inline-flex items-center">
                                <span>Lab Created By</span>
                                <span (click)="changeOrderCrtby('DESC')" *ngIf="isSortCreated">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                                </span>
                                <span (click)="changeOrderCrtby('ASC')" *ngIf="!isSortCreated">
                                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                                </span>
                            </div>
                        </th>
                        <th class="text-center font-semibold text-sm uppercase px-2 py-4">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="text-gray-600 text-sm font-light">
                    <tr class="border-b border-gray-200 hover:bg-gray-100"
                        *ngFor="let item of filteredLabs; let i = index">
                        <td class="text-left  whitespace-nowrap py-3 px-2">
                            <div >{{item.orgName}}</div>
                        </td>
                        <td class="text-left whitespace-nowrap py-3 px-2" [style.text-transform]='"uppercase"'>{{item.labName}}</td>
                        <td class="text-center light-text">
                            <div [class.non-clickable-link]=""  [class.clickable-link]="true" (click)="onClickRegDevice(item)">{{item.registeredDevicesCount}}</div>
                        </td>
                        <td class="text-center whitespace-nowrap py-3 px-2">{{item.createdBy}}</td>
                        <td class="text-center whitespace-nowrap py-3 px-2">
                            <div class="flex item-center justify-center">    
                                <div  class="action-item" (click)="editLab(item)">
                                    <img src="../../../../assets/img/icons/edit.svg" alt="Edit" title="Edit">
                                </div>
                                <div class="action-item" (click)="confirmAndDelete(item)">
                                    <img src="../../../../assets/img/icons/delete.svg" alt="Delete" title="Delete">
                                </div>                           
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    
    <ng-container *ngIf="isModalOpen">
        <app-modal [modalType]="'deleteLab'" [deleteSelectedLab]="selectedLab"
        (emitCloseEvent)="closeModal($event)" (emitDeleteAction)="delete($event)"></app-modal>
    </ng-container>
