import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlankBackgroundComponent } from './ide/containers/blank-background/blank-background.component';
import { AdminAuthGuard } from './shared/guards/admin.auth.guard';
import { IDEAuthGuard } from './shared/guards/ide.auth.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  { path: 'ide', canActivate: [IDEAuthGuard], loadChildren: () => import('./ide/ide.module').then(m => m.IDEModule)},
  { path: 'admin', canActivate: [AdminAuthGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  { path: 'simulator',  loadChildren: () => import('./simulator/simulator.module').then(m => m.SimulatorModule)},
  { path: 'mobile',  loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule)},
  { path: 'sensordata',  loadChildren: () => import('./sensordata/sensordata.module').then(m => m.SensordataModule)},
  { path: '', pathMatch: 'full', redirectTo: 'auth/login' },
  { path: 'lms', pathMatch: 'full', component: BlankBackgroundComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
