import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from '@angular/core';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UserSessionService } from 'src/app/shared/services/user.session.service';

@Component({
  selector: 'app-save-block-circuit',
  templateUrl: './save-block-circuit.component.html',
  styleUrls: ['./save-block-circuit.component.scss'],
})
export class SaveBlockCircuitComponent implements OnInit {
  public applicationName: string = '';
  public applicationDesc: string = '';

  @Output() saveNewBlockEvent = new EventEmitter();
  @Output() emitCancelEvent = new EventEmitter();
  @Input() selectedApplication: any;

  constructor(
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasAppData = changes?.selectedApplication?.currentValue;
    if (hasAppData) {
      const passedData = changes.selectedApplication.currentValue;
      this.applicationName = passedData.applicationName;
      this.applicationDesc = passedData.applicationDesc;
    }
  }

  save() {
    const data = {
      applicationName: this.applicationName,
      applicationDesc: this.applicationDesc,
    };
    this.saveNewBlockEvent.emit(data);
  }

  cancel() {
    this.emitCancelEvent.emit(true);
  }
}
