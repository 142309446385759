export interface IAuthState {
  isAuthenticated: boolean;
  token: string;
  userRole: string;
  errorMessage: string;
  user: any,
}

export const initialAuthState: IAuthState = {
  isAuthenticated: false,
  token: '',
  userRole: '',
  errorMessage: '',
  user: [],
}
