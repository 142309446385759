import { Component, OnInit, HostListener } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-mobile-app",
  templateUrl: "./mobile-app.component.html",
  styleUrls: ["./mobile-app.component.scss"],
})
export class MobileAppComponent implements OnInit {
  mobileUrl: SafeUrl = "";
  simulatorUrl: SafeUrl = "";
  showEmulator = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) {}

  @HostListener('window:message', ['$event'])
  onMessage(e: any) {
    if (e) {
      if (typeof(e.data) === "string") {
        const url = JSON.parse(e.data);
        this.simulatorUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
        this.showEmulator = true;
      }
      if (typeof(e.data) === "boolean") {
        this.simulatorUrl = "";
        this.showEmulator = false;
      }
    }
  }

  async ngOnInit() {
    this.updateSimulatorView();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  private updateSimulatorView() {
    const url = `/mobile/mobile-app`;
    this.mobileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

