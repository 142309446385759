import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Helper Class contains common functions
 */
@Injectable({
  providedIn: 'root',
})

export class CommonActions {
  constructor(private domSanitizer: DomSanitizer) {}

  /**
   * Method to check if parameter passed is-empty or not (type: any)
   * @returns
   */
  isEmpty = (value: any): boolean => {
    if (
      value === undefined ||
      value == null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Method to convert url into iframe embed url
   * @param url
   * @returns
   */
  getIframeUrl = (url: string): SafeUrl => {
    const id: string = url.split('/').find((e) => /^\d+$/.test(e));
    const iframeUrl = `https://player.vimeo.com/video/${id}?h=c6db007fe5&color=ef0800&title=0&byline=0&portrait=0`;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  };


  /**
   * Method to check if parameter passed is-boolean or not (type: any)
   * @returns {boolean} result
   */
   isBoolean = (value: any): boolean => {
    return value === false || value === true;
  };


  /**
   * Method to exactly compare two string arrays. (type: any)
   * @returns {boolean} result
   */
  isEqualStringArray = (array1: string[], array2: string[]): boolean => {
    let result = true;

    if (array1 === undefined || array2 === undefined) {
      if (!(array1 === undefined && array2 === undefined)) {
        result = false;
      }
    } else if (array1.length !== array2.length) {
      result = false;
    } else {
      for (let index in array1) {
        if (array1[index] !== array2[index]) {
          result = false;
        }
      }
    }

    return result;
  }
}
