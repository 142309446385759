import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DraggablePrebuildCircuitComponent } from './draggable-prebuild-circuit.component';



@NgModule({
  declarations: [
    DraggablePrebuildCircuitComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DraggablePrebuildCircuitComponent
  ]
})
export class DraggablePrebuildCircuitModule { }
