import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionStorageService } from '../../services/session-storage.service';
import { UserSessionService } from '../../services/user.session.service';
import { loadApplications, loadApplicationTemplates } from 'src/app/admin/container/application/store/application.actions';
import { getApplications, getApplicationTemplates } from 'src/app/admin/container/application/store/application.selectors';
import { ApplicationService } from '../../services/application.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/index.state';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() modalType: any;
  @Input() isAdmin: any;
  @Input() selectedApplication: any;
  @Input() deleteSelectedDevice: any;
  @Input() deleteSelectedLab: any;
  @Output() emitCloseEvent = new EventEmitter();
  @Output() emitDeleteAction = new EventEmitter();

  applications$ = this.store.select(getApplications);
  applicationTemplates$ = this.store.select(getApplicationTemplates);


  constructor(
    private sessionStorageService: SessionStorageService,
    private userSessionService: UserSessionService,
    private store: Store<IAppState>,
    private applicationService: ApplicationService
  ) { }

  async ngOnInit() {
    console.log("selected lab", this.deleteSelectedLab)
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  // changeInternallyPublishedFlag(): void {
  //   if (this.selectedApplication) {
  //     console.log(this.selectedApplication);
      
  //     const updatedApplication = { ...this.selectedApplication };
  //     updatedApplication.is_published = true;
  //     updatedApplication.isInternallyPublished = true;
    
        
  //     console.log('Update Payload:', updatedApplication);
  //     console.log('Application Identifier:', updatedApplication.applicationIdentifier);
  
  //     this.applicationService.updateApplicationTemp(updatedApplication, updatedApplication.applicationIdentifier)
  //       .subscribe(
  //         (response) => { 
  //           console.log('Application updated successfully:', response);
  //           this.emitCloseEvent.emit(false);
  //           this.store.dispatch(loadApplicationTemplates());
  //         },
  //         (error) => {
  //           console.error('Failed to update application:', error);
  //           console.log('Error response:', error);
  //         }
  //       );
  //   }
  // }

  // changePublishedFlag(): void {

  //   if (this.selectedApplication) {
  //     console.log(this.selectedApplication);
      
  //     const updatedApplication = { ...this.selectedApplication };
  //     updatedApplication.is_published = true;
  //     updatedApplication.isInternallyPublished = false;
        
  //     console.log('Update Payload:', updatedApplication);
  //     console.log('Application Identifier:', updatedApplication.applicationIdentifier);
  
  //     this.applicationService.updateApplicationTemp(updatedApplication, updatedApplication.applicationIdentifier)
  //       .subscribe(
  //         (response) => { 
  //           console.log('Application updated successfully:', response);
  //           this.emitCloseEvent.emit(false);
  //           this.store.dispatch(loadApplicationTemplates());
  //         },
  //         (error) => {
  //           console.error('Failed to update application:', error);
  //           console.log('Error response:', error);
  //         }
  //       );
  //   }
  // }


  closeModal(modalType: string) {
    document.getElementById(modalType).style.display = 'none';
    this.emitCloseEvent.emit(false);
  }
  deleteData(event: any) {
    this.emitDeleteAction.emit(true);
  }
}
