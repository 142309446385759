import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxBlocklyComponent } from 'ngx-blockly';
import { EventEmitter } from 'protractor';
import { BlocklyComponent } from 'src/app/shared/components/blockly/blockly.component';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { IAppState } from 'src/app/store/index.state';
import { showSidePanel } from 'src/app/store/layout/layout.action';
import { BlocklySectionComponent } from '../../components/blockly-section/blockly-section.component';
import { getApplications } from '../../container/application/store/application.selectors';
import { loadApplications } from '../application/store/application.actions';

@Component({
  selector: 'app-block-programming',
  templateUrl: './block-programming.component.html',
  styleUrls: ['./block-programming.component.scss'],
})
export class BlockProgrammingComponent implements OnInit {
  applications$ = this.store.select(getApplications);

  private xml!: string;
  blockName: string = 'BLOCK';
  codeName: string = 'CODE';
  showSideBarList = false;
  showAddBlockBarList = false;
  blockType: string = 'BLOCK';
  public selectedApplication: any;
  public selectedApplicationIdentifier: string = '';
  public isAdminBlockDesign: boolean = false;
  public adminBlockDesign: string = '/admin/block';
  public isBlockWithSimulator: boolean = false;
  selectedOption = '';
  showSelectOptions = false;
  isAdmin: boolean = window.location.href.includes('/admin/application');
  moduleKey!: string;
  isRedo: boolean = false;
  isUndo: boolean = false;
  undoStack: any = [];
  redoStack: any = [];
  selected: boolean = false;
  isFlyout: boolean = false;

  @ViewChild(BlocklySectionComponent)
  blocklySectionComponent!: BlocklySectionComponent;

  constructor(
    private store: Store<IAppState>,
    private applicationTemplateService: ApplicationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isAdminBlockDesign =
      this.adminBlockDesign == this.router.url ? true : false;
    this.loadApplicationData();
    if (this.router.url.includes('/admin/')) {
      // console.log(this.router.url);
      this.moduleKey = '/admin/block';
    }
  }

  loadApplicationData() {
    this.store.dispatch(loadApplications());
  }

  saveXML(xml: string) {
    this.xml = xml;
  }

  toggleOptions(data: boolean) {
    this.showSelectOptions = data;
  }

  openSaveSideBar() {
    this.showAddBlockBarList = true;
  }

  openSideBar() {
    this.showSideBarList = true;
  }

  createNew() {
    this.selectedApplication = '';
    this.selectedApplicationIdentifier = '';
  }

  switch() {
    this.blockType =
      this.blockType?.toLowerCase() == this.blockName?.toLowerCase()
        ? this.codeName
        : this.blockName;
    // this.repairBlockly();
  }

  // repairBlockly() {
  //   window.dispatchEvent(new Event('resize'));
  // }

  toggleBlockState(state: boolean) {
    this.isBlockWithSimulator = state;
    // if (this.selectedApplication)
    //   this.getSingleTemplate(this.selectedApplicationIdentifier)
  }

  sidePanelClosed(event: any) {
    if (event) {
      this.store.dispatch(showSidePanel({ value: false }));
      this.showSideBarList = false;
      this.showAddBlockBarList = false;
    }
  }

  selectOption(selectedValue: string) {
    this.selectedOption = selectedValue;
    this.showSelectOptions = false;
  }

  private async getSingleTemplate(applicationIdentifier: string) {
    const data: any = await this.applicationTemplateService
      .getApplicationTemplateV3(applicationIdentifier)
    if (data?.Application) {
      this.selectedApplication = data.Application;
      this.selectedApplicationIdentifier = applicationIdentifier;
    }
  }

  async getApplicationData(event: any) {
    await this.getSingleTemplate(event.applicationIdentifier);
    this.showSideBarList = false;
  }

  async saveCircuitDesign() {
    let payload: any = {};
    let result: any;
    if (this.isBlockWithSimulator) {
      payload = {
        applicationDefinition: this.xml,
      };
    } else {
      payload = {
        toolboxDefinition: this.xml,
      };
    }

    try {
      if (this.isAdmin) {
        result = await this.applicationTemplateService
          .updateApplicationTemp(payload, this.selectedApplicationIdentifier)
          .toPromise();
      } else {
        result = await this.applicationTemplateService
          .updateApplication(payload, this.selectedApplicationIdentifier)
          .toPromise();
      }
      this.showSideBarList = false;
    } catch (err) {
      console.log(err);
      this.showSideBarList = false;
    }
  }

  /**Zoom in workspace onclick zoom in button*/
  zoomIn(event: any) {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly?.mainWorkspace?.zoom(event.x, event.y, 1);
  }

  /**Zoom out workspace onclick zoom out button */
  zoomOut(event: any) {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly.mainWorkspace?.zoom(event.x, event.y, -1);
  }

  /**recenter  */
  focus() {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly?.mainWorkspace?.scrollCenter();
  }

  undo() {
    let mainWorkspace =
      this.blocklySectionComponent?.blocklyComponent?.workspace?.workspace;
      this.undoStack = mainWorkspace?.undoStack_.length;
      mainWorkspace?.undo(false);
  }

  redo() {
    let mainWorkspace =
      this.blocklySectionComponent?.blocklyComponent?.workspace?.workspace;
      mainWorkspace?.undo(true);
  }

  /**delete selected block  or clear workspace*/
  delete() {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    this.selected = blockly?.selected;
    this.isFlyout = !blockly?.selected?.workspace?.isFlyout;
    if (this.selected && this.isFlyout) {
      blockly.Events.setGroup(true);
      blockly.hideChaff();
      blockly.selected.dispose(/* heal */ true, true);
      blockly.Events.setGroup(false);
    }
    // else {
    //   blockly.mainWorkspace.clear(); // clear the workspace
    // }
  }

  /**check length */
  undoRedo() {
    this.undoStack = [];
    this.redoStack = [];
  }

  isSelected(isSelected: boolean, isFlyout: boolean) {
    if (isSelected && isFlyout) {
      return true;
    } else {
      return false;
    }
  }

}
