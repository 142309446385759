<ng-container *ngIf="sensors">
  <div class="bg-greenish-blue w-full h-full relative side-menu flex flex-col" [class.pointer]="!isMenuOpen"   (mouseenter)="toggleMenu()" (mouseleave)="toggleLeave()">

    <!-- Search Bar -->
    <div *ngIf="isMenuOpen" class="p-10 flex justify-around">
      <div class="relative w-full">
        <input class="input-style p-2" type="text" placeholder="Search For Part" [ngModel]="searchTerm"
          (ngModelChange)="searchFunc($event)">
        <button type="button" class="absolute right-2 search-icon">
          <img src="assets/img/icons/search.svg" class="h-5 w-5" />
        </button>
      </div>
    </div>

    <!-- Menu Options -->
    <div *ngIf="isMenuOpen" class="flex flex-row items-center flex-wrap justify-center text-sm">
      <ng-container *ngFor="let navItem of sensors">
        <ng-container *ngIf="navItem.components.length">
          <label class="bg-white rounded-full p-2 my-2 font-semibold pill-button cursor-pointer"
            [class.selected-menu]="selectedNavMenu == navItem" [for]="navItem.title">
            {{navItem.title}}
          </label>
          <input type="checkbox" [id]="navItem.title" (click)="selectNavMenu(navItem)">
        </ng-container>
      </ng-container>
    </div>

    <!-- Menu Items -->
    <ng-container *ngIf="selectedNavMenu && isMenuOpen">
      <div class="flex items-center justify-center flex-wrap gap-4 p-4">
        <ng-container *ngFor="let item of filteredSensors">
          <div *ngIf="!isSelecting" class="flex items-center flex-col" [class.flex-item]="isMenuOpen">
            <img [src]="item.image" [id]="item.id" (drag)="onDrag($event,item)" class="sensor">
            <p class="text-xs text-white mt-2 break-words text-center tracking-wide">{{ item.title }}</p>
          </div>
          <div *ngIf="isSelecting" class="flex items-center flex-col">
            <button class="focus:outline-none" (click)="emitSensorSelect.emit(item)">
              <div
                class="overlay absolute cursor-pointer w-16 h-16 sm:w-16 sm:h-16 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:h-22 xl:w-22 2xl:w-24 2xl:h-24 rounded-lg"
                *ngIf="item.selected"></div>
              <img [src]="item.image" [id]="item.id" class="sensor">
              <p class="text-xs text-white mt-2 break-words text-center tracking-wide">
                {{item.title}}
              </p>
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- Collapse Button -->
    <div *ngIf="!isSelecting" class="flex arrow-btn absolute" [class.collapsed]="!isMenuOpen" [class.arrow-pos]="!isSelecting && insideForm"
      [class.collapsed-arrow-pos]="!isMenuOpen && !isSelecting && insideForm">
      <button type="button" (click)="toggleLeave()">
        <img [src]="isMenuOpen ?  'assets/img/icons/double-left.svg' : 'assets/img/icons/double-right.svg'"
          class="h-7 w-7"/>
      </button>
    </div>

  </div>
</ng-container>
