<ng-container>
  <div class="w-full h-screen">
    <as-split direction="horizontal" [disabled]="true" [gutterSize]=0>
      <as-split-area [size]="isMenuOpen ? 25 : 5">
        <iot-devices-section [sensors]="sensors" (emitDraggedElement)="setDraggedElement($event)" [isEdit]="isEdit"
          (emitCollapseEvent)="toggleSideMenu($event)" [applicationData]="applicationData"></iot-devices-section>
      </as-split-area>
      <as-split-area [size]="isMenuOpen ? 75 : 95">
        <div class="bg-white w-full h-screen relative">
          <app-workspace data-attribute-id="workspace" [isAdmin]="true" [isEdit]="isEdit" (openInfoPanel)="viewInformation($event)"
            [isNewWorkspace]="isNewWorkspace" [element]="draggedElement" [konvaUtils]="konvaUtils"
            [applicationIdentifier]="applicationIdentifier"
            (emitSavedJSON)="emitSavedJSON.emit($event)" (emitLoadState)="emitLoadState.emit($event)"></app-workspace>
        </div>
      </as-split-area>
    </as-split>
  </div>
</ng-container>

<app-side-panel *ngIf="(showSidePanel$ | async)" (emitCloseEvent)="sidePanelClosed($event)">
  <app-info-panel [sensorInfo]="sensorInfo"> </app-info-panel>
</app-side-panel>
