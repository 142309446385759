<div class="p-8">
  <div class="device-mg">
    Devices
</div> 
    <div class="box">
          <div class="relative w-full btn-1 my-6 ">
              <label class="flex absolute inset-y-0  items-center pl-3 pointer-events-none">                        
                  <svg aria-hidden="true" class="w-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
              </label>
              <input type="text" id="simple-search" class="btn-1 border-gray-200 rounded-lg org-name block p-2.5"  
                      placeholder="Search by device and lab name" (keyup)="applyFilter($event)" 
                      style="padding-left: 35px;"  [formControl]="serachValue">
          </div>  
            <div class="push my-6 mr-2">
              <select *ngIf="isGrokCloudMember" name="labNames" id=""
                class="btn-2 border-gray-200 rounded-lg mb-5 input-style org-name"
                (change)="onChange($event)">
                <option value="" class="org-name">All Organizations</option>
                <ng-container *ngFor="let org of orgList">
                    <option class="input-style"[value]="org.orgName">{{org.orgName}}</option>
                </ng-container>
              </select>
              <select *ngIf="!isGrokCloudMember" name="labNames" id=""
                class="btn-2 border-gray-200 rounded-lg mb-5 input-style org-name">
                <option value="" disabled selected [value]="organizationName">{{organizationName}}</option>
              </select>
            </div>   
            <div class="relative mr-2">
            <div class="dropdown my-6 relative justify-center">
              <button  style="width: 155px;height:55px"  class="bg-greenish-blue text-white rounded-lg shadow-xl"
                  (click)="toggleDeviceMenu()">Device Type
                  <i class="p-4 m-2 arrow down text-white"></i>
                </button>
                <div *ngIf="isDeviceMenuOpen"
                  class="origin-top-right absolute right-0 mt-2 text-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div class="py-1 cursor-pointer">
                    <a class="drop-down-links" (click)="onSelectDeviceType('all')">All</a>
                    <a class="drop-down-links" (click)="onSelectDeviceType('lab')">Lab</a>
                    <a class="drop-down-links" (click)="onSelectDeviceType('user')">User</a>
                  </div>
                </div>
              </div>
            </div>   
            <div class="relative mr-2">
              <div class="dropdown my-6 relative justify-center">
                <button  style="width: 155px;height:55px" class="bg-greenish-blue text-white rounded-lg shadow-xl"
                  (click)="toggleMenu()">New Device
                  <i class="p-4 arrow down text-white"></i>
                </button>
                <div *ngIf="isMenuOpen"  style="width: 155px;"
                  class="origin-top-right absolute right-0 mt-2 text-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div class="py-1 cursor-pointer">
                    <a class="drop-down-links" (click)="onSelectModule('user')">For User</a>
                    <hr class="hr-line items-center">
                    <a class="drop-down-links" (click)="onSelectModule('lab')">For Lab</a>
                  </div>
                </div>
              </div>         
            </div>  
            <div class="relative mr-2">
                  <button
                    class="my-6 relative justify-center bg-button text-white p-4 rounded-lg shadow-xl hover:bg-red-600"
                    (click)="navToInstructions()">Instructions</button>
            </div>     
    </div>

  <div class="min-h-screen flex">
    <div class='overflow-x-auto w-full'>
      <table
        class="mx-auto max-w-full w-full whitespace-nowrap bg-white divide-y divide-gray-300 overflow-hidden">
        <thead class="bg-gray-200">
          <tr class="text-gray-60 text-left">
            <!-- <th class="font-semibold text-sm uppercase px-2 py-2">Mac Address</th> -->
            <th class="font-semibold text-sm uppercase px-2 py-4">
                <div class="flex inline-flex items-center">
                  <span>
                    Device Name
                  </span>
                  <span (click)="changeOrdDevice('DESC')" *ngIf="isDeviceName">
                      <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                  </span>
                  <span (click)="changeOrdDevice('ASC')" *ngIf="!isDeviceName">
                      <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                  </span>
                </div>              
            </th>
            <th class="font-semibold text-sm uppercase px-2 py-4">Device Token</th>
            <th class="font-semibold text-sm uppercase px-2 py-4">
              <div class="flex inline-flex items-center">
                <span>
                  Lab Name/User
                </span>
                <span (click)="changeOrdLab('DESC')" *ngIf="isLab">
                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                </span>
                <span (click)="changeOrdLab('ASC')" *ngIf="!isLab">
                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                </span>
              </div> 
            </th>
            <th class="font-semibold text-sm uppercase px-2 py-4">
              <div class="flex inline-flex items-center">
                <span>
                  Org Name
                </span>
                <span (click)="changeOrdOrg('DESC')" *ngIf="isOrgName">
                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                </span>
                <span (click)="changeOrdOrg('ASC')" *ngIf="!isOrgName">
                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                </span>
              </div> 
            </th>
            <th class="font-semibold text-sm uppercase px-2 py-4">
              <div class="flex inline-flex items-center">
                <span>
                  Status
                </span>
                <span (click)="changeOrdStatus('DESC')" *ngIf="isStatus">
                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                </span>
                <span (click)="changeOrdStatus('ASC')" *ngIf="!isStatus">
                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                </span>
              </div> 
            </th>
            <th class="font-semibold text-sm uppercase px-2 py-4 text-center">
              <div class="flex inline-flex items-center">
                <span>
                  Created By
                </span>
                <span (click)="changeOrdCrtBy('DESC')" *ngIf="isCreatedBy">
                    <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                </span>
                <span (click)="changeOrdCrtBy('ASC')" *ngIf="!isCreatedBy">
                    <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                </span>
              </div> 
            </th>
            <th class="font-semibold text-sm uppercase px-2 py-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="divide-y divide-gray-200 cursor-pointer" *ngFor="let item of filteredDevices">
            <td class="py-3 px-2 text-left whitespace-nowrap">{{item.deviceName}}</td>
            <td class="py-3 px-2 text-left whitespace-nowrap">
              <div class="my-2 flex flex-row text-right">
                <button class="bg-transparent border-rad shadow-xl text-white border p-2"
                  (click)="copyToken(item.deviceToken)">
                  <div class="flex items-center h-4">
                    <img src="assets/img/icons/duplicate.svg" class="h-4 w-4 icon" >
                    <p>Copy</p>
                    <!-- class="cp" -->
                  </div>
                </button>
              </div>
            </td>
            <td  class="py-3 px-2 text-left whitespace-nowrap">{{item.labName && item.deviceType == 'lab' ? item.labName : "User"}}</td>
            <td  class="py-3 px-2 text-left whitespace-nowrap">{{item.orgName}}</td>
            <td><button class="font-light text-xxxs mt-2 text-white px-1 flex items-center justify-end  rounded-lg"
                [class.bg-red-400]="!item.isActive" [class.bg-green-400]="item.isActive">
                {{item.isActive ? 'Registered': 'Not Registered'}}
              </button></td>
            <td class="text-center py-3 px-2 whitespace-nowrap">
              {{item.createdBy ? item.createdBy: '-'}}
            </td>          
            <td class="py-3 px-2 text-left whitespace-nowrap">
              <!-- openSelectedDevice(item) -->
              <div class="my-2 flex flex-row text-right">
                <div  class="action-item" (click)="editDevice(item)">
                  <img src="../../../../assets/img/icons/edit.svg" alt="Edit" title="Edit">
                </div>
                <div class="action-item" (click)="confirmAndDelete(item)">
                    <img src="../../../../assets/img/icons/delete.svg" alt="Delete" title="Delete">
                </div> 
              </div>
            </td>
        </tbody>
      </table>
      <div class="text-center" *ngIf="!isDevice">
        <p class="text-xl font-bold m-5 p-10 text-gray-800">No Device Added</p>
      </div>
    </div>
  </div>
</div>

<app-side-panel *ngIf="showSidePanel" (emitCloseEvent)="sidePanelClosed($event)">
  <div class="scrollable">
    <app-edit-user-device [device]="selectedDevice" (emitCancelEvent)="sidePanelClosed($event)"></app-edit-user-device>
  </div>
</app-side-panel>

<ng-container  *ngIf="isModalOpen">
  <app-modal [modalType]="'deleteDevice'" [deleteSelectedDevice]="deleteSelectedDevice"
    (emitCloseEvent)="closeModal($event)" (emitDeleteAction)="delete($event)"></app-modal>
</ng-container>
