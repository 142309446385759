import { Component, OnInit, ViewChild } from "@angular/core";
import { FileSaverService } from "ngx-filesaver";
import { JoyrideService } from "ngx-joyride";
import { getToolbox } from "../../../shared/utils/toolbox";
import { showSidePanel } from "src/app/store/layout/layout.action";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { ApplicationService } from "src/app/shared/services/application.service";
import { environment } from "src/environments/environment.prod";
import { Router } from "@angular/router";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { CommonActions } from "src/app/shared/utils/helpers";
import { BlocklySectionComponent } from "src/app/admin/components/blockly-section/blockly-section.component";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import * as Blockly from 'ngx-blockly';
import { ToastrService } from "ngx-toastr";
import { BlockComparisonResult } from "src/app/shared/models/block";
import { TimeTrackingService } from "src/app/shared/services/time-tracking.service";



@Component({
  selector: "app-block-programming",
  templateUrl: "./block-programming.component.html",
  styleUrls: ["./block-programming.component.scss"],
})
export class BlockProgrammingComponent implements OnInit {
  code: string = "";
  generatedCode: string = "";
  toolbox = "";
  size: any;
  hasAppId: boolean = false;
  isHide: boolean = false;
  isCurrentBlock: boolean = true;
  blockName: string = "BLOCK";
  codeName: string = "CODE";
  showSideBarList = false;
  showAddBlockBarList = false;
  showSelectOptions = false;
  selectedOption = "";
  blockType: string = "BLOCK";
  tutorialUrl: SafeUrl = "";
  isDeveloperMode: any;
  toggle: boolean = false;
  infoSteps: any;
  tutorials: any;
  moduleKey: string = "";
  isVideo: boolean = false;
  videoUrl: any;
  isCompareResult: BlockComparisonResult = BlockComparisonResult.Default;
  isTutorialsDisabled:boolean = false;
  title:string="Start Tour";


  @ViewChild(BlocklySectionComponent)
  blocklySectionComponent!: BlocklySectionComponent;

  public applications$ = this.getAllTemplates();
  private xml!: string;
  public selectedApplication: any;
  public selectedApplicationIdentifier: string = "";
  public isRedirectUrl: boolean = false;
  public redirectUrl: string = "/ide/block-direct";
  public isBlockWithSimulator: boolean = false;
  public leftBlock!: Blockly.Block[];
  public rightBlock!: Blockly.Block[];
  public highlightedBlocks: { block: any, originalColor: string | number }[] = [];
  isTemplate: boolean;

  initial_steps: any = [
    // {
    //   "type": "text",
    //   "value": "This section shows the pre-defined block program for your reference",
    //   "location": "mid-left",
    //   "direction": "right"
    // },
    // {
    //   "type": "text",
    //   "value": "You can use this section to practice and create your block program",
    //   "location": "mid-right",
    //   "direction": "bottom"
    // },
    // {
    //   "type": "video",
    //   "value": "This is sample video",
    //   "location": "mid",
    //   "url": "https://vimeo.com/253989945",
    //   "direction": "none"
    // }
  ];

  constructor(
    private fileSaverService: FileSaverService,
    private applicationService: ApplicationService,
    private readonly joyrideService: JoyrideService,
    private store: Store<IAppState>,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private commonActions: CommonActions,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService,
    private toasterService: ToastrService,
    private timeTrackingService: TimeTrackingService
  ) {
    this.isTemplate = window.location.href.includes('/template/');
  }

  async ngOnInit() {
    this.timeTrackingService.startTracking();
    this.hasAppId = !window.location.href.includes("-direct");
    this.isRedirectUrl = this.router.url.includes(this.redirectUrl) ? true : false;
    this.toolbox = getToolbox();
    if (this.isRedirectUrl) {
      this.toggle = false;
      this.getAllTemplates();
      if (this.router.url == "/ide/block-direct-iot") {
        this.moduleKey = "iot";
      } else if (this.router.url == "/ide/block-direct-robotics") {
        this.moduleKey = "robotics";
      } else if (this.router.url == "/ide/block-direct-ds") {
        this.moduleKey = "data_science";
      } else if (this.router.url == "/ide/block-direct-aiot") {
        this.moduleKey = "aiot";
      } else if (this.router.url == "/ide/block-direct-ai") {
        this.moduleKey = "artificial_intelligence";
      } else if (this.router.url == "/ide/block-direct-iiot") {
        this.moduleKey = "iiot";
      } else {
        this.moduleKey = "block-direct";
      }
    }
    this.isDeveloperMode = localStorage.getItem("isDeveloperMode");
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();

  }

  ngOnDestroy(): void {
    this.timeTrackingService.stopTracking();
  }

  ngAfterViewInit() {
    this.repairBlockly();
  }

  private async getAllTemplates() {
    const app_id = sessionStorage.getItem("app_id");
    let resultData = [];
    const data: any = await this.applicationService
      .getAllApplication(environment.organizationID, app_id)
      .toPromise();
    if (data) {
      resultData = data?.applications;
    }
    return resultData;
  }

  private async getSingleTemplate(applicationIdentifier: string) {
    const data: any = await this.applicationService
      .getApplication(applicationIdentifier)
      .toPromise();
    if (data?.Application) {
      this.selectedApplication = data.Application;
      this.selectedApplicationIdentifier = applicationIdentifier;
    }
  }

  onCode(data: string) {
    this.code = data;
  }

  toggleBlockState(state: boolean) {
    this.isBlockWithSimulator = state;
    if (this.selectedApplication)
      this.getSingleTemplate(this.selectedApplicationIdentifier)
  }

  setModuleName(moduleKey: string) {
    this.moduleKey = moduleKey;
  }

  setToturials(tutorials: any) {
    if (tutorials) {
      this.tutorials = tutorials;
      this.setInfoSteps();
    }
  }


  private setInfoSteps() {
    const { block: blockSteps, default: basic } = this.tutorials;
    const tutorial = this.commonActions.isEmpty(blockSteps) ? basic : blockSteps;
    const isTutorialPresent = this.commonActions.isEmpty(tutorial) ? true : false;
    if (isTutorialPresent) {
      this.initial_steps = this.initial_steps.filter((el: any) => el.type !== "video");
    } else {
      //checking if url include vimeo or youtube
      let tutorialUrl=tutorial;
      if(tutorialUrl.includes('youtube')|| tutorialUrl.includes('vimeo')){
          // to check if url is from youtube 
        var url = tutorial.split('=');
        let videoUrl: any;
        let urlVideo: any;
        if (url[0] === 'https://www.youtube.com/watch?v') {
          videoUrl = 'https://www.youtube.com/embed/' + url[1];
          const tutorial_url = this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrl);
          urlVideo = tutorial_url;
        } else {
          const tutorial_url = this.commonActions.getIframeUrl(tutorial);
          urlVideo = tutorial_url;
        }
        this.initial_steps.push({
          "type": "video",
          "value": "This is sample video",
          "location": "mid",
          "url": urlVideo,
          "direction": "center",
        });
      }
      else{
        this.isTutorialsDisabled=false;
      }
    
    }
    this.infoSteps = this.initial_steps;
    if(!this.infoSteps.length){
      this.title="Tutorials not available";
      this.isTutorialsDisabled=true;
      // document.getElementById("infoImg")?.setAttribute('disabled', 'false');
      const btn=document.getElementById("infoImg") as HTMLButtonElement | null;
      if (btn != null) {
        // 👉️ btn has type HTMLButtonElement here
        btn.disabled = true;
      }
    }
    else{
      this.isTutorialsDisabled=false;
      // document.getElementById("infoImg")?.disabled=false;
    }
  }

  // TODO: Remove before pushing to prod.
  printXML() {
    if (this.isDeveloperMode) {
      // console.log(this.xml);
    }
  }

  onGeneratedCode(data: string) {
    this.generatedCode = "";
    this.generatedCode = data;
  }

  toggleOptions(data: boolean) {
    this.showSelectOptions = data;
  }

  //get guided tour step Id to show steps
  getJoyId(i: number, step: any) {
    if (step.type != "video") {
      return `Step-${i + 1}`;
    } else {
      return `Step-video`;
    }
  }

  startTour() {
    if (!this.infoSteps) return;
    let stepsName: any = [];
    this.infoSteps.map((value: any, index: number) => {
      if (value.type == "video") {
        stepsName = [...stepsName, `Step-video`]
      } else {
        stepsName = [...stepsName, `Step-${index + 1}`]
      }
    })
    this.joyrideService.startTour({
      steps: stepsName,
      themeColor: "#232023"
    });
  }

  /**
 * @deprecated The method should not be used
 */
  convertCodeToDiv() {
    return this.generatedCode.split("\n");
  }

  downloadPythonCode() {
    this.fileSaverService.saveText(this.generatedCode, "code.py");
  }

  onDrag(event: any) {
    this.size = event.sizes;
  }
  openSideBar() {
    this.showSideBarList = true;
  }

  openSaveSideBar() {
    this.showAddBlockBarList = true;
  }

  createNew() {
    this.selectedApplication = "";
    this.selectedApplicationIdentifier = "";
  }

  sidePanelClosed(event: any) {
    if (event) {
      this.store.dispatch(showSidePanel({ value: false }));
      this.showSideBarList = false;
      this.showAddBlockBarList = false;
    }
  }
  closeModal() {
    document.getElementById("new_variable_model").style.display = "none";
  }


  switch() {
    this.blockType =
      this.blockType?.toLowerCase() == this.blockName?.toLowerCase()
        ? this.codeName
        : this.blockName;
    // this.repairBlockly();
  }

  hideRef() {
    this.isHide = !this.isHide;
    if (this.isHide === true) {
      window.dispatchEvent(new Event("resize"));
      document.querySelector<HTMLElement>("as-split-area:nth-child(1)").style.flex = "0 0 0%";
      document.querySelector<HTMLElement>("as-split-area:nth-child(2)").style.flex = "0 0 100%";
    } else if (this.isHide === false) {
      document.querySelector<HTMLElement>("as-split-area:nth-child(1)").style.flex = "0 0 50%";
      document.querySelector<HTMLElement>("as-split-area:nth-child(2)").style.flex = "0 0 50%";
    } else {
      window.dispatchEvent(new Event("resize"));
      document.querySelector<HTMLElement>("as-split-area:nth-child(1)").style.display = "0 0 0%";
      document.querySelector<HTMLElement>("as-split-area:nth-child(2)").style.flex = "0 0 100%";
    }
  }

  repairBlockly() {
    window.dispatchEvent(new Event("resize"));
  }

  selectOption(selectedValue: string) {
    this.selectedOption = selectedValue;
    this.showSelectOptions = false;
  }

  async getApplicationData(event: any) {
    await this.getSingleTemplate(event.applicationIdentifier);
    this.showSideBarList = false;
  }

  saveXML(xml: string) {
    this.xml = xml;
  }

  updateNewBlock(event: any) {
    if (this.selectedApplicationIdentifier) {
      this.saveEditNewBlock(event);
    } else {
      this.saveNewBlock(event);
    }
  }

  async saveEditNewBlock(event: any) {
    let payload: any = {
      applicationName: event.applicationName,
      applicationDesc: event.applicationDesc,
    };
    if (this.isBlockWithSimulator) {
      payload = {
        ...payload,
        applicationDefinition: this.xml,
      };
    } else {
      payload = {
        ...payload,
        toolboxDefinition: this.xml,
      };
    }
    try {
      const result: any = await this.applicationService
        .updateApplication(payload, this.selectedApplicationIdentifier)
        .toPromise();
      if (result) {
        this.sidePanelClosed("close");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async saveNewBlock(event: any) {
    const user: any = sessionStorage.getItem("user");
    const username: any = JSON.parse(user)?.username;
    try {
      let payload: any = {
        applicationName: event.applicationName,
        applicationDesc: event.applicationDesc,
        boardIdentifier: environment.boardIdentifier,
        organizationID: environment.organizationID,
        moduleName: "robotics",
        // configurationDefinition: "",
        // applicationDefinition: "",
        // toolboxDefinition: "",
        createdBy: username || "Anonymous User",
        appUserID: username
        // courseID: "test course",
        // batchID: "test batch",
        // applicationType: "CONFIG_SETUP",
      };
      if (this.isBlockWithSimulator) {
        payload = {
          ...payload,
          applicationDefinition: this.xml,
        };
      } else {
        payload = {
          ...payload,
          toolboxDefinition: this.xml,
        };
      }
      const result: any = await this.applicationService
        .createApplication(payload)
        .toPromise();
      if (result) {
        this.sidePanelClosed("close");
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**Zoom in workspace onclick zoom in button*/
  zoomIn(event: any) {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly?.mainWorkspace?.zoom(event.x, event.y, 1);
  }

  /**Zoom out workspace onclick zoom out button */
  zoomOut(event: any) {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly.mainWorkspace?.zoom(event.x, event.y, -1);
  }

  /**recenter  */
  focus() {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly?.mainWorkspace?.scrollCenter();
  }

  undo() {
    let mainWorkspace =
      this.blocklySectionComponent?.blocklyComponent?.workspace?.workspace;
    mainWorkspace?.undo(false);
  }

  redo() {
    let mainWorkspace =
      this.blocklySectionComponent?.blocklyComponent?.workspace?.workspace;
    mainWorkspace?.undo(true);
  }

  /**delete selected block  or clear workspace*/
  delete() {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    if (blockly?.selected && !blockly?.selected?.workspace?.isFlyout) {
      blockly.Events.setGroup(true);
      blockly.hideChaff();
      blockly.selected.dispose(/* heal */ true, true);
      blockly.Events.setGroup(false);
    }
    // else {
    //   blockly.mainWorkspace.clear(); // clear the workspace
    // }
  }

  /**Call on click video button */
  onClkVideo(event: any) {
    this.isVideo = event;
    this.isVideoUrl(event.isData);
  }

  /**close video */
  videoClose() {
    this.isVideo = false;
  }

  /**Play video for blockly */
  // get tutorial video url on page load
  isVideoUrl(data: any) {
    if (data.tutorials.block || data.tutorials.default) {
        var url = data.tutorials.block
          ? data.tutorials.block.split('=')
          : data.tutorials.default.split('=');
        // to check url from youtube or vimeo for convert into embed url
        if (url[0] === 'https://www.youtube.com/watch?v') {
          this.videoUrl = 'https://www.youtube.com/embed/' + url[1];
        }
        else {
          const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
          var url = data.tutorials.block ? data.tutorials.block : data.tutorials.default;
          const matches = re.exec(url);
          var vidUrl = matches && "https://player.vimeo.com/video/" + matches[1];
          this.videoUrl = vidUrl;
        }
    }
  }

  onClose() {
    // debugger
    // this.isClose=false;
    // this.isCollaspedEvent.emit(this.isClose);
  }

  //Clear output sreen
  onClear() {
    // this.output='';
  }

  complieCode() {
    // console.log("code in output",this.codeGenetared)
  }

  isScreenCollaps($event: any) {

  }
  onCompareBlockClick(event: any) {
    this.leftBlock = event.leftBlock;
  }

  rightBlockClick(event: any) {
    this.rightBlock = event.rightBlock;
    this.compareSideBySideBlocks();
  }

  compareSideBySideBlocks() {
    this.clearHighlights();
    let problemFound = false;
    let anyProblemFound = false;
    let problemComment: any = null;
    for (let index = 0; index < this.leftBlock.length; index++) {
      const blockLeft: any = this.leftBlock[index];

      if (this.rightBlock[index]) {
        const blockRight: any = this.rightBlock[index];

        //compare if same or not
        if (blockLeft.type !== blockRight.type) {
          problemComment = `code block is different in yours`;
          problemFound = true;
        } else if (!this.compareInputsOfBlocks(blockLeft, blockRight)) {
          problemComment = `input value did not match`;
          problemFound = true;
        } else if (!this.compareChildrenBlocks(blockLeft, blockRight)) {
          problemComment = `children blocks did not match`;
          problemFound = true;
        } else {
          problemFound = false;
          problemComment = null;
        }
      } else { //does not exist on right
        problemComment = "does not exist in yours";
        problemFound = true;
      }
      if (problemFound) {
        anyProblemFound = true;
        this.setHighlight(blockLeft, problemComment);
        break;
      }
    }
    if (this.leftBlock.length == 0 && this.rightBlock.length > 0) {
      anyProblemFound = true;
      problemComment = "extra block(s) on your side"
      this.setHighlight(this.rightBlock[0], problemComment)
    }
    if (this.leftBlock.length < this.rightBlock.length) {
      anyProblemFound = true;
      problemComment = "extra block(s) on your side"
      this.setHighlight(this.rightBlock[this.leftBlock.length], problemComment)
    }

    if (this.rightBlock.length > this.leftBlock.length && this.leftBlock.length===0) {
      anyProblemFound = false;
    }

    return anyProblemFound;

  }

  public compareInputsOfBlocks(leftBlock: any, rightBlock: any): boolean {
    if (leftBlock.type == "text" || leftBlock.type == "mobile_message" || leftBlock.type == "add_simulator") {
      return true; //let text be different
    }

    if (leftBlock.inputList) {
      if (!rightBlock.inputList) {
        return false;
      }
      if (leftBlock.inputList.length != rightBlock.inputList.length) {
        return false;
      }

      for (let index = 0; index < leftBlock.inputList.length; index++) {
        const leftInput = leftBlock.inputList[index];
        const rightInput = rightBlock.inputList[index];

        if (leftInput.fieldRow) {
          if (!rightInput.fieldRow) {
            return false;
          }
          if (leftInput.fieldRow.length != rightInput.fieldRow.length) {
            return false;
          }

          for (let fieldIndex = 0; fieldIndex < leftInput.fieldRow.length; fieldIndex++) {
            const leftFieldRowItem = leftInput.fieldRow[fieldIndex];
            const rightFieldRowItem = rightInput.fieldRow[fieldIndex];

            if (leftFieldRowItem.getText() != rightFieldRowItem.getText()) {
              return false;
            }
          }
        }
      }

    } else if (rightBlock.inputList) {
      return false;
    }

    return true;
  }

  public compareChildrenBlocks(leftBlock: any, rightBlock: any): boolean {
    let result: boolean = true;

    if (leftBlock.statementInputCount > 0 || rightBlock.statementInputCount > 0) {

      const leftBlockChildren = leftBlock.getChildren(true);
      const rightBlockChildren = rightBlock.getChildren(true);
  
      // if (leftBlock.type == "controls_if") {
      //   console.log(leftBlock, rightBlock);
      // }
  
      const leftBlockTreeChildren: string[] = leftBlock?.inputList.map((obj: any) => obj?.connection?.targetConnection?.sourceBlock_?.type);
      const rightBlockTreeChildren: string[] = rightBlock?.inputList.map((obj: any) => obj?.connection?.targetConnection?.sourceBlock_?.type);
      const stringArrayComparisonResult = this.commonActions.isEqualStringArray(leftBlockTreeChildren, rightBlockTreeChildren);
  
      // console.log({ leftBlockTreeChildren, rightBlockTreeChildren, stringArrayComparisonResult });
  
      if (leftBlock.type !== rightBlock.type) {
        result = false;
      } else if (leftBlockChildren.length !== rightBlockChildren.length) {
        result = false;
      } else if (!stringArrayComparisonResult) {
        result = false;
      }

    }


    return result;
  }

  public setHighlight(block: any, warningText?: string): void {
    this.highlightedBlocks.push({ block: block, originalColor: block.getColour() });

    block.setColour("#d2222d");
    if (warningText) {
      block.setWarningText(warningText);
    }
  }

  public clearHighlights(): void {
    while (this.highlightedBlocks.length) {
      let blockHighlight = this.highlightedBlocks.pop();
      blockHighlight?.block.setColour(blockHighlight.originalColor);
      blockHighlight?.block.setWarningText(null);
    }
  }

  isTourData:boolean=false;
  getAppData(event: any) {
    let tourControls = event.data.Application.tourControls
    let tourData: any[] = [];
    (tourControls || []).forEach((item: any) => {
      let location = item.controlVal === 'Reference Section' ? "mid-left" : "mid-right";
      if (item.page === 'Block Designer') {
        tourData.push({
          "type": "text",
          "value": item.displayedText,
          "location": location,
          "direction": item.position
        })
      }
    });
    this.initial_steps.push(...tourData);
  }

  // Event Emitter Methods
  async compareBlockEvent(event?: any) {
    let isCompareResult: boolean = !this.compareSideBySideBlocks();
    this.isCompareResult  = isCompareResult ? BlockComparisonResult.Success : BlockComparisonResult.Error;
  }

  async leftBlockReceiverEvent(event: any) {
    this.leftBlock = event.leftBlock;
  }

  async rightBlockReceiverEvent(event: any) {
    this.rightBlock = event.rightBlock;
  }

  async resetCompareBlockResult(event: any) {
    this.isCompareResult = BlockComparisonResult.Default;
  }

}
