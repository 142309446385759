import { createReducer, on } from "@ngrx/store";
import { showLayout, showSidePanel } from "./layout.action";
import { initialLayoutState } from "./layout.state";

export const layoutReducer = createReducer(
  initialLayoutState,
  on(showLayout, (state, {value}) => ({
    ...state,
    showLayout: value
  })),
  on(showLayout, (state, {value}) => ({
    ...state,
    showSpinner: value
  })),
  on(showSidePanel, (state, {value}) => ({
    ...state,
    showSidePanel: value
  }))
)
