import { Component, OnInit } from '@angular/core';
import { IDEService } from 'src/app/shared/services/ide.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connect-device',
  templateUrl: './connect-device.component.html',
  styleUrls: ['./connect-device.component.scss']
})
export class ConnectDeviceComponent implements OnInit {

  token: string = '';
  debianPackageUrl: string;
  fileName: string = "grok-desktop-v1.deb";

  constructor(
    private ideService: IDEService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService) {
    this.debianPackageUrl = environment.staticFilesUrl + "packages/" + this.fileName;
  }

  async ngOnInit() {
    const user: any = sessionStorage.getItem("user"); 
    const currentUser = this.sessionStorageService.getUsername();
    const username = JSON.parse(user)?.username;
    this.ideService.addDevice(username, currentUser)
      .subscribe((data: any) => {
        this.token = data['token'];
        // data['data'].forEach((item:any)=> this.token =  item.token );
      })
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();

  }
}
