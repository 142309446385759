<div class="mr-4 fixed link right-10 z-50 cursor-pointer">
  <div type="button" class="close text-gray cursor-pointer">
    <div aria-hidden="true" class="external-link">
      <img src="assets/img/icons/external-link.svg" class="h-7 w-7" title="External Link" />
    </div>
  </div>
</div>
<div class="flex flex-col">
  <div>
  <h2 class="font-semibold text-4xl p-4">{{ sensorInfo.name }}</h2>
  </div>
  <div class="p-2 items-center" style="align-self: center;">
    <img [src]="sensorInfo.image_url" alt="" class="info-image">
  </div>
  <div class="p-4">
    <pre class="text-lg text-black-400 mb-10" style="white-space: break-spaces;">
      {{ sensorInfo.desc }}
    </pre>
  </div>
</div>
