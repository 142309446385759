<div class="grid grid-cols-1">
  <div class="p-2">
    <button class="grey-btn float-right custom-lg-btn" (click)="onExit()">Exit</button>
    <button class="blue-btn float-right custom-lg-btn" (click)="onNext()">Save & Next</button>
  </div>
  <div>
    <p class="text-md mb-10">Let's start by adding a few basic details of the solution</p>

    <form #basicForm="ngForm">
      <div class="mb-10 flex flex-col">
        <label id="listbox-label" class="block text-sm font-medium text-gray-500 p-3">
          Module Name
        </label>
        <div class="mt-1 relative">
          <button type="button" (click)="toggleSelectMenu()" [disabled]="isEdit || !isAdmin"
            [class.disabled]="isEdit|| !isAdmin"
            class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-greenish-blue sm:text-sm"
            aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" style="height: 51px">
            <span class="block truncate flex items-center uppercase">
              {{ selectedModule? selectedModule.display : 'Choose One' }}
            </span>
            <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <!-- Heroicon name: solid/selector -->
              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            </span>
          </button>
          <ul *ngIf="showMenu"
            class="height: 51px absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            <li *ngFor="let item of modules" class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
              (click)="selectModule(item)">
              <div class="flex items-center">
                <span class="font-normal ml-3 block truncate uppercase">
                  {{ item.display }}
                </span>
              </div>
              <span *ngIf="selectedModule === item"
                class="greenish-blue absolute inset-y-0 right-0 flex items-center pr-4">
                <!-- Heroicon name: solid/check  -->
                <svg class="h-5 w-5 m-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Only for students -->
      <div *ngIf="selectedModule && !isTemplate" class="floating-input relative mb-10 flex flex-col">
        <label [class.text-red]="!problemTryingToSolveIsValid"
          class="block text-sm font-medium text-gray-500 p-3">What is the problem you are trying to solve?*</label>
        <textarea [class.border-red]="!problemTryingToSolveIsValid" class="text-area-style text-area-custom"
          name="problem-desc" [(ngModel)]="problemTryingToSolve"
          placeholder="Describe the problem statement for building this solution" required></textarea>
      </div>

      <div *ngIf="selectedModule  && !isTemplate" class="mb-10 flex flex-col">
        <label id="listbox-label" class="block text-sm font-medium text-gray-500 p-3" [class.text-red]="!industryDesignedForIsValid">
          What industry are you creating this solution for?*
        </label>
        <div class="mt-1 relative">
          <button type="button" (click)="toggleIndustryMenu()" [class.border-red]="!problemTryingToSolveIsValid"
            class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-greenish-blue sm:text-sm"
            aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" style="height: 51px">
            <span class="block truncate flex items-center uppercase">
              {{ industryDesignedFor?.industry_name || 'Choose One' }}
            </span>
            <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <!-- Heroicon name: solid/selector -->
              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            </span>
          </button>
          <ul *ngIf=showIndustry
            class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            <li *ngFor="let item of listOfIndustries"
              class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9" (click)="selectIndustry(item)">
              <div class="flex items-center">
                <span class="font-normal ml-3 block truncate uppercase">
                  {{ item.industry_name }}
                </span>
              </div>
              <span *ngIf="industryDesignedFor === item"
                class="greenish-blue absolute inset-y-0 right-0 flex items-center pr-4">
                <!-- Heroicon name: solid/check -->
                <svg class="h-5 w-5 m-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="selectedModule  && !isTemplate" class="floating-input relative mb-10 flex flex-col">
        <label [class.text-red]="!applicationCreationProcedureIsValid"
          class="block text-sm font-medium text-gray-500 p-3">How are you planning to build the solution?*</label>
        <textarea [class.border-red]="!applicationCreationProcedureIsValid" class="text-area-style text-area-custom p-3"
          name="app-build" [(ngModel)]="applicationCreationProcedure"
          placeholder="Give a brief description of steps you would like to take to build the solution"></textarea>
      </div>
      <div class="floating-input relative mb-10 flex flex-col">
        <label [class.text-red]="!applicationNameIsValid" class="block text-sm font-medium text-gray-500 p-3">Name of
          the solution*</label>
        <input [class.border-red]="!applicationNameIsValid" class="input-style p-3" name="app-name"
          [(ngModel)]="applicationName" placeholder="Solution Name">
      </div>
      <div class="floating-input relative mb-10 flex flex-col">
        <label class="block text-sm font-medium text-gray-500 p-3">Description of the solution</label>
        <input class="input-style p-3" name="app-desc" [(ngModel)]="applicationDesc"
          placeholder="Solution Description">
      </div>
      <!-- For IOT and ROBOTICS -->
      <div *ngIf="selectedModule && selectedModule.key == 'robotics'"
        class="floating-input relative mb-2 flex flex-col">
        <label class="text-gray-500 font-medium label-custom p-3">Controller Type</label>
        <select name="controller_type" id="" class="w-full border-gray-300 rounded-lg shadow-sm mb-5 input-style"
          [(ngModel)]="controller_type">
          <option value="" disabled selected></option>
          <ng-container *ngFor="let c of controller_types">
            <option class="input-style" [value]="c.key">{{c.display}}</option>
          </ng-container>
        </select>
      </div>
      <!-- simulator type drop down -->
      <div *ngIf="selectedModule && selectedModule.key == 'robotics'"
        class="floating-input relative mb-2 flex flex-col">
        <label class="text-gray-500 font-medium p-3">Visualization Type</label>
        <select name="simulator_type" id="" class="w-full border-gray-300 rounded-lg shadow-sm mb-5 input-style"
          [(ngModel)]="simulator_type">
          <option value="" disabled selected></option>
          <ng-container *ngFor="let c of simulator_types">
            <option class="input-style p-3" [value]="c.key">{{c.display}}</option>
          </ng-container>
        </select>
      </div>
      <!-- ----------------------------------------- -->

      <!-- show viz/msg panel checkboxes -->
      <div *ngIf="selectedModule && selectedModule.key == 'robotics'">
        <div class="flex flex-row mb-2">
          <label class="inline-flex  items-center px-2">
            <input [(ngModel)]="visualization_panel" [ngModelOptions]="{standalone: true}" type="checkbox"
              class="form-checkbox h-5 w-5 text-gray-600"><span class="ml-2 text-gray-700">Show Visualization
              Panel</span>
          </label>

          <label class="inline-flex items-center px-2">
            <input [(ngModel)]="mobile_console" [ngModelOptions]="{standalone: true}" type="checkbox"
              class="form-checkbox h-5 w-5 text-gray-600"><span class="ml-2 text-gray-700">Show Message Console</span>
          </label>
        </div>
      </div>


      <!-- ----------------------------------------- -->
      <div *ngIf="selectedModule && !['data_science', 'artificial_intelligence'].includes(selectedModule.key) && isTemplate"
        class="floating-input relative mb-10 flex flex-col">
        <label class="block text-sm font-medium text-gray-500 p-3">Tutorial URL: Default Video</label>
        <input class="input-style p-3" name="tut-default" [(ngModel)]="tutorial_url.default"
          placeholder="Tutorial URL: Default Video">
      </div>
      <div *ngIf="selectedModule && !['data_science', 'artificial_intelligence'].includes(selectedModule.key) && isTemplate"
        class="floating-input relative mb-10 flex flex-col">
        <label class="block text-sm font-medium text-gray-500 p-3">Tutorial URL: Circuit Video</label>
        <input class="input-style p-3" name="tut-circuit" [(ngModel)]="tutorial_url.circuit"
          placeholder="Tutorial URL: Circuit Video">
      </div>
      <div *ngIf="selectedModule && isTemplate" class="floating-input relative mb-10 flex flex-col">
        <label class="block text-sm font-medium text-gray-500 p-3">Tutorial URL: Block Video</label>
        <input class="input-style p-3" name="tut-block" [(ngModel)]="tutorial_url.block"
          placeholder="Tutorial URL: Block Video">
      </div>
      <div *ngIf="selectedModule && !['data_science', 'artificial_intelligence'].includes(selectedModule.key) && isTemplate"
        class="floating-input relative mb-10 flex flex-col">
        <label class="block text-sm font-medium text-gray-500 p-3">Tutorial URL: Simulator Video</label>
        <input class="input-style p-3" name="tut-simulator" [(ngModel)]="tutorial_url.simulator"
          placeholder="Tutorial URL: Simulator Video">
      </div>

      <!-- Add guided tour inputs start-->
      <div *ngIf="isTemplate" class="floating-input relative flex flex-col" (ngSubmit)="onSubmit(ControlFormGroup)" [formGroup]="ControlFormGroup">        
        <label class="text-sm font-medium text-gray-500 p-3 flex">
          <span>Add Guided Tour</span>
          <span>
            <img src="../../../../assets/img/icons/circle-info-solid-1.svg" 
                class="text-center rounded-lg i-icon text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                data-bs-toggle="tooltip" title="{{tooltipGuidedTour}}">
          </span>          
        </label>  
        <div class="flex flex-col" formArrayName="tourControls"  *ngFor="let control of tourControls.controls; let i=index">
          <div  class="floating-input relative mb-5 flex flex-row select-dropdown">
            <div class="w-full">
                <select  class="input-style" [formControl]="$any(control.get('page'))" [class.border-red]="isValue && !control.get('page').value">
                  <option value="" disabled selected>Select Page</option>
                  <ng-container *ngFor="let page of pageTypes">
                    <option value="{{page.display}}">{{page.display}}</option>
                  </ng-container>
                </select> 
            </div>
            <div class="p-3 w-full">
              <select *ngIf="control.get('page')?.value!=='Block Designer'" [class.border-red]="isValue && !control.get('controlVal').value" class="input-style" [formControl]="$any(control.get('controlVal'))">
                <option value="" disabled selected>Select Control</option>
                <ng-container *ngFor="let control of cicuitControls">
                  <option  value="{{control.name}}">{{control.display}}</option>
                </ng-container>
              </select> 
              <select *ngIf="control.get('page')?.value==='Block Designer'" [class.border-red]="isValue && !control.get('controlVal').value" class="input-style" [formControl]="$any(control.get('controlVal'))">
                <option value="" disabled selected>Select Control</option>
                <ng-container *ngFor="let control of blockControls">
                  <option  value="{{control.name}}">{{control.display}}</option>
                </ng-container>
              </select>
            </div> 
            <div class="p-3 w-full">
              <select class="input-style" [formControl]="$any(control.get('position'))" [class.border-red]="isValue && !control.get('position').value">
                <option value="" disabled selected>Select Position</option>
                <ng-container *ngFor="let position of positions">
                  <option value="{{position.key}}">{{position.display}}</option>
                </ng-container>
              </select> 
            </div> 
            <div  class="p-3 flex flex-row w-auto p-l">
              <img class="h-48" ng-disabled="isAddDisabled" (click)="addControls(i)" src="../../../../assets/img/icons/plus.svg">  
              <img class="h-48" (click)="removeControls(i)" src="../../../../assets/img/icons/delete-red-color.svg">          
            </div>
          </div>        
            <textarea class="rounded-md border-gray-200"  [class.border-red]="isValue && !control.get('displayedText').value" maxlength="2000" placeholder="Add text to be displayed" [formControl]="$any(control.get('displayedText'))" required></textarea>
            <p class="text-red-700 font-light" *ngIf="isValue && !control.get('displayedText').value">
              Please fill displayed text area.
            </p>
          </div>     
    </div>
    <!-- Add guided tour inputs end-->
    </form>
  </div>
</div>

