import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimulatorDashboardModule } from './containers/simulator-dashboard/simulator-dashboard.module';
import { IotAppModule } from "../simulator/containers/iot-app/iot-app.module";
import { routes } from './simulator.routes';
import { RouterModule } from '@angular/router';
import { WsApplicationService } from 'src/app/shared/services/ws-application.service';


@NgModule({
  declarations: [
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    SimulatorDashboardModule,
    IotAppModule,
    RouterModule.forChild(routes)
  ],
  providers:[WsApplicationService]
})
export class SimulatorModule { }
