import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CreateDevice, SearchDevices, UpdateDevice } from "../models/device";


@Injectable({
    providedIn:'root'
})

export class DevicesService{
    constructor(private http:HttpClient){}

    /**
   * Method to create a new device.
   * @param device
   */
    create(device: CreateDevice) {
        const url = `${environment.apiURL}/admin/devices/create`;
        return this.http.post<CreateDevice>(url, device);
    }

        /**
   * Method to get data of a device.
   * @param device_token
   */
    get(device_token: any) {
        const url = `${environment.apiURL}/admin/devices/get?device_token=${device_token}`;
        return this.http.get<CreateDevice>(url);
    }

    /**
   * Method to update data of a device.
   * @param deviceToken
   * @param updates
   */
    update(deviceToken: number, updates?: any) {
        const url = `${environment.apiURL}/admin/devices/update?device_token=${deviceToken}`;
        return this.http.put<UpdateDevice>(url,  updates );
    }

    /**
   * Method to delete a device.
   * @param deviceID
   */
    deleteDevice(deviceID:string){
        const url = `${environment.apiURL}/admin/devices/delete?device_token=${deviceID}`;
        return this.http.delete(url);
    }

    /**
    * Method to search or sort  devices.
    * @param payload
    */
    search(payload: SearchDevices) {
        const sessionId = localStorage.getItem('sessionId');
        const url = `${environment.apiURL}/admin/devices/search`;
        const headers = new HttpHeaders().set('cookie-header' , sessionId);
        return this.http.post<{}>(url, payload , { headers });
    }

}