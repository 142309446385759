import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UsersSessionComponent } from './users-session.component';

@NgModule({
    declarations: [UsersSessionComponent],
    imports: [CommonModule, FormsModule],
    exports: [UsersSessionComponent],
  })
  export class UsersSessionModule {}