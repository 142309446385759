import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApplicationService } from "src/app/shared/services/application.service";
import { IDEService } from "src/app/shared/services/ide.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { WsApplicationService } from "src/app/shared/services/ws-application.service";

@Component({
  selector: "app-iot-app",
  templateUrl: "./iot-app.component.html",
  styleUrls: ["./iot-app.component.scss"],
})
export class IotAppComponent implements OnInit {
  @Input() commandItems: any[] = [];

  user: any;
  device: any;
  apps: any;

  constructor(
    private applicationService: ApplicationService,
    private sessionStorageService: SessionStorageService,
    private wsApplicationService: WsApplicationService,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private userSessionService: UserSessionService
  ) {
    this.apps = {
      selected_module_name: '',
      runningApps: [],
      templates: [],
    }
    this.device = {
      id: null,
      isDeviceOnline: false,
      isRegistered: false,
      isSimulatorActive: false,
      wsSubscription: null,
      lastSeen: null
    }
    this.user = {
      isLoggedIn: false,
    }
  }

  async ngOnInit() {
    if (sessionStorage.getItem("user")) {
      this.loginSuccess();
    }
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  // ngAfterViewInit(): void {
  // }

  // async ngOnChanges(changes: SimpleChanges) {
  //   if (changes?.isLoggedIn) {
  //     console.log(this.isLoggedIn, "111");
  //   }
  // }

  private async checkRPIStatus() {
    const username = this.sessionStorageService.getUsername();
    this.ideService.getRPIStatus(username)
      .then((result: any) => {
        this.device.isRegistered = !!result?.result?.length;
        this.device.id = result.result[0].deviceIdentifier;
      })
      .catch((err) => console.log(err));
  }

  loginSuccess() {
    this.user.isLoggedIn = true;
    // this.checkRPIStatus();
  }

  loginFail() {
    this.user.isLoggedIn = false;
  }

  updateLoggedInStatus(event: boolean) {
    if (event === false) {
      ["sessionId", "user", "state"].forEach((key: any) => sessionStorage.removeItem(key));
      this.loginFail()
    } else {
      this.loginSuccess();
    }
  }

  private createDeviceWebSocketConnection() {
    const appId = this.device.id;
    if (appId) {
      this.device.wsSubscription = this.wsApplicationService
        .createObservableCircuit(appId)
        .subscribe(async (data: any) => {
          let parsedData = JSON.parse(data);
          if (parsedData) {
            this.apps.runningApps = parsedData.runningApps;
            if (this.apps.runningApps) {
              this.apps.templates.map((item: any) => {
                if (
                  this.apps.runningApps?.length &&
                  this.apps.runningApps?.includes(item.applicationName)
                ) {
                  item.isOnline = true;
                } else {
                  item.isOnline = false;
                }
              });
            }


            if (parsedData.topic === "simulator-log") {
              const { message } = parsedData;
              this.printConsoleMessages(message);
            } else if (parsedData.topic === "runApplication") {
              const { code } = parsedData;
              switch (code) {
                case "other_apps_running":
                  // this.store.dispatch(showSidePanel({ value: true }));
                  break;

                case "code_not_found":
                  this.toasterService.error("Code does not exists on the Device");
                  break;

                default:
                  break;
              }
            } else if (parsedData.topic === "forceDissociate") {
              this.toasterService.show("The lab administrator has removed your access to this device. If this was unexpected please contact the lab admin to restore your connect back to the device.");
              this.wsApplicationService.close();
            }

            this.device.lastSeen = parsedData.timestamp;
          }
        });
    }
  }

  printConsoleMessages(messages: any) {
    messages.forEach((item: any) => {
      if (item.value || item.source) {
        this.commandItems.unshift({
          ...item,
          simulation: true,
          title: item.value || "",
          state: item.state,
          color: item.color,
          time: new Date(),
        });

        this.commandItems = this.commandItems.slice(0, 20);
      }
    });
  }

  resetModuleName() {
    this.apps.selected_module_name = '';
  }

  setModuleName(event: any) {
    this.apps.selected_module_name = event;
  }
}
