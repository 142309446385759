import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { IAppState } from 'src/app/store/index.state';
import { environment } from 'src/environments/environment';
import {
  dummyAction,
  loadApplicationFailure,
  loadApplications,
  setApplications,
  loadApplicationTemplates,
  setApplicationTemplates,
} from './application.actions';
import {
  getApplications,
  getApplicationTemplates,
} from './application.selectors';

@Injectable()
export class ApplicationEffect {
  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private applicationTemplateService: ApplicationService
  ) { }
  isAdmin: boolean = window.location.href.includes('/admin/application') || window.location.href.includes('/admin/template');
  user: string = sessionStorage.getItem('user');

  loadApplications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadApplications),
      withLatestFrom(this.store.select(getApplications)),
      exhaustMap(([payload, item]) => {
        this.isAdmin = window.location.href.includes('/admin/application') || window.location.href.includes('/admin/template');
        this.user = sessionStorage.getItem('user');
        let appUserID;
        if (this.isAdmin) {
          appUserID = "*"
        }
        else {
          appUserID = JSON.parse(this.user)?.username;
        }
        return this.applicationTemplateService
          .getAllApplication(environment.organizationID, appUserID)
          .pipe(
            map((data: any) => setApplications({ payload: data.applications })),
            catchError((error) =>
              of(loadApplicationFailure({ payload: error }))
            )
          );
      })
    );
  });

  loadApplicationTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadApplicationTemplates),
      withLatestFrom(this.store.select(getApplicationTemplates)),
      exhaustMap(([payload, item]) => {
        return this.applicationTemplateService
          .getAllApplicationTemp(environment.organizationID)
          .pipe(
            map((data: any) =>
              setApplicationTemplates({ payload: data.applications })
            ),
            catchError((error) =>
              of(loadApplicationFailure({ payload: error }))
            )
          );
      })
    );
  });
}
