import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from '@angular/common';
import { PrebuildCircuitModule } from "src/app/shared/components/prebuild-circuit/prebuild-circuit.module";
import { DraggablePrebuildCircuitModule } from "src/app/shared/components/draggable-prebuild-circuit/draggable-prebuild-circuit.module";
import { WorkspaceModule } from "src/app/shared/components/workspace/workspace.module";
import { AngularSplitModule } from 'angular-split';
import { IotDevicesSectionModule } from 'src/app/shared/components/iot-devices-section/iot-devices-section.module';
import { SidePanelModule } from 'src/app/components/side-panel/side-panel.module';
import { InfoPanelModule } from 'src/app/shared/components/info-panel/info-panel.module';
import { SensorService } from "src/app/shared/services/sensor.service";
import { KonvaUtils } from "src/app/shared/utils/konva.utils";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { showSidePanel } from "src/app/store/layout/layout.action";
import { sidePanelSelector } from "src/app/store/layout/layout.selector";

@Component({
  selector: "app-circuit-section",
  templateUrl: "./circuit-section.component.html",
  styleUrls: ["./circuit-section.component.scss"],
})
export class CircuitSectionComponent implements OnInit, OnChanges {
  @Input() loadExistingCircuitJSON!: JSON;
  @Input() applicationIdentifier!: string;
  @Input() konvaUtils!: KonvaUtils;
  @Input() isEdit!: boolean;
  @Input() isNewWorkspace!: boolean;
  @Input() applicationData:any;
  @Output() emitSavedJSON = new EventEmitter();
  @Output() emitLoadState = new EventEmitter();

  @Input() sensors: any = [];
  selectedNavMenu!: any;
  draggedElement!: any;
  isMenuOpen: boolean = true;
  sensorInfo!: any;
  showSidePanel$ = this.store.select(sidePanelSelector);

  constructor(
    private store: Store<IAppState>,
    private sensorService: SensorService
    ) { }

  ngOnInit(): void {
    if (!this.sensors.length) {
      this.getSensors();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasSensors = changes?.sensors?.currentValue;
    if (!hasSensors) {
      this.getSensors();
    }
  }

  async getSensors() {
    const result: any = await this.sensorService
      .getAllSensors()
      .toPromise();

    // Temp code to add deviceType in sensors list, until we start getting this data from DB
    await result.sensors.forEach((devices: any) => {
      let title = devices.title;
      let components = devices.components;
      components.forEach((device: any) => {
        device['deviceType'] = title;
      });
    });
    if (result) {
      this.sensors = result.sensors;
    }
  }

  setDraggedElement(element: any) {
    this.draggedElement = element;
    this.isMenuOpen = false;
  }
  toggleSideMenu(event: boolean): void {
    this.isMenuOpen = event;
  }
  viewInformation(sensorData: any) {
    if (event) {
      this.sensorInfo = sensorData;
      this.store.dispatch(showSidePanel({ value: true }));
    }
  }
  sidePanelClosed(event: any) {
    if (event) {
      this.store.dispatch(showSidePanel({ value: false }));
    }
  }
}


@NgModule({
  declarations: [CircuitSectionComponent],
  imports: [CommonModule, WorkspaceModule, PrebuildCircuitModule, DraggablePrebuildCircuitModule, AngularSplitModule, IotDevicesSectionModule, SidePanelModule, InfoPanelModule],
  exports: [CircuitSectionComponent],
})
export class CircuitSectionModule { }

