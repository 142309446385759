import { createReducer, on } from "@ngrx/store";
import { addApplication, loadApplications, setApplications, setPageNumber, setPageSize, setTotal, deleteApplication, setApplicationTemplates, loadApplicationTemplates, addApplicationTemplate, deleteApplicationTemplate } from "./application.actions";
import { initialApplication } from "./application.state";

export const applicationReducer = createReducer(
  initialApplication,
  // for applications
  on(loadApplications, (state) => ({
    ...state
  })),
  on(setApplications, (state, {payload}) => ({
    ...state,
    application: payload
    // total: payload.length,
    // currentPageSize: state.currentPageSize,
    // currentPage: state.currentPage
  })),
  on(addApplication, (state, {application}) => ({
    ...state,
    application: [application, ...state.application],
    total: state.total + 1
  })),
  on(deleteApplication, (state, {id}) => ({
    ...state,
    application: state.application.filter((eachItem)=>{return id !== eachItem.applicationIdentifier}),
    total: state.total - 1,
  })),
  // for templates
  on(loadApplicationTemplates, (state) => ({
    ...state
  })),
  on(setApplicationTemplates, (state, {payload}) => ({
    ...state,
    applicationTemplate: payload,
  })),
  on(addApplicationTemplate, (state, {applicationTemplate}) => ({
    ...state,
    applicationTemplate: [applicationTemplate, ...state.applicationTemplate],
  })),
  on(deleteApplicationTemplate, (state, {id}) => ({
    ...state,
    applicationTemplate: state.applicationTemplate.filter((eachItem)=>{return id !== eachItem.applicationIdentifier}),
  })),
  on(setPageNumber, (state, {value}) => ({
    ...state,
    currentPage: value,
    application: state.application.slice((state.currentPage - 1) * state.currentPageSize, (state.currentPage + state.currentPageSize)),
  })),
  on(setPageSize, (state, {value}) => ({
    ...state,
    currentPageSize: value
  })),
  on(setTotal, (state, {value}) => ({
    ...state,
    total: value
  }))
)
