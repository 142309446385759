import { createReducer, on } from "@ngrx/store";
import { authenticateError, authenticateSuccess, logout, setToken, setUser, setUserRole } from "./auth.action";
import { initialAuthState } from "./auth.state";

export const authReducers = createReducer(
  initialAuthState,
  on(authenticateSuccess, (state, {payload}) => ({
    ...state,
    isAuthenticated: true,
    token: payload.token,
    errorMessage: ''
  })),
  on(authenticateError, (state, {error}) => ({
    ...state,
    isAuthenticated: false,
    token: '',
    errorMessage: error.error_description
  })),
  on(setToken, (state, {token}) => ({
    ...state,
    isAuthenticated: true,
    token: token,
    errorMessage: ''
  })),
  on(logout, (state) => ({
    ...state,
    isAuthenticated: false,
    token: '',
    errorMessage: ''
  })),
  on(setUserRole, (state, {role}) => ({
    ...state,
    role: role
  })),
  on(setUser, (state, {user}) => ({
    ...state,
    user: user
  }))
)
