import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IDEService {
  constructor(private http: HttpClient) {}

  initiateUpload(file: File, username: string) {
    const payload = {
      fileName: file.name,
      fileType: file.type,
      username,
    };
    return this.http.post<{}>(
      `${environment.apiURL}/ide/blockly/generate-upload-link`,
      payload
    );
  }

  generateCodeDownloadLink(
    applicationIdentifier: string,
    downloadLink: string,
    username: string
  ) {
    const payload = { applicationIdentifier, downloadLink, username };
    return this.http.post<{}>(
      `${environment.apiURL}/ide/blockly/generate-download-link/`,
      payload
    );
  }

  uploadToS3(file: File, link: string) {
    return this.http.put<{}>(link, file);
  }

  uploadStaticFile(upload_type: string) {
    return this.http.post(`${environment.apiURL}/ide/static-files/upload`,
      { upload_type }
    );
  }

  getRPIStatus(username: string) {
    return this.http.post(`${environment.apiURL}/ide/register/rpi-status`, {
      username,
    }).toPromise();
  }

  getDeviceStatus(device_identifier: string) {
    return this.http.post(
      `${environment.apiURL}/ide/simulator/check-device-status`,
      {
        device_identifier
      }
    );
  }

  runApplication(device_identifier: string, applicationName: string, executionMethod: string) {
    return this.http.post(
      `${environment.apiURL}/ide/simulator/run-application`,
      {
        device_identifier,
        applicationName,
        executionMethod,
      }
    );
  }

  toggle(payload: any) {
    return this.http.post(
      `${environment.apiURL}/ide/simulator/toggle`,
      payload
    );
  }

  // add new device against the username
  addDevice(device_name: any, createdBy: string) {
    return this.http.post(`${environment.apiURL}/ide/rpi/add-device`, {
      device_name, createdBy
    });
  }

  // get all the device registered against username
  getAllDevices(username: string) {
    return this.http.post(`${environment.apiURL}/ide/rpi/get-devices`, {
      username,
    });
  }
  editDevice(payload: any) {
    const { token, device_name } = payload;
    return this.http.post(`${environment.apiURL}/ide/rpi/edit-device`, {
      token,
      device_name,
    });
  }
  deleteDevice(token: string) {
    return this.http.post(`${environment.apiURL}/ide/rpi/delete-device`, {
      token,
    });
  }
  activeDevice(payload: any) {
    const { token, active } = payload;
    return this.http.post(`${environment.apiURL}/ide/rpi/active-device`, {
      token, active
    });
  }
}
