<!-- <div class="p-14">
  <div class=" mb-20 mt-10">
    <h1 class="text-4xl font-bold mb-5">
      <span class="text-greenish-blue">
        Welcome Admin
      </span>
    </h1>

    <p class="text-2xl font-extralight text-gray-500">Please select an operation to get started</p>
  </div>

  <div class="grid grid-cols-12 gap-10 grid-flow-col">
    <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" (click)="routeTo('application/create')">
      <div class="overlay rounded-xl "></div>
      <img src="assets/img/admin/application.svg">
    </div>
    <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" (click)="routeTo('circuit')">
      <div class="overlay rounded-xl "></div>
      <img src="assets/img/admin/circuit.svg" class="h-full w-full">
    </div>
    <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" (click)="routeTo('block')">
      <div class="overlay rounded-xl "></div>
      <img src="assets/img/admin/coding.svg">
    </div>
  </div>

</div> -->



<div class="p-14">
  <div class=" mb-20 mt-10">
    <h1 class="text-4xl font-bold mb-5">
      <span class="text-greenish-blue">
        Welcome Admin
      </span>
    </h1>

    <p class="text-2xl font-extralight text-gray-500">Please select an option to get started</p>
  </div>

  <div class="grid grid-cols-12 gap-10 grid-flow-col">
    <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" (click)="routeTo('application/create')">
      <div class="overlay rounded-xl "></div>
      <div class="flex flex-col">
        <div class="flex flex-row justify-center">
          <img class="img-w-h" src="assets/img/admin/application.svg">  
        </div>
        <div class="flex flex-row justify-center p-2 h-12 text-greenish-blue bg-text">
          Create Application
        </div>
      </div>
    </div>
    <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" (click)="routeTo('circuit')">
      <div class="overlay rounded-xl "></div>
      <div class="flex flex-col">
        <div class="flex flex-row justify-center">
          <img class="img-w-h" src="assets/img/admin/circuit.svg">  
        </div>
        <div class="flex flex-row justify-center p-2 h-12 text-greenish-blue bg-text">
          Circuit Design
        </div>
      </div>
    </div>
    <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" (click)="routeTo('block')">
      <div class="overlay rounded-xl"></div>
      <div class="flex flex-col">
        <div class="flex flex-row justify-center">
          <img class="img-w-h" src="assets/img/admin/coding.svg">  
        </div>
        <div class="flex flex-row justify-center p-2 h-12 text-greenish-blue bg-text">
          Block Design
        </div>
      </div>
    </div>
    <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" (click)="routeTo('python-direct')">
      <div class="overlay rounded-xl"></div>
      <div class="flex flex-col">
        <div class="flex flex-row justify-center">
          <img  class="img-w-h" src="assets/img/admin/Python.svg">  
        </div>
        <div class="flex flex-row justify-center p-2 h-12 text-greenish-blue bg-text">
          Python Direct
        </div>
      </div>      
    </div>
  </div>

</div>