export const blockControls = [
  { display: 'Reference Section', name: 'Reference Section' },
  { display: 'Work Area', name: 'Work Area' },
];

export const cicuitControls = [
  { display: 'Components Bar', name: 'Components Bar' },
  { display: 'Reference Section', name: 'Reference Section' },
  { display: 'Work Area', name: 'Work Area' },
];

export const pageTypes = [
  { display: 'Circuit Designer' },
  { display: 'Block Designer' },
];

export const positions: any = [
  // {display:'Select Position',key:''},
  { display: 'Top', key: 'top' },
  { display: 'Bottom', key: 'bottom' },
  { display: 'Right', key: 'Right' },
  { display: 'Left', key: 'left' },
];

export const modules: any = [
  { display: 'Internet of Things', key: 'iot' },
  { display: 'Robotics', key: 'robotics' },
  { display: 'Data Science', key: 'data_science' },
  { display: 'AIoT', key: 'aiot' },
  { display: 'Artificial Intelligence', key: 'artificial_intelligence' },
  { display: 'IIoT', key: 'iiot' },
];

export const simulator_types: any = [
  { key: 'forward', display: 'Forward' },
  { key: 'backward', display: 'Backward' },
  { key: 'left', display: 'Left' },
  { key: 'right', display: 'Right' },
  { key: 'swing-left', display: 'Swing Left' },
  { key: 'circle', display: 'Gradual Left' },
  { key: 'square', display: 'Square' },
  { key: 'zigzag', display: 'Zig-Zag' },
  { key: 'none', display: 'None' },
];
export const controller_types: any = [
  { display: 'Directions-Record Path & Play', key: 'directions-record_path_&_play'},
  { display: 'Industrial Robotic Arm 1.0', key: 'industrial_robotic_arm'},
  { display: 'Directions', key: 'directions' },
  { display: 'Switch', key: 'switch' },
  { display: "New Directions", key: "new_directions" },
  { display: 'Smart Walker Controller 1.0', key: 'smart_walker_controller_1.0'},
];

export const tooltipGuidedTour = `The guided tour can be used to help guide students on how to use the components on the circuit or block diagram on this template. It will appear when the student clicks the information icon on the circuit or block diagram`;
