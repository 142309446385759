import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/services/auth.service';
import { LoaderService } from './shared/services/loader.service';
import { UserSessionService } from './shared/services/user.session.service';
import { setToken } from './store/auth/auth.action';
import { IAppState } from './store/index.state';
import { showLayout } from './store/layout/layout.action';
import { environment } from "../../src/environments/environment";
import { SessionStorageService } from './shared/services/session-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  token: any = '81296de5-591d-4fb9-ab75-1a3ac15aab86';
  currentRoute: any;
  showNavbar: boolean = false;
  showNav: boolean = true;
  showLayoutComponents$!: Observable<boolean>

  loading$ = this.loaderService.loading$;

  constructor(
    private store: Store<IAppState>,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit() {
    this.showLayoutComponents$ = this.store.select((state: IAppState) => state.layout.showLayout);
    this.setTokenAfterRefresh();
    this.setToken();
    this.checkPath();
  }

  setTokenAfterRefresh() {
    const token: any = sessionStorage.getItem('token');
    if (token) {
      this.store.dispatch(setToken({ token: token }));
      this.store.dispatch(showLayout({ value: true }));
    }
  }

  async checkPath() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((ev: any) => {
      if (ev.url.includes('/app')) {
        this.showNavbar = false;
      } else {
        this.showNavbar = true;
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((ev: any) => {
      if (ev.url.includes('?target=') && ev.id == 1) {
        let url = "lms?" + ev.url.split('?')[1];;
        this.router.navigateByUrl(url);
      }
    });

    this.route.queryParamMap.subscribe(async (data: any) => {
      const app_context = data.get('app_context');
      const app_type = data.get('type');
      const hash = data.get('hash');
      const module_level = data.get('module_level');
      const ts = data.get('ts');
      const affiliate_name = data.get('af') || environment.organizationID;
      const app_id = data.get('app_id');
      const user = JSON.stringify({ username: data.get('uname') });
      if (app_context && hash && ts && user && affiliate_name) {
        sessionStorage.setItem('hash', hash);
        sessionStorage.setItem('module_level', module_level);
        sessionStorage.setItem('ts', ts);
        sessionStorage.setItem('user', user);
        sessionStorage.setItem('app_context', app_context);
        sessionStorage.setItem('affiliate_name', affiliate_name);
        //logic for user verification
        let isValidUser = await this.checkValidUser(data.get('uname'))
        if (isValidUser.message == "Not Valid User") {
          const source = encodeURIComponent(window.location.pathname + window.location.search);
          this.router.navigateByUrl('/ide/credentials-warning?source=' + source);
        }
        else {
          if (app_id) {
            sessionStorage.setItem('app_id', app_id);
          }

          if (app_context) {
            if (app_context === 'show_Config' || app_context === 'show_Circuit') {
              if (app_type === "application") {
                this.router.navigateByUrl('/ide/application/circuit');
              } else {
                this.router.navigateByUrl('/ide/template/circuit');
              }
            }
            else if (app_context === 'show_Block') {
              if (app_type === "application") {
                this.router.navigateByUrl('/ide/application/block');
              } else {
                this.router.navigateByUrl('/ide/template/block');
              }
            } else if (app_context === 'show_Token') {
              this.router.navigateByUrl('/ide/connect-device');
            } else if (app_context === 'show_Files_DS') {
              this.router.navigateByUrl('/ide/files/data_science');
            } else if (app_context === 'show_Files_AI') {
              this.router.navigateByUrl('/ide/files/artificial_intelligence');
            } else if (app_context === 'show_Config_Direct') {
              this.router.navigateByUrl('/ide/circuit-direct');
            } else if (app_context === 'show_Block_Direct') {
              this.router.navigateByUrl('/ide/block-direct');
            } else if (app_context === 'show_Block_Direct_iot') {
              this.router.navigateByUrl('/ide/block-direct-iot');
            } else if (app_context === 'show_Block_Direct_robotics') {
              this.router.navigateByUrl('/ide/block-direct-robotics');
            } else if (app_context === 'show_Block_Direct_ds') {
              this.router.navigateByUrl('/ide/block-direct-ds');
            } else if (app_context === 'show_Block_Direct_aiot') {
              this.router.navigateByUrl('/ide/block-direct-aiot');
            } else if (app_context === 'show_Block_Direct_ai') {
              this.router.navigateByUrl('/ide/block-direct-ai');
            } else if (app_context === 'show_Block_Direct_iiot') {
              this.router.navigateByUrl('/ide/block-direct-iiot');
            } else if (app_context === 'show_python_direct') {
              this.router.navigateByUrl('/ide/python-direct');
            } else if (app_context === 'show_mobile') {
              this.router.navigateByUrl('/ide/mobile-app');
            } else if (app_context === 'show_applications') {
              this.router.navigateByUrl('/ide/applications');
            } else if (app_context === 'show_applications_iot') {
              this.router.navigateByUrl('/ide/applications?moduleName=iot');
            } else if (app_context === 'show_applications_robotics') {
              this.router.navigateByUrl('/ide/applications?moduleName=robotics');
            } else if (app_context === 'show_applications_ds') {
              this.router.navigateByUrl('/ide/applications?moduleName=data_science');
            } else if (app_context === 'show_applications_aiot') {
              this.router.navigateByUrl('/ide/applications?moduleName=aiot');
            } else if (app_context === 'show_applications_ai') {
              this.router.navigateByUrl('/ide/applications?moduleName=artificial_intelligence');
            } else if (app_context === 'show_applications_iiot') {
              this.router.navigateByUrl('/ide/applications?moduleName=iiot');
            } else if (app_context === 'new_application') {
              this.router.navigateByUrl('/ide/application/create');
            } else if (app_context === 'show_dashboard') {
              this.router.navigateByUrl('/ide');
            }
            else if (app_context === 'new_application_iot') {
              this.router.navigateByUrl('/ide/application/create?moduleName=iot');
            }
            else if (app_context === 'new_application_ds') {
              this.router.navigateByUrl('/ide/application/create?moduleName=ds');
            }
            else if (app_context === 'new_application_robotics') {
              this.router.navigateByUrl('/ide/application/create?moduleName=robotics');
            } else if (app_context === 'new_application_aiot') {
              this.router.navigateByUrl('/ide/application/create?moduleName=aiot');
            } else if (app_context === 'new_application_ai') {
              this.router.navigateByUrl('/ide/application/create?moduleName=ai');
            } else if (app_context === 'new_application_iiot') {
              this.router.navigateByUrl('/ide/application/create?moduleName=iiot');
            } else if (app_context === 'show_simulator') {
              this.router.navigateByUrl('/ide/simulator-app');
            } else if (app_context === 'user_session') {
              this.router.navigateByUrl('/ide/user-section');
            } else if (app_context === 'device_session') {
              this.router.navigateByUrl('/ide/device-session');
            } else if (app_context === 'device_management') {
              this.router.navigateByUrl('/ide/devices');
            } else if (app_context === 'lab_management') {
              this.router.navigateByUrl('/ide/labs');
            }
          }
        }
      }
    });
  }

  setToken() {
    this.authService.setToken(this.token)
  }

  async checkValidUser(username: string) {
    let currentOrganization = this.sessionStorageService.getCurrentOrganization();
    let validUser = await this.userSessionService.verifyUserLogin(username, currentOrganization).toPromise();
    if (validUser && validUser?.sessionId) {
      localStorage.setItem("sessionId", validUser?.sessionId);
    }
    return validUser;
  }
}
