import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface CircuitJSON {
  targets: any[];
  wires: any[];
}

interface TargetMeta {
  sensorType: string;
  count: number;
}

interface WireMeta {
  wireId: string;
  fromSensorId: string,
  toSensorId: string,
  fromSensorType: string;
  toSensorType: string;
  fromPin: string;
  toPin: string;
}

@Injectable({
  providedIn: 'root'
})
export class CircuitComparisonService {
  public circuitMismatchesSubject = new Subject<string[]>();
  public referenceCircuit: CircuitJSON = {
    targets: [],
    wires: []
  };
  public workareaCircuit: CircuitJSON = {
    targets: [],
    wires: []
  };

  constructor() { }

  getPinNameFromPinId(pinId: string): string {
    let pinDetails = pinId?.split('-');
    return pinDetails?.[0];
  }

  private parseReferenceCircuit(): { referenceTargets: TargetMeta[], referenceWires: WireMeta[]} {
    let referenceTargets: TargetMeta[] = [];
    let referenceWires: WireMeta[] = [];
    let targetDict: any = {};
    let targetNames: any = {};

    this.referenceCircuit.targets.forEach((item) => {
      let matched: TargetMeta = referenceTargets.find((x) => x.sensorType == item.name) as TargetMeta;
      if (!matched) {
        let target: TargetMeta = {
          sensorType: item.name,
          count: 1
        }
        referenceTargets.push(target);
        targetNames[item.t_id] = item.name;
      } else {
        matched.count++;
        targetNames[item.t_id] = `${item.name}-${matched.count}`;
        let index = referenceTargets.findIndex((x) => x.sensorType == item.name);
        referenceTargets.push(matched);
        referenceTargets.splice(index, 1);
      }
      targetDict[item.t_id] = item.name;
    });
    this.referenceCircuit.wires.forEach((item) => {
      let wire: WireMeta = {
        wireId: item.w_id,
        fromSensorId: targetNames[item.start.t_id],
        toSensorId: targetNames[item.end.t_id],
        fromSensorType: targetDict[item.start.t_id],
        toSensorType: targetDict[item.end.t_id],
        fromPin: this.getPinNameFromPinId(item.start.pinId),
        toPin: this.getPinNameFromPinId(item.end.pinId)
      }
      referenceWires.push(wire);
    });
    return { referenceTargets, referenceWires };
  }

  private parseWorkareaCircuit(): { workTargets: TargetMeta[], workWires: WireMeta[]}  {
    let workTargets: TargetMeta[] = [];
    let workWires: WireMeta[] = [];
    let targetDict: any = {};
    let targetNames: any = {};

    this.workareaCircuit.targets.forEach((item) => {
      let matched: TargetMeta = workTargets.find((x) => x.sensorType == item.name) as TargetMeta;
      if (!matched) {
        let target: TargetMeta = {
          sensorType: item.name,
          count: 1
        }
        workTargets.push(target);
        targetNames[item.t_id] = item.name;
      } else {
        matched.count++;
        targetNames[item.t_id] = `${item.name}-${matched.count}`;
        let index = workTargets.findIndex((x) => x.sensorType == item.name);
        workTargets.splice(index, 1);
        workTargets.push(matched);
      }
      targetDict[item.t_id] = item.name;
    });
    this.workareaCircuit.wires.forEach((item) => {
      let wire: WireMeta = {
        wireId: item.w_id,
        fromSensorId: targetNames[item.start.t_id],
        toSensorId: targetNames[item.end.t_id],
        fromSensorType: targetDict[item.start.t_id],
        toSensorType: targetDict[item.end.t_id],
        fromPin: this.getPinNameFromPinId(item.start.pinId),
        toPin: this.getPinNameFromPinId(item.end.pinId)
      }
      workWires.push(wire);
    });
    return { workTargets, workWires };
  }

  public compareCircuits() {
    let circuitMismatches: string[] = [];
    let targetMismatches: string[] = [];
    let wireMismatches: string[] = [];
    let sensorsAccountedFor: string[] = [];
    let wiresAccountedFor: string[] = [];
    let extraSensors: string[] = [];

    const { referenceTargets, referenceWires } = this.parseReferenceCircuit();
    const { workTargets, workWires }  = this.parseWorkareaCircuit();

    //log missing sensors from reference targets
    referenceTargets.forEach((item) => {
      let workDevice = workTargets.find(x => item.sensorType == x.sensorType) as TargetMeta;
      if (workDevice) {
        if (workDevice.count > item.count) {
          targetMismatches.push(`${workDevice.sensorType} is extra`);
        } else if (workDevice.count < item.count) {
          targetMismatches.push(`${workDevice.sensorType} is missing`);
        }
        sensorsAccountedFor.push(workDevice.sensorType);
      } else {
        targetMismatches.push(`${item.sensorType} is missing`);
        sensorsAccountedFor.push(item.sensorType);
      }
    });

    //log extra sensors from work targets
    workTargets.forEach((item) => {
      if (!sensorsAccountedFor.includes(item.sensorType)) {
        targetMismatches.push(`${item.sensorType} is extra`);
        extraSensors.push(item.sensorType);
      }
    })

    //log extra connections from extra sensors
    workWires.forEach((item) => {
      if (wiresAccountedFor.includes(item.wireId)) return;
      if (extraSensors.includes(item.fromSensorType) || extraSensors.includes(item.toSensorType)) {
        wiresAccountedFor.push(item.wireId);
        wireMismatches.push(`${item.fromSensorType} ${item.fromPin} pin to ${item.toSensorType} ${item.toPin} pin connection is extra`);
      }
    });
    
    //log missing connections and mismatched connections 
    referenceWires.forEach((referenceWire) => {
      if (!wiresAccountedFor.includes(referenceWire.wireId)) {
        let matchedFromWorkWires = workWires.filter((workWire) => {
          if (workWire.fromSensorId == referenceWire.fromSensorId) return workWire; else return null;
        })

        let matchedToWorkWires = workWires.filter((workWire) => {
          if (workWire.fromSensorId == referenceWire.toSensorId) return workWire; else return null;
        })

        let filteredFromWorkWires = matchedFromWorkWires.filter((workWire) => {
          if (!(wiresAccountedFor.includes(workWire.wireId)) 
            && (referenceWire.fromSensorType == workWire.fromSensorType) 
            && (referenceWire.toSensorType == workWire.toSensorType) 
            && (referenceWire.fromPin == workWire.fromPin) 
            && (referenceWire.toPin == workWire.toPin))
              return workWire;
          else return null;
        });
        let filteredToWorkWires = matchedToWorkWires.filter((workWire) => {
          if (!(wiresAccountedFor.includes(workWire.wireId)) 
            && (referenceWire.fromSensorType == workWire.toSensorType) 
            && (referenceWire.toSensorType == workWire.fromSensorType) 
            && (referenceWire.fromPin == workWire.toPin) 
            && (referenceWire.toPin == workWire.fromPin))
              return workWire;
          else return null;
        });

        let filteredWorkWires: WireMeta[] = [];
        filteredWorkWires.push(...filteredFromWorkWires);
        filteredWorkWires.push(...filteredToWorkWires);

        let mismatchedFromWorkWires = matchedFromWorkWires.filter((workWire) => {
          if (!(wiresAccountedFor.includes(workWire.wireId))
            && (referenceWire.fromSensorType == workWire.fromSensorType)
            && (referenceWire.toSensorType == workWire.toSensorType)
            && (referenceWire.fromPin == workWire.fromPin)
            && !(referenceWire.toPin == workWire.toPin))
            return workWire;
          else return null;
        })
        let mismatchedToWorkWires = matchedToWorkWires.filter((workWire) => {
          if (!(wiresAccountedFor.includes(workWire.wireId))
            && (referenceWire.toSensorType == workWire.fromSensorType)
            && (referenceWire.fromSensorType == workWire.toSensorType)
            && !(referenceWire.fromPin == workWire.toPin)
            && (referenceWire.toPin == workWire.fromPin))
            return workWire;
          else return null;
        })
        let mismatchedWorkWires: WireMeta[] = [];
        mismatchedWorkWires.push(...mismatchedFromWorkWires);
        mismatchedWorkWires.push(...mismatchedToWorkWires); 

        if (!filteredWorkWires.length && !mismatchedWorkWires.length) {
          wireMismatches.push(`${referenceWire.fromSensorType} ${referenceWire.fromPin} pin is not connected to 
            ${referenceWire.toSensorType} ${referenceWire.toPin} pin`);
        } else if (mismatchedFromWorkWires.length) {
          mismatchedFromWorkWires.forEach((workWire) => {
            wiresAccountedFor.push(workWire.wireId);
            wireMismatches.push(`${workWire.fromSensorType} ${workWire.fromPin} pin is connected to 
            ${workWire.toSensorType} ${workWire.toPin} pin instead of ${referenceWire.toSensorType} ${referenceWire.toPin}`);
          })
        } else if (mismatchedToWorkWires.length) {
          mismatchedToWorkWires.forEach((workWire) => {
            wiresAccountedFor.push(workWire.wireId);
            wireMismatches.push(`${workWire.fromSensorType} ${workWire.fromPin} pin is connected to 
            ${workWire.toSensorType} ${workWire.toPin} pin instead of ${referenceWire.fromSensorType} ${referenceWire.fromPin}`);
          })
        }

        if (filteredWorkWires.length) {
          filteredWorkWires.forEach((workWire) => {
            wiresAccountedFor.push(workWire.wireId)
          })
        }
      }
    })

    //log extra connections from valid sensors
    workWires.forEach((workWire) => {
      if (!wiresAccountedFor.includes(workWire.wireId)) {
        wireMismatches.push(`${workWire.fromSensorType} ${workWire.fromPin} pin to
          ${workWire.toSensorType} ${workWire.toPin} pin connection is extra`);
      }
    })

    circuitMismatches.push(...targetMismatches);
    circuitMismatches.push(...wireMismatches);
    this.circuitMismatchesSubject.next(circuitMismatches);
  }
}
