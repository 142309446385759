import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApplicationService } from "src/app/shared/services/application.service";
import { WsApplicationService } from "src/app/shared/services/ws-application.service";
import { Subscription } from "rxjs";
import { showLayout } from "src/app/store/layout/layout.action";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";

@Component({
  selector: "app-simulator-dashboard",
  templateUrl: "./simulator-dashboard.component.html",
  styleUrls: ["./simulator-dashboard.component.scss"],
})
export class SimulatorDashboardComponent implements OnInit {
  blockDataCircuitDefinition: any;
  messageFromServer: any;
  wsSubscription!: Subscription;
  audioFile: boolean = false;
  actionableItems: any;
  actionableItemsState: any;

  commandItems: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private applicationService: ApplicationService,
    private wsApplicationService: WsApplicationService,
    private store: Store<IAppState>,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(showLayout({ value: false }));
    let applicationIdentifier = this.activatedRoute.snapshot.params.id;
    await this.getApplication(applicationIdentifier);
    this.initWebSocket(applicationIdentifier);
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  private initWebSocket(appId: string) {
    if (appId) {
      this.wsSubscription = this.wsApplicationService
        .createObservableCircuit(appId)
        .subscribe((data: any) => {
          let parsedData = JSON.parse(data);
          if (!parsedData) return;

          const state = parsedData?.state;

          // const actionableItems = parsedData?.actionableItems;
          // if (actionableItems) {
          //   actionableItems.forEach((item: any) => {
          //     if (item) {
          //       this.commandItems.unshift({
          //         ...item,
          //         state: state,
          //         time: new Date(),
          //       });
          //     }
          //   });
          // }

          this.updateCircuitDefinition(parsedData);
        });
    }
  }

  async getApplication(applicationIdentifier: string) {
    try {
      const template: any = await this.applicationService
        .getApplicationTemp(applicationIdentifier)
        .toPromise();
      if (template) {
        const application = template.Application;
        let blockData = JSON.parse(application.configurationDefinition);
        this.blockDataCircuitDefinition = blockData?.circuitDefinition;
        this.makeDraggableFalse();
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  makeDraggableFalse() {
    this.blockDataCircuitDefinition["attrs"]["draggable"] = false;
    let blockedChildren = this.blockDataCircuitDefinition.children[0]?.children;
    let changedBlockedData = blockedChildren.map((eachChild: any) => {
      if (
        eachChild.children !== undefined &&
        eachChild.children[0]?.className === "Image"
      ) {
        eachChild.children[0]["attrs"]["draggable"] = false;
      }
      return eachChild;
    });
    this.blockDataCircuitDefinition.children[0].children = changedBlockedData;
  }

  updateCircuitDefinition(updatedData: any) {
    const outputItems = updatedData.find((ele:any) => ele.ioType === "OUTPUT");
    this.actionableItems = outputItems?.actionableItems;
    this.actionableItemsState = outputItems?.state;
    // toggle output-item actions
    if (this.actionableItems) {
      this.actionableItems.forEach((eachItem: any) => {
        switch (eachItem.type) {
          case "audio":
            this.audioFile = this.actionableItemsState
              ? eachItem.meta.active
              : "";
            break;
          case "blink":
            this.toggleSensor(eachItem);
            break;
        }
      });
    }

    let changedBlockedDefinition = this.blockDataCircuitDefinition;
    this.blockDataCircuitDefinition = JSON.parse(
      JSON.stringify(changedBlockedDefinition)
    );
  }

  private toggleSensor(data: any) {
    let blockedChildren = this.blockDataCircuitDefinition.children[0]?.children;
    const global = this;
    let changedBlockedData = blockedChildren.map(
      (eachChild: any, index: number) => {
        const activeSensor = data.meta.active;
        const inActiveSensor = data.meta.inActive;
        if (
          eachChild.children !== undefined &&
          eachChild.children[0]?.className === "Image"
        ) {
          var imageSource = eachChild.children[0].attrs?.imageSrc;
          var isSensor =
            imageSource.includes(inActiveSensor) ||
            imageSource.includes(activeSensor)
              ? true
              : false;
          if (isSensor) {
            let replaceImage = !global.actionableItemsState
              ? imageSource.replace(activeSensor, inActiveSensor)
              : imageSource.replace(inActiveSensor, activeSensor);
            eachChild.children[0].attrs.imageSrc = replaceImage;
          }
        }

        return eachChild;
      }
    );
    blockedChildren = changedBlockedData;
  }
}
