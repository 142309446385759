import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { element } from 'protractor';
import { SensorService } from "src/app/shared/services/sensor.service";
import { SessionStorageService } from '../../services/session-storage.service';
import { UserSessionService } from '../../services/user.session.service';
@Component({
  selector: 'iot-devices-section',
  templateUrl: './iot-devices-section.html',
  styleUrls: ['./iot-devices-section.scss'],
})
export class IotDevicesSection implements OnInit {
  @Input() sensors: any;
  @Input() sensors_id!: any;
  @Input() isSelecting: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() applicationData: any;//to get module name for filter
  @Output() emitDraggedElement = new EventEmitter();
  @Output() emitCollapseEvent = new EventEmitter();
  @Output() emitSensorSelect = new EventEmitter();
  @Output() emitSelectedNavMenu = new EventEmitter();

  selectedNavMenu!: any;
  tempSelectedNavMenu!: any;
  selectedSensors: any = [];
  filteredSensors: any = [];
  draggedElement!: any;
  isMenuOpen: boolean = true;
  searchTerm: string = "";
  insideForm: boolean = window.location.href.includes('/application/');

  constructor(
    private sensorService: SensorService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) {
  }

  async ngOnInit() {
    if (!this.sensors) {
      this.filterSelectedSensors();
    }
    if (this.sensors != undefined && this.sensors.length === 0) {
      this.filterSelectedSensors();
    }
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  private async filterSelectedSensors() {
    let filteredSensorsByOwner: any = [];
    let allSensors: any = await this.sensorService.getAllSensors().toPromise();
    if (this.sensors_id !== null && this.sensors_id !== undefined && this.sensors_id !== '' && this.sensors_id.length > 0) {
      allSensors.sensors?.forEach((category: any) => {
        category.components = category.components?.filter((sensor: any) => this.sensors_id.includes(sensor.id));
        filteredSensorsByOwner.push(category);
      });
    }
    if (filteredSensorsByOwner.length > 0 && this.sensors_id.length > 0) {
      this.sensors = filteredSensorsByOwner;
    } else {
      this.sensors = allSensors.sensors;
    }
    // this.selectedSensors = allSensors.sensors;
    this.selectedNavMenu = this.sensors[0];
    this.filteredSensors = this.selectedNavMenu?.components || [];

  }

  private preSelectAllCategories(): void {
    this.sensors.forEach((navItem: any) => {
      this.selectNavMenu(navItem);
    });
  }

  // selectNavMenu(item: any): void {
  //   if (this.applicationData) {
  //     const newItems = item.components.filter((item: any) => {
  //       let moduleName = this.applicationData?.moduleName ? this.applicationData?.moduleName : this.applicationData?.key;
  //       if (item.moduleType?.includes(moduleName)) {
  //         return item
  //       }
  //     });
  //     item.components = newItems;
  //   }
  //   this.selectedNavMenu = item;
  //   this.filteredSensors = [...item.components];
  //   this.emitSelectedNavMenu.emit(this.selectedNavMenu);
  // }

  selectNavMenu(item: any): void {
    if (this.selectedNavMenu !== item) {
      this.selectedNavMenu = item;
      this.filteredSensors = [...item.components];
      this.emitSelectedNavMenu.emit(this.selectedNavMenu);
    }
  }

  // selectNavMenu(item: any): void {
  //   if (this.selectedNavMenu !== item) {
  //     this.selectedNavMenu = item;
  //     this.filteredSensors = [...item.components];
  //     this.emitSelectedNavMenu.emit(this.selectedNavMenu);
  //   }
  // }

  onDrag(event: any, item: any): void {
    event.preventDefault();
    this.tempSelectedNavMenu = this.selectedNavMenu;
    this.selectedNavMenu = null;
    let newElement = event.target.currentSrc;
    let newElementReference = new Array();
    newElementReference.push({ newElement: newElement, item });
    this.draggedElement = newElementReference;
    this.emitDraggedElement.emit(this.draggedElement);
    this.isMenuOpen = !this.isMenuOpen;
  }
  toggleMenu(): void {
    this.selectedNavMenu = this.tempSelectedNavMenu;
    this.isMenuOpen = true;
    this.emitCollapseEvent.emit(this.isMenuOpen);
  }

  toggleLeave(): void {
    this.selectedNavMenu = this.tempSelectedNavMenu;
    this.isMenuOpen = false;
    this.emitCollapseEvent.emit(this.isMenuOpen);
  }

  onScreenClick(): void {
    this.isMenuOpen ? null : this.toggleMenu();
  }


  async searchFunc(term: any) {
    // const allSensors = this.tempSensor;
    let allSensorsData: any = await this.sensorService.getAllSensors().toPromise();
    const allSensors = allSensorsData && allSensorsData.sensors;
    let sensors: any = [];
    let categories: any = [];
    let menuItem = 0;
    let flag = false;
    let main: any = [];
    if (term === '' || term === undefined || term === null) {
      this.sensors = this.selectedSensors;
      return;
    }
    allSensors.forEach((category: any, index: any) => {
      categories = category;
      category?.components.forEach((sensor: any) => {
        if (sensor.title?.toLowerCase().includes(term)) {
          flag = true;
          sensors.push(sensor)
        }
      });
      categories.components = sensors;
      sensors = [];
      if (flag) {
        menuItem = index
      }
      main.push(categories)
    });
    this.sensors = main;
  }
}
