interface CurrentApplication {
  applicationData: any;
  application_identifier: string;
}

export interface IApplicationState {
  application: any[];
  applicationTemplate: any[];
  currentPage: number;
  currentPageSize: number;
  total: number;
}

export const initialApplication: IApplicationState = {
  application: [],
  applicationTemplate: [],
  currentPage: 1,
  currentPageSize: 10,
  total: 0
}
