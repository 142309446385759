import { ActionReducerMap } from "@ngrx/store";
import { authReducers } from "./auth/auth.reducer";
import { IAppState } from "./index.state";
import { layoutReducer } from "./layout/layout.reducer";
import { circuitDesignReducer } from "../store/circuit-design/c-d.reducer";
import { applicationReducer } from "../admin/container/application/store/application.reducers";

export const appReducers: ActionReducerMap<IAppState, any> = {
  application: applicationReducer,
  auth: authReducers,
  layout: layoutReducer,
  circuitDesign: circuitDesignReducer,
};
