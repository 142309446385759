import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ISensor } from "../models/sensor";
import { ISearch } from "../models/inteface";

/**
 * Service class to handle data and http request related to Sensors APIs.
 */
@Injectable({
  providedIn: "root",
})
export class SensorService {
  constructor(private http: HttpClient) {}

  /**
   * Method to create a new sensor.
   * @param sensor
   */
  create(sensor: ISensor) {
    const url = `${environment.apiURL}/admin/sensor/create`;
    return this.http.post<ISensor>(url, sensor);
  }

  /**
   * Method to get data of a sensor.
   * @param sensorIdentifier
   */
  get(sensorIdentifier: string) {
    const url = `${environment.apiURL}/admin/sensor/get?sensor_identifier=${sensorIdentifier}`;
    return this.http.get<ISensor>(url);
  }

  /**
   * Method to get data of all the sensors available.
   */
  getAllSensors(){
    const url = `${environment.apiURL}/admin/sensor/get-sensors-list`;
    return this.http.get(url)
  }

  /**
   * Method to update data of a sensor.
   * @param sensorIdentifier
   * @param updates
   */
  update(sensorIdentifier: string, updates?: any) {
    const url = `${environment.apiURL}/admin/sensor/update?sensor_identifier=${sensorIdentifier}`;
    return this.http.put<ISensor>(url,  updates );
  }

  updateV2(sensorIdentifier: string, updates?: any) {
    const url = `${environment.apiURL}/admin/sensor/v2/update?sensor_identifier=${sensorIdentifier}`;
    return this.http.put<ISensor>(url,  updates );
  }
  /**
   * Method to search a sensor.
   * @param payload
   */
  search(payload: ISearch) {
    const url = `${environment.apiURL}/admin/sensor/search`;
    return this.http.post<{}>(url, payload);
  }

  /**
   * Method to delete a sensor.
   * @param sensorID
   */
  deleteSensor(sensorId:string){
    const url = `${environment.apiURL}/admin/sensor/delete?sensor_identifier=${sensorId}`;
    return this.http.delete(url);
  }

  /**
   * Method to get block script for a sensor.
   * @param sensorIdentifier
   */
  getBlockScripts(sensorIdentifier: string[]) {
    const url = `${environment.apiURL}/admin/sensor/get-block-script`;
    return this.http.post<{}>(url, sensorIdentifier);
  }

  getAllLocalSensors() {
    let ts = Date.now();
    return this.http.get<{}>(`/assets/json/sensor-list-v2.json?ts=${ts}`);
  }
}
