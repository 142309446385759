import { createSelector, createFeatureSelector } from '@ngrx/store';

export const APPLICATION_FEATURE = 'application'
export const getApplicationSelector = createFeatureSelector(APPLICATION_FEATURE);

export const getApplications = createSelector(getApplicationSelector,(state: any) => state.application);
export const getApplicationTemplates = createSelector(getApplicationSelector,(state: any) => state.applicationTemplate);
export const getTotal = createSelector(getApplicationSelector,(state: any) => state.total);
export const getPageNumber = createSelector(getApplicationSelector,(state: any) => state.currentPage);
export const getPageSize = createSelector(getApplicationSelector,(state: any) => state.currentPageSize);


export const getFilteredApplicationItems = createSelector(getApplicationSelector,(state: any) => state.application.slice(0, state.currentPageSize));
