import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDEService } from 'src/app/shared/services/ide.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connect-device',
  templateUrl: './download-files.component.html',
  styleUrls: ['./download-files.component.scss']
})
export class DownloadFilesComponent implements OnInit {

  module_name!: string;
  files: any;

  constructor(
    private route: ActivatedRoute,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    this.module_name = String(routeParams.get('module_name'));
    this.files = [{
      name: 'school_to_home.xlsx',
      title: 'Time to reach from School to Home'
    }, {
      name: '4countriesData.csv',
      title: 'Corona'
    }, {
      name: 'retail.csv',
      title: 'Retail'
    }, {
      name: 'Basel-temp.txt',
      title: 'Temperature'
    }, {
      name: 'cholesterolData.csv',
      title: 'Cholesterol'
    }, {
      name: 'Rock.csv',
      title: 'Rock'
    }, {
      name: 'SpeedDistance.csv',
      title: 'Brand Cars'
    }];
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

}
