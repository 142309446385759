import { ApplicationCreatorComponent } from './container/application-creator/application-creator.component';
import { ApplicationComponent } from './container/application/application.component';
import { BlockProgrammingComponent } from './container/block-programming/block-programming.component';
import { CircuitDesignComponent } from './container/circuit-design/circuit-design.component';
import { DashboardComponent } from './container/dashboard/dashboard.component';
import { DeviceComponent } from './container/device/device.component';
import { AddDeviceLabComponent } from './container/device/add-device-lab/add-device-lab.component';
import { SensorsListComponent } from './container/sensors-list/sensors-list.component';
import { CreateNewSensorComponent } from './container/sensors-list/create-new-sensor/create-new-sensor.component';
import { EditSensorComponent } from './container/sensors-list/edit-sensor/edit-sensor.component';
import { ConnectDeviceComponent } from '../ide/containers/connect-device/connect-device.component';
import { UsersSessionComponent } from './container/users-session/users-session.component';
import { LabsListComponent } from './container/labs-list/labs-list.component';
import { CreateLabComponent } from './container/labs-list/create-lab/create-lab.component';
import { EditLabComponent } from './container/labs-list/edit-lab/edit-lab.component';
import { EditDeviceComponent } from './container/device/components/user-device-add/edit-device.component';
import { EditUserDeviceComponent } from './container/device/components/edit-user-device/edit-user-device.component';
import { PythonDirectComponent } from './container/python-direct/python-direct.component';

export const routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'applications', component: ApplicationComponent },
  { path: 'template/create', component: ApplicationCreatorComponent },
  { path: 'application/create', component: ApplicationCreatorComponent },
  { path: 'users-session', component: UsersSessionComponent },
  {
    path: 'application/edit/:application_identifier',
    component: ApplicationCreatorComponent,
  },
  {
    path: 'template/edit/:application_identifier',
    component: ApplicationCreatorComponent,
  },
  { path: 'devices', component: DeviceComponent },
  { path: 'device/lab/add', component: AddDeviceLabComponent },
  { path: 'circuit', component: CircuitDesignComponent },
  { path: 'block', component: BlockProgrammingComponent },
  { path: 'sensors', component: SensorsListComponent },
  { path: 'create-sensor', component: CreateNewSensorComponent },
  { path: 'edit-sensor/:sensorid', component: EditSensorComponent },
  { path: 'connect-device', component: ConnectDeviceComponent },
  { path: 'labs', component: LabsListComponent },
  { path: 'create-lab', component: CreateLabComponent },
  { path: 'edit-lab/:labid', component: EditLabComponent },
  { path: 'device/lab/edit/:device_token', component: AddDeviceLabComponent },
  { path: 'device/user/add', component: EditDeviceComponent },
  { path: 'device/user/edit/:device_token', component: EditUserDeviceComponent },
  { path: 'python-direct', component: PythonDirectComponent }
];
