import { Component, OnInit } from '@angular/core';
import { ISensor } from 'src/app/shared/models/sensor';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/index.state';
import { sidePanelSelector } from 'src/app/store/layout/layout.selector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sensors-list',
  templateUrl: './sensors-list.component.html',
  styleUrls: ['./sensors-list.component.scss'],
})
export class SensorsListComponent implements OnInit {

  sensorListData!: Array<any>;
  filteredSenorData!:Array<any>;
  isDeleteClicked = false;
  deleteSensorData:any;
  isMenuOpen: boolean = false;
  isAllModule:boolean=false;
  isIotModule:boolean=false;
  isRoboticsModule:boolean=false;
  isArtificialIntelligenceModule:boolean=false;
  isAIoTModule:boolean=false;
  isIIoTModule:boolean=false;

  constructor(
    private sensorService: SensorService,
    private store: Store<IAppState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllSensorData();
  }

  getAllSensorData(){
    this.sensorService.getAllSensors().subscribe((data: any) => {
      this.sensorListData = data['sensors'];
      this.filteredSenorData= JSON.parse(JSON.stringify(this.sensorListData));
    });
  }

  createNewSensor(){
    this.router.navigateByUrl('/create-sensor')
  }

  toDateTime(dateTime: string) {
    let dateObj = new Date(dateTime).toString().split("GMT")[0];
    return dateObj;
  }

  editSensor(sensor: any) {
    this.router.navigateByUrl(`edit-sensor/${sensor.id}`);
  }

  confirmAndDelete() {
    //alert('deleted '+this.deleteSensorData.id);
    this.sensorService.deleteSensor(this.deleteSensorData.id)
    .subscribe(data=>{
      // console.log('deleted sensor:' + data);
    });
    this.isDeleteClicked = false;
  }

  cancelDelete() {
    this.isDeleteClicked = false;
  }

  deleteClicked(sensor:any){
    this.isDeleteClicked = true;
    this.deleteSensorData = sensor;
  }

  delete() {}

  sidePanelClosed(event: any) {}

  toggleMenu(){
    this.isMenuOpen = !this.isMenuOpen;
  }

  /**Filter Sensor list by Module Name */
  onSelectModule(moduleName: string) {
    this.sensorListData.forEach((sensor, index) => {
      this.filteredSenorData[index].components = moduleName === 'all' 
      ? sensor.components 
      : sensor.components.filter((data: any) => data.moduleType.includes(moduleName));
    });
    this.toggleMenu();
  }
}
