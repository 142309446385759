import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddDeviceLabComponent } from './add-device-lab.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [AddDeviceLabComponent],
  imports: [CommonModule, FormsModule,NgSelectModule,ReactiveFormsModule],
  exports: [AddDeviceLabComponent],
})
export class AddDeviceLabModule {}
