import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { ApplicationService } from "src/app/shared/services/application.service";
import { environment } from "src/environments/environment";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ToastrService } from "ngx-toastr";
import { IDEService } from "src/app/shared/services/ide.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { initialSimulatorDefinition, simulatorDefinition } from "src/app/shared/models/configDef";
import { blockControls, cicuitControls, controller_types, modules, pageTypes, positions, simulator_types } from "src/app/shared/constants/create-basic";


@Component({
  selector: "app-create-basic-app",
  templateUrl: "./create-basic-app.component.html",
  styleUrls: ["./create-basic-app.component.scss"],
})
export class CreateBasicAppComponent implements OnInit, OnChanges {
  @Input() isAdmin: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() selectedApplication: any;
  @Input() isTemplate: boolean = false;
  @Input() isSaveAndExitInitiated: boolean = false;
  @Output() emitApplicationID = new EventEmitter();
  @Output() onExitPressed = new EventEmitter();
  @Output() onUnsavedChanges = new EventEmitter();
  @Output() emitModuleKey = new EventEmitter();
  @Output() onSavePressed = new EventEmitter();
  @Output() emitAppData = new EventEmitter();

  applicationIdentifier = sessionStorage.getItem('appId') || '';
  applicationName!: string;
  applicationNameIsValid: boolean = true;
  applicationDesc!: string;
  controller_type!: string;
  simulator_type!: string;
  visualization_panel: boolean = true;
  mobile_console: boolean = false;
  problemTryingToSolve!: string;
  problemTryingToSolveIsValid: boolean = true
  industryDesignedFor!: any;
  industryDesignedForIsValid: boolean = true;
  applicationCreationProcedure!: string;
  applicationCreationProcedureIsValid: boolean = true;
  basicForm: any;
  AddControlFormArray!: FormGroup;
  showControl: boolean = true;
  appData: any;
  isValue: boolean = false;
  programmingType!: string;

  showMenu: boolean = false;
  showIndustry: boolean = false;

  simulator_types = simulator_types;
  controller_types = controller_types;
  modules = modules;
  positions = positions;
  pageTypes = pageTypes;
  cicuitControls = cicuitControls;
  blockControls = blockControls;
  tooltipGuidedTour = `The guided tour can be used to help guide students on how to use the components on the circuit or block diagram on this template. It will appear when the student clicks the information icon on the circuit or block diagram`;

  isBlock: boolean = false;
  listOfIndustries: any[] = [];
  selectedModule!: any;
  tutorial_url: TutorialVideoUrl = {
    default: "",
    circuit: "",
    block: "",
    simulator: ""
  }
  tourControlsData: any[] = []
  ControlFormGroup!: FormGroup;
  controlsType: any[] = [];
  isAddDisabled: boolean = false;
  isInformation: boolean = false;
  isDeleteFirstCtrl: boolean = false;
  isDefaultSelect: boolean = true;
  isFromGcc: boolean = false;

  errorMessage: any = {
    validationFailed: "Please fill information in all required fields."
  };

  constructor(
    private applicationTemplateService: ApplicationService,
    private sessionStorageService: SessionStorageService,
    private toasterService: ToastrService,
    private ideService: IDEService,
    private route: ActivatedRoute,
    private userSessionService: UserSessionService,
    private fb: FormBuilder
  ) {
    this.ControlFormGroup = this.fb.group({
      tourControls: this.fb.array([]),
    });
  }

  get tourControls(): FormArray {
    return this.ControlFormGroup.get("tourControls") as FormArray
  }

  newControls(): FormGroup {
    return this.fb.group({
      page: ['', Validators.required],
      controlVal: '',
      position: '',
      displayedText: ['', Validators.required]
    })
  }

  //Add guided tour controls, if all possible combination selected then show error
  addControls(i: number) {
    this.tourControls.push(this.newControls());
  }

  returnValue(flag: boolean) {
    if (flag) {
      return true;
    }
    else {
      return false;
    }
  }

  //Delete guided tour controls
  removeControls(i: number) {
    if (this.tourControls.length > 1) {
      this.tourControls.removeAt(i);
    }
    else if (i === 0) {// reset form control values if only one control remaining and delete clicked 
      this.ControlFormGroup.get("tourControls")?.reset();
      this.tourControls.removeAt(i);
      const group = this.newControls();
      Object.keys(group.controls)?.forEach((key) => {
        group.setValue({
          page: '',
          controlVal: '',
          position: '',
          displayedText: ''
        });
      });
      this.tourControls.push(group);
    }
  }

  async ngOnInit() {

    if (!this.isTemplate) {
      await this.getModuleDataFromUrl();
      await this.getIndustriesInformation();
    }
    if (!this.isEdit) {
      this.tourControls.push(this.newControls());//add control dropdown at least one
    }
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
    // this.onChange(1);
    if(sessionStorage.getItem('studentId')) {
      this.isFromGcc = true;
    }
    else{
      this.isFromGcc = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    const hasSelectedApplication = changes?.selectedApplication?.currentValue;
    const goToNextStep = changes?.nextStep?.currentValue;
    const isSaveAndExitInitiated = changes?.isSaveAndExitInitiated?.currentValue;

    if (!!hasSelectedApplication) {
      this.presetValuesOnForm();
      if (!this.selectedApplication.tourControls) {
        this.tourControls.push(this.newControls());//add control dropdown at least one
      }
    }
    if (goToNextStep) {
      this.onNext();
    }
    if (isSaveAndExitInitiated === true) {
      this.onSaveAndExit();
    }
  }

  private presetValuesOnForm(): void {
    const {
      moduleName,
      applicationName,
      applicationDesc,
      applicationIdentifier,
      tutorials,
      controller_type,
      simulatorDefinition,
      problemTryingToSolve,
      industry,
      applicationCreationProcedure,
      tourControls,
      programmingType
    } = this.selectedApplication;
    const display = this.modules.find(
      (el: any) => el.key === moduleName
    )?.display;
    const item = { key: moduleName, display };
    this.selectModule(item);
    this.applicationName = applicationName;
    this.applicationDesc = applicationDesc;
    this.tutorial_url = JSON.parse(JSON.stringify(tutorials || {}));
    this.controller_type = controller_type;
    var simDef = JSON.parse(simulatorDefinition || {});
    this.visualization_panel = simDef.visualization_panel;
    this.simulator_type = simDef.visualization;
    this.mobile_console = simDef.mobile_console;
    this.problemTryingToSolve = problemTryingToSolve;
    this.applicationCreationProcedure = applicationCreationProcedure;
    this.industryDesignedFor = { "industry_name": industry };
    this.programmingType = programmingType;

    //patch data to add guided controls group
    if (this.selectedApplication.tourControls) {
      this.tourControls.clear();
      this.selectedApplication.tourControls?.forEach((element: any, index: number) => {
        const group = this.newControls();
        Object.keys(group.controls)?.forEach((key) => {
          group.patchValue({
            page: element.page,
            controlVal: element.controlVal,
            position: element.position,
            displayedText: element.displayedText
          });
        });
        this.tourControls.push(group);
      });
    }
  }


  toggleSelectMenu() {
    this.showMenu = !this.showMenu;
  }

  toggleIndustryMenu() {
    this.showIndustry = !this.showIndustry;
  }

  selectModule(item: any) {
    this.selectedModule = item;
    if (!this.isEdit) this.toggleSelectMenu();
    this.emitModuleKey.emit({ moduleData: this.selectedModule });
  }

  selectIndustry(item: any) {
    this.industryDesignedFor = item;
    this.toggleIndustryMenu();

  }

  async onNext() {
    const flag = this.guidedTourValidator();
    if (flag) {
      this.toasterService.error(this.errorMessage.validationFailed);
      return
    } else {
      // let selectedData=this.ControlFormGroup.value;  
      const applicationIdentifier = await this.save();
      if (applicationIdentifier) {
        this.emitApplicationID.emit({
          applicationIdentifier,
          moduleData: this.selectedModule
        });

        if (this.isFromGcc){
          let student = sessionStorage.getItem('studentId');
          let challengeId = sessionStorage.getItem('challengeId');
          
          await this.applicationTemplateService
            .gccAPI(challengeId, student, applicationIdentifier)
            .toPromise();
        }
      }
    }

  }

  async save() {
    let applicationIdentifier;
    if (this.isEdit) {
      applicationIdentifier = await this.updateApplication();
    } else {
      applicationIdentifier = await this.createNewApplication();
    }
    this.applicationIdentifier = applicationIdentifier;
    this.updatesimulatorDataToS3();
    return applicationIdentifier;
  }

  onExit() {
    let isUnsavedChangesPresent = this.checkUnsavedChanges();
    this.onUnsavedChanges.emit(isUnsavedChangesPresent);
    this.onExitPressed.emit({});
  }

  checkUnsavedChanges(): boolean {

    if (!!this.selectedApplication) {
      const {
        applicationName,
        applicationDesc,
        controller_type,
        simulator_type,
        problemTryingToSolve,
        industry,
        applicationCreationProcedure,
        tutorials,
        tourControls,
        programmingType
      } = this.selectedApplication;
      if (this.isTemplate == true) { // Template
        if (
          applicationName != this.applicationName ||
          applicationDesc != this.applicationDesc ||
          controller_type != this.controller_type ||
          simulator_type != this.simulator_type ||
          tutorials.default != this.tutorial_url.default ||
          tutorials.block != this.tutorial_url.block ||
          tutorials.circuit != this.tutorial_url.circuit ||
          tutorials.simulator != this.tutorial_url.simulator
        ) {
          return true;
        } else {
          return false;
        }
      } else { // User Solution / Application
        if (
          applicationName != this.applicationName ||
          applicationDesc != this.applicationDesc ||
          controller_type != this.controller_type ||
          simulator_type != this.simulator_type ||
          industry != this.industryDesignedFor?.industry_name ||
          problemTryingToSolve != this.problemTryingToSolve ||
          applicationCreationProcedure != this.applicationCreationProcedure
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  async onSaveAndExit() {
    await this.save();
    await this.onExit();
  }

  public async updateApplication() {
    let isApplicationValid: boolean = this.applicationValidator();
    // console.log({ isApplicationValid });
    if (isApplicationValid == true) {
      try {
        let result: any;
        const { application_identifier } = this.selectedApplication;
        const payload: any = {
          applicationName: this.applicationName || '',
          applicationDesc: this.applicationDesc || '',
          controller_type: this.controller_type || '',
          simulator_type: this.simulator_type || '',
          problemTryingToSolve: this.problemTryingToSolve,
          industryDesignedFor: this.industryDesignedFor?.industry_name || "",
          applicationCreationProcedure: this.applicationCreationProcedure,
          tutorials: this.tutorial_url,
          // programmingType:this.programmingType|| ''
        };
        // console.log(payload);
        if (this.isTemplate) {
          let selectedTourControls = this.ControlFormGroup.value;
          this.tourControlsData = selectedTourControls.tourControls;
          payload.tourControls = selectedTourControls.tourControls;//adding add guided tour controls in create payload
        }

        this.emitAppData.emit(this.selectedModule);
        if (this.isTemplate) {
          result = await this.applicationTemplateService
            .updateApplicationTempV3(payload, application_identifier)
            .toPromise();
        } else {
          result = await this.applicationTemplateService
            .updateApplication(payload, application_identifier)
            .toPromise();
        }
        if (result) {
          this.selectedApplication.applicationName = this.applicationName;
          this.selectedApplication.applicationDesc = this.applicationDesc;
          this.selectedApplication.controller_type = this.controller_type;
          this.selectedApplication.simulator_type = this.simulator_type;
          this.selectedApplication.problemTryingToSolve = this.problemTryingToSolve;
          this.selectedApplication.applicationCreationProcedure = this.applicationCreationProcedure;
          this.selectedApplication.industry = this.industryDesignedFor?.industry_name || "";
          this.selectedApplication.tutorials = {};
          this.selectedApplication.tutorials['default'] = this.tutorial_url.default;
          this.selectedApplication.tutorials['block'] = this.tutorial_url.block;
          this.selectedApplication.tutorials['circuit'] = this.tutorial_url.circuit;
          this.selectedApplication.tutorials['simulator'] = this.tutorial_url.simulator;
          this.selectedApplication.tourControls = this.tourControlsData
          return application_identifier;
        }
      } catch (error) {
        console.log(error);

      }
    } else {
      this.toasterService.error(this.errorMessage.validationFailed);
    }
  }

  public async createNewApplication() {
    let isApplicationValid: boolean = this.applicationValidator();
    if (isApplicationValid === true) {
      try {
        let result: any;
        const username = this.sessionStorageService.getUsername();
        let payload: any = {
          applicationName: this.isFromGcc ? "GIAC-" + this.applicationName : this.applicationName,
          applicationDesc: this.applicationDesc,
          boardIdentifier: environment.boardIdentifier,
          organizationID: environment.organizationID,
          moduleName: this.selectedModule?.key,
          controller_type: this.controller_type || '',
          simulator_type: this.simulator_type || '',
          appUserID: username || "Anonymous",
          createdBy: username || "Anonymous",
          updatedBy: username || "Anonymous",
          problemTryingToSolve: this.problemTryingToSolve,
          industryDesignedFor: this.industryDesignedFor?.industry_name || "",
          applicationCreationProcedure: this.applicationCreationProcedure,
          // programmingType:this.programmingType|| ''
        };

        if (this.isTemplate) {
          let selectedTourControls = this.ControlFormGroup.value;
          payload.tourControls = selectedTourControls.tourControls;//adding add guided tour controls in create payload
        }
        payload = { ...payload, tutorials: this.tutorial_url };

        /** for filter sensors need selected module name of basic details to filter sensors*/
        this.emitAppData.emit(payload);
        //this.updatesimulatorDataToS3();
        if (this.isTemplate) {
          result = await this.applicationTemplateService
            .createApplicationTemp(payload)
            .toPromise();
        } else {
          result = await this.applicationTemplateService
            .createApplication(payload)
            .toPromise();
        }
        // if (this.isAdmin) {
        //   result = await this.applicationTemplateService
        //     .createApplicationTemp(payload)
        //     .toPromise();
        // } else {
        //   result = await this.applicationTemplateService
        //     .createApplication(payload)
        //     .toPromise();
        // }
        if (result) {
          // Saving application payload in selectedApplication
          this.selectedApplication = payload;
          return result.applicationIdentifier;
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.toasterService.error(this.errorMessage.validationFailed);
    }
  }

  private async getS3URL(payload: any) {
    let s3_urls: any;
    if (this.isTemplate) {
      s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
    } else {
      s3_urls = await this.applicationTemplateService.updateApplicationV2(payload, this.applicationIdentifier).toPromise();
    }
    return s3_urls;
  }

  async updatesimulatorDataToS3() {
    const s3_urls: any = await this.getS3URL({ simulatorDefinition: true });
    if (s3_urls?.data?.simulatorDefinition) {
      const tempSimulatorDefinition: simulatorDefinition = JSON.parse(this.selectedApplication?.simulatorDefinition || JSON.stringify(initialSimulatorDefinition));
      const validSimulatorDefinition: simulatorDefinition = {
        visualization: this.simulator_type,
        visualization_panel: this.visualization_panel,
        mobile_console: this.mobile_console,
        mobile_controller_logic: tempSimulatorDefinition.mobile_controller_logic,
      }
      const configString = JSON.stringify(validSimulatorDefinition);
      const blob = new Blob([configString], {
        type: "application/json",
      });
      const file = new File([blob], this.applicationIdentifier + ".json", {
        type: "application/json",
      });
      this.toasterService.warning("Saving visualization config...");
      this.ideService.uploadToS3(file, s3_urls.data.simulatorDefinition).toPromise()
        .then(() => {
          this.toasterService.success("visualization config saved!");
          this.onSavePressed.emit(this.simulator_type);
          if (this.selectedApplication) {
            this.selectedApplication.simulatorDefinition = configString;
          }
        })
        .catch((err) => {
          this.toasterService.error("Could not save visualization config.");
          console.error(err);
        });
    }
    else {
      this.toasterService.error("Upload storage is unavailable at the moment. Please try again later.");
    }
  }

  private async getModuleDataFromUrl() {
    this.route?.queryParams?.subscribe((data: any) => {
      if (data['moduleName'] === "iot") {
        this.selectedModule = {
          key: "iot",
          display: "Internet of Things"
        }
      }
      else if (data['moduleName'] === "ds") {
        this.selectedModule = {
          key: "data_science",
          display: "Data Science"
        }
      }
      else if (data['moduleName'] === "robotics") {
        this.selectedModule = {
          key: "robotics",
          display: "Robotics"
        }
      }
      else if (data['moduleName'] === "aiot") {
        this.selectedModule = {
          key: "aiot",
          display: "AIoT"
        }
      }
      else if (data['moduleName'] === "ai") {
        this.selectedModule = {
          key: "artificial_intelligence",
          display: "Artificial Intelligence"
        }
      }
      else if (data['moduleName'] === "iiot") {
        this.selectedModule = {
          key: "iiot",
          display: "IIoT"
        }
      }
      this.emitModuleKey.emit({ moduleData: this.selectedModule });
    });

  }

  public async getIndustriesInformation() {

    let result = await this.applicationTemplateService.getApplicationIndustries();
    this.listOfIndustries = result['Industries'];
  }

  applicationValidator() {
    if (this.isTemplate) {
      this.applicationNameIsValid = (!!this.applicationName && this.applicationName.length > 0);
      return this.applicationNameIsValid
    } else {
      this.applicationNameIsValid = (!!this.applicationName && this.applicationName.length > 0);
      this.problemTryingToSolveIsValid = (!!this.problemTryingToSolve && this.problemTryingToSolve.length > 0);
      this.industryDesignedForIsValid = (!!this.industryDesignedFor?.industry_name && (this.industryDesignedFor?.industry_name || '').length > 0);
      this.applicationCreationProcedureIsValid = (!!this.applicationCreationProcedure && this.applicationCreationProcedure.length > 0);
      return this.applicationNameIsValid && this.problemTryingToSolveIsValid && this.applicationCreationProcedureIsValid && this.industryDesignedForIsValid
    }

  }

  //validation for guided tour
  guidedTourValidator() {
    if (this.ControlFormGroup.dirty && this.ControlFormGroup.invalid) {
      this.isValue = true

    } else {
      this.isValue = false
    }
    return this.isValue
  }



  toggleInfo() {
    this.isInformation = !this.isInformation;
  }

  onSubmit(form: any) {
    // console.log("valur",form)
  }
}

@NgModule({
  declarations: [CreateBasicAppComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [CreateBasicAppComponent],

})
export class CreateBasicAppModule { }

interface TutorialVideoUrl {
  default: string;
  circuit: string;
  block: string;
  simulator: string;
}