import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BlocklySectionModule } from "../../components/blockly-section/blockly-section.component";
import { BlockProgrammingComponent } from "./block-programming.component";
import { SaveListModule } from "../../components/save-list/save-list.component";
import { SidePanelModule } from "src/app/components/side-panel/side-panel.module";
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { PythonDirectModule } from "../python-direct/python-direct.module";
import { AngularSplitModule } from "angular-split";

@NgModule({
  declarations: [BlockProgrammingComponent],
  imports: [
    CommonModule,
    BlocklySectionModule,
    SaveListModule,
    SidePanelModule,
    ModalModule,
    PythonDirectModule,
    AngularSplitModule,
  ],
  exports: [BlockProgrammingComponent],
})
export class BlockProgrammingModule {}
