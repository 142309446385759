import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseModuleComponent } from './choose-module.component';

@NgModule({
  declarations: [
    ChooseModuleComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ChooseModuleComponent
  ]
})
export class ChooseModuleModule { }
