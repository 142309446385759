<div class="text-center mt-4 step-tracker">

  <div class="inner">
    <div class="line-container" [class.isInline]="isInline">
      <div class="line"></div>
      <div class="line success" [style.width]="progress() + '%'"></div>
    </div>

    <div style="position: relative">
      <ng-container *ngFor="let status of statusTracker">
        <div class="box text-center"
             [class.success]="statusNumber() > status.index"
             [class.active]="statusNumber() == status.index"
             [class.disabled]="statusNumber() < status.index"
             [class.inline]="isInline" >
          <div class="flex flex-col items-center">
            <span class="text-md">{{status.title}}</span>
            <span class="circle number">{{status.index}}</span>
            <img class="tick text-green-600" src="assets/img/check-white.svg">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
