import { Location } from '@angular/common';
import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd,Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { logout } from 'src/app/store/auth/auth.action';
import { IAppState } from 'src/app/store/index.state';
import { showLayout } from 'src/app/store/layout/layout.action';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isDashboard = false;
  showNav: boolean = true;
  constructor(
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private location: Location,
    private store: Store<IAppState>,
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isDashboard = val.url === '/';
        //for device dropdown 
        if(val.url==='/admin/devices' ||val.url==='/admin/device-session' ){
          this.isActive=true;
        }
        else{
          this.isActive=false;
          this.isMenuOpen=false;
        }
      }
    });
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('challengeId')) {
      this.showNav = false
    }
  }
  
  logout() {
    this.store.dispatch(logout());
    this.store.dispatch(showLayout({ value: false }));
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

  goBack() {
    this.location.back();
  }

  isMenuOpen:boolean=false;
  isActive:boolean=false;
  toggleMenu(){    
    this.isMenuOpen=!this.isMenuOpen;
  }
  //on click devices 
  onClick(name:string){    
    if(name==='devices'){
      this.router.navigateByUrl('/admin/devices');
      this.isMenuOpen=false;
      this.isActive=true;
      
    }else{
      this.router.navigateByUrl('/admin/device-session');
      this.isMenuOpen=false;
      this.isActive=true;
    }
  }

}
