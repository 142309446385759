import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/shared/services/auth.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { UserSessionService } from "src/app/shared/services/user.session.service";

@Component({
  selector: "app-mobile-login",
  templateUrl: "./mobile-login.component.html",
  styleUrls: ["./mobile-login.component.scss"],
})
export class MobileLoginComponent implements OnInit {
  loginForm!: FormGroup;
  isSubmitted: boolean = false;
  showErrorMessage = false;
  errorMessage = "";
  showLoader = false;

  @Output() emitLoggedInEvent = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) {}

  async ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(25),
        ]),
      ],
    });
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  get f() {
    return this.loginForm.controls;
  }
  /**
   *  @return
   * true if Administrator OR Content Manager
   */
  // private isValidGroup(groups: any) {
  //   const validGroups = ["Learner", "Instructor"];
  //   return groups.every((el: any) => validGroups.indexOf(el) > -1);
  // }

  async onLogin() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    try {
      this.showLoader = true;
      const { username, password } = this.loginForm.value;
      const result: any = await this.authService
        .authenticateLMS(username, password)
        .toPromise();
      const groups = result.groups;
      let user = result;
      user['username'] = username;
      if (result && result.session_id) { //  && this.isValidGroup(groups)
        sessionStorage.setItem("sessionId", result.session_id);
        sessionStorage.setItem("user", JSON.stringify(user));
        this.emitLoggedInEvent.emit(true);
      } else if (result && result.error_description) {
        this.showErrorMessage = true;
        this.errorMessage = result.error_description;
      }
      // else if (!this.isValidGroup(groups)) {
      //   this.showErrorMessage = true;
      //   this.errorMessage =
      //     "The username and password seems to be invalid. Try again or click on Forgot password.";
      // }
    } catch (err) {
      // Nothing to do here..
      this.showErrorMessage = true;
      this.errorMessage = "Some error occurred, we are looking into it";
    } finally {
      // Nothing to do here.,
      this.showLoader = false;
    }
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
  }
}
