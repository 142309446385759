<div class="p-8">
  <div class="bg-white rounded-lg p-8">
    <p class="text-xl font-bold mb-5 text-gray-800">
      <span *ngIf="devicetoken">Edit Device For Lab </span>
      <span  *ngIf="!devicetoken">Create New Device For Lab</span></p>
    <p class="text-md mb-10">Fill In The Details Of The Lab</p>
    <form [formGroup]="deviceForm">
      <!-- create device start -->
      <div>
        <div class="floating-input relative mb-5 flex flex-col">
          <label  class= "block text-sm font-medium text-gray-500 p-3">Device Name</label>
          <input  class="input-style px-2" name="device-name" formControlName="deviceName"  placeholder="Device Name">
        </div>
        <div class="floating-input relative mb-5 flex flex-col">
          <label  class= "block text-sm font-medium text-gray-500 p-3">Organization Name</label>
          <select *ngIf="isGrokCloudMember" name="orgNames" id=""
            class="w-full border-gray-300 rounded-lg shadow-sm mb-5 input-style"
              formControlName="orgName" (change)="onOrgNameChange()">
            <option value="" disabled selected></option>
            <ng-container *ngFor="let org of orgList">
              <option class="input-style"[value]="org.orgName">{{org.orgName}}</option>
            </ng-container>
          </select>
          <select *ngIf="!isGrokCloudMember" name="labNames" id=""
            class="w-full border-gray-300 rounded-lg shadow-sm mb-5 input-style">
            <option value="" disabled selected [value]="organizationName">{{organizationName}}</option>
          </select>
        </div>
        <div class="floating-input relative mb-5 flex flex-col">
          <label  class="block text-sm font-medium text-gray-500 p-3">Lab Name</label>
          <select name="labNames" id="" class="w-full border-gray-300 rounded-lg shadow-sm mb-5 input-style" formControlName="lab">
            <option value="" disabled selected></option>
            <ng-container *ngFor="let lab of filteredLabList">
              <option class="input-style" [value]="lab | json">{{lab.labName}}</option>
            </ng-container>
          </select>
        </div>
      </div>      
      <div class="flex">
        <button  class="mr-2 text-white btn-1" (click)="onSubmit()" [class.opacity-50]="showInfoPanel"
        [disabled]="showInfoPanel">
          <span *ngIf="devicetoken">Update Device</span>
          <span  *ngIf="!devicetoken">Create Device</span>
        </button>
        <button class="text-gray-600 p-2 rounded-md font-bold mr-2 btn-2" type="button btn-1" (click)="cancel()">CANCEL</button>
      </div>
      <!-- create device end -->
    </form>
  </div>
</div>

<div class="p-8 py-8" *ngIf="showInfoPanel">
  <p class="text-lg px-2 font-medium mb-5 text-gray-800"><span class="text-xl font-bold px-3">Device Name: </span>{{
    savedDeviceName }}</p>
  <div class="bg-white rounded-lg p-8">
    <div class="my-2 flex justify-between flex-row items-center flex-nowrap">
      <p class="p-2 m-2 text-md mb-5 text-gray-800">{{ deviceToken }}</p>
      <button class="bg-button text-white p-2 rounded-lg shadow-xl hover:bg-red-600" (click)="copyToken()">
        <div class="flex items-center">
          <img src="assets/img/copy.png" class="h-8 w-8 icon">
        </div>
      </button>
    </div>
  </div>
</div>
