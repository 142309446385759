import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { IAppState } from 'src/app/store/index.state';
import { showSidePanel } from 'src/app/store/layout/layout.action';
import { BlocklySectionModule } from '../../../../components/blockly-section/blockly-section.component';
import { CircuitSectionModule } from '../../../../components/circuit-section/circuit-section.component';
import { updateApplication } from '../../store/application.actions';

@Component({
  selector: 'app-edit-application',
  templateUrl: './edit-application.component.html',
  styleUrls: ['./edit-application.component.scss']
})
export class EditApplicationComponent implements OnInit {

  @Input() application!: any;
  @Input() isEdit: boolean = false;
  @Input() isAdmin?: boolean;
  @Output() emitCancelEvent = new EventEmitter();

  showCircuitPage = false;
  showBlockPage = false;
  configurationDefinition!: JSON;

  constructor(
    private store: Store<IAppState>,
    private applicationTemplateService: ApplicationService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) {
    this.application = {
      applicationDesc: "",
      applicationDefinition: "",
      applicationIdentifier: "",
      applicationName: "",
      boardIdentifier: "",
      configurationDefinition: "",
      toolboxDefinition: "",
      pythonInterfaceDefinition:""
    }
  }

  async ngOnInit() {
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  async onSubmit() {
    try {
      let result: any;
      const payload = {
        configurationDefinition: this.application.configurationDefinition,
        applicationDefinition: this.application.applicationDefinition,
        toolboxDefinition: this.application.toolboxDefinition,
        pythonInterfaceDefinition:this.application.pythonInterfaceDefinition
      }

      if (this.isAdmin) {
        result = await this.applicationTemplateService.updateApplicationTempV2(payload, this.application.applicationIdentifier).toPromise();
      } else {
        result = await this.applicationTemplateService.updateApplication(payload, this.application.applicationIdentifier).toPromise();
      }
      if (result) {
        this.store.dispatch(updateApplication(result));
        this.store.dispatch(showSidePanel({ value: false }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  cancel() {
    this.emitCancelEvent.emit(true);
  }


  onJSONSaved(event: string) {
    this.application.configurationDefinition = event;
  }
}

@NgModule({
  declarations: [
    EditApplicationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CircuitSectionModule,
    BlocklySectionModule
  ],
  exports: [
    EditApplicationComponent
  ],
})
export class EditApplicationModule { }
