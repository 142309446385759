import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Permission } from 'src/app/shared/guards/ide.auth.guard';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UserSessionService } from 'src/app/shared/services/user.session.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isAuthenticated = false;

  sections = [
    {
      title: 'Connect Device',
      path: '/ide/connect-device',
    },
    {
      title: 'Circuit Designing',
      path: '/ide/circuit',
    },
    {
      title: 'Block Coding',
      path: '/ide/block',
    },
    {
      title: 'Mobile Application',
      path: '/ide/mobile-app',
    },
    {
      title: 'Create Application',
      path: '/ide/application/create',
    },
    {
      title: 'Applications',
      path: '/ide/applications',
    }
  ]

  constructor(
    private router: Router,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    this.isAuthenticated = !!(Permission.isActive());
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();

  }

  routeTo(path: string): void {
    this.router.navigateByUrl(path);
  }

  login() {
    window.location.href = 'https://grok.seeklms.com/lms/';
  }

}
