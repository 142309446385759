import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/index.state';
import { showLayout } from 'src/app/store/layout/layout.action';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  
  constructor(private router: Router, private store: Store<IAppState>) { }

  canActivate() {
    const isAuthenticated = sessionStorage.getItem('token');
    if (isAuthenticated) {
      this.store.dispatch(showLayout({value: true}));
      return true;
    }
    this.store.dispatch(showLayout({value: false}));
    this.router.navigateByUrl('/auth/login');
    return false;
  }
}

