<div class="flex py-5 px-15 text-gray justify-between align-items-center">
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 g-15 flex items-dropdown">
    <p class="m-b-0">Items per Page</p>
    <div>
      <select name="itemsPerPage"class="" [(ngModel)]="customPageSize" (change)="reload($event)">
        <option value="2">2</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
      </select>
    </div>
  </div>
  <div class="flex pagination-view gap-4">
    <div class="btn-group gap-4" role="group">
      <button (click)="firstPage()" [disabled]="page == 1" type="button" class="btn btn-sm bg-light1 mr-2" *ngIf="total">
        <img src="assets/img/last-page.svg" class="icon reverse" alt="previous"  [class.enable]="page > 1">
      </button>
      <button (click)="prevPage()" [disabled]="page == 1" type="button" class="mr-2">
        <img src="assets/img/next.svg" class="icon reverse" alt="previous" [class.enable]="page > 1">
      </button>
      <button type="button" class="btn btn-sm bg-light1 text-gray mr-2">{{ page }} {{getPaginationText()}}</button>
      <button (click)="nextPage()" [disabled]="items != null && items.length != customPageSize" type="button" class="mr-2">
        <img src="assets/img/next.svg" class="icon" alt="next" [class.enable]="items != null && items.length === customPageSize">
      </button>
      <button (click)="lastPage()" [disabled]="items != null && items.length != customPageSize" *ngIf="total" type="button" class=" mr-2">
        <img src="assets/img/last-page.svg" class="icon" alt="next" [class.enable]="items != null && items.length === customPageSize">
      </button>
    </div>
  </div>
</div>
