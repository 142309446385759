<div class="p-14">
  <div class="inline-flex">
    <p class="text-xl font-medium mb-5 px-4">{{ isEdit? 'Edit' :'Create' }} {{ isTemplate ? "Application Template" : "User Solution"
      }}</p><span class="text-xl font-bold mb-5 text-green-600 px-4">{{selectedApplication?.applicationName}}</span>
  </div>
  <div class="bg-white rounded-lg p-8">
    <app-step-tracker [moduleKey]="moduleKey" [isInline]="true" [currentStep]="currentStep"
      (allSteps)="updateAllSteps($event)"></app-step-tracker>

    <app-create-basic-app *ngIf="currentStep === 1" (emitApplicationID)="createBasicAppResponse($event)"
      (emitModuleKey)="createBasicAppResponse($event)" [isAdmin]="isAdmin" [isEdit]="isEdit" [isTemplate]="isTemplate"
      [selectedApplication]="selectedApplication" (onExitPressed)="onExitPressed($event)"
      (onUnsavedChanges)="onUnsavedChanges($event)" [isSaveAndExitInitiated]="isSaveAndExitInitiated" (emitAppData)="getAppData($event)">
    </app-create-basic-app>

    <app-select-sensors *ngIf="currentStepName === 'Select Sensors'" (emitSensorData)="setSensors($event)"
      [isEdit]="isEdit" [allSelectedSensors]="selectedSensors" [selectedApplication]="selectedApplication"
      (onBackPressed)="onBackPressed(2)" [applicationData]="appData">
    </app-select-sensors>

    <app-create-circuit *ngIf="currentStepName === 'Create Circuit'" [applicationIdentifier]="applicationIdentifier"
      [currentStepName]="currentStepName" [isAdmin]="isAdmin" [sensors]="selectedSensors"
      [selectedApplication]="selectedApplication" [isEdit]="isEdit" (onSavePressed)="circuitSaved($event)"
      [isSaveAndExitInitiated]="isSaveAndExitInitiated" (refreshApplication)="refreshApplication()"
      (onUnsavedChanges)="onUnsavedChanges($event)" (onExitPressed)="onExitPressed($event)" 
      (onBackPressed)="onBackPressed(3)" [applicationData]="appData">
    </app-create-circuit>

    <!-- <app-create-block-program *ngIf="currentStepName === 'Create Program'"
      [applicationIdentifier]="applicationIdentifier" [selectedApplication]="selectedApplication"
      (onSavePressed)="blockSaved($event)" [isAdmin]="isAdmin" (onBackPressed)="onBackPressed(4)"
      (refreshApplication)="refreshApplication()" [moduleKey]="moduleKey" (onExitPressed)="onExitPressed($event)"
      (onUnsavedChanges)="onUnsavedChanges($event)" [isSaveAndExitInitiated]="isSaveAndExitInitiated">
    </app-create-block-program> -->

    <!-- Choose Interface step start -->
    <app-create-block-program *ngIf="currentStepName === 'Create Program' && isBlockSwitch"
      [applicationIdentifier]="applicationIdentifier" [selectedApplication]="selectedApplication"
      (onSavePressed)="blockSaved($event)" [isAdmin]="isAdmin" (onBackPressed)="onBackPressed(4)"
      (refreshApplication)="refreshApplication()" [moduleKey]="moduleKey" (onExitPressed)="onExitPressed($event)"
      (onUnsavedChanges)="onUnsavedChanges($event)" [isSaveAndExitInitiated]="isSaveAndExitInitiated"  (onPopupOpen)="onPopupOpen($event)" 
      (getGeneratedCode)="getGeneratedCode($event)" [isDiscardInitiated]="isDiscardInitiated">
    </app-create-block-program>

    <app-python-direct *ngIf="currentStepName === 'Create Program' && isPythonSwitch"
    [isCreateApplication]="isPythonSwitch" (refreshApplication)="refreshApplication()" [applicationIdentifier]="applicationIdentifier"
    [isAdmin]="isAdmin" (onPopupOpen)="onPopupOpen($event)" [selectedApplication]="selectedApplication" (onExitPressed)="onExitPressed($event)" (onBackPressed)="onBackPressed(4)" [moduleKey]="moduleKey" 
    (onSavePressed)="onPyCodeSaved($event)" [blockGeneratedCode]="blockGeneratedCode"  [isSaveAndExitInitiated]="isSaveAndExitInitiated" 
    (onUnsavedChanges)="onUnsavedChanges($event)" [isSimulatorBack]="isSimulatorBack">
    </app-python-direct>
    <!-- Choose Interface step end-->
    <app-create-simulator *ngIf="currentStepName === 'Mobile Control Panel'" [isAdmin]="isAdmin" [isEdit]="isEdit"
      [selectedApplication]="selectedApplication" [applicationIdentifier]="applicationIdentifier"
      (onSavePressed)="simulatorSaved($event)" (onBackPressed)="onBackPressed(5)"
      (onExitPressed)="onExitPressed($event)" (onBackGetPycode)="onBackGetPycode($event)">
    </app-create-simulator>
  </div>
</div>

<ng-container *ngIf="isModalOpen == true || isSwitchToBlock">
  <!-- isModalOpen -->
  <app-modal-v2 [modalTitle]="'Changes will be lost'" [modalBody]="isSwitchToBlock ? 'Switching between interfaces will lose the changes you have made, Are you sure you
  want to switch?' :'You have unsaved changes on this page'"
    [modalActions]="isSwitchToBlock ? mdActionForInterface:modalActions" (actionExecutor)="actionExecutor($event)" [classObject]="global"
    (closeEvent)="$event.isModalOpen = false || isSwitchToBlock=false" [switchType]="isSwitchToBlock ? 'block-program':'python-direct'">
  </app-modal-v2>
</ng-container>

<!-- Interface to choose If any programming type not selected -->

<div *ngIf="currentStepName === 'Create Program' && !isPythonSwitch && !isBlockSwitch && isNotProgramType">
  <div class="flex justify-center items-center p-2 font-normal text-lg">
    <p>Choose the type of interface to write your program</p>
  </div>
  <div class="p-14 flex justify-center items-center">
    <div class="grid grid-cols-3 gap-10 grid-flow-col items-center">
      <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" style="width:323px" (click)="openInterFace('block')">
        <div class="overlay rounded-xl"></div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-center">
            <img class="choose-interface-img" src="assets/img/admin/coding.svg">  
          </div>
          <div class="flex flex-row justify-center p-2 h-12 text-greenish-blue bg-text">
            I Want to Use A Block Designer
          </div>
        </div>      
      </div>
      <div class="flex flex-row justify-center items-center p-2 h-12 text-greenish-blue">OR</div>      
      <div class="col-span-3 bg-white shadow-lg rounded-xl cursor-pointer action" style="width:323px" (click)="openInterFace('python-direct')">
        <div class="overlay rounded-xl"></div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-center">
            <img class="choose-interface-img" src="assets/img/admin/Python.svg">  
          </div>
          <div class="flex flex-row justify-center p-2 h-12 text-greenish-blue bg-text">
            I Want to Use A Python Only Interface
          </div>
        </div>      
      </div>
    </div>  
  </div>
</div>

