import { createAction, props } from '@ngrx/store';
import { undoable } from '../circuit-history/circuit-history.action';

export enum EState {
  ADD_TARGET = '[Target] add target',
  REMOVE_TARGET = '[Target] remove target',
  UPDATE_TARGET = '[Target] update target',
  UPDATE_DRAG_TARGET = '[Target] update target non-undoable',
  UPDATE_NAME_TARGET = '[Target] update name target',
  ADD_WIRE = '[Wire] add wire',
  ADD_FULL_WIRE = '[Wire] add Full wire',
  REMOVE_WIRE = '[Wire] remove wire',
  UPDATE_WIRE = '[Wire] update wire',
  UPDATE_DRAG_WIRE = '[Wire] update wire non-undoable',
  UPDATE_WIRE_COLOR = '[Wire] update wire color',
  UPDATE_CONNECTOR = '[Target] update connector',
  CLEAR_CANVAS = '[Target] [Wire] clear all',
  UPDATE_DATA = '[Target] [Wire] update all'
}

const addTarget = undoable(createAction(EState.ADD_TARGET, props<{ payload: any, undoableData?: any }>()));
const removeTarget = undoable(createAction(EState.REMOVE_TARGET, props<{ payload: any, undoableData?: any }>()));
const updateTarget = undoable(createAction(EState.UPDATE_TARGET, props<{ payload: any, undoableData?: any }>()));
const updateNameTarget = undoable(createAction(EState.UPDATE_NAME_TARGET, props<{ payload: any, undoableData?: any }>()));
const addWire = undoable(createAction(EState.ADD_WIRE, props<{ payload: any, undoableData?: any }>()));
const addFullWire = undoable(createAction(EState.ADD_FULL_WIRE, props<{ payload: any, undoableData?: any }>()));
const removeWire = undoable(createAction(EState.REMOVE_WIRE, props<{ payload: any, undoableData?: any }>()));
const updateWire = undoable(createAction(EState.UPDATE_WIRE, props<{ payload: any, undoableData?: any }>()));
const updateWireColor = undoable(createAction(EState.UPDATE_WIRE_COLOR, props<{ payload: any, undoableData?: any }>()));
const updateConnector = undoable(createAction(EState.UPDATE_CONNECTOR, props<{ payload: any, undoableData?: any }>()));
const clearCanvas = undoable(createAction(EState.CLEAR_CANVAS));
const updateWholeData = undoable(createAction(EState.UPDATE_DATA, props<{ payload: any, undoableData?: any }>()));

// to update wire position without making undoable
const updateDragTarget = createAction(EState.UPDATE_DRAG_TARGET, props<{ payload: any, undoableData?: any }>());
const updateDragWire = createAction(EState.UPDATE_DRAG_WIRE, props<{ payload: any, undoableData?: any }>()); 

const circuitDesignActions = {
  addTarget,
  removeTarget,
  updateTarget,
  updateDragTarget,
  updateNameTarget,
  addWire,
  addFullWire,
  removeWire,
  updateWire,
  updateDragWire,
  updateWireColor,
  updateConnector,
  clearCanvas,
  updateWholeData
};
export default circuitDesignActions;
