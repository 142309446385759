import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { 
    AssociateUser, 
    DissociateUser, 
    ExtendAssociation, 
    DeviceSessionsList,
    SearchDeviceSessions
} from "../models/device-sessions";


@Injectable({
    providedIn: 'root'
})

export class DeviceSessionsService {
    constructor(private http: HttpClient) { }

    associate(payload: AssociateUser, deviceToken: string) {
        const url = `${environment.apiURL}/admin/devices/associate?device_token=${deviceToken}`;
        return this.http.post<AssociateUser>(url, payload);
    }

    dissociate(payload: DissociateUser, deviceToken: string) {
        const url = `${environment.apiURL}/admin/devices/dissociate?device_token=${deviceToken}`;
        return this.http.post<DissociateUser>(url, payload);
    }

    extend(payload: ExtendAssociation, deviceToken: string) {
        const url = `${environment.apiURL}/admin/devices/extend?device_token=${deviceToken}`;
        return this.http.post<ExtendAssociation>(url, payload);
    }

    search(payload: SearchDeviceSessions) {
        const sessionId = localStorage.getItem('sessionId');
        const url = `${environment.apiURL}/admin/devices/search`;
        const headers = new HttpHeaders().set('cookie-header' , sessionId);
        return this.http.post<SearchDeviceSessions>(url, payload , { headers });
    }
}