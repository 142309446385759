<div [id]="modalType" class="d">
  <div class="overlay bg-black w-full h-full fixed top-0 left-0 opacity-10 z-50 text-4xl">
  </div>
  <div
    class="transition duration-700 ease-in-out fixed right-0 top-0  overflow-auto z-50 h-full bg-white shadow-xl modal shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
    <div class="modal-close" (click)="close()">
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6"
          d="M0 14C0 6.26719 6.26719 0 14 0C21.7328 0 28 6.26719 28 14C28 21.7328 21.7328 28 14 28C6.26719 28 0 21.7328 0 14ZM9.57031 11.3805L12.1461 13.9508L9.57031 16.5703C9.06172 17.0844 9.06172 17.9156 9.57031 18.3805C10.0844 18.9383 10.9156 18.9383 11.3805 18.3805L13.9508 15.8539L16.5703 18.3805C17.0844 18.9383 17.9156 18.9383 18.3805 18.3805C18.9383 17.9156 18.9383 17.0844 18.3805 16.5703L15.8539 13.9508L18.3805 11.3805C18.9383 10.9156 18.9383 10.0844 18.3805 9.57031C17.9156 9.06172 17.0844 9.06172 16.5703 9.57031L13.9508 12.1461L11.3805 9.57031C10.9156 9.06172 10.0844 9.06172 9.57031 9.57031C9.06172 10.0844 9.06172 10.9156 9.57031 11.3805Z"
          fill="#737373" />
      </svg>
    </div>
    <div class="relative">

      <!-- Modal Body For creating New Variable  -->
      <div>
        <div class="modal-title">{{modalTitle}}</div>
        <div class="modal-line-separator"></div>
        <div class="modal-body mb-5">{{modalBody}}</div>
        <div class="modal-btn">
          <ng-container *ngFor="let modalAction of modalActions">
            <button class="btn btn-{{modalAction.class}}" (click)="onclickAction(modalAction.method)">{{modalAction.display}}</button>
          </ng-container>
        </div>
      </div>
    
    </div>
  </div>
</div>
