import { undoableCircuitData } from "src/app/shared/models/circuit";

interface Connector {
  c_id: string;
  t_id: string;
  prev: {
    x: number;
    y: number;
  };
  current: {
    x: number;
    y: number;
  };
}
interface Coordinates {
    x: number;
    y: number;
}
export interface Wire {
  w_id: string;
  color: string;
  start: { [key: string]: Connector };
  control: { [key: string]: Connector };
  end: { [key: string]: Connector };
}
export interface Target {
  t_id: string;
  name: string;
  customName: string;
  customEditName: string;
  sensorType: string;
  controlType: string;
  deviceType: string;
  prev: {
    x: number;
    y: number;
    scaleX: number;
    scaleY: number;
    customName: Coordinates;
    overlays: Array<Coordinates>;
  };
  current: {
    x: number;
    y: number;
    scaleX: number;
    scaleY: number;
    customName: Coordinates;
    overlays: Array<Coordinates>;
  };
  elementSrc: string;
  connectors: Array<any>; //will contain wire-id
}

export interface CircuitDesignState {
  targets: Array<Target>;
  wires: Array<Wire>;
  undoableData?: undoableCircuitData;
}

export const initialCircuitState: CircuitDesignState = {
  targets: [],
  wires: [],
  undoableData: new undoableCircuitData(),
};
