
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { modalButtonAction } from '../../models/modal';
import { SessionStorageService } from '../../services/session-storage.service';
import { UserSessionService } from '../../services/user.session.service';

@Component({
  selector: 'app-modal-v2',
  templateUrl: './modal-v2.component.html',
  styleUrls: ['./modal-v2.component.scss']
})
export class ModalV2Component implements OnInit {

  @Input() modalTitle: any = "Modal Title";
  @Input() modalBody: any = "Modal Body";
  @Input() modalActions: modalButtonAction[] = [];
  @Input() classObject: any;
  @Input() modalType: any;
  @Input() switchType:any;


  @Output() actionExecutor = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  constructor(
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  onclickAction(method: modalButtonAction['method']): void {
    if (this.switchType === 'python-direct') {
      this.classObject.switchType = 'python-direct'
    }
    else {
      this.classObject.switchType = 'block-program'
    }
    this.actionExecutor.emit([method, this.classObject]);
  }

  // onclickAction(method: modalButtonAction['method']): void {
  //   this.actionExecutor.emit([method, this.classObject]);
  // }

  close(): void {
    this.closeEvent.emit(this.classObject);
  }
}
