import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDEService } from 'src/app/shared/services/ide.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { DevicesService } from 'src/app/shared/services/devices.service';
import { CreateDevice, UpdateDevice } from 'src/app/shared/models/device';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { Organization, OrganizationsService } from 'src/app/shared/services/organizations.service';
import { LabService } from 'src/app/shared/services/lab.service';
import { serachLab, Lab } from 'src/app/shared/models/lab';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-device-add',
  templateUrl: './add-device-lab.component.html',
  styleUrls: ['./add-device-lab.component.scss'],
})
export class AddDeviceLabComponent implements OnInit {
  deviceName!: string;
  token!: any;
  showInfoPanel: boolean = false;
  savedDeviceName!: string;
  currentUser: any;
  orgList: Organization[] = [];
  labList: Lab[] = [];
  filteredLabList: Lab[] = [];
  deviceForm = new FormGroup({
    deviceName: new FormControl(''),
    orgName: new FormControl(''),
    lab: new FormControl('')
  })
  deviceToken: any;
  devicetoken: any;
  deviceData: any;
  currentOrganization!: string;
  isSuperAdmin: boolean = true;
  isGrokCloudMember: boolean = true;
  organizationName!: string;
  
  constructor(
    private router: Router,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private deviceService: DevicesService,
    private sessionStorageService: SessionStorageService,
    private activateRoute: ActivatedRoute,
    private orgService: OrganizationsService,
    private labService: LabService
  ) { }

  setDefaultOrganization() {
    this.currentOrganization = this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization === environment.organizationID) { }
    else {
      this.organizationName = this.currentOrganization;
      this.isGrokCloudMember = false;
      this.deviceForm.patchValue({
        orgName: this.organizationName
      });
      this.onOrgNameChange();
    }
  }

  async ngOnInit() {
    await this.getAllLabs();
    this.searchOrganizations();
    this.setDefaultOrganization();
    this.isSuperAdmin = window.location.href.includes('/admin/');
    this.activateRoute.params.subscribe((params: any) => {
      this.devicetoken = params.device_token;
      if (this.devicetoken) {
        this.getDevice(this.devicetoken);
      }
    });
    this.currentUser = this.sessionStorageService.getUsername();
  }

  async searchOrganizations() {
    try {
      const result: any = await this.orgService.search().toPromise();
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getAllLabs() {
    const getlabs: serachLab = {
      pageSize: 99999,
      pageNumber: 0,
      orderBy: [
        {
          sortBy: "lab_name",
          sortOrder: "ASC"
        }
      ]
    };
    let response: any = await this.labService.search(getlabs).toPromise();
    this.labList = response.labs as Lab[];
  }

  onOrgNameChange() {
    let orgName = this.deviceForm.controls['orgName'].value;
    this.filteredLabList = this.labList.filter((lab) => { if (lab.orgName === orgName) return lab; else return null; });
  }

  getDevice(token: any) {
    this.deviceService.get(token).subscribe((data: any) => {
      this.deviceData = data['device'];
      if (this.deviceData) {
        this.deviceForm.patchValue({
          orgName: this.deviceData.orgName,
          lab: JSON.stringify({
            labName: this.deviceData.labName,
            labIdentifier: this.deviceData.labIdentifier
          }),
          deviceName: this.deviceData.deviceName
        });
        this.onOrgNameChange();
      }
    });
  }

  async onSubmit() {
    if (!this.deviceForm.invalid) {  
      if (this.devicetoken) {
        this.updateDevice();
      } else {
        this.createDevice();
      }
    }
  }

  copyToken() {
    let dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = this.deviceToken;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.toasterService.success(`${this.deviceToken} is copied!`);
  }

  enableAddDevice() {
    this.showInfoPanel = false;
    this.savedDeviceName = '';
    this.deviceToken = '';
  }

  async createDevice() {
    const controls = this.deviceForm.controls;
    let labFormControlValue = controls['lab'].value ? JSON.parse(controls['lab'].value) : null;
    const payload: CreateDevice = {
      deviceName: controls['deviceName'].value,
      orgName: controls['orgName'].value,
      labName: labFormControlValue?.labName,
      labIdentifier: labFormControlValue?.labIdentifier,
      createdBy: this.currentUser
    };
    try {
      const result: any = await this.deviceService.create(payload).toPromise();
      if (result) {
        this.deviceToken = result?.token;
        this.showInfoPanel = true;
        this.savedDeviceName = controls['deviceName'].value;
        this.deviceName = '';
      }
      this.cancel();
    } catch (err) {
      console.log(err);
    }
  }

  updateDevice() {
    const controls = this.deviceForm.controls;
    let labFormControlValue = controls['lab'].value ? JSON.parse(controls['lab'].value) : null;
    const payload: UpdateDevice = {
      deviceName: controls['deviceName'].value,
      orgName: controls['orgName'].value,
      labName: labFormControlValue?.labName,
      labIdentifier: labFormControlValue?.labIdentifier,
      updatedBy: this.currentUser
    };
    this.deviceService.update(this.devicetoken, payload).subscribe((res: any) => {
      if (res?.success == true) {
        this.toasterService.success("Device updated successfully");
        this.cancel();
      } else {
        this.toasterService.error("Can not update the device currently. Please try again later.");
      }
    })
  }

  cancel() {
    if (this.isSuperAdmin) {
      this.router.navigateByUrl('/admin/devices')
    } else {
      this.router.navigateByUrl('/ide/devices')
    }
  }
}
