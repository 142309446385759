import { Injectable } from '@angular/core';
import { interval, Subscription, timer } from 'rxjs';

const DATA_SEND_INTERVAL = 15000; // Interval for sending data (15 seconds)
const IDLE_TIMEOUT = 90000; // Timeout period for idle state (90 seconds)
const MAX_ACCUMULATED_TIME = 15500; // Maximum accumulated active time (15.5 seconds with buffer)
const THROTTLE_TIMEOUT = 200; // Throttle timeout for handling rapid events (200ms)

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {
  private moduleName: string = "None"; // Default module name
  private lastUpdateTime: number | null = null; // Timestamp of last update
  private activeTimeSeconds: number = 0; // Accumulated active time in seconds
  private isActive: boolean = false; // Current active state
  private dataSendSubscription: Subscription; // Subscription for data sending interval
  private idleTimeoutSubscription: Subscription; // Subscription for idle timeout
  private throttleTimeout: ReturnType<typeof setTimeout> | undefined; // Timeout for throttling rapid events

  // Event targets and their corresponding active/inactive events
  private readonly targets = {
    window: {
      active: ['focus', 'scroll', 'resize', 'load'],
      inactive: ['blur', 'unload', 'beforeunload']
    },
    document: {
      active: ['mousemove', 'click', 'keydown', 'touchstart', 'touchmove'],
      inactive: ['mouseleave', 'touchend', 'touchcancel', 'visibilitychange']
    }
  };

  // Flattened list of event handlers for active and inactive states
  private readonly events = Object.entries(this.targets).flatMap(([targetName, eventTypes]) => {
    const target = targetName === 'window' ? window : document;
    return Object.entries(eventTypes).flatMap(([state, types]) => {
      const handler = state === 'active' ? this.handleActiveEvent.bind(this) : this.handleInactiveEvent.bind(this);
      return types.map(type => ({ target, type, handler }));
    });
  });

  constructor() {}

  // Getter and setter for moduleName
  get modulename(): string {
    return this.moduleName;
  }

  set modulename(value: string) {
    this.moduleName = value;
  }

  // Handle active events
  private handleActiveEvent(): void {
    if (this.throttleTimeout) {
      clearTimeout(this.throttleTimeout);
    }
    this.throttleTimeout = setTimeout(() => {
      if (document.hasFocus() && !this.isPageHidden()) {
        if (!this.isActive) {
          this.startTimer();
        }
        this.updateActiveTime();
        this.resetIdleTimer();
      }
    }, THROTTLE_TIMEOUT);
  }

  // Handle inactive events
  private handleInactiveEvent(): void {
    if (this.isActive && (this.isPageHidden() || !document.hasFocus())) {
      this.isActive = false;
      this.clearIdleTimer();
    }
  }

  // Check if the page is hidden
  private isPageHidden(): boolean {
    return document.hidden || document.visibilityState !== 'visible';
  }

  // Start tracking by adding event listeners and starting the timer
  startTracking(): void {
    this.handleEventListeners('add');
    this.startTimer();
  }

  // Stop tracking by removing event listeners and stopping the timer
  stopTracking(): void {
    this.stopTimer();
    this.handleEventListeners('remove');
  }

  // Start the active time tracking timer
  private startTimer(): void {
    if (!this.isActive) {
      this.isActive = true;
      this.lastUpdateTime = performance.now();
      this.startDataSendInterval();
      this.resetIdleTimer();
    }
  }

  // Stop the active time tracking timer
  private stopTimer(): void {
    this.isActive = false;
    this.clearIntervals();
    this.clearIdleTimer();
    clearTimeout(this.throttleTimeout);
    this.sendData();
  }

  // Update the active time based on the elapsed time since the last update
  private updateActiveTime(): void {
    const now = performance.now();
    if (this.lastUpdateTime !== null && this.isActive) {
      let elapsedMilliseconds = now - this.lastUpdateTime;

      // Cap the elapsed time to prevent over-accumulation
      if (this.activeTimeSeconds * 1000 + elapsedMilliseconds > MAX_ACCUMULATED_TIME) {
        elapsedMilliseconds = MAX_ACCUMULATED_TIME - (this.activeTimeSeconds * 1000);
      }

      const elapsedSeconds = elapsedMilliseconds / 1000;
      this.activeTimeSeconds += elapsedSeconds;
    }
    this.lastUpdateTime = now;
  }

  // Send the accumulated active time data to the parent window
  private sendData(): void {
    const timespentSeconds = Math.min(Math.round(this.activeTimeSeconds), Math.round(MAX_ACCUMULATED_TIME / 1000));
    const payload = {
      uniqueHash: "a1aa9802656697a0903a39f076d383d43a25e39140fe391af373766181749c7f",
      moduleName: this.modulename,
      status: true,
      data: {
        timespent: timespentSeconds,
        unit: "seconds"
      }
    };
    console.log(`Sending data: ${JSON.stringify(payload)}`);
    window.parent.postMessage(JSON.stringify(payload), '*');
    this.activeTimeSeconds = Math.max(0, this.activeTimeSeconds - timespentSeconds); // Reset active time after sending
  }

  // Start the interval for periodically sending data
  private startDataSendInterval(): void {
    if (!this.dataSendSubscription) {
      this.dataSendSubscription = interval(DATA_SEND_INTERVAL).subscribe(() => {
        this.sendData();
      });
    }
  }

  // Clear the data send interval
  private clearIntervals(): void {
    if (this.dataSendSubscription) {
      this.dataSendSubscription.unsubscribe();
      this.dataSendSubscription = undefined;
    }
  }

  // Reset the idle timer
  private resetIdleTimer(): void {
    this.clearIdleTimer();
    if (this.isActive) {
      this.idleTimeoutSubscription = timer(IDLE_TIMEOUT).subscribe(() => {
        this.isActive = false;
        this.sendData(); // Send data before going idle
      });
    }
  }

  // Clear the idle timer
  private clearIdleTimer(): void {
    if (this.idleTimeoutSubscription) {
      this.idleTimeoutSubscription.unsubscribe();
      this.idleTimeoutSubscription = undefined;
    }
  }

  // Add or remove event listeners for active and inactive events
  public handleEventListeners(action: 'add' | 'remove'): void {
    this.events.forEach(({ target, type, handler }) => {
      action === 'add' ? target.addEventListener(type, handler) : target.removeEventListener(type, handler);
    });
  }
}