import { createAction, props } from "@ngrx/store";

export enum ELayout {
  SHOW_LAYOUT = '[LAYOUT] SHOW LAYOUT',
  SHOW_SPINNER = '[LAYOUT] SHOW SPINNER',
  SHOW_SIDEPANEL = '[LAYOUT] SHOW SIDEPANEL'
}

export const showLayout = createAction(ELayout.SHOW_LAYOUT, props<{value: boolean}>());
export const showSpinner = createAction(ELayout.SHOW_SPINNER, props<{value: boolean}>());

export const showSidePanel = createAction(ELayout.SHOW_SIDEPANEL, props<{value: boolean}>());
