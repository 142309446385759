import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { modalButtonAction } from 'src/app/shared/models/modal';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { Store } from '@ngrx/store';
import { TimeTrackingService } from 'src/app/shared/services/time-tracking.service';

@Component({
  selector: 'app-application-creator',
  templateUrl: './application-creator.component.html',
  styleUrls: ['./application-creator.component.scss'],
})
export class ApplicationCreatorComponent implements OnInit {
  currentStep: number = 1;
  applicationIdentifier!: string;
  selectedSensors: any[] = [];
  controlPanel: any[] = [];
  selectedPanelSensors: any[] = [];
  moduleKey!: string;
  currentStepName!: string;
  allSteps!: any;
  isAdmin: boolean =
    window.location.href.includes('/admin/template') ||
    window.location.href.includes('/admin/application');
  isTemplate: boolean = window.location.href.includes('/template');
  isEdit: boolean = window.location.href.includes('/edit');
  selectedApplication!: any;
  appData: any;
  isSwitchToBlock: boolean = false;
  isBlockSwitch: boolean = false;
  isPythonSwitch: boolean = false;
  isSaveAndExitInitiated: boolean = false;
  isDiscardInitiated: boolean = false;
  isUnsavedChangesPresent: boolean = false;
  isModalOpen: boolean = false;
  modalActions: modalButtonAction[] = [
    {
      display: 'Save and Exit',
      class: 'blue',
      method: this.onModalSaveAndExit,
    },
    {
      display: 'Discard',
      class: 'red',
      method: this.onModalDiscard,
    },
    {
      display: 'Cancel',
      class: 'gray',
      method: this.modalClose,
    },
  ];
  mdActionForInterface: modalButtonAction[] = [
    {
      display: 'Switch',
      class: 'blue',
      method: this.onSwitchToBlock,
    },
    {
      display: 'Cancel',
      class: 'gray',
      method: this.onModalClose,
    },
  ];
  global: any;
  switchType: any;
  isNotProgramType: boolean = false;
  isSimulatorBack: boolean = false;

  blockGeneratedCode: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private applicationTemplateService: ApplicationService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService,
    private location: Location,
    private timeTrackingService: TimeTrackingService
  ) {}

  async ngOnInit() {
    this.timeTrackingService.startTracking();
    if (this.isEdit) {
      const routeParams = this.route.snapshot.paramMap;
      this.applicationIdentifier = String(
        routeParams.get('application_identifier')
      );
      this.getApplicationData(this.applicationIdentifier);
    } else {
      this.getProgramInterface();
    }
    await this.userSessionService
      .extendToken(this.sessionStorageService.getUsername())
      .toPromise();
  }

  ngOnDestroy(): void {
    this.timeTrackingService.stopTracking();
  }

  updateAllSteps(steps: any) {
    this.allSteps = steps;
  }

  private updateStepNameAndCount() {
    this.currentStep = this.currentStep + 1;
    this.currentStepName = this.allSteps.find(
      (item: any) => item.index === this.currentStep
    ).title;
    this.updateAddressBarURL();
    if (this.currentStep == 1) {
      this.refreshApplication();
    }
    if (this.currentStep === 4) {
      this.setSwitchData(this.selectedApplication.programmingType);
    }
    if (this.currentStep === 5) {
      this.isBlockSwitch = false;
      this.isPythonSwitch = false;
      delete this.blockGeneratedCode;
    }
  }

  private async getApplicationData(application_identifier: string) {
    let data: any;
    try {
      if (this.isTemplate) {
        data = await this.applicationTemplateService.getApplicationTemplateV3(
          application_identifier
        );
      } else {
        data = await this.applicationTemplateService
          .getApplication(application_identifier)
          .toPromise();
      }
      // if (this.isAdmin) {
      //   data = await this.applicationTemplateService.getApplicationTemplateV3(application_identifier);
      // } else {
      //   data = await this.applicationTemplateService.getApplication(application_identifier).toPromise();
      // }
      if (data?.Application) {
        data.Application['application_identifier'] = application_identifier;
        this.selectedApplication = data.Application;
        this.moduleKey = this.selectedApplication.moduleName;
        this.getProgramInterface(this.selectedApplication);
      }
    } catch (error) {
      console.log(error);
    }
  }

  createBasicAppResponse(data: any) {
    this.moduleKey = data?.moduleData?.key;
    this.timeTrackingService.modulename = data?.moduleData?.display;
    if (data.applicationIdentifier) {
      this.applicationIdentifier = data.applicationIdentifier;
      if (['data_science', 'artificial_intelligence'].includes(this.moduleKey)) {
        // only for data science and artificial intelligence module in which selected application data not updating so getting data again
        this.getApplicationData(this.applicationIdentifier);
      }
      this.updateStepNameAndCount();
    }
  }

  setSensors(sensors: any) {
    this.selectedSensors = sensors;
    this.updateSensorsInConfig(sensors.ids);
    this.updateStepNameAndCount();
  }

  updateSensorsInConfig(sensorIds: string[]) {
    if (!this.selectedApplication) {
      this.selectedApplication = {};
    }
    if (!this.selectedApplication?.configurationDefinition) {
      this.selectedApplication.configurationDefinition = '{}';
    }
    let configurationDefinition =
      this.selectedApplication.configurationDefinition;
    configurationDefinition = JSON.parse(configurationDefinition);
    configurationDefinition.sensors = sensorIds;
    this.selectedApplication.configurationDefinition = JSON.stringify(
      configurationDefinition
    );
  }

  panelData(panelItems: any) {
    this.selectedPanelSensors = panelItems;
  }

  onBackPressed(step: any) {
    if (step === 5) {
      this.isNotProgramType = false;
    }
    this.currentStep = this.currentStep - 2;
    this.updateStepNameAndCount();
  }

  controlPanelSaved(controlPanel: any) {
    this.controlPanel = controlPanel;
  }

  circuitSaved(event: any) {
    if (event) {
      this.updateStepNameAndCount();
    }
  }

  refreshApplication() {
    this.getApplicationData(this.applicationIdentifier);
  }

  updateAddressBarURL() {
    if (!!this.isAdmin && !!this.isTemplate && !!this.applicationIdentifier) {
      let platformType = this.isAdmin ? 'admin' : 'ide';
      let solutionType = this.isTemplate ? 'template' : 'application';
      this.location.replaceState(
        `/${platformType}/${solutionType}/edit/${this.applicationIdentifier}`
      );
      this.isEdit = true;
    }
  }

  blockSaved(event: any) {
    if (event) {
      if (['iot', 'aiot', 'iiot'].includes(this.moduleKey)) {
        this.updateStepNameAndCount();
      } else {
        this.openApplicationsList();
      }
    } else {
      this.updateStepNameAndCount();
    }
  }

  simulatorSaved(event: any) {
    if (event) {
      this.openApplicationsList();
    }
  }
  //to get apllication data from create basic for sensor filter with module name
  getAppData(event: any) {
    this.appData = event;
  }

  onUnsavedChanges(flag: boolean): void {
    this.isUnsavedChangesPresent = flag;
  }

  onExitPressed(event: any) {
    if (this.isUnsavedChangesPresent === true) {
      this.isModalOpen = true;
      this.global = this;
    } else {
      this.openApplicationsList();
    }
  }

  modalClose(_this: ApplicationCreatorComponent): void {
    _this.isModalOpen = false;
    _this.isSwitchToBlock = false;
  }

  openApplicationsList(_this?: ApplicationCreatorComponent): void {
    if (!_this) {
      _this = this;
    }
    _this.currentStep = _this.currentStep + 1;
    let redirectUrl = `/${_this.isAdmin ? 'admin' : 'ide'}/applications`;
    if (this.moduleKey.length > 0 && !_this.isAdmin) {
      redirectUrl += "?moduleName=" + this.moduleKey;
    }
    _this.router.navigateByUrl(redirectUrl);
  }

  actionExecutor([method, classObject]: any): void {
    method(classObject);
  }

  //get programming type if existing & show interface as type
  //if programming type not present then show selct interface screen
  getProgramInterface(selectedApp?: any, backstep?: any) {
    if (
      this.currentStep === 4 ||
      backstep === 5 ||
      ['data_science', 'artificial_intelligence'].includes(selectedApp?.moduleName)
    ) {
      if (selectedApp['programmingType'] === 'Block') {
        this.openInterFace('block');
        this.isNotProgramType = false;
      } else if (selectedApp['programmingType'] === 'Python') {
        this.openInterFace('python-direct');
        this.isNotProgramType = false;
      } else {
        this.isNotProgramType = true;
      }
    } else {
      this.isNotProgramType = true;
    }
  }

  //To choose interface for create program
  openInterFace(step: string) {
    if (step === 'block') {
      this.isBlockSwitch=true;
      this.isPythonSwitch = false;
      this.updateApplication('Block');
    }
    else if (step === 'python-direct') {
      this.isPythonSwitch=true;
      this.isBlockSwitch=false;
      this.updateApplication('Python');
    }
  }

  //update application with programming type which one is choosen
  async updateApplication(type: string) {
    let payload: any = {};
    let applicationData = { ...this.selectedApplication };
    // this.applicationData = { ...this.selectedApplication };
    if (applicationData?.hasOwnProperty('configurationDefinition')) {
      delete applicationData['configurationDefinition'];
    }
    if (applicationData?.hasOwnProperty('applicationDefinition')) {
      delete applicationData['applicationDefinition'];
    }
    if (applicationData?.hasOwnProperty('toolboxDefinition')) {
      delete applicationData['toolboxDefinition'];
    }
    if (applicationData?.hasOwnProperty('simulatorDefinition')) {
      delete applicationData['simulatorDefinition'];
    }
    if (applicationData.hasOwnProperty('pythonInterfaceDefinition_url')) {
      delete applicationData['pythonInterfaceDefinition_url'];
    }
    payload = applicationData;
    payload['programmingType'] = type;
    this.selectedApplication.programmingType = type;
    try {
      if (this.isTemplate) {
        let result = await this.applicationTemplateService
          .updateApplicationTemp(payload, this.applicationIdentifier)
          .toPromise();
      } else {
        let result = await this.applicationTemplateService
          .updateApplication(payload, this.applicationIdentifier)
          .toPromise();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // switch to python or block interface on selection of image
  onSwitchToBlock(_this: ApplicationCreatorComponent) {
    const programmingType =
      _this.switchType === 'python-direct' ? 'Python' : 'Block';
    _this.setSwitchData(programmingType);
    _this.updateApplication(programmingType);
    _this.isSwitchToBlock = false;
    _this.isModalOpen = false;
  }

  setSwitchData(data: string): void {
    if (data === 'Python') {
      this.isPythonSwitch = true;
      this.isBlockSwitch = false;
    } else if (data === 'Block') {
      this.isBlockSwitch = true;
      this.isPythonSwitch = false;
    } else {
      this.isPythonSwitch = false;
      this.isBlockSwitch = false;
    }
  }

  //open switch to block warning popup or switch directly to block interface
  onPopupOpen(event: any) {
    if (event.data === 'switchToBlock') {
      this.isSwitchToBlock = true;
      this.global = this;
    } else {
      //switch to python direct interface and update application with programming type
      this.isPythonSwitch = true;
      this.isBlockSwitch = false;
      this.updateApplication('Python')
    }
  }

  //get generated code from blocks and pass to python direct interface
  getGeneratedCode(event: any) {
      this.blockGeneratedCode = event;
  }

  // On python code save pressed
  onPyCodeSaved(event: any) {
    if (event) {
      if (['iot', 'aiot', 'iiot'].includes(this.moduleKey)) {
        this.updateStepNameAndCount();
      } else {
        this.openApplicationsList();
      }
    } else {
      this.updateStepNameAndCount();
    }
  }

  // Modal Button Actions
  onModalDiscard(_this: ApplicationCreatorComponent) {
    // Discard auto-saved data
    _this.isDiscardInitiated = true;
  }

  onModalClose(_this: ApplicationCreatorComponent) {
    _this.isModalOpen = false;
    _this.isSwitchToBlock = false;
  }

  onModalSaveAndExit(_this: ApplicationCreatorComponent): void {
    _this.isSaveAndExitInitiated = true;
    _this.isModalOpen = false;
  }

  onBackGetPycode(event: any) {
    this.isSimulatorBack = event.isSimulatorBack?event.isSimulatorBack: false;
  }
}
