import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/index.state';
import { showLayout } from 'src/app/store/layout/layout.action';

@Injectable({
  providedIn: 'root'
})
export class IDEAuthGuard implements CanActivate {

  constructor(private router: Router, private store: Store<IAppState>) { }

  canActivate() {
    if (Permission.isActive()) {
      this.store.dispatch(showLayout({value: false}));
      return true
    }
    this.store.dispatch(showLayout({value: false}));
    this.router.navigateByUrl('/auth/login');
    return false;
  }
}

export class Permission {

  static isActive() {
    const hash = sessionStorage.getItem('hash');
    const ts = sessionStorage.getItem('ts');
    const app_context = sessionStorage.getItem('app_context');
    const affiliate_name = sessionStorage.getItem('af');
    const app_id = sessionStorage.getItem('app_id');
    const user = sessionStorage.getItem('user');
    return !!(app_context && hash && ts && user)
  }
}
