import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit, OnChanges {

  @Input() sensors: any = [];
  @Output() onSavePressed = new EventEmitter();
  @Output() onBackPressed = new EventEmitter();

  outputs: any[] = [];
  controlPanel: any[] = [];

  constructor(
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService) { }

  ngOnChanges(changes?: SimpleChanges) {
    const hasSensors = changes?.sensors?.currentValue;
    if (hasSensors) {
      const outputs = this.sensors.find((item: any) => item.title === 'OUTPUT');
      if (outputs) {
        this.outputs = outputs;
      }
    }
  }

  async ngOnInit() {
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  goBack(){
    this.onBackPressed.emit();
  }

}

@NgModule({
  declarations: [
    ControlPanelComponent
  ],
  imports: [ CommonModule ],
  exports: [
    ControlPanelComponent
  ],
})
export class ControlPanelModule {}
