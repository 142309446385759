import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { serachLab } from 'src/app/shared/models/lab';
import { IDEService } from 'src/app/shared/services/ide.service';
import { LabService } from 'src/app/shared/services/lab.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { OrganizationsService, Organization } from 'src/app/shared/services/organizations.service';
import {PAGE_NUMBER,PAGE_SIZE} from "src/app/shared/constants/utils.constants"
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-labs-list',
  templateUrl: './labs-list.component.html',
  styleUrls: ['./labs-list.component.scss']
})
export class LabsListComponent implements OnInit {

  list:any=[];
  filteredLabs:any;
  orgList: Organization[] = [];
  orgNames:any;
  selectedOrgName:any;
  isMenuOpen:boolean=false;
  selectedLab:any;
  isModalOpen:boolean=false;
  searchControl = new FormControl(null);
  organizationName:any;
  sortBy={
    LAB_NAME : "lab_name",
    ORG_NAME : "organization_name",
    CREATED_BY: "created_by",
    REGISTERED_DEVICES: "registered_devices_count"
  }
  currentUser:any;
  filteredValue:any;
  isSortCreated:boolean=true;
  isSortOrg:boolean=true;
  isSort:boolean=false;
  isSortLab:boolean=true;
  isSortRegisteredDevices: boolean = true;
  sortOrder=[{
    sortBy: '',
    sortOrder: ''
  }];
  currentOrganization!: string;
  isSuperAdmin:boolean=true;
  isGrokCloudMember: boolean = true;

  constructor(
    private router:Router,
    private toasterService: ToastrService,
    private labService:LabService,
    private sessionStorageService:SessionStorageService,
    private orgService: OrganizationsService
  ) { 
    this.setDefaultOrganization();
    this.isSuperAdmin = window.location.href.includes('/admin/');
  }

  setDefaultOrganization() {
    this.currentOrganization = this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization === environment.organizationID) { } 
    else {
      this.isGrokCloudMember = false;
      this.organizationName = this.currentOrganization;
    }
  }

  ngOnInit(): void {
    this.searchOrganizations();
    this.sortfilterColumn();
    this.currentUser=this.sessionStorageService.getUsername();
  }

  async searchOrganizations() {
    try {
      const result: any = await this.orgService.search().toPromise();
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }

  createLab(){
    if (this.isSuperAdmin) {
      this.router.navigateByUrl('/admin/create-lab')
    } else {
      this.router.navigateByUrl('/ide/create-lab')
    }
  }

  editLab(lab:any){
    if (this.isSuperAdmin) {
      this.router.navigateByUrl(`/admin/edit-lab/${lab.labIdentifier}`)
    } else {
      this.router.navigateByUrl(`/ide/edit-lab/${lab.labIdentifier}`)
    }
  }

  applyFilter(event: Event) {
		const filterValue = ((event.target as HTMLInputElement).value).toLowerCase();
    this.sortfilterColumn('','',filterValue,PAGE_SIZE,PAGE_NUMBER)
	}

  toggleMenu(){
    this.isMenuOpen=!this.isMenuOpen;
  }

  confirmAndDelete(item:any){
    this.selectedLab = item;
    this.isModalOpen = true;
  }

  async delete(event:any){
    console.log("event delte",this.selectedLab)
    const labId = this.selectedLab.labIdentifier;
    try {
      const result: any = await this.labService.deleteLab(labId).toPromise();
      if (result) {
        this.sortfilterColumn();
        this.isModalOpen = false;
        this.toasterService.success(`${this.selectedLab.labName} is deleted!`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  closeModal(event:any){
    this.selectedLab = null;
    this.isModalOpen = false;
  }

  onClickRegDevice(item:any){
    console.log("data",item)
  }

  changeOrderOrg(sortOrder:any){
    this.isSortOrg=!this.isSortOrg
    this.isSort=true;
    this.sortfilterColumn(this.sortBy.ORG_NAME,sortOrder)
  }

  changeOrderRegisteredDevices(sortOrder: any) {
    this.isSortRegisteredDevices = !this.isSortRegisteredDevices
    this.sortfilterColumn(this.sortBy.REGISTERED_DEVICES, sortOrder)
  }

  changeOrderlab(sortOrder:any){
    this.isSortLab=!this.isSortLab
    this.sortfilterColumn(this.sortBy.LAB_NAME,sortOrder)
  }

  changeOrderCrtby(sortOrder:any){
    this.isSortCreated=!this.isSortCreated;
    this.sortfilterColumn(this.sortBy.CREATED_BY,sortOrder)
  }

  onChange(event:any){
    if (event.target.value != "") {
      this.organizationName = event.target.value;
    } else { 
      this.organizationName = null;
    }
    this.sortfilterColumn();
  }

  sortfilterColumn(sortByName?:any,sortOrder?:any,value?:any,pageSize?:number,pageNumber?:number){
    const getlabs: any = {} as serachLab;
    getlabs.pageSize=Number(pageSize?pageSize:PAGE_SIZE);
    getlabs.pageNumber=pageNumber?pageNumber:PAGE_NUMBER;
    getlabs.orgName=this.organizationName ? this.organizationName:null;
    getlabs.searchString=value ? value : this.searchControl.value;
    getlabs.orderBy= [
        {
        sortBy: sortByName?sortByName:"lab_name",
        sortOrder: sortOrder?sortOrder:"DESC"
        }
    ]
    this.labService.search(getlabs).subscribe((response:any)=>{
      this.list=response.labs;
      this.filteredLabs=this.list;
    })
  }

}
