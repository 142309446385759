import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  EventEmitter,
  Output
} from "@angular/core";
import { KonvaUtils, CircuitLoadOptions } from "../../utils/konva.utils";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { UserSessionService } from "../../services/user.session.service";
import { SessionStorageService } from "../../services/session-storage.service";

@Component({
  selector: "app-draggable-prebuild-circuit",
  templateUrl: "./draggable-prebuild-circuit.component.html",
  styleUrls: ["./draggable-prebuild-circuit.component.scss"],
})
export class DraggablePrebuildCircuitComponent implements OnInit, OnChanges {
  @Input() loadExistingCircuitJSON!: JSON;
  @Input() element!: any;
  @Input() zoom = false;
  @Input() isAdmin = true;
  @Input() isFullCircuit!: boolean;
  @Input() isMenuOpen!: boolean;
  @Output() emitWindowSize = new EventEmitter();
  konvaUtils!: KonvaUtils;


  constructor(private store: Store<IAppState>,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.konvaUtils) {
      this.konvaUtils = new KonvaUtils(this.store, null, `${DraggablePrebuildCircuitComponent.name}-${this.ngOnChanges.name}`);
    }
    const hasJSONValue = changes?.loadExistingCircuitJSON?.currentValue;
    if (hasJSONValue) {
      this.loadExisting();
    }
  }

  async ngOnInit() {
    this.loadExisting();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  private loadExisting(): void {
    this.konvaUtils.loadExisting(
      this.loadExistingCircuitJSON,
      CircuitLoadOptions.DRAGGABLE,
      DraggablePrebuildCircuitComponent.name
    );
  }

  controlLogic(action: string): void {
    switch (action) {
      case "zoomIn":
        this.konvaUtils.zoomInOut({ action: 'zoomin', areaName: 'pre-build' });
        break;
      case "zoomOut":
        this.konvaUtils.zoomInOut({ action: 'zoomout', areaName: 'pre-build' });
        break;
      case "maximize":
        this.emitWindowSize.emit({ action: 'minimize', areaName: 'workspace' });
        break;
    }
  }
}
