import { Target, Wire } from "src/app/store/circuit-design/c-d.state";

export interface circuitDefinition {
  className: string;
  attrs: circuitAttrs,
  children: circuitDefinitionChildren[];
  targets: Target[];
  wires: Wire[];
}

interface circuitAttrs {
  id?: string,
  customName?: string,
  title?: string,
  draggable?: boolean,
  height?: number,
  width?: number,
  x: number,
  y: number
}

interface circuitDefinitionChildren {
  attrs: circuitAttrs,
  children: circuitDefinitionChildren[],
  className: string
}

export class undoableCircuitData {
  pinsInUse: any[] = [];
  children: any = [];
}