import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';
import { CircuitBuilderComponent } from './circuit-builder.component';
import { PrebuildCircuitModule } from '../../../shared/components/prebuild-circuit/prebuild-circuit.module';
import { DraggablePrebuildCircuitModule } from '../../../shared/components/draggable-prebuild-circuit/draggable-prebuild-circuit.module';
import { WorkspaceModule } from 'src/app/shared/components/workspace/workspace.module';
import { IotDevicesSectionModule } from 'src/app/shared/components/iot-devices-section/iot-devices-section.module';
import { JoyrideModule } from 'ngx-joyride';
import { SidePanelModule } from 'src/app/components/side-panel/side-panel.module';
import { InfoPanelModule } from 'src/app/shared/components/info-panel/info-panel.module'

@NgModule({
  declarations: [
    CircuitBuilderComponent
  ],
  imports: [
    CommonModule,
    AngularSplitModule,
    PrebuildCircuitModule,
    DraggablePrebuildCircuitModule,
    WorkspaceModule,
    IotDevicesSectionModule,
    SidePanelModule,
    InfoPanelModule,
    JoyrideModule.forRoot(),
  ],
  exports: [
    CircuitBuilderComponent
  ]
})
export class CircuitBuilderModule { }
